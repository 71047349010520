import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { FC }           from 'react';
import styled                  from 'styled-components';
import { TThemeColor }         from '../../../styles/theme';

interface IProps extends ButtonProps {
  label?: string;
  margin?: string;
  minwidth?: string;
  wrap?: string;
  bgcolor?: TThemeColor;
  textcolor?: TThemeColor;
  onClick?: (props?: any) => void;
}

const StyledButton = styled(Button)`&& {
  overflow: hidden;

  ${ ({ wrap }: any): string => `white-space: ${ wrap ? 'wrap' : 'nowrap' }` };
  ${ ({ minwidth }: any): string => minwidth && `min-width: ${ minwidth }` };
  ${ ({ margin }: any): string => margin && `margin: ${ margin }` };
  
  ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
    font-size: 0.8em;
  }

  color: ${ ({ textcolor, theme: { colors } }: any): false | string => (
    !!textcolor && colors[textcolor]
  ) };

  background-color: ${ ({ bgcolor, theme: { colors } }: any): false | string => (
    !!bgcolor && colors[bgcolor]
  ) };

  .MuiSvgIcon-root {
      margin-right: 6px;
      MuiSvgIcon: {
        fill: '#6f90d9',
      },
  }
}` as any;

export const MuiButton: FC<IProps> = (props) => (
  <StyledButton {...props}>
    { props.children || props.label }
  </StyledButton>
);

MuiButton.defaultProps = {
  color         : 'primary',
  variant       : 'contained',
  disableRipple : true,
};
