import * as Yup from 'yup';

export const addEventFormSchema = Yup.object().shape({
  title              : Yup.string().required('Event Title is required'),
  assigned           : Yup.array().min(1, 'Event has to be assigned').of(Yup.string()).required('Event has to be assigned').nullable(),
  assignedPermission : Yup.string().required('Assigned permission is required'),
  description        : Yup.string().nullable(),
  tagged             : Yup.array().of(Yup.string()).nullable(),
  categoryId         : Yup.string().required('Category is required'),
  recursStartDate    : Yup.string().required('Date is required'),
});
