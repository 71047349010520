import React, { FC }                                from 'react';
import { useFetchSelectedFamily }                   from '../../../../../shared/components/SelectFamily/queries';
import { useFetchFamilyUser }                       from '../../../../../shared/queries/family';
import { useGetUserAccount, useUpdateAboutProfile } from '../../../../../shared/queries/user';
import { EFamilyAccountType }                       from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { AboutMeForm }                              from './AboutMeForm';

export const AboutMePage: FC = () => {
  const { mutate: updateProfile, isLoading } = useUpdateAboutProfile();
  const { data: aboutMe } = useGetUserAccount();
  const { data: family } = useFetchSelectedFamily();
  const { data: user } = useFetchFamilyUser(family?.id);

  return (
    <AboutMeForm
      isDisabled={!user?.editAboutMe}
      data={aboutMe}
      isLoading={isLoading}
      onUpdate={updateProfile}
    />
  );
};
