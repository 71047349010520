import { Grid }                             from '@material-ui/core';
import React, { FC, Fragment }              from 'react';
import styled                               from 'styled-components';
import { GridDivider }                      from '../../../shared/components/layout/GridDivider';
import { SwitchField }                      from '../../../shared/formFields/SwitchField';
import { OptionDescription, SpacedDivider } from '../NotificationPreferences';
import { SectionHeader }                    from './PlatformSelector';

export const Subsection = styled.div`
  padding: 30px 8px 0;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    padding: 30px 0 0 18px;
  }
`;

const SpacedSwitchField = styled(SwitchField)`
  margin-bottom: 20px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 0;
  }

  ${ ({ disabled }) => disabled && `
  .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
    background-color: #f7f7f7 !important;
  }

  .Component-thumb-9 {
    border: 3px solid #f7f7f7 !important;
  }

  .MuiSwitch-thumb {
    border: 3px solid #f7f7f7 !important;
    background-color: #bdbdbd !important;
  }

  .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 1!important;
    background-color: #f7f7f7 !important;
    border: 3px solid #f7f7f7 !important;
  }
  `}
`;

const ToggleDescription = styled(Grid)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ToggleContainerGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface IToggleSectionProps {
  title: string;
  subtitle: React.ReactNode;
  data: Array<{ name: string; title: string; subtitle?: string; onChange?: (value: boolean) => void }>;
  disabled?: boolean;
  onChange? : (field: any, meta: any, helpers: any) => void;
}

export const ToggleSection: FC<IToggleSectionProps> = ({ title, subtitle, data, disabled, onChange }) => (
  <>
    <Grid item xs={12}>
      <SectionHeader>{ title }</SectionHeader>

      { subtitle && (
        <OptionDescription>{ subtitle }</OptionDescription>
      ) }
    </Grid>

    <Grid item xs={12}>
      <Subsection>
        { data?.map((item, index) => (
          <Fragment key={index}>
            <Grid container spacing={3}>
              <ToggleDescription
                item
                xs={9}
                lg={10}
              >
                <SectionHeader dark>{ item.title }</SectionHeader>

                { item?.subtitle && (
                  <OptionDescription>
                    { item?.subtitle }
                  </OptionDescription>
                ) }
              </ToggleDescription>

              <ToggleContainerGrid
                item
                xs={3}
                lg={2}
              >
                <SpacedSwitchField name={item.name} disabled={disabled} onChange={item.onChange} />
              </ToggleContainerGrid>
            </Grid>

            { index !== data.length - 1 && <GridDivider margin="21px 0" /> }
          </Fragment>
        )) }
      </Subsection>
    </Grid>

    <SpacedDivider />
  </>
);
