import { useEffect, useState } from 'react';
import differenceBy            from 'lodash/differenceBy';

import { HubConnection } from '@microsoft/signalr';

import { setupHubConnection }                 from '../Header';
import { IFamily }                            from '../../Auth/shared/interfaces';
import { IChat, IMessage, useFetchChatsList } from '../../modules/MessageCenter/queries';
import { useFetchFamilyUser }                 from '../../shared/queries/family';
import { getAuthToken }                       from '../../utils/authHelpers';
import { usePrevious }                        from './usePreviousValue';

interface IChatHubConnectionHookArgs {
  family?          : IFamily;
  onReceiveMessage : (message: IMessage) => void;
}

export const useChatHubConnection = ({
  family,
  onReceiveMessage,
}: IChatHubConnectionHookArgs) => {
  const authToken = getAuthToken();

  const [chatHub, setChatHub] = useState<HubConnection>();

  const { data: user }      = useFetchFamilyUser(family?.id);
  const { data: chatRooms } = useFetchChatsList(user?.id);

  const prevChatRooms    = usePrevious(chatRooms) ?? [];
  const getLastChatRooms = differenceBy(chatRooms, prevChatRooms, 'id');

  const setupChatHub = () => {
    const hubConnection = setupHubConnection('/hubs/chat', authToken.accessToken);

    setChatHub(hubConnection);
    console.log('Chat hub connection is ready.');
  };

  const joinRooms = async (rooms: IChat[], userId: string) => {
    if (!chatHub) {
      throw new Error('Cannot join rooms, chat hub connection is not found.');
    }

    chatHub.on('ReceiveMessage', (message: IMessage) => onReceiveMessage(message));
    chatHub.on('UserAdded', (message: IMessage) => onReceiveMessage(message));
    chatHub.on('UserDeleted', (message: IMessage) => onReceiveMessage(message));
    console.log('Subscribed on ReceiveMessage, UserAdded, UserDeleted events.');

    chatHub.onclose(() => {
      setChatHub(undefined);
      console.log('Set chat hub connection to undefined.');
    });

    console.log('Launching the chat hub connection.');
    await chatHub.start();
    await Promise.all(rooms.map((room) => {
      console.log(`Joining room ${room.id}.`);

      return chatHub.invoke('JoinRoom', room.id, userId);
    }));
    console.log('Rooms are joined.');
  };

  useEffect(() => {
    if (chatRooms && chatRooms.length && user?.id && !chatHub) {
      setupChatHub();
    }
  }, [chatRooms, user, chatHub]);

  useEffect(() => {
    if (getLastChatRooms && getLastChatRooms.length && user?.id && chatHub) {
      chatHub.invoke('JoinRoom', getLastChatRooms[0].id, user.id);
    }
  }, [getLastChatRooms]);

  useEffect(() => {
    if (chatRooms && chatRooms.length && user?.id && chatHub) {
      joinRooms(chatRooms, user.id);
    }

    return () => {
      if (chatHub) {
        chatHub.stop()
          .then(() => console.log('Chat hub connection was stopped.'))
          .catch((error) => console.error(error));
      }
    };
  }, [chatHub]);
};
