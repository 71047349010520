import { Button, Grid, InputLabel, Typography }            from '@material-ui/core';
import { FastField, Form, Formik, FormikProps }            from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Link,
  NavLink,
  useHistory,
}                                                          from 'react-router-dom';
import styled                                              from 'styled-components';
import { GridDivider }                                     from '../../../../../shared/components/layout/GridDivider';
import {
  useFetchSelectedFamily,
  useUpdateSelectedFamily,
}                                                          from '../../../../../shared/components/SelectFamily/queries';
import {
  EAppRoutes,
  EAuthRoutes,
  familyCoreConfig,
}                                                          from '../../../../../shared/constants/constants';
import { timeZones }                                       from '../../../../../shared/constants/timeZones';
import { FamilyMembersSelector }                           from '../../../../../shared/formFields/FamilyMembersSelector';
import { SelectField }                                     from '../../../../../shared/formFields/SelectField';
import {
  SwitchComponent,
  SwitchField,
}                                                          from '../../../../../shared/formFields/SwitchField';
import {
  isNative,
}                                                          from '../../../../../shared/utils/platform';
import {
  useFetchFamilies,
  useFetchFamilyMembers,
  useFetchMemberAccount,
  useUpdateMemberAccount,
}                                                          from '../../../../../shared/queries/family';
import { useFetchCurrentUserProfile }                      from '../../../../../shared/queries/user';
import {
  FlexEndGrid,
  FormAction,
  FormSpacing,
  PageHeaderContainer,
  TextBrand,
  TextLabel,
}                                                          from '../../../../../shared/styledComponents';
import { formatDate, generateInitials }                    from '../../../../../shared/utils/commonFunctions';
import { logout }                                          from '../../../../../shared/utils/setAuthorizationToken';
import { FamilyMemberCircle }                              from '../../../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { DeleteUserDialog }                                from '../../../shared/DeleteUserDialog';
import { FormFooterActions }                               from '../../../shared/FormFooterActions';
import { IMemberAccountRequest }                           from '../../ManageFamilyAccount/ManageAccountForm/validation';
import { useDeleteUserAccount }                            from '../../ManageUsers/Account/queries';
import {
  CHANGE_MEMBER_EMAIL_ROUTE,
  CHANGE_MEMBER_PASSWORD_ROUTE,
  CHANGE_MEMBER_PIN_ROUTE,
}                                                          from '../ManageMemberAccount';
import { memberAccountValidationSchema }                   from './validation';

const Divider = () => <GridDivider margin="5px 0" />;

const FamilyName = styled.div`
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  flex-direction: row;

  & > span {
    margin-right: 15px;
  }
`;

const CreateFamilyLink = styled(NavLink)`
  font-size: 20px;
  color: #2c3f69;
  letter-spacing: 0.4px;
`;

export const TextSubscription = styled.span<{ mb?: number }>`
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: #e4572e;
  margin-bottom: ${ ({ mb }) => mb || 0 }px;
  margin-top: 5px;
`;

export interface IFamilyUserPermissions {
  chatAccess: boolean;
  viewAboutMe: boolean;
  taggedItemsOption: string;
  geoLocationAccess: string[];
  aboutMeAccess: string[];
}

export const ManageMemberAccountForm: FC = () => {
  const formikRef = useRef<FormikProps<IMemberAccountRequest>>(null);
  const { data: family } = useFetchSelectedFamily();
  const { data: currentUser } = useFetchCurrentUserProfile(family?.id);
  const { data: familyAccount, isLoading: loadingFamilyAccount } = useFetchMemberAccount(family?.id);
  const { mutate: updateMemberAccount, isLoading: updating } = useUpdateMemberAccount();
  const [ closeAccountDialogOpen, setCloseAccountDialogOpen ] = useState(false);
  const { mutate: deleteUserAccount, isSuccess: deleteUserSuccess } = useDeleteUserAccount(currentUser?.familyUserId, family?.id);
  const { data: families } = useFetchFamilies();
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily();
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const history = useHistory();

  const initialValues = {
    id                : family?.id,
    timeZoneOffset    : familyAccount?.timeZoneOffset,
    chatAccess        : familyAccount?.chatAccess,
    viewAboutMe       : familyAccount?.viewAboutMe,
    geoLocationAccess : familyAccount?.geoLocationAccess,
  };

  const toggleGroupGeoLocationAccess = (geoLocationAccess: string[]) => {
    const { setFieldValue, setFieldTouched } = formikRef.current as FormikProps<IMemberAccountRequest>;

    if (!!geoLocationAccess?.length) {
      setFieldValue('geoLocationAccess', []);
    } else {
      setFieldValue('geoLocationAccess', familyMembers?.map(member => member.id));
    }

    setFieldTouched('geoLocationAccess', true);
  };

  const onCloseAccountSubmit = () => {
    deleteUserAccount();
    setCloseAccountDialogOpen(false);
  };

  const [plannedDeletionDate, setPlannedDeletionDate] = useState('');

  useEffect(() => {
    families?.forEach((userFamily) => {
      if (userFamily.plannedDeletionDate && userFamily.id === family?.id) {
        setPlannedDeletionDate(userFamily.plannedDeletionDate);
      } else if (userFamily.plannedDeletionDate === null && userFamily.id === family?.id){
        setPlannedDeletionDate('');
      }
    });
  }, [families]);

  useEffect(() => {
    if (deleteUserSuccess) {
      if (families && families.length > 1) {
        const newFamily = families.filter(iteratedFamily => iteratedFamily.id !== family?.id);
        updateSelectedFamily(newFamily[0]?.id);
        history.push(EAppRoutes.MyProfile);
      } else {
        logout();
      }
    }
  }, [ deleteUserSuccess ]);

  const FormContent = ({ dirty, values }: FormikProps<IMemberAccountRequest>) => useMemo(() =>
    (<Form>
      <PageHeaderContainer>
        <Typography variant="h3">{ family?.name ? `${ family?.name } Family` : '' }</Typography>
      </PageHeaderContainer>

      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={6}
            sm={5}
          >
            <FamilyName>
              <FamilyMemberCircle
                color={currentUser?.color}
                initials={generateInitials(family?.name, 'F')}
              />

              <span>
                { family?.name } Family
              </span>
            </FamilyName>
          </Grid>

          {(isNative) && (
            <FlexEndGrid
              item
              xs={6}
              sm={7}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setCloseAccountDialogOpen(true)}
              >
                Close Account
              </Button>
            </FlexEndGrid>
          )}
          <Divider />

          <Grid
            item
            xs={12}
          >
            <TextBrand mb={-5}>Account Settings</TextBrand>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={5}
            xl={3}
          >
            <FastField
              label="Time Zone"
              name={'timeZoneOffset'}
              options={timeZones}
              component={SelectField}
            />
          </Grid>

          <Divider />

          <Grid
            item
            xs={12}
          >
            <TextBrand mb={-5}>Login Information</TextBrand>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            xl={4}
          >
            <InputLabel>Email</InputLabel>

            <FormAction>
              { currentUser?.email }

              <Link to={CHANGE_MEMBER_EMAIL_ROUTE}>Update</Link>
            </FormAction>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            xl={4}
          >
            <InputLabel>Password</InputLabel>

            <FormAction>
              ••••••••

              <Link to={CHANGE_MEMBER_PASSWORD_ROUTE}>Update</Link>
            </FormAction>
          </Grid>

          <Divider />

          <Grid
            item
            xs={12}
            sm={6}
            xl={4}
          >
            <TextBrand mb={15}>Account Pin</TextBrand>

            <FormAction>
              ••••

              <Link to={CHANGE_MEMBER_PIN_ROUTE}>Update</Link>
            </FormAction>
          </Grid>

          {(!isNative) && (
            <Grid
              item
              xs={12}
              sm={6}
              xl={6}
            >
              <TextBrand mb={10}>Manage Account</TextBrand>

              <Button
                color="primary"
                variant="outlined"
                onClick={() => setCloseAccountDialogOpen(true)}
              >
                Close account
              </Button>
            </Grid>
          )}

          <Divider />

          <Grid
            item
            xs={12}
          >
            <TextBrand mb={15}>Permissions</TextBrand>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              { familyCoreConfig.isGeolocationEnabled && (
                <>
                  <Grid
                    item
                    xs={8}
                    lg={10}
                  >
                    <TextLabel>Geo Location Access</TextLabel>
                    <span>Select members that can have access to your location</span>
                  </Grid>

                  <FlexEndGrid
                    item
                    xs={4}
                    lg={2}
                  >
                    <SwitchComponent
                      value={!!values?.geoLocationAccess?.length}
                      onChange={() => toggleGroupGeoLocationAccess(values?.geoLocationAccess)}
                    />
                  </FlexEndGrid>

                  <Grid
                    item
                    xs={12}
                  >
                    <FamilyMembersSelector
                      multiple
                      hideAllSelection
                      name="geoLocationAccess"
                    />
                  </Grid>

                  <GridDivider margin={'-10px 0 10px 0'} />
                </>
              ) }
              <Grid
                item
                xs={8}
                lg={10}
              >
                <TextLabel>About Me</TextLabel>
                <span>Allow members to view your ‘About Me’</span>
              </Grid>

              <FlexEndGrid
                item
                xs={4}
                lg={2}
              >
                <SwitchField name={'viewAboutMe'} />
              </FlexEndGrid>

              { familyCoreConfig.isChatEnabled && (
                <>
                  <GridDivider margin={'10px 0'} />

                  <Grid
                    item
                    xs={8}
                    lg={10}
                  >
                    <TextLabel>Chat</TextLabel>
                    <span>Allow members to chat with you</span>
                  </Grid>

                  <FlexEndGrid
                    item
                    xs={4}
                    lg={2}
                  >
                    <SwitchField name={'chatAccess'} />
                  </FlexEndGrid>
                </>
              ) }
            </Grid>
          </Grid>

          <GridDivider margin={'10px 0'} />

          <Grid
            item
            xs={12}
          >
            <CreateFamilyLink
              to={EAuthRoutes.SignUp}
              target="_blank"
            >
              Want to create your own family account?
            </CreateFamilyLink>
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        dirty={dirty}
        isLoading={updating}
        formikRef={formikRef}
        message="Account was successfully updated"
      />

      { closeAccountDialogOpen && (
        <DeleteUserDialog
          handleSubmit={onCloseAccountSubmit}
          onClose={() => setCloseAccountDialogOpen(false)}
        />
      ) }

    </Form>), [ dirty, values.geoLocationAccess, loadingFamilyAccount, updating, closeAccountDialogOpen ]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues as IMemberAccountRequest}
      validationSchema={memberAccountValidationSchema}
      onSubmit={(values) => updateMemberAccount(values)}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
