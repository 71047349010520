import { FC }                     from 'react';
import styled                     from 'styled-components';
import Dialog, { DialogProps }    from '@material-ui/core/Dialog';
import { Clear }                  from '@material-ui/icons';
import { CloseModalIcon }         from '../../../styledComponents';
import { TermsAndPrivacyContent } from '../../TermsAndPrivacyContent';

const DialogWrapper = styled(Dialog)`
  .MuiPaper-root {
    margin: 32px 16px;
  }
`;

export const TermsModal: FC<DialogProps> = ({ open, onClose }) => {
  const handleClose = () => {
    onClose && onClose({}, 'escapeKeyDown');
  };

  return (
    <DialogWrapper
      maxWidth="xl"
      open={open}
      aria-labelledby="max-width-dialog-title"
      onClose={handleClose}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={handleClose}
      >
        <Clear />
      </CloseModalIcon>

      <TermsAndPrivacyContent />
    </DialogWrapper>
  );
};
