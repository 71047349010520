import axios, { AxiosResponse }             from 'axios';
import { useMutation }                      from 'react-query';
import { queryClient }                      from '../../App';
import { IErrorResponse, IRequestResponse } from '../../Auth/shared/interfaces';
import { handleApiErrorResponse }           from '../../utils/apiHelpers';
import { Notistack }                        from '../components/Notistack/Notistack';
import { useCreateQuery }                   from '../utils/hooks/useReactQuery';

export interface ISubCategory {
  id: string;
  name: string;
  isBasic: boolean;
}

export interface ICategory extends ISubCategory {
  subCategories: ISubCategory[];
}

interface ICreateTypeRequest {
  name: string;
  familyId: string;
}

interface IDeleteTypeRequest {
  categoryId: string;
  familyId: string;
}

interface IDeleteSubCategoryRequest {
  subСategoryId: string;
  familyId: string;
}

interface ICreateSubCategoryRequest extends ICreateTypeRequest {
  categoryId: string;
}

export const CATEGORIES = 'CATEGORIES';
const DOCUMENT_TYPE_CREATE = 'DOCUMENT_TYPE_CREATE';
const DOCUMENT_TYPE_DELETE = 'DOCUMENT_TYPE_DELETE';
const DOCUMENT_SUB_TYPE_CREATE = 'DOCUMENT_SUB_TYPE_CREATE';
const DOCUMENT_SUB_TYPE_DELETE = 'DOCUMENT_SUB_TYPE_DELETE';

const DOCUMENTS = 'DOCUMENTS';

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const onSuccess = (message?: string, invalidateTypes = true) => {
  invalidateTypes && queryClient.invalidateQueries([ CATEGORIES ]);
  if (message) {
    Notistack.enqueueSnackbar(message, 'success');
  }
};

export const useFetchCategories = (
  familyId?: string
) => useCreateQuery<ICategory[]>({
  queryKey : [ CATEGORIES ],
  apiUrl   : `/api/v1/categories?familyId=${ familyId }`,
  options  : {
    staleTime            : Infinity,
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
  },
});

const createCategory = (data: ICreateTypeRequest) =>
  axios.post('/api/v1/categories', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<ICategory>>) => data)
    .catch(onCatch);

export const useCreateCategory = () => useMutation(
  DOCUMENT_TYPE_CREATE,
  (data: ICreateTypeRequest) => createCategory(data),
  {
    onSuccess: () => {
      onSuccess('', false);
      queryClient.invalidateQueries([ CATEGORIES ]);
    },
    onError: handleApiErrorResponse,
  }
);

const createSubCategory = (data: ICreateSubCategoryRequest) =>
  axios.post('/api/v1/categories/subcategories', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<ISubCategory>>) => data)
    .catch(onCatch);

export const useCreateSubCategory = () => useMutation(
  DOCUMENT_SUB_TYPE_CREATE,
  (data: ICreateSubCategoryRequest) => createSubCategory(data),
  {
    onSuccess: () => {
      onSuccess('', false);
      queryClient.invalidateQueries([ CATEGORIES ]);
    },
    onError: handleApiErrorResponse,
  }
);

const deleteDocumentType = (data: IDeleteTypeRequest) =>
  axios.delete('/api/v1/categories', { data })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<ICategory[]>>) => data)
    .catch(onCatch);

export const useDeleteDocumentType = () => useMutation(
  DOCUMENT_TYPE_DELETE,
  (data: IDeleteTypeRequest) => deleteDocumentType(data),
  {
    onSuccess: () => {
      queryClient.invalidateQueries([ DOCUMENTS ]);
      onSuccess('Document type was removed.');
    },
    onError: handleApiErrorResponse,
  }
);

const deleteDocumentSubCategory = (data: IDeleteSubCategoryRequest) =>
  axios.delete('api/v1/categories/subcategories', { data })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IDeleteSubCategoryRequest>>) => data)
    .catch(onCatch);

export const useDeleteDocumentSubCategory = () => useMutation(
  DOCUMENT_SUB_TYPE_DELETE,
  (data: IDeleteSubCategoryRequest) => deleteDocumentSubCategory(data),
  {
    onSuccess: () => {
      queryClient.invalidateQueries([ DOCUMENTS ]);
      onSuccess('Document type was removed.');
    },
    onError: handleApiErrorResponse,
  }
);
