import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { EAppRoutes }      from '../../shared/constants/constants';
import { Dashboard }       from './Dashboard';

export const DashboardRoutes = () => (
  <>
    <Route
      exact
      path="/"
    >
      <Redirect to={EAppRoutes.Dashboard} />
    </Route>

    <Route
      path={EAppRoutes.Dashboard}
      component={Dashboard}
    />
  </>
);
