import { Grid, InputLabel }                    from '@material-ui/core';
import { AxiosError }                          from 'axios';
import React, { FC, useState }                 from 'react';
import { useHistory }                          from 'react-router-dom';
import styled                                  from 'styled-components';
import { IErrorResponse }                      from '../../../../../Auth/shared/interfaces';
import { CodeInput, SecurityCodeErrorMessage } from '../../../../../Auth/shared/styles';
import { Notistack }                           from '../../../../../shared/components/Notistack/Notistack';
import { EAppRoutes }                          from '../../../../../shared/constants/constants';
import { queryParams }                         from '../../../../../shared/functions';
import { PasswordInput }                       from '../../../../../shared/inputs/PasswordInput';
import { useUpdateCurrentUserPin }             from '../../../../../shared/queries/settings';
import { FormSpacing, TextBrand }              from '../../../../../shared/styledComponents';
import { FormFooterActions }                   from '../../../shared/FormFooterActions';

const PasswordInputWrapper = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export interface IChangePinRequest {
  password: string;
  securityCode: string;
  existingSecurityCode: string;
}

export const ChangePinForm: FC = () => {
  const history = useHistory();
  const params = queryParams();
  const [ password, setPassword ] = useState('');
  const [ securityCode, setSecurityCode ] = useState('');
  const [ existingSecurityCode, setExistingSecurityCode ] = useState('');
  const [ validationMessage, setValidationMessage ] = useState('');
  const [ validationPinMessage, setValidationPinMessage ] = useState('');
  const securityCodeConfirmed = securityCode && existingSecurityCode && securityCode === existingSecurityCode;

  const { mutate: updatePinCode, isLoading: updatingPinCode } = useUpdateCurrentUserPin();

  const updatePin = () => updatePinCode({
    password, securityCode: securityCode, existingSecurityCode: existingSecurityCode,
  }, {
    onSuccess: (data) => {
      setPassword('');
      setSecurityCode('');
      setExistingSecurityCode('');
      Notistack.enqueueSnackbar(data?.message, 'success');

      if (params?.goBack) {
        setTimeout(() => {
          history.push({
            pathname: EAppRoutes.Dashboard,
          });
        }, 500);
      }
    },
    onError: (error: AxiosError<IErrorResponse> | any) => {
      const errorDetails = error && error.response && error.response.data.ErrorDetails;
      if (errorDetails) {
        if (errorDetails?.Key === 'password') {
          setValidationMessage(errorDetails?.Message);
        } else {
          setValidationPinMessage(errorDetails?.Message);
        }
      }
    },
  });

  const onProceed = (event: any) => {
    event.preventDefault();

    if (!securityCode) {
      setValidationPinMessage('Provide your Current Pin');
    } else if (securityCodeConfirmed) {
      updatePin();
    } else {
      setValidationPinMessage(securityCodeConfirmed ? '' : 'Codes need to match');
    }
  };

  return (
    <form onSubmit={onProceed}>
      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextBrand mb={15}>Update Your Pin</TextBrand>

            <p>
              Your 4 Digit PIN will be used for verification.
              Please select a PIN to keep your account and information secure.
              If you are updating your PIN, please select a unique number and enter below.
            </p>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <PasswordInputWrapper>
              <InputLabel>Your Password</InputLabel>

              <PasswordInput
                value={password}
                onChange={({ target: { value } }) => {
                  setValidationMessage('');
                  setPassword(value);
                }}
              />
            </PasswordInputWrapper>

            { validationMessage && (
              <SecurityCodeErrorMessage position="relative">{ validationMessage }</SecurityCodeErrorMessage>
            ) }

            <InputLabel>New Pin</InputLabel>

            <CodeInput
              length={4}
              placeholder={''}
              value={securityCode}
              onChange={(value) => {
                setSecurityCode(value);
                setValidationPinMessage('');
              }}
            />

            <InputLabel>Confirm New Pin</InputLabel>

            <CodeInput
              length={4}
              placeholder={''}
              value={existingSecurityCode}
              onChange={(value) => {
                setExistingSecurityCode(value);
                setValidationPinMessage('');
              }}
            />

            { validationPinMessage && (
              <SecurityCodeErrorMessage position="relative">{ validationPinMessage }</SecurityCodeErrorMessage>
            ) }
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        type="submit"
        dirty={!!(securityCode && existingSecurityCode)}
        isLoading={updatingPinCode}
      />
    </form>
  );
};
