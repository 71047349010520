import { MenuItem }                                                                from '@material-ui/core';
import React, { ForwardRefRenderFunction, forwardRef, memo, useContext, useState } from 'react';
import { queryClient }                                                             from '../../../../App';
import { AlertDialog }                                                             from '../../../../shared/components/modals/AlertDialog';
import { useChangeTaskStatus }                                                     from '../../../../shared/components/modals/UploadTask/queries';
import { UploadTaskModal }                                                         from '../../../../shared/components/modals/UploadTask/UploadTaskModal';
import { ETaskStatus }                                                             from '../../../../shared/constants/constants';
import { isAdminRoles }                                                            from '../../../../shared/functions';
import AuthorizationContext
  from '../../../../shared/utils/withAuthorization/authorizationContext';
import { ITask, TASKS }                                                            from '../queries';

interface ITaskMenuItemsProps {
  familyId?: string;
  task?: ITask;
}

// eslint-disable-next-line react/display-name
const TaskMenuItemsBase: ForwardRefRenderFunction<HTMLDivElement, ITaskMenuItemsProps> = ({
  familyId,
  task,
}, _ref) => {
  const [ updateTaskModalOpen, setUpdateTaskModalOpen ] = useState(false);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const { mutate: changeTaskStatus, isLoading: changingTaskStatus } = useChangeTaskStatus(task?.id);
  const currentUser = useContext(AuthorizationContext);
  const isCreator = currentUser?.data?.id === task?.creator?.id;
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);

  const onDeleteTask = () => {
    changeTaskStatus(ETaskStatus.Deleted, {
      onSuccess: () => {
        setDeleteModalOpen(false);
        queryClient.invalidateQueries(TASKS);
      },
    });
  };

  return (
    <>
      <MenuItem onClick={() => setUpdateTaskModalOpen(true)}>Edit</MenuItem>

      <MenuItem
        hidden={!(isCreator || isAdminRole)}
        disabled={task?.status === ETaskStatus.Deleted}
        onClick={() => setDeleteModalOpen(true)}
      >
        Delete
      </MenuItem>

      { updateTaskModalOpen && (
        <UploadTaskModal
          familyId={familyId}
          task={task}
          open={updateTaskModalOpen}
          onClose={() => setUpdateTaskModalOpen(false)}
        />
      ) }

      <AlertDialog
        dialogWidth="350px"
        open={deleteModalOpen}
        title="Are you sure you want to delete this task?"
        content={`Task "${ task?.title }"`}
        submitButtonDisabled={changingTaskStatus}
        handleSubmit={() => onDeleteTask()}
        handleCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export const TaskMenuItems = memo(forwardRef(TaskMenuItemsBase));
