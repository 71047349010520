import { ICroppedArea } from "./CropEasy";

const createImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = url;
});

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

interface CropResult {
  file: Blob | null;
  url: string | null;
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: ICroppedArea,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<CropResult> {
  const image = await createImage(imageSrc) as unknown as HTMLImageElement;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx?.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx?.rotate(rotRad);
  ctx?.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx?.translate(-image.width / 2, -image.height / 2);

  ctx?.drawImage(image as HTMLOrSVGImageElement, 0, 0);

  const data = ctx?.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  if (data) {
    ctx?.putImageData(data, 0, 0);
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], 'cropped.jpeg', { type: 'image/jpeg' });
        resolve({ file, url: URL.createObjectURL(file) });
      } else {
        reject(new Error('Failed to create blob.'));
      }
    }, 'image/jpeg');
  });
}
