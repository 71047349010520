import { FieldProps, getIn } from 'formik';
import React                 from 'react';
import styled                from 'styled-components';
import { PasswordInput }     from '../inputs/PasswordInput';

const PasswordInputStyled = styled(PasswordInput)`&& {
 currentColor: #000;
}`;

export const PasswordInputField = ({ field, form, ...rest }: FieldProps) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return (
    <PasswordInputStyled
      {...field}
      {...rest}
      error={!!touched && !!error}
      helperText={(touched && error) || ''}
    />
  );
};
