import {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
}                                 from 'react';
import styled, { css }            from 'styled-components';
import { Box, IconButton, Paper } from '@material-ui/core';
import { Clear }                  from '@material-ui/icons';
import MinimizeIcon               from '@material-ui/icons/Remove';
import SendIcon                   from '@material-ui/icons/Send';
import { PushNotifications }      from '@capacitor/push-notifications';

import { queryClient }            from '../../../App';
import { TextInput }              from '../../../shared/inputs/TextInput/TextInput';
import { Flex }                   from '../../../shared/styledComponents';
import {
  formatDate,
  fullName,
}                                 from '../../../shared/utils/commonFunctions';
import AuthorizationContext       from '../../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember }          from '../../../shared/utils/withAuthorization/withAuthorization';
import { RecordLabel }            from '../../Activity/ActivityRecordPreview';
import { FamilyMembersList }      from '../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { Message }                from '../Message';
import {
  CHAT_ROOMS,
  IChat, IMessage,
  useMarkMessageAsRead,
  useSendChatMessage,
}                                  from '../queries';
import { ChatMenuItems }           from './ChatMenuItems';
import { isAndroid }               from '../../../shared/utils/platform';

const ChatSection = styled.div<{ responsiveWidth: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  flex-direction: column;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 0 32px 0 0;
    ${ ({ responsiveWidth }) => responsiveWidth && { width: 'calc(100% - 280px)' } }
  }
`;

const ChatHeader = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 10px 10px 18px;
  align-items: center;
  box-shadow: 4px 0 5px 2px rgba(0, 0, 0, 0.1);

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 10px 10px 10px 24px;
  }
`;

const DynamicChatHeader = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  font-family: Jost;
  font-size: 24px;
  font-weight: 500;
  color: #2c3f69;
  justify-content: space-between;
  align-items: center;

  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 32px;
  }

  span:last-child {
    flex-wrap: nowrap;
    display: flex;
  }
`;

const ChatContainer = styled(Paper)<{ fixed?: number }>`
  display: flex;
  flex-direction: column;
  padding: 0 0 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  border-radius: ${ ({ fixed }) => fixed ? '6px 6px 0 0' : '8px' };
  max-height: 580px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    max-height: 100%;
    padding: 0;
  }
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  width: 100%;
  padding: 10px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ChatFooterForm = styled.form<{ fixed?: number }>`
  display: flex;
  gap: 10px;
  align-items: center;

  ${ ({ fixed }) => fixed ? css`
    padding: 16px;
  ` : css`
    padding: 28px 25px 28px 23px;
    box-shadow: 4px 0 5px 2px rgba(0, 0, 0, 0.1);
  ` };

  .MuiInputBase-input {
    height: 36px;
    padding: 10px 15px 12px;
    font-family: Lato;
    font-size: 15px;
  }

  .MuiOutlinedInput-inputMultiline {
    min-height: 43px;
  }
`;

const ButtonSend = styled(IconButton)`
  width: 28px;
  height: 28px;

  &.MuiButtonBase-root {
    background-color: #6f90d9;
  }

  &[disabled] {
    opacity: .5;
  }
`;

export const IconSend = styled(SendIcon)`
  width: 12px;
  fill: #fff;
`;

const ChatHeaderMembersList = styled.div`
  flex-shrink: 0;
`;

const BackgroundText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  opacity: .2;
  line-height: 1.3;
`;

const ChatHeaderInfo = styled.div`
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    padding-left: 20px;
  }
`;

const DataText = styled.div`
  color: #6c7289;
  font-size: 12px;
  line-height: 1.3;
  text-align: left;
  display: flex;
  overflow: hidden;
  padding-left: 20px;
`;

const FixedChatHeader = styled(Flex)`
  width: calc(100% - 46px);
`;

interface IChatWindowProps {
  chat?: IChat;
  loading?: boolean;
  fixed?: boolean;
  familyUser?: IFamilyMember;
  chatId?: string;
  familyId?: string;

  onMinimize?(): void;
  onClose?(): void;
}

export const ChatWindow: FC<IChatWindowProps> = ({
  chat,
  fixed,
  chatId,
  loading,
  onClose,
  familyId,
  familyUser,
  onMinimize,
}) => {
  const [ message, setMessage ] = useState('');
  const [ chatHistory, setChatHistory ] = useState<IMessage[]>([]);
  const user = chat?.users?.[0];

  const currentUser = useContext(AuthorizationContext);
  const { mutate: markAsRead, isLoading: markingAsRead } = useMarkMessageAsRead(currentUser?.data?.id, chat?.id);

  const scrollToBottom = (): void => {
    const element1 = document.getElementById('chat');
    const element2 = document.getElementById('chat-anchor');
    const topPos = element2?.offsetTop;
    topPos && element1 && element1.scrollTo(0, topPos);
  };

  useEffect(() => {
    if (chat?.messages) {
      const reversedMessages = [...chat?.messages].reverse();
      setChatHistory(reversedMessages);

      const lastMessage = reversedMessages[reversedMessages.length - 1];

      if (user?.id && lastMessage?.id && currentUser?.data?.id && chat?.id && !markingAsRead) {
        markAsRead(lastMessage?.id);
      }

    }
  }, [ chat ]);

  useEffect(() => {
    scrollToBottom();
  },
  [chatHistory]
  );

  const addMessage = (text: string) => {
    const newMessage = {
      text      : text.trim(),
      user      : familyUser,
      timestamp : new Date().toISOString(),
    };

    queryClient.setQueryData([ CHAT_ROOMS, chatId ], (oldData: any) => oldData?.data?.data?.messages ? {
      ...oldData,
      data: {
        ...oldData?.data,
        data: {
          ...oldData?.data?.data,
          unreadMessagesCount : 0,
          messages            : [ newMessage, ...oldData?.data?.data?.messages ],
        },
      },
    } : null);

    scrollToBottom();
  };

  const { mutate: sendMessage, isLoading: sendingMessage } = useSendChatMessage(familyUser?.id, chatId, {
    onSuccess: () => {
      addMessage(message);
      setMessage('');
    },
  });

  const onSendMessage = (event: any) => {
    event?.preventDefault();

    const trimmedMessage = message.trim();

    trimmedMessage && sendMessage(trimmedMessage);
  };

  useEffect(() => {
    const handleDeliveredNotifications = async () => {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      if (notificationList.notifications && chat?.id) {
        const notificationsToRemove = notificationList.notifications.filter(item => {
          const regex = /^(.*?)(?:\s|$)/;
          const match = item.body && item.body.match(regex);
          const extractedSubstring = match ? match[1] : item.body;

          if (item.title?.length) {
            return item.title === chat.name;
          } else if (!item.title?.length && item.body) {
            return extractedSubstring === user?.firstName;
          }
        });

        PushNotifications.removeDeliveredNotifications({ notifications: notificationsToRemove });
      }
    };

    isAndroid && handleDeliveredNotifications();

  }, [isAndroid, chat?.id, user?.firstName, chat?.name]);


  const recipientFullName = fullName(user?.firstName || currentUser?.data?.firstName, user?.lastName || currentUser?.data?.lastName);

  const isSender = (message: IMessage) => familyUser && message && (familyUser?.id === message?.user?.id || familyUser?.id === (message?.user as any)?.familyUserId);

  const MessagesList = useMemo(() => (
    <ChatMessages id="chat">
      { chatHistory?.length ? chatHistory?.map((message, index) => (
        <Message
          key={index}
          data={message}
          isSender={isSender(message)}
        />
      )) : (
        <BackgroundText>
          { loading ? (
            'Loading...'
          ) : (
            <>
              <span>Write a message </span>
              <span>to start the conversation</span>
            </>
          ) }
        </BackgroundText>
      )}
      <div id="chat-anchor" />
    </ChatMessages>
  ), [ chatHistory ]);

  return (
    <ChatSection responsiveWidth={!onClose}>
      <ChatContainer
        fixed={fixed ? 1 : 0}
        elevation={fixed ? 3 : 1}
      >
        <ChatHeader>
          { onClose ? (
            <DynamicChatHeader>
              <span>{ chat?.name || recipientFullName }</span>

              <span>
                <IconButton
                  color="inherit"
                  onClick={onMinimize}
                >
                  <MinimizeIcon />
                </IconButton>

                <IconButton
                  color="inherit"
                  size="medium"
                  onClick={onClose}
                >
                  <Clear />
                </IconButton>
              </span>
            </DynamicChatHeader>
          ) : (
            <Flex justify="space-between" align="center">
              <FixedChatHeader align="center">
                <ChatHeaderMembersList>
                  <FamilyMembersList
                    shifted
                    hideAllSelection
                    data={chat?.users.length ? chat?.users : (currentUser?.data ? [ currentUser.data ] : [])}
                  />
                </ChatHeaderMembersList>

                <ChatHeaderInfo>
                  <RecordLabel>
                    <b>{ chat?.name || recipientFullName }</b>
                  </RecordLabel>

                  <Box height={5} />

                  <DataText>
                    { formatDate(new Date().toISOString()) }
                  </DataText>
                </ChatHeaderInfo>
              </FixedChatHeader>

              <ChatMenuItems
                familyId={familyId}
                chat={chat}
              />
            </Flex>
          ) }
        </ChatHeader>

        { MessagesList }

        <ChatFooterForm
          fixed={fixed ? 1 : 0}
        >
          <TextInput
            fullWidth
            multiline
            autoFocus
            maxRows={3}
            placeholder="Enter Message"
            value={message || ''}
            inputProps={{ maxLength: 1024 }}
            onChange={({ target: { value } }) => setMessage(value)}
          />

          <ButtonSend
            size="medium"
            disabled={!message || sendingMessage}
            onMouseDown={onSendMessage}
            onTouchEnd={onSendMessage}
          >
            <IconSend />
          </ButtonSend>
        </ChatFooterForm>
      </ChatContainer>
    </ChatSection>
  );
};
