import { Grid }                               from '@material-ui/core';
import React, { FC, useMemo }                 from 'react';
import { theme }                              from '../../../../styles';
import { Layout }                             from '../../../Layout/Layout';
import { FullWidthGrid }                      from '../../../shared/components';
import { IRouterDrivenTab, RouterDrivenTabs } from '../../../shared/components/RouterDrivenTabs';
import { EAppRoutes }                         from '../../../shared/constants/constants';
import { ResponsivePageTitle }                from '../../../shared/styledComponents';
import { SettingsPageStyleWrapper }           from '../../Settings/Settings';
import { PreRegistration }                    from './PreRegistration/PreRegistration';

const baseUrl = EAppRoutes.AddMembers;

export const AddMembers: FC = () => {
  const ByInviteTab = (
    <PreRegistration byInvite />
  );

  const ByAccountCreationTab = (
    <PreRegistration />
  );

  const tabs: IRouterDrivenTab[] = [ {
    label     : 'BY INVITE',
    link      : 'by-invite',
    color     : theme.colors.attention,
    component : ByInviteTab,
  }, {
    label     : 'BY PROFILE CREATION',
    link      : 'by-account-creation',
    color     : theme.colors.attention,
    component : ByAccountCreationTab,
  } ];

  const Page = (
    <SettingsPageStyleWrapper>
      <ResponsivePageTitle>
        <span>Add Members</span>
      </ResponsivePageTitle>

      <FullWidthGrid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
        >
          <RouterDrivenTabs
            baseURL={baseUrl}
            tabs={tabs}
          />
        </Grid>
      </FullWidthGrid>
    </SettingsPageStyleWrapper>
  );

  return useMemo(() =>
    (<Layout>
      { Page }
    </Layout>)
  , [ window.location.href ]);
};
