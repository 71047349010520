import { Grid }                                             from '@material-ui/core';
import { AxiosError }                                       from 'axios';
import { FastField, Form, Formik, FormikProps }             from 'formik';
import qs                                                   from 'qs';
import React, { FC, useMemo, useRef }                       from 'react';
import { useMutation }                                      from 'react-query';
import { useHistory }                                       from 'react-router';
import { IErrorResponse }                                   from '../../../../../Auth/shared/interfaces';
import { Notistack }                                        from '../../../../../shared/components/Notistack/Notistack';
import { EAuthRoutes }                                      from '../../../../../shared/constants/constants';
import { PasswordInputField }                               from '../../../../../shared/formFields/PasswordInputField';
import { TextInputField }                                   from '../../../../../shared/formFields/TextInputField';
import { updateCurrentUserEmail }                           from '../../../../../shared/queries/settings';
import { FormSpacing, TextBrand }                           from '../../../../../shared/styledComponents';
import { FormFooterActions }                                from '../../../shared/FormFooterActions';
import { IChangeEmailRequest, changeEmailValidationSchema } from './validation';

export const ChangeEmailForm: FC = () => {
  const updateMutation = useMutation('updateUserEmail', (data: IChangeEmailRequest) => updateCurrentUserEmail(data));
  const formikRef = useRef<FormikProps<IChangeEmailRequest>>(null);
  const history = useHistory();

  const updateEmail = (formValues: IChangeEmailRequest) => updateMutation.mutate(formValues, {
    onSuccess: () => {
      formikRef?.current?.resetForm();
      history.push(`${ EAuthRoutes.EmailChangeConfirmation }?${ qs.stringify({ email: formValues?.email }) }`);
      Notistack.enqueueSnackbar('The verification code has been sent to your email.', 'success');
    },
    onError: (error: AxiosError<IErrorResponse> | any) => {
      const errorDetails = error && error.response && error.response.data.ErrorDetails;
      if (errorDetails) {
        const { Key, Message } = errorDetails;
        formikRef?.current?.setErrors({
          [Key]: Message,
        });
      }
    },
  });

  const FormContent = ({ dirty }: FormikProps<IChangeEmailRequest>) => useMemo(() =>
    (<Form>
      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextBrand mb={5}>Update Email</TextBrand>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FastField
                  name="email"
                  label="New Email"
                  component={TextInputField}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FastField
                  name="password"
                  label="Your Password"
                  component={PasswordInputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        dirty={dirty}
        isLoading={updateMutation.isLoading}
        formikRef={formikRef}
      />
    </Form>), [ dirty, updateMutation.isLoading ]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ email: '', password: '' } as IChangeEmailRequest}
      validationSchema={changeEmailValidationSchema}
      onSubmit={updateEmail}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
