import { FC }                from 'react';
import { IFamilyMember }     from '../../../../shared/utils/withAuthorization/withAuthorization';
import { AllMembersCircle }  from './AllMembersCircle';
import { FamilyMembersList } from './FamilyMembersList';

interface IAllMembersCircleProps {
  data?: IFamilyMember[];
  assigned?: string[];
  justify?: string;
  noWrap?: boolean;
}

export const AssignedMembers: FC<IAllMembersCircleProps> = ({
  data = [],
  assigned = [],
  justify,
  noWrap,
}) => {
  const assignedMembers = data?.filter(member => assigned.indexOf(member?.id) >= 0).reverse();
  const allAssigned = !!assigned.length && (assigned.length === data.length);

  return allAssigned ?
    <AllMembersCircle selected={false} />
    : (
      <FamilyMembersList
        shifted
        justify={justify}
        noWrap={noWrap}
        data={assignedMembers || []}
      />
    );
};
