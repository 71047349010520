import { Tooltip }                                             from '@material-ui/core';
import CheckIcon                                               from '@material-ui/icons/Check';
import React, { FC, useContext, useMemo }                      from 'react';
import styled                                                  from 'styled-components';
import { FadeContainer }                                       from '../../../../shared/components/layout/Fader';
import { EAccountStatus }                                      from '../../../../shared/constants/constants';
import { isAdminRoles, orderMembers }                          from '../../../../shared/functions';
import { fullName, generateInitials }                          from '../../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../../shared/utils/withAuthorization/authorizationContext';
import {
  Authorized,
  IFamilyMember,
  adminRoles,
}                                                              from '../../../../shared/utils/withAuthorization/withAuthorization';
import { AllMembersCircle }                                    from './AllMembersCircle';
import { FamilyMemberCircle }                                  from './FamilyMemberCircle';
import { CheckedFamilyMemberBadge, FamilyMemberCircleWrapper } from './FamilyMembers';

interface IFamilyMembersListProps {
  shifted?: boolean;
  justify?: string;
  noWrap?: boolean;
  loading?: boolean;
  fixed?: boolean;
  shaded?: boolean;
  disabledForMembers?: boolean;
  data?: IFamilyMember[];
  selected?: string | string[];
  hideAllSelection?: boolean;
  showOnlyActive?: boolean;
  showAllBubbleForMembers?: boolean;
  filledArrayForAllSelection?: boolean;

  onSelect?(id: string): void;
}

const FamilyMembersWrapper = styled.div<{ justify?: string; shifted?: boolean; single?: boolean; noWrap?: boolean }>`
  display: flex;
  gap: 8px;
  flex-wrap: ${ ({ noWrap }) => noWrap ? 'nowrap' : 'wrap' };;
  justify-content: ${ ({ justify }) => justify || 'flex-start' };
  flex-direction: ${ ({ shifted }) => shifted ? 'row-reverse' : 'row' };
  margin: ${ ({ shifted, single }) => shifted ? single ? '0 10px' : '0 15px 0 15px' : '0' };

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    gap: 12px;
  }
`;

export const CheckedBadge = () => (
  <CheckedFamilyMemberBadge>
    <CheckIcon />
  </CheckedFamilyMemberBadge>
);

export const FamilyMembersList: FC<IFamilyMembersListProps> = ({
  data,
  shifted,
  justify,
  noWrap,
  loading,
  selected,
  shaded,
  onSelect,
  disabledForMembers,
  hideAllSelection,
  fixed,
  showOnlyActive,
  showAllBubbleForMembers,
  filledArrayForAllSelection,
}) => {
  let allMembers: IFamilyMember[] = orderMembers(data);
  const userProfile = useContext(AuthorizationContext);
  const isAdminRole = isAdminRoles(userProfile?.data?.accountType);
  const selectionAllowed = onSelect && (isAdminRole || !disabledForMembers);

  if (showOnlyActive) {
    allMembers = allMembers.filter((familyMember) =>
      familyMember.status === EAccountStatus.Accepted && familyMember.hasEmail);
  }

  const onMemberClick = (memberId = '') => {
    if (selectionAllowed) {
      onSelect?.(memberId);
    }
  };

  const AllUsersSelection = (
    <AllMembersCircle
      selected={!!allMembers?.length && filledArrayForAllSelection ? (selected?.length === allMembers?.length) : !selected?.length}
      onSelect={() => onMemberClick()}
    />
  );

  const Members = useMemo(() =>
    (<FamilyMembersWrapper
      single={!allMembers?.length}
      shifted={shifted}
      justify={justify}
      noWrap={noWrap}
    >
      { (!hideAllSelection && !shifted) && (showAllBubbleForMembers ?
        AllUsersSelection : (
          <Authorized accountTypes={adminRoles}>
            { AllUsersSelection }
          </Authorized>
        )
      ) }

      { allMembers?.map((member, index) => (
        <Tooltip
          key={index}
          title={fullName(member?.firstName, member?.lastName)}
          PopperProps={{ modifiers: { offset: { enabled: true, offset: "0px, -85px" } } }}
        >
          <FamilyMemberCircleWrapper
            shifted={shifted}
            allowed={selectionAllowed}
            selected={!!selected && selected.indexOf(member.id) >= 0}
            shaded={shaded && (!selected || selected.indexOf(member.id) < 0)}
            onClick={() => onMemberClick(member.id)}
          >
            <CheckedBadge />

            <FamilyMemberCircle
              color={member?.color}
              image={member?.avatar?.compactPath}
              pending={member?.status === EAccountStatus.Pending}
              initials={generateInitials(member?.firstName, member?.lastName)}
              fixed={fixed}
            />
          </FamilyMemberCircleWrapper>
        </Tooltip>
      )) }
    </FamilyMembersWrapper>), [ data, selected ]);

  return (
    <FadeContainer visible={!loading}>
      { Members }
    </FadeContainer>
  );
};
