import { Grid, Hidden }                              from '@material-ui/core';
import ArrowForwardIcon                              from '@material-ui/icons/ArrowForward';
import axios, { AxiosError, AxiosResponse }          from 'axios';
import { FastField, Form, Formik, FormikHelpers }    from 'formik';
import React, { FC, useEffect, useState }            from 'react';
import { useHistory }                                from 'react-router-dom';
import { useMutation }                               from 'react-query';
import { useQueryClient }                            from 'react-query';
import styled                                        from 'styled-components';
import { ReactComponent as ImageGroup }              from '../../../images/create-a-new-family.svg';
import { iOS }                                       from '../../Layout/Sidebar';
import { FamilyMemberCircle }                        from '../../modules/Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { MuiButton }                                 from '../../shared/components/MuiButton';
import { useUpdateSelectedFamily }                   from '../../shared/components/SelectFamily/queries';
import { EAuthRoutes }                               from '../../shared/constants/constants';
import { TextInputField }                            from '../../shared/formFields/TextInputField';
import { queryParams }                               from '../../shared/functions';
import { TextBrandColor }                            from '../../shared/styledComponents';
import { getUserDetails }                            from '../../utils/authHelpers';
import { getSubscriptionState }                      from '../../utils/subscription';
import { IErrorResponse, IFamily, IRequestResponse } from '../shared/interfaces';
import {
  AuthSVGSceneHolder,
  Instructions,
  LogoSpaced,
  MultiButton,
  PageHeaderText,
  ReverseGridContainer,
  SignUpContainer,
}                                                    from '../shared/styles';
import { createFamilyFormSchema }                    from './validation';
import { fallbackPrice }                             from '../Checkout/Checkout';
import { useFetchFamilies }                          from '../../shared/queries/family';
import { createFamilyPaymentMethod }                 from '../../shared/queries/payments';
import { isNative }                                  from '../../shared/utils/platform';

const PageHeader = styled.div<{ mbXs?: string; mbMd?: string }>`
  font-family: 'Lato';
  color: #2c3f69;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  margin-bottom: ${ ({ mbXs }) => mbXs || '33px' };
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: ${ ({ mbMd }) => mbMd || '35px' };
  }
`;

const ActionText = styled.div`
  color: #76a578;
  font-size: 13px;
  font-family: 'Lato';
  margin: 32px 0 24px;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: 15px;
    line-height: 1.6em;
    margin: 24px 0;
    width: 270px;
  }
`;

const Circle = styled.div`
  color: #fff;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 48px;
  margin: auto;
  font-size: 20px;
  font-weight: 700;
  background: #fac05e;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translateY(50%);
`;

const JoinFamilySection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  padding: 40px 0;
`;

export const FormFieldWrapper = styled.div<{ centered?: boolean }>`&& {
  width: 100%;
  max-width: 310px;
  margin: ${ ({ centered }) => centered ? 'auto' : 0 };
}`;

const JoinFamilyImageGroup = styled(AuthSVGSceneHolder)<{ isNative?: boolean }>`&& {
  margin: 30px 0 50px;
  
  svg {
    min-height: 335px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin: -50px 0 50px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.down('sm') } {
    margin: 50px 0 50px;
    svg {
      min-height: 281px;
    }
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    height: ${ ({ isNative }) => isNative ? '335px' : '100%' };
  }
}`;

const ButtonWrapper = styled.div`&& {
  width: 100%;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    width: 200px;
  }
}`;

interface ICreateForm {
  familyName: string;
}

export const CreateFamily: FC = () => {
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily();
  const [ displayJoinOption ] = useState(false);
  const history = useHistory();
  const { cardToken, familyName } = queryParams();
  const storedUserDetails = getUserDetails();
  const initialValues: ICreateForm = {
    familyName: (familyName as string) || storedUserDetails?.lastName,
  };
  const { data: families } = useFetchFamilies();

  const mutation = useMutation(createFamilyPaymentMethod);

  const queryClient = useQueryClient();
  const couponId = queryClient.getQueryData('couponId');
  const couponDurationDate = queryClient.getQueryData('durationDate');
  let couponPercent: number | undefined = queryClient.getQueryData('couponPercent');
  let subscriptionPlan: string | undefined = queryClient.getQueryData('SubscriptionPlan');

  const fcmRegistrationToken = localStorage.getItem('fcmRegistrationToken');

  const handleSubmit = ({ familyName }: ICreateForm, formikActions: FormikHelpers<ICreateForm>) => {
    formikActions.setSubmitting(true);

    const subscription = getSubscriptionState();

    const nativePurchaseReceipt = {
      appStoreReceipt     : '',
      googlePurchaseToken : '',
    };

    if (subscription.receipt) {
      if ('nativeData' in subscription.receipt) {
        nativePurchaseReceipt.appStoreReceipt = subscription.receipt.nativeData.appStoreReceipt;
      } else if ('purchaseToken' in subscription.receipt) {
        nativePurchaseReceipt.googlePurchaseToken = subscription.receipt.purchaseToken;
      }
    }

    axios.post('/api/v1/families', {
      familyName,
      cardToken,
      appStoreReceipt      : nativePurchaseReceipt.appStoreReceipt || undefined,
      googlePurchaseToken  : nativePurchaseReceipt.googlePurchaseToken || undefined,
      couponId             : couponId,
      fcmRegistrationToken : isNative ? fcmRegistrationToken : undefined,
    })
      .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamily>>) => {
        formikActions.setSubmitting(false);
        updateSelectedFamily(data?.id);
        history.push(`${ EAuthRoutes.CustomizeProfile }/${ data?.id }`);
      })
      .catch((err: AxiosError<IErrorResponse>) => {
        const errorDetails = err && err.response && err.response.data.ErrorDetails;
        if (errorDetails) {
          formikActions.setErrors({
            'familyName': errorDetails?.Message,
          });
          formikActions.setSubmitting(false);
        }
      });
  };

  useEffect(() => {
    if (subscriptionPlan === 'Free') {
      try {
        const data = mutation.mutateAsync({
          cardToken           : undefined,
          appStoreReceipt     : undefined,
          googlePurchaseToken : undefined,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [subscriptionPlan]);

  const getCorrectDescription = () => {
    if (subscriptionPlan === 'Free') {
      return (<ActionText />);
    } else if (!!couponPercent) {
      return (<ActionText>
        {` 4-week free trial then ${couponPercent}% discount applied to ${fallbackPrice} per month subscription ${couponDurationDate}!`}
      </ActionText>);
    } else {
      return ( <ActionText> 4-week free trial then {fallbackPrice} per month</ActionText>);
    }
  };

  return (
    <SignUpContainer maxWidth="md">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={createFamilyFormSchema}
        onSubmit={handleSubmit}
      >
        { ({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <PageHeader
              mbXs="10px"
              mbMd="35px"
            >
              <LogoSpaced margin="0 0 50px" />

              <PageHeaderText centeredXs>
                Create a <TextBrandColor>New Family</TextBrandColor> Core
              </PageHeaderText>
            </PageHeader>

            <ReverseGridContainer
              container
              spacing={3}
            >
              <Grid
                item
                sm={6}
                lg={7}
              >
                <JoinFamilyImageGroup isNative={iOS}>
                  <ImageGroup />
                </JoinFamilyImageGroup>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                lg={5}
              >
                <Hidden xsDown>
                  <Instructions
                    mbXs="50px"
                    widthSm="375px"
                  >
                    <span>You will always be able to connect to other families later.</span>
                  </Instructions>
                </Hidden>

                <Form onSubmit={handleSubmit}>
                  <FormFieldWrapper>
                    <FastField
                      label="Family Core Last Name"
                      name="familyName"
                      inputProps={{ maxLength: 100 }}
                      component={TextInputField}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormFieldWrapper>

                  { getCorrectDescription() }

                  <ButtonWrapper>
                    <MuiButton
                      fullWidth
                      type="submit"
                      margin="0 0 24px 0"
                      disabled={isSubmitting}
                    >
                      Create New Family
                    </MuiButton>
                  </ButtonWrapper>

                  { displayJoinOption && (
                    <Circle>Or</Circle>
                  ) }
                </Form>
              </Grid>
            </ReverseGridContainer>

            { displayJoinOption && (
              <JoinFamilySection>
                <PageHeader>
                  <PageHeaderText>Join Another Family</PageHeaderText>
                </PageHeader>

                <Instructions mbMd="35px">
                  The following families have invited you to join their Family Core account.
                </Instructions>

                <MultiButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<FamilyMemberCircle
                    color="#50bbae"
                    initials="JW"
                  />}
                  endIcon={<ArrowForwardIcon />}
                >
                  West Family
                </MultiButton>
              </JoinFamilySection>
            ) }
          </>
        ) }
      </Formik>
    </SignUpContainer>
  );
};
