import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { EAppRoutes }      from '../../shared/constants/constants';
import { Locate }          from './Locate';

export const LocateRoutes = () => (
  <>
    <Route
      exact
      path="/"
    >
      <Redirect to={EAppRoutes.Locate} />
    </Route>

    <Route
      path={EAppRoutes.Locate}
      component={Locate}
    />
  </>
);
