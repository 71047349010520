import { Grid }                               from '@material-ui/core';
import { FC, useCallback }                    from 'react';
import { useDropzone }                        from 'react-dropzone';
import styled                                 from 'styled-components';
import { FileSpecificationMessage }           from '../../../../styles/components';
import { documentsMimeTypes, imageMimeTypes } from '../../constants/constants';
import { getDocument }                        from '../../utils/getDocument';
import { isNative }                           from '../../utils/platform';
import { DocumentPreview }                    from './DocumentPreview';

const INVALID_TYPE_ERROR = 'file-invalid-type';

export const Border = styled.div`
  display: block;
  padding: 45px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3em;
  border: 1px dashed rgba(151, 151, 151, 0.5);
  margin-bottom: 15px;

  &:hover {
    background-color: #f9fbff;
    cursor: pointer;
  }
`;

export const LinkLikeText = styled.span`
  color: #5071bb;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

interface IDocumentPickerProps {
  icon?: JSX.Element;
  title?: string;
  files: File[];
  multiple?: boolean;
  imageOnly?: boolean;

  onChange(files: File[] | null): void;
}

export const DocumentPicker: FC<IDocumentPickerProps> = ({
  icon,
  title = 'Drop your document here',
  files,
  multiple,
  imageOnly,
  onChange,
}) => {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      const nextFiles = [...files, ...acceptedFiles].slice(0, 5);

      onChange(nextFiles);
    }
  }, [files, onChange]);

  const twentyMB = 1024 * 1024 * 20;

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    multiple,
    maxSize : twentyMB,
    accept  : [imageMimeTypes, !imageOnly && documentsMimeTypes].filter(i => !!i).join(', '),
  });

  const onRemove = useCallback((index: number) => {
    const nextFiles = [...files];
    nextFiles.splice(index, 1);

    onChange(nextFiles);
  }, [files]);

  const resolveMedia = async () => {
    const document = await getDocument();

    if (!document) {
      return;
    }

    const newImageFile = new File([document.blob], document.name, {
      type         : document.mimeType,
      lastModified : Date.now(),
    });

    const nextFiles = [...files, newImageFile].slice(0, 5);

    onChange(nextFiles);
  };

  const acceptedFileItems = files?.map((file, index, array) => {
    const size = array.length > 2 ? 4 : array.length > 1 ? 6 : 12;

    return (
      <Grid
        key={index}
        item
        xs={12}
        sm={size}
      >
        <DocumentPreview
          key={index}
          file={file}
          onRemove={() => onRemove(index)}
        />
      </Grid>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
    <li key={index}>
      { file.name } - { file.size } bytes
      <ul>
        { errors.map(e => (
          <li key={e.code}>
            <FileSpecificationMessage error>
              { e.code === INVALID_TYPE_ERROR ? 'This format is not supported' : e.message }
            </FileSpecificationMessage>
          </li>
        )) }
      </ul>
    </li>
  ));

  const hintMessage = isDragActive && !isNative ?
    <div>Drop the files here ...</div> :
    <div>{ title } or <LinkLikeText>browse</LinkLikeText></div>;

  const pickerProps = isNative ? {
    onClick: () => resolveMedia(),
  } : getRootProps();

  const displayFilePicker = (!files || files?.length < 5);

  return (
    <>
      { displayFilePicker ? (
        <Border {...pickerProps}>
          { !isNative && <input {...getInputProps()} /> }

          { icon && (<IconWrapper>{ icon }</IconWrapper>) }

          { hintMessage }

          <ul>
            { fileRejectionItems }
          </ul>
        </Border>
      ) : null }

      <Grid
        container
        spacing={1}
      >
        { acceptedFileItems }
      </Grid>
    </>
  );
};
