import { DialogProps }                                 from '@material-ui/core';
import { Clear }                                        from '@material-ui/icons';
import { Formik, FormikProps }                          from 'formik';
import React, { FC, useRef }                            from 'react';
import { CloseModalIcon }                               from '../../../styledComponents';
import { StyledUploadDialog, UploadDialogTitle }        from '../styles';
import { IContact, useUpdateContact, useUploadContact } from './queries';
import { UploadContactModalForm }                       from './UploadContactModalForm';
import { addContactFormSchema }                         from './validation';

interface IAlertDialogProps extends DialogProps {
  familyId?: string;
  contact?: IContact;
  onClose: () => void;
}

export const UploadContactModal: FC<IAlertDialogProps> = ({
  contact,
  familyId,
  onClose,
  ...props
}) => {
  const formikRef = useRef<FormikProps<IContact>>(null);

  const { mutate: uploadContact, isLoading: uploadingContact } = useUploadContact(familyId);
  const { mutate: updateContact, isLoading: updatingContact } = useUpdateContact();

  const initialValues: IContact = {
    id            : contact?.id,
    title         : contact?.title || '',
    firstName     : contact?.firstName || '',
    lastName      : contact?.lastName || '',
    categoryId    : contact?.category?.id || '',
    role          : contact?.role || '',
    officeAddress : contact?.officeAddress || '',
    homeAddress   : contact?.homeAddress || '',
    email         : contact?.email || '',
    officeNumber  : contact?.officeNumber || '',
    homeNumber    : contact?.homeNumber || '',
    notes         : contact?.notes || '',
    tagged        : contact?.tagged || [],
  };

  const submit = (values: IContact) => {
    if (contact?.id) {
      updateContact({
        ...values,
        familyId,
      }, {
        onSuccess: onClose,
      });
    } else {
      uploadContact({
        ...values,
        familyId,
      }, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <StyledUploadDialog
      onClose={onClose}
      {...props}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        { contact ? 'Edit' : 'Add' } Contact
      </UploadDialogTitle>

      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={addContactFormSchema}
        onSubmit={(values) => submit(values)}
      >
        { (props) => (
          <UploadContactModalForm
            familyId={familyId}
            formikRef={formikRef}
            loading={uploadingContact || updatingContact}
            {...props}
          />
        ) }
      </Formik>
    </StyledUploadDialog>
  );
};

UploadContactModal.defaultProps = {
  disableBackdropClick: true,
};
