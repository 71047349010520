import { Divider, Grid }                               from '@material-ui/core';
import React, { FC, useEffect, useState }              from 'react';
import { useHistory }                                  from 'react-router-dom';
import { InfiniteQueryObserverResult }                 from 'react-query/types/core/types';
import styled                                          from 'styled-components';
import { IPaginatedResponse }                          from '../../../Auth/shared/interfaces';
import { dateFilterOptions, taskStatusesList }         from '../../../shared/constants/constants';
import { Loader }                                      from '../../../shared/enhancers/withLoader';
import { ICategory, ISubCategory, useFetchCategories } from '../../../shared/queries/categories';
import { familyRoles }                                 from '../../../shared/queries/family';
import { Select }                                      from '../../../shared/select/Select/Select';
import { FlexEndGrid, FlexVerticalCenterGrid }         from '../../../shared/styledComponents';
import { IDocument }                                   from '../../../shared/components/modals/UploadDocument/queries';
import { useFetchFamilies }                            from '../../../shared/queries/family';
import { useFetchSelectedFamily }                      from '../../../shared/components/SelectFamily/queries';
import { StyledList }                                  from '../../Activity/ActivityItems';
import { LoadMoreFooter }                              from '../../Activity/LoadMoreFooter';
import { AddEntityControl }                            from './AddEntityControl';
import { IFamily }                                     from '../../../Auth/shared/interfaces';
import { SummaryHeader, SummaryListWrapper }           from './styles';
import { LimitedModal }                                from '../../../shared/components/modals/UploadTask/LimitedModal';
import { EAuthRoutes }                                 from '../../../shared/constants/constants';

export interface IEntityFilters {
  status?: string;
  role?: string;
  categoryId: string;
  subCategoryId?: string;
  dateFilter: string;
}

export interface IFamilyDocumentsPages {
  items?: Array<IDocument>;
  totalCount: number;
  next: number;
}

export interface IFamilyDocuments {
  pages?: Array<IFamilyDocumentsPages>;
}

export interface IEntitiesListPreviewProps {
  familyId?: string;
  children: any;
  entityName: string;
  title: string;
  loading?: boolean;
  displayDateFilter?: boolean;
  displayRoleFilter?: boolean;
  displaySubCategory?: boolean;
  displayStatusFilter?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;

  onAdd?(): void;
  fetchNextPage?(): Promise<InfiniteQueryObserverResult<IPaginatedResponse<any>>>;
  onFilterChange?(filters: IEntityFilters): void;
}

const AddEntityControlWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 30px 15px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
`;

export const EntitiesListDetailed: FC<IEntitiesListPreviewProps> = ({
  familyId,
  children,
  entityName,
  title,
  loading,
  onAdd,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  onFilterChange,
  displayDateFilter,
  displayRoleFilter,
  displayStatusFilter,
  displaySubCategory,
}) => {
  const [ role, setRole ] = useState('');
  const [ status, setStatus ] = useState('');
  const [ categoryId, setCategoryId ] = useState('');
  const [ subCategoryId, setSubCategoryId ] = useState('');
  const [ dateFilter, setDateFilter ] = useState('');
  const [ categoriesList, setCategoriesList ] = useState([] as ICategory[]);
  const [ subCategoriesList, setSubCategoriesList ] = useState([] as ISubCategory[]);
  const [ userAddedCount, setUserAddedCount ] = useState(0);
  const [ openFreeInfo, setOpenFreeInfo ] = useState(false);

  const { data: categories, isLoading: loadingTypes } = useFetchCategories(familyId);
  const { data: families } = useFetchFamilies();
  const { data: family } = useFetchSelectedFamily();

  const history = useHistory();

  useEffect(() => {
    onFilterChange && onFilterChange({
      status,
      role,
      categoryId,
      subCategoryId,
      dateFilter,
    });
  }, [ status, role, categoryId, subCategoryId, dateFilter ]);

  useEffect(() => {
    if (categories) {
      const categoriesList = categories?.map(({ id, name }) => ({ id, name }));
      setCategoriesList([ {
        id   : '',
        name : 'Category - All',
      }, ...categoriesList ] as ICategory[] || []);
      setCategoryId('');
    }
  }, [ categories, loadingTypes ]);

  useEffect(() => {
    const subCategoriesList = categories?.find((type) => type.id === categoryId)
      ?.subCategories?.map(({ id, name }) => ({ id, name })) || [];
    setSubCategoriesList([ {
      id   : '',
      name : 'Subcategory - All',
    }, ...subCategoriesList ] as ISubCategory[] || []);
    setSubCategoryId('');
  }, [ categoryId ]);

  useEffect(() => {
    setUserAddedCount(children && children.length);
  }, [children]);

  const getInfoForFreeAccount = () => {
    setOpenFreeInfo(true);
  };

  const handleClose = () => {
    setOpenFreeInfo(false);
  };

  return (
    <SummaryListWrapper height="100%">
      <SummaryHeader variant="h5">
        <Grid
          container
          spacing={3}
        >
          <FlexVerticalCenterGrid
            item
            xs={12}
            md={4}
            lg={5}
          >
            { title }
          </FlexVerticalCenterGrid>

          <Grid
            item
            xs={12}
            md={8}
            lg={7}
          >
            <FlexEndGrid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={4}
                hidden={!displayRoleFilter}
              >
                <Select
                  fullWidth
                  value={role}
                  options={[ {
                    id   : '',
                    name : 'User Role - All',
                  }, ...familyRoles ]}
                  renderValue={value => !value ? <><b>User Role</b> - All</> : value}
                  onChange={(value) => setRole(value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                hidden={!displayStatusFilter}
              >
                <Select
                  fullWidth
                  value={status}
                  options={taskStatusesList}
                  onChange={(value) => setStatus(value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
              >
                <Select
                  fullWidth
                  options={categoriesList}
                  value={categoryId}
                  renderValue={value =>
                    !value ? <><b>Category</b> - All</> : categoriesList.find(category => category.id === value)?.name}
                  onChange={(value) => setCategoryId(value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                hidden={!displaySubCategory}
              >
                <Select
                  fullWidth
                  options={subCategoriesList}
                  value={subCategoryId}
                  onChange={(value) => setSubCategoryId(value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                hidden={!displayDateFilter}
              >
                <Select
                  fullWidth
                  options={dateFilterOptions}
                  value={dateFilter}
                  onChange={(value) => setDateFilter(value)}
                />
              </Grid>
            </FlexEndGrid>
          </Grid>
        </Grid>
      </SummaryHeader>

      <Divider />

      <StyledList>
        { loading && !isFetchingNextPage ? <Loader minHeight="300px" /> : (
          <>
            <AddEntityControlWrapper>
              <AddEntityControl
                label={`Add ${ entityName }`}
                onAdd={() => onAdd && onAdd()}
              />
            </AddEntityControlWrapper>

            { children }
          </>
        ) }
      </StyledList>

      <LimitedModal
        openFreeInfo={openFreeInfo}
        entityName={entityName}
        setOpenFreeInfo={setOpenFreeInfo}
      />

      { hasNextPage && (
        <LoadMoreFooter
          loading={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
        />
      ) }
    </SummaryListWrapper>
  );
};
