import axios, { AxiosError }                                         from 'axios';
import { FormikHelpers }                                             from 'formik';
import qs                                                            from 'qs';
import React, { FC }                                                 from 'react';
import { useHistory }                                                from 'react-router-dom';
import { LogoBlue }                                                  from '../../shared/components/Logo';
import { Notistack }                                                 from '../../shared/components/Notistack/Notistack';
import { EAuthRoutes }                                               from '../../shared/constants/constants';
import { queryParams }                                               from '../../shared/functions';
import { IResetPasswordForm, ResetPasswordForm }                     from '../ResetPassword/ResetPasswordForm';
import { IErrorResponse }                                            from '../shared/interfaces';
import { PageHeader, PageHeaderText, SignInUpPage, SignUpContainer } from '../shared/styles';

export const AcceptInvitation: FC = () => {
  const { email, code, familyId } = queryParams();
  const history = useHistory();

  const handleSubmit = ({ password }: IResetPasswordForm, formikActions: FormikHelpers<IResetPasswordForm>) => {
    const requestBody = {
      email,
      code,
      familyId,
      password,
      joinFamily: true,
    };

    formikActions.setSubmitting(true);

    axios.post('/api/v1/invites/acception', requestBody)
      .then(() => {
        Notistack.enqueueSnackbar('Your password was successfully set.', 'success');
        history.push({
          pathname : EAuthRoutes.SignIn,
          search   : qs.stringify({ email }),
        });
      })
      .catch((err: AxiosError<IErrorResponse>) => {
        const errorDetails = err && err.response && err.response.data.ErrorDetails;
        if (errorDetails) {
          const { Message } = errorDetails;
          formikActions.setErrors({
            'password': Message,
          });
          formikActions.setSubmitting(false);
        }
      });
  };

  return (
    <SignInUpPage>
      <SignUpContainer maxWidth="md">
        <PageHeader mb="35px">
          <LogoBlue margin="0 0 30px" />

          <PageHeaderText
            centered
            maxWidth="630px"
            padding="0 30px"
          >
            Choose Your Password to Complete Registration
          </PageHeaderText>
        </PageHeader>

        <ResetPasswordForm handleSubmit={handleSubmit} />
      </SignUpContainer>
    </SignInUpPage>
  );
};
