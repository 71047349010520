import { Typography }            from '@material-ui/core';
import LockOutlinedIcon          from '@material-ui/icons/LockOutlined';
import React, { FC, useContext } from 'react';
import { Redirect }              from 'react-router-dom';
import styled                    from 'styled-components';
import { theme }                 from '../../../../styles';
import { MultiButton }           from '../../../Auth/shared/styles';
import { IFamilyUserAccount }    from '../../../modules/Settings/components/ManageUsers/Account/queries';
import { Loader }                from '../../enhancers/withLoader';
import { withQueryParams }       from '../../functions';
import AuthorizationContext      from './authorizationContext';

export interface IFamilyMember extends IFamilyUserAccount {
  viewAboutMe: boolean;
  editAboutMe: boolean;
  hasPin: boolean;
  hasEmail: boolean;
  locationAccess: boolean;
  chatAccess: boolean;
}

export enum EFamilyAccountType {
  Member     = 'Member',
  Admin      = 'Admin',
  SuperAdmin = 'SuperAdmin',
}

export const adminRoles = [ EFamilyAccountType.Admin, EFamilyAccountType.SuperAdmin ];
export const memberRole = [ EFamilyAccountType.Member ];
export const allRoles = [ ...adminRoles, ...memberRole ];

export const familyAccountTypes = [
  { value: EFamilyAccountType.Member, label: 'Member' },
  { value: EFamilyAccountType.Admin, label: 'Admin' },
];

export type TFamilyAccountType = EFamilyAccountType.Member | EFamilyAccountType.Admin | EFamilyAccountType.SuperAdmin;

const IconDenied = styled(LockOutlinedIcon)`&& {
  margin-bottom: 20px;
  font-size: 15rem;
  fill: #6f90d9;
}`;

const Page = styled.div`&& {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top, 0));
  padding: 3rem;
  background: #ebf1f6;
}`;

const StyledTypography = styled(Typography)`&& {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #2c3f69;
  font-family: 'Jost';
}`;

const StyledDescription = styled(StyledTypography)`&& {
  font-size: 18px;
  color: ${ theme.colors.disabled };
  font-family: 'Lato';
}`;

const BackButton = styled(MultiButton)`&& {
  // color: ${ theme.colors.disabled };
  margin: 25px 0 0;
  width: 100%;
  max-width: 250px;
}`;

export const withAuthorization = (
  accountTypes: TFamilyAccountType[],
  displayMessage = true
) => (WrappedComponent: any) => (props: any) => {
  const user = useContext(AuthorizationContext);

  if (!user?.data?.accountType || !accountTypes?.length) {
    return displayMessage && <Loader absolute />;
  }

  const allowed = accountTypes.includes(user?.data?.accountType);

  if (allowed) {
    if (typeof WrappedComponent === 'object'
      && WrappedComponent.$$typeof === Symbol.for('react.element')
    ) {
      return WrappedComponent;
    }

    return <WrappedComponent {...props} />;
  } else {
    const permissions = accountTypes.join(' and ');

    return displayMessage && (
      <Page>
        <IconDenied />

        <StyledTypography>
          Permission Denied
        </StyledTypography>

        <StyledDescription>
          Only { permissions } can access this page.
        </StyledDescription>

        <BackButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => window.history.back()}
        >
          Go Back
        </BackButton>
      </Page>
    );
  }
};

export const Authorized = (
  props: {
    accountTypes?: (TFamilyAccountType | undefined)[];
    redirect?: string;
    children: any;
  }
) => {
  const { accountTypes, redirect, children } = props;
  const user = useContext(AuthorizationContext);
  const _accountTypes = accountTypes?.filter(item => !!item) as TFamilyAccountType[];

  if (!_accountTypes) {
    return children;
  }

  if (!user?.data?.accountType || !accountTypes?.length) {
    return null;
  }

  const allowed = user?.data?.accountType && _accountTypes.includes(user.data.accountType);

  if (user?.data?.accountType && !allowed) {
    return redirect ? <Redirect to={withQueryParams(redirect)} /> : null;
  }

  return children;
};

export const VisibleForAdminRoles: FC = ({ children }) => (
  <Authorized accountTypes={[ EFamilyAccountType.SuperAdmin, EFamilyAccountType.Admin ]}>
    { children }
  </Authorized>
);
