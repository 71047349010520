import { FC }         from 'react';
import { datePassed } from '../../../../shared/utils/commonFunctions';
import { ITask }      from '../queries';

interface ITaskDetailsProps {
  task: ITask;
}

export const TaskAddedBy: FC<ITaskDetailsProps> = ({ task }) => (
  <span>
    Added { datePassed(task?.creationTime, true) }
  </span>
);
