import {
  CircularProgress,
  IconButton,
  MenuItem,
  AppBar as MuiHeader,
  Menu as MuiMenu,
  Toolbar,
}                                                                    from '@material-ui/core';
import { AppBarProps }                                               from '@material-ui/core/AppBar/AppBar';
import { Menu }                                                      from '@material-ui/icons';
import { HubConnectionBuilder, LogLevel }                            from '@microsoft/signalr';
import { App }                                                       from '@capacitor/app';
import axios                                                         from 'axios';
import qs                                                            from 'qs';
import { FC, useContext, useMemo, useRef, useState }                 from 'react';
import { NavLink, useHistory, useLocation }                          from 'react-router-dom';
import styled, { css }                                               from 'styled-components';
import { queryClient }                                               from '../App';
import { IFamily }                                                   from '../Auth/shared/interfaces';
import { MenuAnchor }                                                from '../Auth/shared/styles';
import { FamilyMemberCircle }                                        from '../modules/Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { CHAT_ROOMS, IMessage, useFetchChatById, useFetchChatsList } from '../modules/MessageCenter/queries';
import { LogoWhite }                                                 from '../shared/components/Logo';
import { Notistack }                                                 from '../shared/components/Notistack/Notistack';
import { EAppRoutes, PlatformNotificationType, familyCoreConfig }    from '../shared/constants/constants';
import { queryParams, withQueryParams }                              from '../shared/functions';
import { useFetchFamilyUser }                                        from '../shared/queries/family';
import { StyledBadge }                                               from '../shared/styledComponents';
import { generateInitials }                                          from '../shared/utils/commonFunctions';
import { logout }                                                    from '../shared/utils/setAuthorizationToken';
import AuthorizationContext                                          from '../shared/utils/withAuthorization/authorizationContext';
import { AddToFamilyCorePopover }                                    from './components/AddToFamilyCorePopover';
import { DynamicMessageCenter }                                      from './components/DynamicMessageCenter';
import { MessageNotification }                                       from './components/MessageNotification';
import { DRAWER_WIDTH }                                              from './Sidebar';
import { Notifications }                                             from './components/Notifications';
import { useChatHubConnection }                                      from './hooks/useChatHubConnection';
import { useNotificationsHubConnection }                             from './hooks/useNotificationsHubConnection';

interface IStyledHeaderProps extends AppBarProps {
  shifted: number;
}

const MenuIcon = styled(Menu)`&& {
  font-size: 30px;
  margin-right: 2px;
}`;

const Section = styled.div<{ key?: string }>`
  display: flex;
  align-items: center;
  min-width: 138px;
  justify-content: flex-end;
`;

const LogoSection = styled(Section)`
  ${ ({ theme: { breakpoints } }) => `${ breakpoints.up('lg') } { visibility: hidden; }` }
`;

const StyledHeader: FC<IStyledHeaderProps> = styled(MuiHeader)`&& ${ ({ shifted, theme: { transitions, breakpoints } }: any) => css`{
  position         : absolute;
  background-color : #6f90d9;
  box-shadow       : none;

  ${ shifted && `width: calc(100% - ${ DRAWER_WIDTH }px)` };

  ${ breakpoints.up('lg') } {
    background-color: #ebf1f6;
  }

  transition: ${ transitions.create([ 'margin', 'width' ], {
    easing   : shifted ? transitions.easing.easeOut : transitions.easing.sharp,
    duration : shifted ? transitions.duration.enteringScreen : transitions.duration.leavingScreen,
  }) };
` }`;

const StyledLink = styled(NavLink)`&& {
  color: #262a32;
}`;

const StyledToolbar = styled(Toolbar)`&& {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0 30px 0 10px;
}`;

const TransitionWrapper = styled.div<{ visible: boolean }>`
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ StyledBadge } {
    transition: opacity .5s ease;
    opacity: ${ ({ visible }) => visible ? 1 : 0 }
  }

  .MuiCircularProgress-root {
    color: #fff;
    opacity: ${ ({ visible }) => visible ? 0 : 1 };
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }
`;

export const IconButtonSpaced = styled(IconButton)`&& {
  margin-right: -12px;
}`;

const HeaderMenuItem = styled(MenuItem)`&& {
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;

  &:first-child {
    padding-top: 4px;
  }

  &:last-child {
    padding-bottom: 4px;
  }
}`;

declare const window: any;

interface IHeaderOwnProps {
  family?: IFamily;
  isMobile: boolean;
  sidebarOpen: boolean;
  toggleDrawer: () => void;
}

export const notificationRedirection = (type: PlatformNotificationType, id?: string, userId?: string): string => {
  switch (type) {
    case(PlatformNotificationType.Checkin):
      return EAppRoutes.Locate;
    case(PlatformNotificationType.EventAdded):
      return `${EAppRoutes.Schedule}?id=${userId}`;
    case(PlatformNotificationType.EventDeleted):
    case(PlatformNotificationType.EventReminder):
      return `${EAppRoutes.Schedule}?id=${userId}`;
    case(PlatformNotificationType.EventUpdated):
      return `${EAppRoutes.Schedule}?id=${userId}`;
    case(PlatformNotificationType.TaskReminder):
      return `${EAppRoutes.TasksSummary}/${id}`;
    case(PlatformNotificationType.TaskAdded):
      return `${EAppRoutes.TasksSummary}/${id}`;
    case(PlatformNotificationType.DocumentAdded):
      return `${EAppRoutes.DocumentsSummary}/${id}`;
    case(PlatformNotificationType.ContactAdded):
      return `${EAppRoutes.ContactsSummary}/${id}`;
    case(PlatformNotificationType.DocumentViewed):
      return `${EAppRoutes.DocumentsSummary}/${id}`;
    default:
      return EAppRoutes.Dashboard;
  }
};

export const setupHubConnection = (hubPath: string, accessToken: string) => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${axios.defaults.baseURL}${hubPath}`, {
      accessTokenFactory: () => `${accessToken}`,
    })
    .configureLogging(LogLevel.Debug)
    .build();

  return connection;
};

export const Header: FC<IHeaderOwnProps> = ({
  family,
  isMobile,
  sidebarOpen,
  toggleDrawer,
}) => {
  const { chatOpened, selectedChat } = queryParams();

  const menuAnchorRef = useRef(null);
  const currentUser   = useContext(AuthorizationContext);

  const [anchorEl, setAnchorEl]           = useState(null);
  const [isRefetchDone, setIsRefetchDone] = useState(false);

  const history    = useHistory();
  const { search } = useLocation();

  const { data: user }                                                          = useFetchFamilyUser(family?.id);
  const { data: chat, isLoading: loadingChat, refetch: refetchChatById }        = useFetchChatById(selectedChat);
  const { data: chatRooms, isLoading: loadingRooms, refetch: refetchChatsList } = useFetchChatsList(user?.id);

  const setChatOpened = (chatOpened: boolean) => {
    history.push({
      search: qs.stringify({ ...queryParams(), chatOpened }, { skipNulls: true }),
    });
  };

  const onOpenChat = (roomId = '', messageId?: number) => {
    Notistack.closeSnackbar(messageId);

    history.push({
      search: qs.stringify({
        ...queryParams(),
        chatOpened   : !!roomId,
        selectedChat : roomId,
      }, { skipNulls: true }),
    });
  };

  const onSelectChat = (id: string) => {
    history.push({
      search: qs.stringify({
        ...queryParams(),
        chatOpened   : id ? true : null,
        selectedChat : id,
      }, { skipNulls: true }),
    });
  };

  const updateChatHistory = (message: IMessage) => {

    if (currentUser?.data?.id !== message?.user?.familyUserId) {
      const { selectedChat } = queryParams();

      queryClient.setQueryData([CHAT_ROOMS, message?.roomId], (oldData: any) => {
        if (oldData?.data?.data?.messages) {

          return {
            ...oldData,
            data: {
              ...oldData?.data,
              data: {
                ...oldData?.data?.data,
                unreadMessagesCount : oldData.data.data.id === selectedChat ? 0 : oldData.data.data.unreadMessagesCount + 1,
                messages            : [message, ...oldData?.data?.data?.messages],
              },
            },
          };
        };

        return oldData;
      });

      refetchChatsList();
    }
  };

  const onOpenNotification = (redirectRoute: string, key: number) => {
    history.push(redirectRoute);
    Notistack.closeSnackbar(key);
  };

  const createNotification = (data: any) => {
    const { EventType: type, Id: id } = data;

    const redirectRoute = notificationRedirection(type, id, user?.id);

    const Content = (key: number) => (
      <Notifications
        key={key}
        type={type}
        data={data}
        onOpen={() => onOpenNotification(redirectRoute, key)}
        onClose={() => Notistack.closeSnackbar(key)}
      />
    );

    {type && (
      Notistack.enqueueSnackbar('', 'default', {
        preventDuplicate : true,
        autoHideDuration : 5000,
        action           : Content,
      })
    );
    };
  };

  const createChatNotification = (message: IMessage) => {
    const { chatOpened, selectedChat } = queryParams();
    const isOtherChatMessage = selectedChat && selectedChat !== message?.roomId;
    if ((isOtherChatMessage || !selectedChat || !chatOpened) && message.user) {
      const Content = (key: number) => (
        <MessageNotification
          key={key}
          message={message}
          onOpen={() => onOpenChat(message?.roomId, key)}
          onClose={() => Notistack.closeSnackbar(key)}
        />
      );
      Notistack.enqueueSnackbar('', 'default', {
        preventDuplicate : false,
        autoHideDuration : 5000,
        action           : Content,
      });
    }
  };

  const createReceiveChatCreationNotification = (message: IMessage) => {
    createChatNotification(message);
    refetchChatsList();
  };

  useChatHubConnection({
    family,
    onReceiveMessage: updateChatHistory,
  });
  useNotificationsHubConnection({
    family,
    onReceiveNotification             : createNotification,
    onReceiveChatMessage              : createChatNotification,
    onReceiveChatCreationNotification : createReceiveChatCreationNotification,
  });

  const handleClick = () => {
    setAnchorEl(menuAnchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  App.addListener('appStateChange', ({ isActive }) => {
    if (isActive && !isRefetchDone) {
      refetchChatsList();
      setIsRefetchDone(true);
    } else if (!isActive) {
      setIsRefetchDone(false);
    }
  });

  return useMemo(() => (
    <StyledHeader shifted={+(sidebarOpen && !isMobile)}>
      <StyledToolbar
        disableGutters
        variant="dense"
      >
        <LogoSection>
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <NavLink to={`${ EAppRoutes.Dashboard }?id=${ user?.id }`}>
            <LogoWhite
              width="83px"
              height="34px"
            />
          </NavLink>
        </LogoSection>

        <Section>
          <AddToFamilyCorePopover
            familyId={family?.id}
            familyMemberId={user?.id}
          />

          { familyCoreConfig.isChatEnabled &&
              <DynamicMessageCenter
                chat={chat}
                user={user}
                loadingChat={loadingChat}
                loadingRooms={loadingRooms}
                chatRooms={chatRooms}
                setSelectedChat={onSelectChat}
                selectedChat={selectedChat}
                familyId={family?.id}
                chatOpened={search.includes('chatOpened=true')}
                setChatOpened={setChatOpened}
              />}

          <IconButtonSpaced
            color="inherit"
            onClick={handleClick}
          >
            <TransitionWrapper visible={!!currentUser}>
              <CircularProgress
                size={20}
                color="primary"
              />

              <StyledBadge
                color="secondary"
                badgeContent={0}
                aria-controls="user-menu"
                aria-haspopup="true"
              >
                <FamilyMemberCircle
                  fixed
                  fontSize="14px"
                  color={currentUser?.data?.color}
                  image={currentUser?.data?.avatar?.compactPath}
                  initials={generateInitials(currentUser?.data?.firstName, currentUser?.data?.lastName)}
                />

                <MenuAnchor
                  ref={menuAnchorRef}
                  top="55px"
                  right="90px"
                />
              </StyledBadge>
            </TransitionWrapper>
          </IconButtonSpaced>

          <MuiMenu
            keepMounted
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledLink to={EAppRoutes.MyProfile}>
              <HeaderMenuItem>Settings</HeaderMenuItem>
            </StyledLink>

            <StyledLink to={withQueryParams(EAppRoutes.NotificationPreferences, { familyUserId: currentUser?.data?.id })}>
              <HeaderMenuItem>Notifications</HeaderMenuItem>
            </StyledLink>

            <HeaderMenuItem onClick={logout}>Log Out</HeaderMenuItem>
          </MuiMenu>
        </Section>
      </StyledToolbar>
    </StyledHeader>
  ), [
    chat,
    chatRooms,
    chatOpened,
    selectedChat,
    loadingChat,
    loadingRooms,
    anchorEl,
    currentUser,
    sidebarOpen,
    isMobile,
    window.location.search,
  ]);
};
