import Box                                         from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography                                  from '@material-ui/core/Typography';
import React, { FC }                               from 'react';
import styled                                      from 'styled-components';

interface ICircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
  unit?: string;
  margin?: string;
  fontSize?: string;
  scale?: number;
}

const Wrapper = styled(Box)<{ margin?: string }>`
  background: #f1f1f1;
  border-radius: 100%;
  margin: ${ ({ margin }) => margin || '50px 0 20px' };
`;

const Count = styled(Typography)<{ fontSize?: string }>`&& {
  color: #2c3f69;
  font-size: ${ ({ fontSize }) => fontSize || '36px' };
  text-align: center;
  display: flex;
  flex-direction: column;
}`;

const Unit = styled.span`
  font-size: 16px;
  color: #e5572e;
`;

export const CircularProgressWithLabel: FC<ICircularProgressWithLabelProps> = (props) => {
  const {
    scale = 100,
    unit  = '%',
    value,
    margin,
    fontSize,
  } = props;

  const displayValue = scale * value / 100;

  return (
    <Wrapper
      margin={margin}
      position="relative"
      display="inline-flex"
    >
      <CircularProgress
        size={60}
        variant="determinate"
        {...props}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        display="flex"
        position="absolute"
        alignItems="center"
        justifyContent="center"
      >
        <Count variant="h5" fontSize={fontSize}>
          { `${ Math.round(displayValue) }` }
          <Unit>{ unit }</Unit>
        </Count>
      </Box>
    </Wrapper>
  );
};
