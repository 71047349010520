import { Title, Wrapper, ReactLogoBasketWrapper } from './NoDataFound.style';

const NoDataFound = () => (
  <Wrapper>
    <ReactLogoBasketWrapper />
    <Title>Unfortunately, there's nothing here.</Title>
  </Wrapper>
);

export { NoDataFound };
