import React, { FC, useContext, useState }           from 'react';
import { useHistory }                                from 'react-router-dom';
import styled                                        from 'styled-components';
import { useLoadDocumentsPage }                      from '../../../shared/components/modals/UploadDocument/queries';
import { UploadDocumentModal }                       from '../../../shared/components/modals/UploadDocument/UploadDocumentModal';
import { useFetchSelectedFamily }                    from '../../../shared/components/SelectFamily/queries';
import { EAppRoutes }                                from '../../../shared/constants/constants';
import { documentTypes }                             from '../../../shared/documentTypes';
import { queryParams, shouldDisplayActionsMenu }     from '../../../shared/functions';
import { useFetchFamilyMembers, useFetchFamilyUser } from '../../../shared/queries/family';
import AuthorizationContext                          from '../../../shared/utils/withAuthorization/authorizationContext';
import { NoActivityScreen }                          from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                     from '../../Activity/ActivityRecordPreview';
import { CategoryPills }                             from '../shared/CategoryPills';
import { EntitiesListDetailed, IEntityFilters }      from '../shared/EntitiesListDetailed';
import { DocumentAddedBy }                           from './components/DocumentAddedBy';
import { DocumentIcon }                              from './components/DocumentIcon';
import { DocumentMenuItems }                         from './components/DocumentMenuItems';
import { IFamily }                                   from '../../../Auth/shared/interfaces';

const NoActivityScreenWrapper = styled.div`
  height: 220px;
`;

interface IDocumentsListPreviewProps {
  familyId?: string;
}

export const DocumentsListDetailed: FC<IDocumentsListPreviewProps> = ({ familyId }) => {
  const history = useHistory();
  const { data: familyMembers } = useFetchFamilyMembers(familyId, { enabled: !!familyId });
  const { data: family, isLoading: loadingFamily } = useFetchSelectedFamily();
  const { data: user } = useFetchFamilyUser(family?.id);

  const [ filters, setFilters ] = useState<IEntityFilters>();
  const params = queryParams();
  const familyMemberId = params?.id as string;

  const currentUser = useContext(AuthorizationContext);

  const { data: documents, isFetching, refetch } = useLoadDocumentsPage(
    familyId,
    familyMemberId,
    filters?.categoryId,
    filters?.subCategoryId,
    filters?.dateFilter,
    100
  );
  const [ addDocumentModalOpen, setAddDocumentModalOpen ] = useState(false);
  const items = documents?.pages?.[0]?.items;

  const viewDocument = (documentId?: string) => {
    history.push(`${ EAppRoutes.DocumentsSummary }/${ documentId }`);
  };

  return (
    <>
      <EntitiesListDetailed
        displaySubCategory
        title="Documents"
        entityName="Document"
        familyId={familyId}
        loading={isFetching}
        onAdd={() => setAddDocumentModalOpen(true)}
        onFilterChange={(filters) => setFilters(filters)}
      >
        { items?.length ? items.map((document, index, array) => (
          <ActivityRecordPreview
            key={index}
            showMenu={shouldDisplayActionsMenu(document, currentUser)}
            description={document.name}
            isLast={index === array.length - 1}
            icon={<DocumentIcon />}
            familyMembers={familyMembers}
            assigned={document?.assigned}
            details={<DocumentAddedBy document={document} />}
            content={<CategoryPills category={document?.category} subCategory={document?.subCategory} />}
            menuItems={<DocumentMenuItems
              document={document}
              familyId={familyId}
              familyUserId={user?.id}
            />}
            onClick={() => viewDocument(document?.id)}
          />
        )) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.documents.icon }
              There are no documents available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListDetailed>

      { addDocumentModalOpen && (
        <UploadDocumentModal
          familyId={familyId}
          familyUserId={familyMemberId}
          open={addDocumentModalOpen}
          onClose={() => setAddDocumentModalOpen(false)}
        />
      ) }
    </>
  );
};
