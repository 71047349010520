import axios, { AxiosResponse }   from 'axios';
import { useMutation }            from 'react-query';
import { queryClient }            from '../../../../App';
import { IRequestResponse }       from '../../../../Auth/shared/interfaces';
import { Notistack }              from '../../../../shared/components/Notistack/Notistack';
import { FAMILIES_QUERY_KEY }     from '../../../../shared/queries/family';
import { useCreateQuery }         from '../../../../shared/utils/hooks/useReactQuery';
import { handleApiErrorResponse } from '../../../../utils/apiHelpers';
import { IInvite }                from '../../../Dashboard/AddMembers/PreRegistration/queries';

export const INVITE_REQUESTS_GET = 'INVITE_REQUESTS_GET';
export const INVITE_REQUESTS_ACCEPT = 'INVITE_REQUESTS_ACCEPT';
export const INVITE_REQUESTS_SEND_REMINDER = 'INVITE_REQUESTS_SEND_REMINDER';

export const useFetchPendingRequests = (familyId?: string) => useCreateQuery<IInvite[]>({
  queryKey : [ INVITE_REQUESTS_GET, familyId ],
  apiUrl   : '/api/v1/invites',
  options  : {
    enabled              : !!familyId,
    refetchOnWindowFocus : false,
  },
  config: {
    params: {
      familyId,
    },
  },
});

export const useFetchIncomingRequests = () => useCreateQuery<IInvite[]>({
  queryKey : [ INVITE_REQUESTS_GET ],
  apiUrl   : '/api/v1/invites/user/me',
  options  : {
    refetchOnWindowFocus: false,
  },
});

const sendInvitationReminder = (familyUserId: string) =>
  axios.post(`/api/v1/invites/reminder?familyUserId=${ familyUserId }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data);

export const useSendInvitationReminder = () => useMutation(
  INVITE_REQUESTS_SEND_REMINDER,
  (familyUserId: string) => sendInvitationReminder(familyUserId), {
    onSuccess: (data) => {
      Notistack.enqueueSnackbar(data?.message || 'Reminder sent.', 'success');
    },
    onError: handleApiErrorResponse,
  });

const acceptInvitationRequest = (familyUserId: string) =>
  axios.post(`/api/v1/invites/request?familyUserId=${ familyUserId }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data);

export const useAcceptInvitationRequest = () => useMutation(
  INVITE_REQUESTS_ACCEPT,
  (familyUserId: string) => acceptInvitationRequest(familyUserId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ FAMILIES_QUERY_KEY ]);
      queryClient.invalidateQueries([ INVITE_REQUESTS_GET ]);
      Notistack.enqueueSnackbar(data?.message || 'Request accepted.', 'success');
    },
    onError: handleApiErrorResponse,
  });
