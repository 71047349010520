import axios, { AxiosError, AxiosResponse }                       from 'axios';
import { useMutation }                                            from 'react-query';
import { queryClient }                                            from '../../../../../App';
import { IErrorResponse, IRequestResponse, IUploadImageResponse } from '../../../../../Auth/shared/interfaces';
import { Notistack }                                              from '../../../../../shared/components/Notistack/Notistack';
import { EAccountStatus }                                         from '../../../../../shared/constants/constants';
import { EFamilyRole, FAMILIES_QUERY_KEY, FAMILY_MEMBERS_GET }    from '../../../../../shared/queries/family';
import {
  TCreateQueryOptions,
  useCreateQuery,
}                                                                 from '../../../../../shared/utils/hooks/useReactQuery';
import {
  EFamilyAccountType,
  IFamilyMember,
}                                                                 from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { INVITE_REQUESTS_GET }                                    from '../../Requests/queries';

export interface IFamilyUserAccount {
  id: string;
  color: string;
  familyRole: EFamilyRole;
  accountType: EFamilyAccountType;
  avatar: IUploadImageResponse;
  firstName: string;
  lastName: string;
  status: EAccountStatus;
}

export interface IUpdateFamilyUserAccount {
  familyRole: string;
  accountType: string;
}

export const FAMILY_USER_ACCOUNT_GET = 'FAMILY_USER_ACCOUNT_GET';
export const FAMILY_USER_ACCOUNT_UPDATE = 'FAMILY_USER_ACCOUNT_UPDATE';
export const FAMILY_USER_ACCOUNT_DELETE = 'FAMILY_USER_ACCOUNT_DELETE';
export const FAMILY_USER_INVITE_CANCEL = 'FAMILY_USER_INVITE_CANCEL';

export const useFetchUserAccount = (
  familyId?: string,
  familyUserId?: string,
  options?: TCreateQueryOptions<IFamilyMember>
) => useCreateQuery<IFamilyMember>({
  queryKey : [ FAMILY_USER_ACCOUNT_GET, familyUserId ],
  apiUrl   : `/api/v1/family-users/${ familyId }/${ familyUserId }`,
  options  : {
    enabled              : !!familyId && !!familyUserId,
    refetchOnWindowFocus : false,
    ...options,
  },
});

export const updateUserAccount = (
  data: IUpdateFamilyUserAccount,
  familyUserId?: string,
  familyId?: string,
) =>
  axios.put(`/api/v1/family-users/${ familyId }/${ familyUserId }`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamilyUserAccount>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateUserAccount = (
  familyUserId?: string,
  familyId?: string,
) => useMutation(
  FAMILY_USER_ACCOUNT_UPDATE,
  (
    data: IUpdateFamilyUserAccount
  ) => updateUserAccount(data, familyUserId, familyId),
  {
    onSuccess: data => {
      Notistack.enqueueSnackbar('Account was successfully updated.', 'success');
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
    },
  }
);

export const deleteUserAccount = (
  familyUserId?: string,
  familyId?: string,
) =>
  axios.delete(`/api/v1/family-users/${ familyId }/${ familyUserId }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useDeleteUserAccount = (
  familyUserId?: string,
  familyId?: string,
) => useMutation(
  FAMILY_USER_ACCOUNT_DELETE,
  () => deleteUserAccount(familyUserId, familyId),
  {
    onSuccess: data => {
      Notistack.enqueueSnackbar(`${ data?.message }`, 'success');
      queryClient.invalidateQueries([ FAMILY_MEMBERS_GET, familyId ]);
      queryClient.invalidateQueries([ FAMILIES_QUERY_KEY ]);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
    },
  }
);

export const cancelInvite = (
  familyUserId?: string,
  isAdmin?: boolean
) =>
  axios.delete(`/api/v1/invites/${ isAdmin ? 'admin' : 'user' }?familyUserId=${ familyUserId }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useCancelInvitationRequest = (isAdmin = true) => useMutation(
  [ FAMILY_USER_INVITE_CANCEL, isAdmin ],
  (familyUserId?: string) => cancelInvite(familyUserId, isAdmin),
  {
    onSuccess: data => {
      Notistack.enqueueSnackbar('Request was successfully canceled.', 'success');
      queryClient.invalidateQueries([ FAMILY_MEMBERS_GET ]);
      queryClient.invalidateQueries([ INVITE_REQUESTS_GET ]);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
    },
  }
);
