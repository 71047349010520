import axios from 'axios';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Grid, Button } from '@material-ui/core';
import { SnackbarKey, useSnackbar } from 'notistack';
import { UserDataTable } from '../DataTable/UserDataTable';
import { IAdminStatistics, IAdminUserStatistics } from './interfaces';
import { formatDate } from '../../utils/commonFunctions';
import { PLATFORM_TAG, SUBSCRIPTION_TAG } from '../../constants/constants';
import { DatePickerStatistics } from './DatePickerStatistics';
import { getReport } from './reportsApi';
import { getAuthToken } from '../../../utils/authHelpers';
import { ITokens } from '../../interfaces/auth';
import { ICreatorTabStatistics, IDate } from './interfaces';
import {
  optionsError,
  optionsSuccess,
  optionsLoading,
} from './constants';

const validationSchema = Yup.object().shape({
  startDate : Yup.string(),
  endDate   : Yup.string(),
  isDeleted : Yup.boolean(),
});

const DateWrapper = styled(Grid)`
  margin: 50px 0;
`;

const FormButtonWrapper = styled(Grid)`
  height: 44px;
  margin-top: 15px;
  width: 80%;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.down('md') } {
    margin-left: -27px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.down('sm') } {
    margin-left: -47px;
  }
`;

const PageContainer = styled.div`
  background-color: rgb(247, 250, 253);
  padding: 2px 50px;
`;

const SelectDateBlock = styled(Grid)`
  display: flex;

  input {
    width: auto;
  }

  h3 {
    font-size: 22px;
    font-weight: 505;
    color: rgb(70, 108, 157);
    align-items: center;
    display: flex;
    margin: 0 25px 0 -8px;

    ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
      font-size: 15px;
    }
  }
`;

const FormButton = styled(Button)`&& {
  border-radius: 9px;
  background-color: #547cd3;
  float: right;
  width: 80%;
  
  &:hover {
    background-color: #4b6ebb;
  }
}`;

export const CreatorTabStatistics: FC<ICreatorTabStatistics> = ({ familyCreator }) => {
  const defaultDate = {
    endDate   : new Date().toISOString().slice(0, -1),
    startDate : new Date().toISOString().slice(0, -1),
    isDeleted : false,
  };

  const authToken: ITokens = getAuthToken();

  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

  const testLink = 'http://localhost:3000';
  const qaLink = 'https://familycore-qa-app-service-ui';

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey | undefined>(undefined);

  const [selectedDate, setSelectedDate] = useState<IDate>(defaultDate);
  const [isProdVersion, setIsProdVersion] = useState<boolean>(false);
  const [statisticsData, setStatisticsData] = useState<IAdminStatistics[] | IAdminUserStatistics[] | undefined | null | any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError]     = useState<boolean>(false);

  const formikRef = useRef<FormikProps<any>>(null);

  const currentUrl = window.location.href;

  useEffect(() => {
    if (currentUrl.startsWith(testLink) || currentUrl.startsWith(qaLink)) {
      setIsProdVersion(false);
    } else {
      setIsProdVersion(true);
    }
  }, [currentUrl]);

  function checkDateFormat(dateStr: any) {
    if (!isoDatePattern.test(dateStr)) {
      const date = new Date(dateStr);
      const localDateStr = date.toLocaleString('sv-SE', { timeZoneName: 'short' }).replace(' ', 'T');
      return localDateStr.split(' ')[0];
    } else {
      return dateStr;
    }
  }

  const getStatisticsCreatorsInfo = (date: IDate) => {
    const endDate = date?.endDate ? date?.endDate : date?.startDate;

    axios.get(`api/v1/statistics/creators?StartDate=${checkDateFormat(date?.startDate)}Z&EndDate=${checkDateFormat(endDate)}Z&IsDeleted=${date?.isDeleted}`)
      .then((data) => {
        setStatisticsData(data?.data?.data?.item1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getStatisticsUsersInfo = (date: IDate) => {
    const endDate = date?.endDate ? date?.endDate : date?.startDate;
    axios.get(`api/v1/statistics/new-users?StartDate=${checkDateFormat(date?.startDate)}Z&EndDate=${checkDateFormat(endDate)}Z&IsDeleted=${date?.isDeleted}`)
      .then((data) => {
        setStatisticsData(data?.data?.data?.item1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCorrectStatisticsFunction = () => {
    if (familyCreator) {
      getStatisticsCreatorsInfo(selectedDate);
    } else {
      getStatisticsUsersInfo(selectedDate);
    }
  };

  useEffect(() => {
    selectedDate && getCorrectStatisticsFunction();
  }, [familyCreator, selectedDate]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading && !snackbarKey) {
      const key = enqueueSnackbar(`Please wait. Don't refresh and don't close page.`, optionsLoading);
      setSnackbarKey(key);
    } else if (isSuccess && snackbarKey) {
      closeSnackbar(snackbarKey);
      setSnackbarKey('');
      enqueueSnackbar('Report downloaded', optionsSuccess);
    } else if (isError) {
      closeSnackbar(snackbarKey);
      setSnackbarKey(undefined);
      enqueueSnackbar('Error', optionsError);
    }
  }, [isLoading, isSuccess, isError, snackbarKey, enqueueSnackbar, closeSnackbar]);

  const getTableRow = (itemData: IAdminStatistics[] | undefined) => itemData?.map((item) => {
    const mainContent = {
      firstName    : item?.user?.firstName,
      lastName     : item?.user?.lastName,
      middleName   : item?.user?.middleName || '-',
      country      : item?.user?.country || '-',
      registered   : formatDate(item?.user?.registeredAt) || '-',
      email        : item?.user?.email || '-',
      phoneNumber  : item?.user?.phoneNumber || '-',
      platformType : PLATFORM_TAG[item?.user?.platformType as keyof typeof PLATFORM_TAG] || '-',
      planType     : SUBSCRIPTION_TAG[item?.paymentPlatformType as keyof typeof SUBSCRIPTION_TAG] || '-',
    };

    return mainContent;
  });

  const getUserTableRow = (itemData: IAdminUserStatistics[] | undefined) => itemData?.map((item) => {
    const mainContent = {
      firstName    : item?.firstName,
      lastName     : item?.lastName,
      middleName   : item?.middleName || '-',
      country      : item?.country || '-',
      registered   : formatDate(item?.registeredAt) || '-',
      email        : item?.email || '-',
      phoneNumber  : item?.phoneNumber || '-',
      platformType : PLATFORM_TAG[item?.platformType as keyof typeof PLATFORM_TAG] || '-',
    };

    return mainContent;
  });

  const getCorrectTable = (data: any) => {
    if (familyCreator) {
      return getTableRow(data);
    } else if (!familyCreator) {
      return getUserTableRow(data);
    }
  };

  const getUserReports = () => {
    const endDate = selectedDate?.endDate ? selectedDate?.endDate : selectedDate?.startDate;
    getReport({
      requestString : `StartDate=${checkDateFormat(selectedDate?.startDate)}Z&EndDate=${checkDateFormat(endDate)}Z&IsDeleted=${selectedDate?.isDeleted}`,
      typeReport    : familyCreator ? 'creators-excel-report' : 'new-users-excel-report',
      fileName      : `spent-${selectedDate?.startDate}-${endDate || selectedDate?.startDate}.xlsx`,
      authToken     : authToken.accessToken,
      isProdVersion,
      setIsLoading,
      setIsSuccess,
      setIsError,
    });
  };

  return (
    <PageContainer>
      <div>
        <DateWrapper container spacing={2}>
          <SelectDateBlock item xs={12} sm={6} md={6}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                startDate : new Date(),
                endDate   : new Date(),
                isDeleted : false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (!values.endDate) {
                  setSelectedDate({
                    endDate   : values.startDate,
                    startDate : values.startDate,
                    isDeleted : values.isDeleted,
                  });
                } else setSelectedDate(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <DatePickerStatistics formikRef={formikRef} values={values} setFieldValue={setFieldValue} />
              )}
            </Formik>
          </SelectDateBlock>
          <FormButtonWrapper item xs={12} sm={6} md={6}>
            <FormButton
              type="button"
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => getUserReports()}
            >
              Export the .xlsx table
            </FormButton>
          </FormButtonWrapper>
        </DateWrapper>

        <Grid container xs={12} sm={12} md={12} spacing={2}>
          <UserDataTable
            familyCreator={familyCreator}
            statisticsData={(statisticsData && getCorrectTable(statisticsData)) || []}
          />
        </Grid>
      </div>
    </PageContainer>
  );
};
