import { FieldProps, getIn } from 'formik';
import React, { FC }         from 'react';
import { PhoneInput }        from '../inputs/PhoneInput/PhoneInput';

interface IPhoneFieldOwnProps extends FieldProps {
  id?: string;
}

export const PhoneInputField: FC<IPhoneFieldOwnProps> = ({
  field,
  form,
  ...props
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return (
    <PhoneInput
      {...field}
      {...props}
      id={props.id || field.name}
      error={touched && !!error}
      helperText={(touched && error) || ''}
    />
  );
};
