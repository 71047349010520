import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { FC, useEffect, useState }     from 'react';
import styled                                 from 'styled-components';
import { ReactComponent as IconDocument }     from '../../../../images/icons/icon-document.svg';
import { LinkLikeText }                       from '../../../shared/components/documents/DocumentPicker';
import { ProvidePinModal }                    from '../../../shared/components/modals/ProvidePinModal';
import { TermsModal }                         from '../../../shared/components/modals/Terms/TermsModal';
import { IDocument, useDownloadDocument }     from '../../../shared/components/modals/UploadDocument/queries';
import { IAttachment }                        from '../../../shared/components/modals/UploadDocument/validation';
import { formatBytes }                        from '../../../shared/utils/commonFunctions';

const DocumentField = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 337px;
  padding: 24px;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 6px;
  background-color: #f7f7f7;
  color: #272932;
  word-break: break-all;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 48px;
  }
`;

const DocumentImage = styled(IconDocument)`
  margin-bottom: 15px;
`;

const CheckboxWrapper = styled.div`
  margin: 22px 0 12px;
  text-align: left;
`;

const CheckboxLabel = styled.span`
  font-size: 12px;
`;

const FileSize = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: rgba(39, 41, 50, 0.7);
  margin-top: 6px;
`;

interface IDownloadDocumentProps {
  familyId?: string;
  attachment: IAttachment;
  document: IDocument;
}

export const DownloadDocument: FC<IDownloadDocumentProps> = ({
  familyId,
  attachment,
  document,
}) => {
  const [ acceptedTerms, setAcceptedTerms ] = useState(false);
  const [ termsModalOpened, setTermsModalOpened ] = useState(false);
  const [ downloadModalOpenFor, setDownloadModalOpenFor ] = useState('');
  const [ downloadProgress, setDownloadProgress ] = useState<number>();
  const { mutate: downloadDocument, isSuccess: documentIsLoaded } =
          useDownloadDocument(document?.id, attachment?.path, familyId, setDownloadProgress);

  useEffect(() => {
    if (documentIsLoaded) {
      setDownloadProgress(0);
      setDownloadModalOpenFor('');
    }
  }, [ documentIsLoaded ]);

  const onPinAccepted = (code: string, options: any) => {
    downloadDocument([ code, downloadModalOpenFor ], { onError: options.onError });
  };

  const termsAndConditionsLink = (
    <CheckboxLabel>
      I accept the <LinkLikeText onClick={() => setTermsModalOpened(true)}>terms and conditions</LinkLikeText> of
      downloading this document
    </CheckboxLabel>
  );

  const documentName = attachment?.path.split('/').reverse()?.[0];

  return (
    <>
      <DocumentField>
        <DocumentImage />

        { documentName }

        <FileSize>
          { formatBytes(attachment?.documentSize) }
        </FileSize>

        <CheckboxWrapper>
          <FormControlLabel
            label={termsAndConditionsLink}
            control={
              <Checkbox
                value={acceptedTerms}
                checked={acceptedTerms}
                onChange={(event) => setAcceptedTerms(event.target.checked)}
              />
            }
          />
        </CheckboxWrapper>

        <Button
          type="submit"
          size="medium"
          color="primary"
          variant="contained"
          disabled={!acceptedTerms}
          onClick={() => setDownloadModalOpenFor(attachment.id)}
        >
          Download
        </Button>
      </DocumentField>

      <TermsModal
        open={termsModalOpened}
        onClose={() => setTermsModalOpened(false)}
      />

      { downloadModalOpenFor && (
        <ProvidePinModal
          open={!!downloadModalOpenFor}
          progress={downloadProgress}
          onSuccess={(code, options) => onPinAccepted(code, options)}
          onClose={() => setDownloadModalOpenFor('')}
        />
      ) }
    </>
  );
};
