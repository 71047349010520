import { Grid }                   from '@material-ui/core';
import React, { FC }              from 'react';
import { useFetchSelectedFamily } from '../../../../shared/components/SelectFamily/queries';
import { queryParams }            from '../../../../shared/functions';
import { ActivityItems }          from '../../../Activity/ActivityItems';
import { ActivityPageHeader }     from '../../../MyFamilyCore/shared/styles';
import { ActivitiesMenu }         from './ActivitiesMenu';

export const MembersActivity: FC = () => {
  const params = queryParams();
  const familyMemberId = params?.id as string;
  const { data: family } = useFetchSelectedFamily();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={3}
      >
        <ActivityPageHeader>Summary</ActivityPageHeader>

        <ActivitiesMenu
          familyId={family?.id}
          familyMemberId={familyMemberId}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
      >
        <ActivityItems
          familyId={family?.id}
          familyMemberId={familyMemberId}
        />
      </Grid>
    </Grid>
  );
};
