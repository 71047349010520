import React, { FC, useState }                  from 'react';
import { useHistory }                           from 'react-router-dom';
import styled                                   from 'styled-components';
import { useLoadContactsPage }                  from '../../../shared/components/modals/UploadContact/queries';
import { UploadContactModal }                   from '../../../shared/components/modals/UploadContact/UploadContactModal';
import { EAppRoutes }                           from '../../../shared/constants/constants';
import { documentTypes }                        from '../../../shared/documentTypes';
import { queryParams }                          from '../../../shared/functions';
import { useFetchFamilyMembers }                from '../../../shared/queries/family';
import { addSpaceBeforeCapitals, fullName }     from '../../../shared/utils/commonFunctions';
import { NoActivityScreen }                     from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                from '../../Activity/ActivityRecordPreview';
import { EntitiesListDetailed, IEntityFilters } from '../shared/EntitiesListDetailed';
import { EntityRecordAvatar }                   from '../shared/EntityRecordAvatar';
import { ContactMenuItems }                     from './components/ContactMenuItems';
import { ContactPills }                         from './components/ContactPills';

const NoActivityScreenWrapper = styled.div`
  height: 220px;
`;

interface IContactsListPreviewProps {
  familyId?: string;
}

export const ContactsListDetailed: FC<IContactsListPreviewProps> = ({ familyId }) => {
  const history = useHistory();
  const { data: familyMembers } = useFetchFamilyMembers(familyId, { enabled: !!familyId });
  const [ filters, setFilters ] = useState<IEntityFilters>();
  const params = queryParams();

  const { data: contacts, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useLoadContactsPage(
    familyId,
    params?.id as string,
    filters?.role,
    filters?.categoryId,
    100
  );
  const [ addContactModalOpen, setAddContactModalOpen ] = useState(false);
  const items = contacts?.pages?.[0]?.items;

  const viewContact = (contactId?: string) => {
    history.push(`${ EAppRoutes.ContactsSummary }/${ contactId }`);
  };

  return (
    <>
      <EntitiesListDetailed
        displayRoleFilter
        title="Contacts"
        entityName="Contact"
        familyId={familyId}
        loading={isFetching}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        onAdd={() => setAddContactModalOpen(true)}
        onFilterChange={(filters) => setFilters(filters)}
      >
        { items?.length ? items.map((contact, index, array) => {
          const icon = (
            <EntityRecordAvatar
              badge={documentTypes.contacts.icon}
              member={contact?.creator}
            />
          );

          const pills = (
            <ContactPills
              role={addSpaceBeforeCapitals(contact?.role)}
              category={contact?.category?.name}
            />
          );

          return (
            <ActivityRecordPreview
              key={index}
              showMenu
              truncateMessage
              icon={icon}
              description={fullName(contact?.firstName, contact?.lastName)}
              isLast={index === array.length - 1}
              familyMembers={familyMembers}
              assigned={contact?.tagged}
              details={<>{ contact.title }</>}
              content={pills}
              menuItems={(
                <ContactMenuItems
                  contact={contact}
                  familyId={familyId}
                />
              )}
              onClick={() => viewContact(contact?.id)}
            />
          );
        }) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.contacts.icon }
              There are no contacts available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListDetailed>

      { addContactModalOpen && (
        <UploadContactModal
          familyId={familyId}
          open={addContactModalOpen}
          onClose={() => setAddContactModalOpen(false)}
        />
      ) }
    </>
  );
};
