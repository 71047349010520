import { getIn }                              from 'formik';
import * as Yup                               from 'yup';
import { phoneNumberIsValid, zipCodeIsValid } from './commonFunctions';

const getInputName = (path = '') => {
  const name = path.split('.').pop();
  const splitByCapitals = name?.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);
  const inputName = splitByCapitals ? splitByCapitals.join(' ') : 'field';

  return `${ inputName[0].toUpperCase() }${ inputName.substring(1) }`;
};

const schemas = [
  Yup.string,
  Yup.number,
  Yup.object,
];

schemas.forEach(schema => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Yup.addMethod(schema, 'isRequired', function (validationSchema: any, fieldName?: string) {
    return this.test(
      'conditional-required',
      'Field is required',
      function (value?: unknown) {
        const { path = '', createError } = this;

        const valueIsMissing = !value && value !== 0;

        if (valueIsMissing && getIn(validationSchema, path)) {
          return createError({
            path,
            message: `${ fieldName || getInputName(path) } is required`,
          });
        }

        return true;
      }
    );
  });
});

Yup.addMethod(Yup.string, 'phone', function (fieldName?: string) {
  return this.test(
    'phone-number',
    'Phone number is invalid',
    function (value?: string) {
      const { path = '', createError } = this;

      if (value && !phoneNumberIsValid(value)) {
        const name = fieldName || getInputName(path);
        const message = value.length < 12
          ? `${ name } is too short`
          : `${ name } is invalid`;

        return createError({
          path,
          message,
        });
      }

      return true;
    }
  );
});

Yup.addMethod(Yup.string, 'zipCode', function (fieldName?: string) {
  return this.test(
    'zip-code',
    'Zip Code is invalid',
    function (value?: string) {
      const { path = '', createError } = this;

      if (value && !zipCodeIsValid(value)) {
        const name = fieldName || getInputName(path);
        const message = value.length < 5
          ? `${ name } is too short`
          : `${ name } is invalid`;

        return createError({
          path,
          message,
        });
      }

      return true;
    }
  );
});

Yup.setLocale({
  mixed: {
    required: ({ path }: { path?: string }) => `${ getInputName(path) } is required`,
  },
  string: {
    email: ({ path }: { path?: string }) => `${ getInputName(path) } is invalid`,
  },
  number: {
    positive: ({ path }: { path?: string }) => `${ getInputName(path) } should be positive`,
  },
});
