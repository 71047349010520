import React, { FC }                     from 'react';
import { useHistory }                    from 'react-router-dom';
import styled                            from 'styled-components';
import { EAppRoutes }                    from '../../../shared/constants/constants';
import { formatDate, formatPhoneNumber } from '../../../shared/utils/commonFunctions';
import { useFetchAboutUser }             from '../../Settings/components/ManageUsers/About/queries';
import { EntitiesListPreview }           from '../shared/EntitiesListPreview';

const Wrapper = styled.div`
  padding: 20px 15px;
  font-family: Lato;
  width: 100%;
    
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 20px 5px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #e4572e;
  margin-bottom: 18px;
`;

const PropertiesRow = styled.div`
  margin-bottom: 28px;
  display: flex;
`;

const PropertyItem = styled.div`
  margin-right: 38px;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    margin-right: 30px;
  }
`;

const PropertyItemLabel = styled.div`
  font-size: 12px;
  line-height: 1.83;
  color: #6c7289;
`;

const PropertyItemValue = styled.div<{ noWrap?: boolean }>`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #272932;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    font-size: 14px;
    white-space: ${ ({ noWrap }) => noWrap ? 'nowrap' : 'wrap' };
  }
`;

interface IDocumentsListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

const PreviewItem: FC<{ label: string; value?: string; nowrap?: boolean }> = ({ label, value, nowrap }) => (
  <PropertyItem>
    <PropertyItemLabel>{ label }</PropertyItemLabel>
    <PropertyItemValue noWrap={nowrap}>{ value || '—' }</PropertyItemValue>
  </PropertyItem>
);

export const AboutMemberPreview: FC<IDocumentsListPreviewProps> = ({ familyId, familyMemberId }) => {
  const { data: aboutMember, isLoading: loadingAboutMe } = useFetchAboutUser(familyMemberId, familyId);
  const history = useHistory();

  const onViewAll = () => {
    history.push(`${ EAppRoutes.AboutSummary }${ familyMemberId ? `?id=${ familyMemberId }` : '' }`);
  };

  return (
    <EntitiesListPreview
      showViewAll
      title="About Me"
      loading={loadingAboutMe}
      onViewAll={onViewAll}
    >
      <Wrapper>
        <Title>Basic Info</Title>

        <PropertiesRow>
          <PreviewItem label="Nickname" value={aboutMember?.nickname} />
          <PreviewItem label="Email" value={aboutMember?.contactEmail} />
        </PropertiesRow>

        <PropertiesRow>
          <PreviewItem nowrap label="Phone Number" value={formatPhoneNumber(aboutMember?.phoneNumber)} />
          <PreviewItem label="Birthdate" value={formatDate(aboutMember?.dateOfBirth)} />
          <PreviewItem label="Gender" value={aboutMember?.gender} />
        </PropertiesRow>

        <PropertiesRow>
          <PreviewItem label="Address" value={aboutMember?.address} />
          <PreviewItem label="Country" value={aboutMember?.country} />
        </PropertiesRow>
      </Wrapper>
    </EntitiesListPreview>
  );
};
