import { Button, Grid, InputLabel, Typography } from '@material-ui/core';

import {
  FastField,
  Form,
  Formik,
  FormikProps,
}                                               from 'formik';
import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
}                                               from 'react';
import {
  Link,
  NavLink,
  Prompt,
  useHistory,
}                                               from 'react-router-dom';
import styled                                   from 'styled-components';
import axios                                    from 'axios';

import { Notistack }                            from '../../../../../shared/components/Notistack/Notistack';
import {
  GridDivider,
}                                               from '../../../../../shared/components/layout/GridDivider';
import {
  useFetchSelectedFamily,
  useUpdateSelectedFamily,
}                                               from '../../../../../shared/components/SelectFamily/queries';
import {
  EAppRoutes,
  EAuthRoutes,
  familyCoreConfig,
}                                               from '../../../../../shared/constants/constants';
import {
  timeZones,
}                                               from '../../../../../shared/constants/timeZones';
import {
  Loader,
}                                               from '../../../../../shared/enhancers/withLoader';
import {
  FamilyMembersSelector,
}                                               from '../../../../../shared/formFields/FamilyMembersSelector';
import {
  SelectField,
}                                               from '../../../../../shared/formFields/SelectField';
import {
  SwitchComponent,
  SwitchField,
}                                               from '../../../../../shared/formFields/SwitchField';
import {
  TextInputField,
}                                               from '../../../../../shared/formFields/TextInputField';
import {
  familyRoles,
  useFetchFamilies,
  useFetchFamilyAccount,
  useFetchFamilyMembers,
  useFetchMemberAccount,
  useUpdateFamilyAccount,
  useUpdateMemberAccount,
}                                               from '../../../../../shared/queries/family';
import {
  useFetchMonthlyPrice,
  useFetchSubscriptionInfo,
}                                               from '../../../../../shared/queries/payments';
import {
  useFetchCurrentUserProfile,
}                                               from '../../../../../shared/queries/user';
import {
  FormAction,
  FormSpacing,
  PageHeaderContainer,
  TextBrand,
  TextLabel,
}                                               from '../../../../../shared/styledComponents';
import {
  capitalizeFirstLetter,
  generateInitials,
}                                               from '../../../../../shared/utils/commonFunctions';
import {
  isIOS,
  isNative,
}                                               from '../../../../../shared/utils/platform';
import {
  logout,
}                                               from '../../../../../shared/utils/setAuthorizationToken';
import {
  EFamilyAccountType,
}                                               from '../../../../../shared/utils/withAuthorization/withAuthorization';
import {
  DeleteUserDialog,
}                                               from '../../../shared/DeleteUserDialog';
import { AlertDialog }                          from '../../../../../shared/components/modals/AlertDialog';
import {
  RestoreSubscription,
}                                               from '../../../shared/AccountRecoveryConfirmation';
import {
  FormFooterActions,
}                                               from '../../../shared/FormFooterActions';
import {
  SettingsParagraph,
}                                               from '../../../shared/styles';
import { addMonth }                             from '../../../../../shared/functions';
import { formatDate }                           from '../../../../../shared/utils/commonFunctions';

import {
  useDeleteUserAccount,
}                                               from '../../ManageUsers/Account/queries';
import {
  CHANGE_EMAIL_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PIN_ROUTE,
}                                               from '../ManageFamilyAccount';
import {
  EPaymentPlatformType,
  IFamilyAccountRequest,
  IFamilyAccountResponse,
  familyAccountValidationSchema,
}                                               from './validation';
import {
  FamilyMemberCircle,
}                                               from "../../../../Dashboard/components/FamilyMembers/FamilyMemberCircle";
import { TextInput }                            from '../../../../../shared/inputs/TextInput/TextInput';
import { fallbackPrice }                        from '../../../../../Auth/Checkout/Checkout';
import { IFamily }                              from '../../../../../Auth/shared/interfaces';

export const FlexEndGrid = styled(Grid)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ApplyWrapper = styled(Grid)`
  display: flex;
`;

const FamilyName = styled.div`
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  flex-direction: row;

  & > span {
    margin-right: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  height: 36px;
`;

const CreateFamilyLink = styled(NavLink)`
  font-size: 20px;
  color: #2c3f69;
  letter-spacing: 0.4px;
`;

const ChangePlanBtn = styled(Button)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const CloseAccountBtn = styled(Button)`
  margin-right: 10px;
  margin-bottom: 10px;
  color: rgb(228, 87, 46);
  border: solid 2px rgb(228, 87, 46);
`;

const Divider = () => <GridDivider margin="5px 0" />;

export const Text = styled.span<{ mb?: number }>`
  display: block;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #76a578;
  margin-bottom: ${ ({ mb }) => mb || 0 }px;
  margin-top: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const TextMobile = styled.span<{ mb?: number }>`
  display: flex;
  font-size: 12px;
  color: #535252;
  margin-bottom: ${ ({ mb }) => mb || 0 }px;
  margin-top: 5px;
  text-align: justify;
  line-height: 21px;
`;

export const TextSubscription = styled.span<{ mb?: number }>`
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: #e4572e;
  margin-bottom: ${ ({ mb }) => mb || 0 }px;
  margin-top: 5px;
`;

export enum ETaggedItemsOptions {
  ViewOnly    = 'ViewOnly',
  ViewAndEdit = 'ViewAndEdit'
}

export interface IFamilyUserPermissions {
  chatAccess: boolean;
  viewAboutMe: boolean;
  taggedItemsOption: string;
  geoLocationAccess: string[];
  aboutMeAccess: string[];
}

export const ManageAccountForm: FC = () => {
  const formikRef = useRef<FormikProps<IFamilyAccountRequest>>(null);

  const { data: family } = useFetchSelectedFamily();
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const { mutate: updateFamilyAccount, isLoading: updating } = useUpdateFamilyAccount();
  const { mutate: updateMemberAccount, isLoading: memberUpdating } = useUpdateMemberAccount();
  const { data: currentUser } = useFetchCurrentUserProfile(family?.id);
  const isSuperAdmin = () => currentUser?.accountType === EFamilyAccountType.SuperAdmin;
  const isAdmin = () => currentUser?.accountType === EFamilyAccountType.Admin;
  const { data: familyAccount, isLoading: loadingFamilyAccount, refetch } = useFetchFamilyAccount(family?.id, {
    enabled: isSuperAdmin(),
  });
  const { data: familyMemberAccount, isLoading: loadingFamilyMemberAccount } = useFetchMemberAccount(family?.id, {
    enabled: !isSuperAdmin(),
  });
  const { data: monthlyPrice, isLoading: fetchingPrice } = useFetchMonthlyPrice({
    enabled: isSuperAdmin(),
  });
  const { data: subscriptionInfo } = useFetchSubscriptionInfo({
    enabled: isSuperAdmin(),
  });

  const {
    isSuccess: deleteUserSuccess, isLoading: deleteAccountLoading, mutate: deleteUserAccount,
  } = useDeleteUserAccount(currentUser?.familyUserId, family?.id);
  const [closeAccountDialogOpen, setCloseAccountDialogOpen] = useState(false);
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);
  const [confirmActionDialog, setConfirmActionDialog] = useState(false);
  const [isNativeCancelAccountOpen, setIsNativeCancelAccountOpen] = useState(false);
  const [isNativeCancelSubscription, setIsNativeCancelSubscription] = useState(false);
  const [plannedDeletionDate, setPlannedDeletionDate] = useState('');
  const [userPlan, setUserPlan] = useState('');
  const { data: families, refetch: familiesRefetch } = useFetchFamilies();
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily();
  const history = useHistory();

  const card = familyAccount?.card;

  const locationValue = window.location.hash.slice(1);

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === family?.id);

  const [ coupon, setCoupon ] = useState<string | null>(null);

  const getEndDateCoupon = (date: IFamilyAccountResponse) => {
    if (date?.coupon?.duration === 'once') {
      return 'for one month';
    } else if (date?.coupon?.duration === 'forever') {
      return 'for unlimited months';
    } else {
      return `until ${addMonth(new Date(), date?.coupon?.duration_in_months).toDateString().slice(4)}`;
    }
  };

  const scrollToAnchor = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if(locationValue === '/settings/manage-account#changeSubscriptionSettings') {
      scrollToAnchor('changeSubscriptionSettings');
    }
  }, []);

  const getCorrectDescription = () => {
    if(familyAccount?.coupon){
      return (
        <DescriptionWrapper>
          <Text>
            {familyAccount?.coupon.percent_off}% discount applied to {fallbackPrice} per month subscription {getEndDateCoupon(familyAccount)}
          </Text>
        </DescriptionWrapper>
      );
    } else return <p />;
  };

  const updateAboutMe = (formValues: IFamilyAccountRequest) => {
    if (isSuperAdmin()) {
      updateFamilyAccount(formValues, {
        onSuccess: () => {
          setActualFormValues(formValues);
        },
      });
    } else {
      updateMemberAccount(formValues, {
        onSuccess: () => {
          setActualFormValues(formValues);
        },
      });
    }
  };

  const toggleGroupGeoLocationAccess = (geoLocationAccess: string[]) => {
    const { setFieldValue, setFieldTouched } = formikRef.current as FormikProps<IFamilyAccountRequest>;

    if (!!geoLocationAccess?.length) {
      setFieldValue('geoLocationAccess', []);
    } else {
      setFieldValue('geoLocationAccess', familyMembers?.map(member => member.id));
    }

    setFieldTouched('geoLocationAccess', true);
  };
  const familyData = isSuperAdmin() ? familyAccount : familyMemberAccount;
  const initialValues = {
    id                : family?.id,
    familyName        : familyData?.familyName,
    familyRole        : familyData?.familyRole,
    timeZoneOffset    : familyData?.timeZoneOffset,
    chatAccess        : familyData?.chatAccess,
    coupon            : familyData?.coupon,
    viewAboutMe       : familyData?.viewAboutMe,
    geoLocationAccess : familyData?.geoLocationAccess,
    aboutMeAccess     : familyData?.aboutMeAccess,
  };

  useEffect(() => {
    setActualFormValues(initialValues as IFamilyAccountRequest);
  }, [familyAccount, familyMemberAccount]);

  const [actualFormValues, setActualFormValues] = useState<IFamilyAccountRequest>(initialValues as IFamilyAccountRequest);
  const showCloseAccountButton = Boolean(!isSuperAdmin() || (isSuperAdmin() && !(families && families[0])?.plannedDeletionDate));
  const onCloseAccountSubmit = () => {
    deleteUserAccount();
    setCloseAccountDialogOpen(false);
  };

  const onCancelSubscriptionSubmit = () => {
    familyDeletionClick(family?.id);
    setCancelSubscriptionDialogOpen(false);
    if ((!isStripeSubscription || isNative)) {
      setIsNativeCancelAccountOpen(true);
    }
  };

  useEffect(() => {
    if (deleteUserSuccess) {
      if (families && families.length > 1) {
        const newFamily = families.filter(iteratedFamily => iteratedFamily.id !== family?.id);
        updateSelectedFamily(newFamily[0]?.id);
        history.push(EAppRoutes.MyProfile);
      } else {
        logout();
      }
    }
  }, [deleteUserSuccess]);

  useEffect(() => {
    families?.forEach((userFamily) => {
      if (userFamily.plannedDeletionDate && userFamily.id === family?.id) {
        setPlannedDeletionDate(userFamily.plannedDeletionDate);
      } else if (userFamily.plannedDeletionDate === null && userFamily.id === family?.id){
        setPlannedDeletionDate('');
      }
    });
  }, [families]);

  const handleApplyClick = useCallback(() => {
    axios
      .post(`api/v1/users/coupon?promoCode=${coupon}`)
      .then(response => {
        Notistack.enqueueSnackbar(response.data.data.message, 'success');
        refetch();

      })
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar('Error with promocode', 'error');
        setCoupon(null);
      });
  }, [coupon]);

  const cancelSubscriptionClick = (id?: string ) => {
    axios
      .post(`api/v1/users/cancel-subscription?familyId=${id}`)
      .then(response => {
        Notistack.enqueueSnackbar(response.data.data.message, 'success');
        familiesRefetch();
      })
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar(errorDetails, 'error');
      });
  };

  const familyDeletionClick = (id?: string ) => {
    axios
      .post(`api/v1/users/family-deletion?familyId=${id}`)
      .then(response => {
        Notistack.enqueueSnackbar(response.data.data.message, 'success');
        familiesRefetch();
      })
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar(errorDetails, 'error');
      });
  };

  const stopFamilyDeletionClick = (id?: string) => {
    axios
      .post(`api/v1/users/stop-family-deletion?familyId=${id}`)
      .then(response => {
        Notistack.enqueueSnackbar(response.data.data.message, 'success');
        familiesRefetch();
      })
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar(errorDetails, 'error');
      });
  };

  const stopCancelSubscriptionClick = (id?: string) => {
    axios
      .post(`api/v1/Users/stop-cancellation?familyId=${id}`)
      .then(response => {
        Notistack.enqueueSnackbar(response.data.data.message, 'success');
        familiesRefetch();
      })
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar(errorDetails, 'error');
      });
  };

  const getCorrectCancelSubscriptionAdminBtn = () => {
    if((families && plannedDeletionDate) && isSuperAdmin()){
      return(
        <CloseAccountBtn
          color="primary"
          variant="outlined"
          onClick={() => setConfirmActionDialog(true)}
        >
         Restore Account
        </CloseAccountBtn>
      );
    } else if (isSuperAdmin()) {
      return(
        <CloseAccountBtn
          color="primary"
          variant="outlined"
          onClick={() => setCancelSubscriptionDialogOpen(true)}
        >
          Delete Account
        </CloseAccountBtn>
      );
    } else if (isAdmin()) {
      return(
        <CloseAccountBtn
          color="primary"
          variant="outlined"
          onClick={() => setCloseAccountDialogOpen(true)}
        >
         Close Account
        </CloseAccountBtn>
      );
    }
  };

  const manageSubscriptionFunction = () => {
    if (!matchingFamily?.isActivePayment) {
      history.push(EAuthRoutes.Checkout);
    } else if (matchingFamily?.isSubscriptionCancelsAtEndOfBillingPeriod && !isNative) {
      stopCancelSubscriptionClick(family?.id);
    } else if (matchingFamily?.isActivePayment && isNative) {
      setIsNativeCancelSubscription(true);
    } else {
      cancelSubscriptionClick(family?.id);
    }
  };

  const getCorrectBtnName = () => {
    if (!matchingFamily?.isActivePayment) {
      return 'Upgrade to Premium';
    } else if (matchingFamily?.isActivePayment && matchingFamily?.isSubscriptionCancelsAtEndOfBillingPeriod) {
      return 'Stop Downgrade to Free';
    } else return 'Downgrade to Free';
  };

  const stripeCardSettings = () => {
    if(card) {
      return (
        <div>
          <div>{ capitalizeFirstLetter(card?.brand) } ending in { card?.last4 }</div>
          <span>Expires: { card?.expMonth }/{ card?.expYear }</span>
        </div>
      );
    } else if (!matchingFamily?.isActivePayment) {
      return (<div>Free Plan</div>);
    } else if ( !card && familyAccount?.id) {
      return (<div>Card is not added</div>);
    } else if (!card && !familyAccount?.id) {
      return (<div>Loading...</div>);
    } else return (<div>Loading...</div>);
  };

  const isStripeSubscription = familyAccount?.paymentPlatformType === EPaymentPlatformType.Stripe;
  const isAppleSubscription = familyAccount?.paymentPlatformType === EPaymentPlatformType.Apple;

  useEffect(() => {
    if (monthlyPrice && matchingFamily?.isActivePayment) {
      setUserPlan(`${monthlyPrice} Premium Plan`);
    } else if (!matchingFamily?.isActivePayment) {
      setUserPlan('Free Plan');
    } else setUserPlan('Loading...');
  }, [monthlyPrice, matchingFamily]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={actualFormValues}
      validationSchema={familyAccountValidationSchema}
      onSubmit={updateAboutMe}
    >
      { ({ values, dirty }) => (
        <Form>
          <PageHeaderContainer>
            <Typography variant="h3">{ values?.familyName ? `${ values?.familyName } Family` : '' }</Typography>
          </PageHeaderContainer>

          <FormSpacing>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={6}
                sm={5}
              >
                <TextBrand mb={15}>Account</TextBrand>

                { isSuperAdmin() && (
                  <FastField
                    label="Family Name"
                    name="familyName"
                    inputProps={{ maxLength: 100 }}
                    component={TextInputField}
                  />
                ) }

                { !isSuperAdmin() && (
                  <FamilyName>
                    <FamilyMemberCircle
                      color={currentUser?.color}
                      initials={generateInitials(family?.name, 'F')}
                    />
                    <span>
                      { family?.name } Family
                    </span>
                  </FamilyName>
                ) }
              </Grid>

              { isNative && showCloseAccountButton && isAdmin() &&
                <FlexEndGrid
                  item
                  xs={6}
                  sm={7}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setCloseAccountDialogOpen(true)}
                  >
                Close Account
                  </Button>
                </FlexEndGrid>
              }

              <Divider />

              <Grid
                item
                xs={12}
              >
                <TextBrand mb={-5}>Account Settings</TextBrand>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                xl={2}
              >
                <FastField
                  label="Family Role"
                  name="familyRole"
                  options={familyRoles}
                  component={SelectField}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                lg={5}
                xl={3}
              >
                <FastField
                  label="Time Zone"
                  name="timeZoneOffset"
                  options={timeZones}
                  component={SelectField}
                />
              </Grid>

              <Divider />

              <Grid
                item
                xs={12}
              >
                <TextBrand mb={-5}>Login Information</TextBrand>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                xl={4}
              >
                <InputLabel>Email</InputLabel>

                <FormAction>
                  { currentUser?.email }

                  <Link to={CHANGE_EMAIL_ROUTE}>Update</Link>
                </FormAction>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                xl={4}
              >
                <InputLabel>Password</InputLabel>

                <FormAction>
                ••••••••

                  <Link to={CHANGE_PASSWORD_ROUTE}>Update</Link>
                </FormAction>
              </Grid>

              <Divider />

              { isSuperAdmin() && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                  >
                    <TextBrand mb={15}>Payments</TextBrand>

                    <FormAction>
                      {/*TODO refactor logic of displaying stripe subscription settings*/ }

                      { ((!isStripeSubscription || isNative) && matchingFamily?.isActivePayment) ? (
                        `You can manage and cancel your subscriptions by going to your account settings on the ${ isIOS ? 'App Store' : 'Play Store' }`
                      ) : stripeCardSettings() }

                      { (isStripeSubscription && !isNative) && (
                        <Link to={CHANGE_PAYMENT_METHOD}>Update</Link>
                      ) }
                    </FormAction>

                    { ((!isStripeSubscription || isNative) && matchingFamily?.isActivePayment) &&
                    <TextMobile>Note about cancelling this family subscription: You have until {formatDate(subscriptionInfo?.nextPaymentDate)} to download any important documents and copy any information. Once you close this Super Admin account, all Admin and Members will no longer have access to this family. All users will be notified via email and given the same date to download files and save any information from their accounts.</TextMobile>}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                  >
                    <TextBrand id="changeSubscriptionSettings" mb={15}>Current Plan</TextBrand>

                    <FormAction>
                      <div>
                        { fetchingPrice ? 'Loading...' : userPlan }
                      </div>
                    </FormAction>

                    {getCorrectDescription()}
                  </Grid>

                  <Divider />

                  { (!isNative) && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                    >
                      {(
                        <>
                          <TextBrand mb={10}>Coupon</TextBrand>
                          <Wrapper>
                            <Grid
                              container
                              spacing={3}
                              xs={12}
                              sm={12}
                              xl={12}
                            >
                              <Grid
                                item
                                xs={8}
                                sm={9}
                                xl={10}
                              >
                                <TextInput
                                  value={coupon}
                                  onChange={(e: any)=> setCoupon(e.target.value)}
                                />
                              </Grid>
                              <ApplyWrapper
                                item
                                xs={3}
                                sm={2}
                                xl={1}
                              >
                                <Button
                                  disabled={!matchingFamily?.isActivePayment}
                                  color="primary"
                                  variant="outlined"
                                  onClick={handleApplyClick}
                                >
                                  Apply
                                </Button>
                              </ApplyWrapper>
                            </Grid>
                          </Wrapper>
                        </>
                      )}
                    </Grid>
                  )}

                  {(
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                    >
                      <TextBrand mb={10}>Manage Subscription</TextBrand>

                      <ChangePlanBtn
                        color="primary"
                        variant="outlined"
                        onClick={() => manageSubscriptionFunction()}
                      >
                        {getCorrectBtnName()}
                      </ChangePlanBtn>

                      {getCorrectCancelSubscriptionAdminBtn()}
                    </Grid>
                  )}
                  <Divider />
                </>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                xl={4}
              >
                <TextBrand mb={15}>Account Pin</TextBrand>

                <FormAction>
                ••••

                  <Link to={CHANGE_PIN_ROUTE}>Update</Link>
                </FormAction>
              </Grid>

              {(!isNative) &&  isAdmin() && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  xl={6}
                >
                  <TextBrand mb={10}>Manage Account</TextBrand>

                  {getCorrectCancelSubscriptionAdminBtn()}
                </Grid>
              )}

              <Divider />

              {/*<Grid*/ }
              {/*  item*/ }
              {/*  xs={ 12 }*/ }
              {/*>*/ }
              {/*  <TextBrand mb={ 15 }>Connected Families</TextBrand>*/ }
              {/*</Grid>*/ }

              {/*<Grid*/ }
              {/*  item*/ }
              {/*  xs={ 12 }*/ }
              {/*  sm={ 6 }*/ }
              {/*>*/ }
              {/*  <ConnectedFamily>*/ }
              {/*    <FamilyMemberCircle*/ }
              {/*      color={ '#50bbae' }*/ }
              {/*      initials={ generateInitials('West', 'F') }*/ }
              {/*    />*/ }

              {/*    <p>West family</p>*/ }
              {/*  </ConnectedFamily>*/ }
              {/*</Grid>*/ }

              {/*<FlexEndGrid*/ }
              {/*  item*/ }
              {/*  xs={ 12 }*/ }
              {/*  sm={ 6 }*/ }
              {/*>*/ }
              {/*  <Button*/ }
              {/*    color="primary"*/ }
              {/*    variant="outlined"*/ }
              {/*  >*/ }
              {/*    Disconnect Account*/ }
              {/*  </Button>*/ }
              {/*</FlexEndGrid>*/ }

              {/*<Divider/>*/ }

              <Grid
                item
                xs={12}
              >
                <TextBrand mb={15}>Permissions</TextBrand>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid
                  container
                  spacing={3}
                >
                  { familyCoreConfig.isGeolocationEnabled && (
                    <>
                      <Grid
                        item
                        xs={8}
                        lg={10}
                      >
                        <TextLabel>Geo Location Access</TextLabel>
                        <SettingsParagraph>Select members that can have access to your location</SettingsParagraph>
                      </Grid>

                      <FlexEndGrid
                        item
                        xs={4}
                        lg={2}
                      >
                        <SwitchComponent
                          value={!!values?.geoLocationAccess?.length}
                          onChange={() => toggleGroupGeoLocationAccess(values?.geoLocationAccess)}
                        />
                      </FlexEndGrid>

                      <Grid
                        item
                        xs={12}
                      >
                        <FamilyMembersSelector
                          multiple
                          hideAllSelection
                          name="geoLocationAccess"
                        />
                      </Grid>

                      <GridDivider margin={'-10px 0 10px 0'} />
                    </>
                  ) }
                  <Grid
                    item
                    xs={8}
                    lg={10}
                  >
                    <TextLabel>About Me</TextLabel>
                    <SettingsParagraph>Allow members to view your ‘About Me’</SettingsParagraph>
                  </Grid>

                  <FlexEndGrid
                    item
                    xs={4}
                    lg={2}
                  >
                    <SwitchField name="viewAboutMe" />
                  </FlexEndGrid>

                  { familyCoreConfig.isChatEnabled && (
                    <>
                      <GridDivider margin={'10px 0'} />

                      <Grid
                        item
                        xs={8}
                        lg={10}
                      >
                        <TextLabel>Chat</TextLabel>
                        <SettingsParagraph>Allow members to chat with you</SettingsParagraph>
                      </Grid>
                      <FlexEndGrid
                        item
                        xs={4}
                        lg={2}
                      >
                        <SwitchField name="chatAccess" />
                      </FlexEndGrid>

                      { !isSuperAdmin() && (
                        <>
                          <GridDivider margin={'10px 0'} />

                          <Grid
                            item
                            xs={12}
                          >
                            <CreateFamilyLink
                              to={EAuthRoutes.SignUp}
                              target="_blank"
                            >
                            Want to create your own family account?
                            </CreateFamilyLink>
                          </Grid>
                        </>
                      ) }
                    </>
                  ) }
                </Grid>
              </Grid>
            </Grid>
          </FormSpacing>

          <FormFooterActions
            dirty={dirty}
            isLoading={updating || memberUpdating}
            formikRef={formikRef}
            message="Account was successfully updated"
          />

          <Prompt
            when={dirty}
            message={`Are you sure you want to leave this page? Unsaved changes in the Customer Info would be lost.`}
          />

          { cancelSubscriptionDialogOpen && (
            <DeleteUserDialog
              isCancelSubscription
              handleSubmit={() => onCancelSubscriptionSubmit()}
              submitButtonDisabled={deleteAccountLoading}
              nextPaymentDate={subscriptionInfo?.nextPaymentDate}
              onClose={() => {
                setCancelSubscriptionDialogOpen(false);
              }}
            />
          ) }
          { confirmActionDialog && (
            <RestoreSubscription
              handleSubmit={() => {
                stopFamilyDeletionClick(family?.id);
                setConfirmActionDialog(false);
              }}
              submitButtonDisabled={deleteAccountLoading}
              nextPaymentDate={subscriptionInfo?.nextPaymentDate}
              onClose={() => {
                setConfirmActionDialog(false);
              }}
            />
          ) }

          { closeAccountDialogOpen && (
            <DeleteUserDialog
              handleSubmit={onCloseAccountSubmit}
              submitButtonDisabled={deleteAccountLoading}
              nextPaymentDate={subscriptionInfo?.nextPaymentDate}
              onClose={() => setCloseAccountDialogOpen(false)}
            />
          ) }

          <AlertDialog
            hideCancelButton
            dialogWidth="350px"
            open={isNativeCancelSubscription}
            title="Manage Subscription"
            content={`You can Upgrade or cancel your subscription by going to your account settings on the ${ isIOS ? 'App Store' : 'Play Store' }`}
            handleCancel={() => setIsNativeCancelSubscription(false)}
          />
          <AlertDialog
            hideCancelButton
            dialogWidth="350px"
            open={isNativeCancelAccountOpen}
            title="Delete Account"
            content={`Your account will be deleted in order to unsubscribe going to your account settings on the ${ isIOS ? 'App Store' : 'Play Store' }`}
            handleCancel={() => setIsNativeCancelAccountOpen(false)}
          />

          { deleteAccountLoading && <Loader absolute /> }

        </Form>
      )}
    </Formik>
  );
};
