import { Divider, Grid } from '@material-ui/core';
import React, { FC }     from 'react';
import styled            from 'styled-components';

interface IGridDividerProps {
  margin?: string;
  color?: string;
}

export const StyledDivider = styled(Divider)<IGridDividerProps>`&& {
  margin: ${ ({ margin }) => margin };
  background-color: ${ ({ color }) => color ? color : '#e9e9e9' }
}`;

export const GridDivider: FC<IGridDividerProps> = ({ margin, color }) => (
  <Grid
    item
    xs={12}
  >
    <StyledDivider
      color={color}
      margin={margin}
    />
  </Grid>
);
