import { Hidden }         from '@material-ui/core';
import React, { FC }      from 'react';
import styled             from 'styled-components';
import NotFoundPage       from '../../../images/404-icon.png';
import { PageHeaderText } from '../../Auth/shared/styles';
import { Layout }         from '../../Layout/Layout';

const PageContent = styled.div`
  width: 100%;
  margin: auto;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    margin: 0 auto;
  }
`;

const Description = styled.div`
  font-family: Lato;
  text-align: center;
  padding-bottom: 17px;
  font-size: 14px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    font-size: 18px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 24px;
    padding: 0 0 35px 12px;
  }
`;

const NotFoundImgHolder = styled.div`
  height: 177px;
  display: flex;
  margin-bottom: 48px;
  align-items: center;
  justify-content: center;
  padding-left: 88px;
  margin-bottom: 34px;

  img {
    height: 100%;
    max-width: 100%;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding-left: 120px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    height: 283px;
  }
`;

const NotFoundPageHeader = styled(PageHeaderText)`
  line-height: 1.67;
  letter-spacing: normal;
  font-weight: 500;
`;

const GoBackLink = styled.div`
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 14px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    font-size: 18px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 24px;
  }
`;

export const NotFound: FC = () => (
  <Layout>
    <PageContent>
      <Hidden xsDown>
        <NotFoundImgHolder>
          <img src={NotFoundPage} alt="Not Found" />
        </NotFoundImgHolder>
      </Hidden>
      <NotFoundPageHeader centered>
          Page Not Found
      </NotFoundPageHeader>
      <Description>
          Sorry the page you are looking for doesn't exsist
      </Description>
      <GoBackLink>
        <a href="/">
            Go Back
        </a>
      </GoBackLink>
    </PageContent>
  </Layout>
);
