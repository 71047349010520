import React, { FC }                     from 'react';
import { IDocument }                     from '../../../../shared/components/modals/UploadDocument/queries';
import { datePassed, generateShortName } from '../../../../shared/utils/commonFunctions';

interface IDocumentDetailsProps {
  document: IDocument;
}

export const DocumentAddedBy: FC<IDocumentDetailsProps> = ({ document }) => {
  const { creationTime, creator } = document;

  return (
    <span>
      Added { datePassed(creationTime, true) } • { generateShortName(creator.firstName, creator.lastName) }
    </span>
  );
};
