import { Grid, Hidden }        from '@material-ui/core';
import axios                   from 'axios';
import React, { FC, useState } from 'react';
import { useMutation }         from 'react-query';
import { useHistory }          from 'react-router-dom';
import styled                  from 'styled-components';

import securePinImage       from '../../../images/secure-pin-graphic.png';
import { LogoWhite }        from '../../shared/components/Logo';
import { MuiButton }        from '../../shared/components/MuiButton';
import { EAppRoutes }       from '../../shared/constants/constants';
import { Loader }           from '../../shared/enhancers/withLoader';
import { useFetchFamilies } from '../../shared/queries/family';
import {
  CodeInput,
  CodeInputBox,
  PageHeaderText,
  ProfilePage,
  ProfilePageContent,
  ProfilePageHeader,
  SecurityCodeErrorMessage,
  SignUpContainer,
}                           from '../shared/styles';

const Description = styled.div`
  font-weight: 400;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  margin: 10px;
  text-align: center;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    text-align: left;
    margin: 22px 0 33px;
    line-height: 1.7em;
  }
`;

const Image = styled.img`
  width: 150px;
  display: flex;
  margin: auto;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    width: 100%;
    margin-top: 35px;
  }
`;

export const CreatePin: FC = () => {
  const [ pin, setPin ] = useState('');
  const [ pinConfirmation, setPinConfirmation ] = useState('');
  const [ showValidationMessage, setShowValidationMessage ] = useState(false);
  const pinConfirmed = pin && pinConfirmation && pin === pinConfirmation;
  const { data: families, isLoading: initialLoad } = useFetchFamilies();
  const family = families?.[0];
  const history = useHistory();

  const createCode = () => axios.post('/api/v1/users/security-code', {
    securityCode: pin,
  }).then(() => {
    history.push(`${ EAppRoutes.Dashboard }/?showTipsModal=true`);
  });

  const createCodeMutation = useMutation('createCode', createCode);

  const onProceed = () => {
    if (pinConfirmed) {
      createCodeMutation.mutate();
    } else {
      setShowValidationMessage(!pinConfirmed);
    }
  };

  const disableButton = !pin || pin.length < 4 || !pinConfirmation || createCodeMutation.isLoading;

  return initialLoad ? <Loader /> : (
    <ProfilePage>
      <ProfilePageHeader>
        <LogoWhite />

        Welcome { family?.name } Family
      </ProfilePageHeader>

      <ProfilePageContent>
        <SignUpContainer maxWidth="md">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={7}
            >
              <PageHeaderText centeredXs>
                Create a Secure Pin
              </PageHeaderText>

              <Description>
                Creating a secure pin creates an extra step of security, keeping your information private.
              </Description>

              <Hidden smUp>
                <Image
                  src={securePinImage}
                  alt="Secure Pin"
                />
              </Hidden>

              <CodeInputBox>
                Enter a 4 Digit Code

                <CodeInput
                  length={4}
                  placeholder={''}
                  value={pin}
                  onChange={(value) => {
                    setPin(value);
                    setShowValidationMessage(false);
                  }}
                />

                Re-Enter Code

                <CodeInput
                  length={4}
                  placeholder={''}
                  value={pinConfirmation}
                  onChange={(value) => {
                    setPinConfirmation(value);
                    setShowValidationMessage(false);
                  }}
                />

                { showValidationMessage && (
                  <SecurityCodeErrorMessage>Codes need to match</SecurityCodeErrorMessage>
                ) }
              </CodeInputBox>

              <MuiButton
                fullWidth
                type="submit"
                margin="10px 0 0"
                disabled={disableButton}
                onClick={onProceed}
              >
                Complete
              </MuiButton>
            </Grid>

            <Hidden xsDown>
              <Grid
                item
                sm={6}
                md={5}
              >
                <Image
                  src={securePinImage}
                  alt="Secure Pin"
                />
              </Grid>
            </Hidden>
          </Grid>
        </SignUpContainer>
      </ProfilePageContent>
    </ProfilePage>
  );
};
