import React, { useEffect }        from 'react';
import {
  App,
  URLOpenListenerEvent,
}                                  from '@capacitor/app';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
}                                  from '@capacitor/push-notifications';
import qs                          from 'qs';
import { useHistory }              from 'react-router-dom';
import { queryParams }             from '../shared/functions';
import { isNative }                from '../shared/utils/platform';
import { notificationRedirection } from '../Layout/Header';
import axios                       from 'axios';
import { useFetchFamilyUser }      from '../shared/queries/family';
import { useFetchSelectedFamily }  from '../shared/components/SelectFamily/queries';

export const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  const loggedIn = !!axios.defaults.headers.common.authorization;

  const { data: family } = useFetchSelectedFamily({
    enabled: loggedIn,
  });
  const { data: user } = useFetchFamilyUser(family?.id, {
    enabled: !!family && loggedIn,
  });

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://familycore-qa-app-service-ui.azurewebsites.net/#/auth/confirmation?email=yaroslav.zaklynskyi%40techfabric.com&code=853840
      // slug = /auth/confirmation?email=yaroslav.zaklynskyi%40techfabric.com&code=853840
      const slug = event.url.split('#').pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    if (isNative) {
      // Request permission to use push notifications
      PushNotifications.checkPermissions()
        .then((permStatus) => {
          if (permStatus.receive === 'prompt') {
            return PushNotifications.requestPermissions();
          }

          return permStatus;
        })
        .then((permStatus) => {
          if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
          }

          PushNotifications.register();
        });

      PushNotifications.addListener('registration', (token: Token) => {
        localStorage.setItem('fcmRegistrationToken', token.value);
        //console.log('Push registration success, token: ' + token.value);

        // PushNotifications.getDeliveredNotifications().then((notificationList) => {
        //   console.log('delivered notifications', notificationList);
        // });
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        //console.log('Error on registration: ' + JSON.stringify(error));
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          //console.log('Push received: ' + JSON.stringify(notification));
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          const notificationData = JSON.parse(notification?.notification?.data?.data);
          const redirectRoute = notificationRedirection(notificationData.EventType, notificationData.Id, user?.id);
          if (notificationData?.data?.roomId) {
            history.push({
              search: qs.stringify({
                ...queryParams(),
                chatOpened   : true,
                selectedChat : notificationData?.data?.roomId,
              }),
            });
          } else {
            history.push(redirectRoute);
          }
        },
      );
    }
  }, [loggedIn, user]);

  return null;
};
