import React, { FC }              from 'react';
import { Breadcrumbs }            from '../../../../shared/components/Breadcrumbs';
import { EAppRoutes }             from '../../../../shared/constants/constants';
import { SettingsPageWithAvatar } from '../../shared/SettingsPageWithAvatar';
import { IUserProfile }           from '../Profile/MyProfileForm/validation';
import { ChangeEmailForm }        from './ChangeEmailForm/ChangeEmailForm';
import { ChangePasswordForm }     from './ChangePasswordForm/ChangePasswordForm';
import { ChangePinForm }          from './ChangePinForm/ChangePinForm';
import { ManageAccountForm }      from './ManageAccountForm/ManageAccountForm';

const UpdatePaymentMethod = React.lazy(() => import('./UpdatePaymentMethod/UpdatePaymentMethod'));

export interface IMyProfileProps {
  currentUser?: IUserProfile;
}

export const MANAGE_ACCOUNT_ROUTE = `${ EAppRoutes.Settings }/manage-account`;
export const CHANGE_EMAIL_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-email`;
export const CHANGE_PASSWORD_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-password`;
export const CHANGE_PIN_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-pin`;
export const CHANGE_PAYMENT_METHOD = `${ MANAGE_ACCOUNT_ROUTE }/change-payment-method`;
export const CHANGE_USER_PERMISSIONS = `${ MANAGE_ACCOUNT_ROUTE }/change-user-permissions`;

export const ManageFamilyAccount: FC = () => {
  const breadcrumbs = [ {
    label     : 'Manage Account',
    to        : MANAGE_ACCOUNT_ROUTE,
    component : <ManageAccountForm />,
  }, {
    label     : 'Update Email',
    to        : CHANGE_EMAIL_ROUTE,
    component : <ChangeEmailForm />,
  }, {
    label     : 'Update Password',
    to        : CHANGE_PASSWORD_ROUTE,
    component : <ChangePasswordForm />,
  }, {
    label     : 'Change Pin',
    to        : CHANGE_PIN_ROUTE,
    component : <ChangePinForm />,
  }, {
    label     : 'Update Payment Method',
    to        : CHANGE_PAYMENT_METHOD,
    component : <UpdatePaymentMethod />,
  } ];

  return (
    <SettingsPageWithAvatar loading={false}>
      <Breadcrumbs data={breadcrumbs} />
    </SettingsPageWithAvatar>
  );
};
