import React, { FC, useState }          from 'react';
import { useHistory }                   from 'react-router';
import styled                           from 'styled-components';
import { FormExpansionPanel }           from '../../../../../../shared/components/ExpansionPanel';
import { AlertDialog }                  from '../../../../../../shared/components/modals/AlertDialog';
import { EventDetailsModal }            from '../../../../../../shared/components/modals/UploadEvent/EventDetailsModal';
import { EAppRoutes }                   from '../../../../../../shared/constants/constants';
import { Loader }                       from '../../../../../../shared/enhancers/withLoader';
import { IFamilyMember }                from '../../../../../../shared/utils/withAuthorization/withAuthorization';
import { NoActivityScreen }             from '../../../../../Activity/ActivityItems';
import { useDeleteAssignedItem }        from '../../AssignedItems/queries';
import { useDeleteTaggedItem }          from '../../TaggedItems/queries';
import { EFamilyItemType, ITaggedItem } from '../shared';
import { TaggedItemRow }                from './TaggedItemRow';

const PanelContent = styled.div`
  width: 100%;
  padding: 10px 10px 25px;

  .MuiGrid-item:first-child {
    padding: 12px 0;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 10px 35px 25px;

    .MuiGrid-item:first-child {
      padding: 12px;
    }
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    font-size: 18px;
  }
`;

const CategoryCount = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 18px;
  min-width: 18px;
  padding: 3px;
  border-radius: 5.1px;
  background-color: #6f90d9;
  margin: 0 10px;
`;

interface ITaggedItemsSectionProps {
  familyId?: string;
  data?: ITaggedItem[];
  title: string;
  loading?: boolean;
  isAssignees?: boolean;
  icon: JSX.Element;
  isLast?: boolean;
  familyMembers?: IFamilyMember[];
}

const NoActivityFor: FC<{ type: string; icon: JSX.Element }> = ({ type, icon }) => (
  <NoActivityScreen>
    { icon }
    There are no { type } available.
  </NoActivityScreen>
);

export const TaggedItemsSection: FC<ITaggedItemsSectionProps> = ({
  familyId,
  data,
  isAssignees,
  title,
  loading,
  icon,
  isLast,
  familyMembers,
}) => {
  const [ removeTagDialogOpenFor, setRemoveTagDialogOpenFor ] = useState<string>('');
  const [ selectedEventId, setSelectedEventId ] = useState('');
  const { mutate: deleteTaggedItem } = useDeleteTaggedItem();
  const { mutate: deleteAssignedItem } = useDeleteAssignedItem();
  const history = useHistory();

  const onDelete = () => {
    if (isAssignees) {
      deleteAssignedItem(removeTagDialogOpenFor);
    } else {
      deleteTaggedItem(removeTagDialogOpenFor);
    }

    setRemoveTagDialogOpenFor('');
  };

  const noActivityBanner = (
    <NoActivityFor
      type={title}
      icon={icon}
    />
  );

  const navigateToViewPage = (document?: ITaggedItem) => {
    switch (document?.familyItemType) {
      case EFamilyItemType.Event:
        setSelectedEventId(document?.id);
        break;
      case EFamilyItemType.Contact:
        history.push(`${ EAppRoutes.ContactsSummary }/${ document?.id }`);
        break;
      case EFamilyItemType.Document:
        history.push(`${ EAppRoutes.DocumentsSummary }/${ document?.id }`);
        break;
      case EFamilyItemType.Task:
        history.push(`${ EAppRoutes.TasksSummary }/${ document?.id }`);
        break;
    }
  };

  return (
    <>
      <FormExpansionPanel
        last={isLast}
        expanded={!!data?.length}
        title={<CategoryTitle>{ title } <CategoryCount>{ data?.length || 0 }</CategoryCount></CategoryTitle>}
      >
        <PanelContent>
          { loading ? <Loader /> : data?.length ? data?.map((document, index, array) => (
            <TaggedItemRow
              key={index}
              data={document}
              isAssignees={isAssignees}
              last={index === array.length - 1}
              familyMembers={familyMembers}
              onRemove={(id) => setRemoveTagDialogOpenFor(id)}
              onView={() => navigateToViewPage(document)}
            />
          )) : noActivityBanner }
        </PanelContent>
      </FormExpansionPanel>

      <AlertDialog
        dialogWidth="350px"
        open={!!removeTagDialogOpenFor}
        title={`${ isAssignees ? 'Assignment' : 'Tag' } would be removed`}
        content={`Are you sure you want to remove this ${ isAssignees ? 'assignment' : 'tag' }?`}
        handleSubmit={() => onDelete()}
        handleCancel={() => setRemoveTagDialogOpenFor('')}
      />

      { selectedEventId && (
        <EventDetailsModal
          eventId={selectedEventId}
          familyId={familyId}
          open={!!selectedEventId}
          onClose={() => setSelectedEventId('')}
        />
      ) }
    </>
  );
};
