import { DialogProps, Grid }                    from '@material-ui/core';
import { Clear }                                 from '@material-ui/icons';
import moment                                    from 'moment-timezone';
import React, { FC, useContext }                 from 'react';
import styled                                    from 'styled-components';
import { ReactComponent as IconDate }            from '../../../../../images/icons/icon-date.svg';
import { ReactComponent as IconTime }            from '../../../../../images/icons/icon-time.svg';
import { ActivityRecordPreview }                 from '../../../../modules/Activity/ActivityRecordPreview';
import { FamilyMembersList }                     from '../../../../modules/Dashboard/components/FamilyMembers/FamilyMembersList';
import { CategoryPills }                         from '../../../../modules/MyFamilyCore/shared/CategoryPills';
import {
  DetailsPageCreatedDate,
  DetailsPageFooter,
  DetailsPageSpacedLabel,
  EntityDetailsHeader,
}                                                from '../../../../modules/MyFamilyCore/shared/styles';
import {
  DueDate,
  DueDateDelimiter,
  DueDateWrapper,
  TaskDescription,
  TaskStatusWrapper,
}                                                from '../../../../modules/MyFamilyCore/tasks/TaskDetails';
import {
  IEvent,
  useFetchEventById,
}                                                from '../../../../modules/Schedule/queries';
import { Loader }                                from '../../../enhancers/withLoader';
import {
  filterMembersById,
  shouldDisplayActionsMenu,
}                                                from '../../../functions';
import { useFetchFamilyMembers }                 from '../../../queries/family';
import {
  CloseModalIcon,
  FlexEndGrid,
  FlexVerticalCenterGrid,
}                                                from '../../../styledComponents';
import {
  formatDate,
  formatTime,
}                                                from '../../../utils/commonFunctions';
import AuthorizationContext                      from '../../../utils/withAuthorization/authorizationContext';
import { GridDivider }                           from '../../layout/GridDivider';
import { UploadedFiles }                         from '../../UploadedFiles';
import { ERecurrence }                           from '../shared';
import { StyledUploadDialog, UploadDialogTitle } from '../styles';
import { EventMenuItems }                        from './components/EventMenuItems';
import { UploadEventModal }                      from './UploadEventModal';

const DialogContent = styled.div`
  padding: 0 32px 32px;
  font-size: 14px;
`;

const RepeatDays = styled.div`
  color: #979797;
  display: flex;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

const EventDialogTitle = styled(UploadDialogTitle)`
  margin: 0 -32px 20px;
`;

interface IEventDetailsModalProps extends DialogProps {
  eventId?: string;
  event?: IEvent;
  familyId?: string;
  onClose: () => void;
}

export const EventDetailsModal: FC<IEventDetailsModalProps> = ({
  eventId,
  event,
  onClose,
  familyId,
  ...props
}) => {
  const { data: fetchedEvent, isLoading } = useFetchEventById(eventId);
  const currentUser = useContext(AuthorizationContext);
  const { data: familyMembers } = useFetchFamilyMembers(familyId, { enabled: !!familyId });
  const data = event || fetchedEvent;

  const assignedMembers = filterMembersById(familyMembers, data?.assigned);
  const taggedMembers = filterMembersById(familyMembers, data?.tagged);

  const menuItems = (
    <EventMenuItems
      familyId={familyId}
      event={data}
      handleClose={onClose}
    />
  );

  const weekDays = data?.recursWeekDays?.map((day: any) => {
    const [ first, ...rest ] = day.split('');
    return first.toUpperCase() + rest.join('').toLowerCase();
  }).join(', ');

  return (
    <StyledUploadDialog
      onClose={onClose}
      {...props}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <DialogContent>
        <EventDialogTitle>
          Event Details
        </EventDialogTitle>

        { isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <EntityDetailsHeader>
              <ActivityRecordPreview
                isLast
                menuItems={menuItems}
                description={data?.title}
                showMenu={shouldDisplayActionsMenu(event, currentUser)}
              />
            </EntityDetailsHeader>

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <TaskStatusWrapper>
                  <CategoryPills category={data?.category} />
                </TaskStatusWrapper>
              </Grid>

              <GridDivider margin="5px 0 -5px" />

              <FlexVerticalCenterGrid
                item
                xs={12}
              >
                <DetailsPageSpacedLabel>Assigned</DetailsPageSpacedLabel>

                <FamilyMembersList
                  shifted
                  hideAllSelection
                  data={assignedMembers}
                />
              </FlexVerticalCenterGrid>

              <GridDivider margin="0" />

              <FlexVerticalCenterGrid
                item
                xs={12}
              >
                <DetailsPageSpacedLabel>Due Date</DetailsPageSpacedLabel>

                <DueDateWrapper>
                  <DueDate>
                    <IconDate />

                    { formatDate(data?.recursStartDate) }
                    { data?.recursEndDate ? (
                      <>
                        <DueDateDelimiter>-</DueDateDelimiter>
                        { formatDate(data?.recursEndDate) }
                      </>
                    ) : '' }
                  </DueDate>

                  <DueDate>
                    <IconTime />

                    { formatTime(data?.recursStartTime) }
                  </DueDate>

                  <RepeatDays>
                    { data?.recurs }
                    { data?.recurs === ERecurrence.Weekly && ' on ' + weekDays }
                  </RepeatDays>
                </DueDateWrapper>
              </FlexVerticalCenterGrid>

              <GridDivider margin="0" />
            </Grid>

            <DetailsPageFooter>
              { data?.location && (
                <>
                  <DetailsPageSpacedLabel mb="10px">Location</DetailsPageSpacedLabel>

                  <TaskDescription>
                    { data?.location }
                  </TaskDescription>

                  <GridDivider margin="0 0 24px" />
                </>
              ) }

              { data?.description && (
                <>
                  <DetailsPageSpacedLabel mb="10px">Description</DetailsPageSpacedLabel>

                  <TaskDescription>
                    { data?.description }
                  </TaskDescription>

                  <GridDivider margin="0 0 24px" />
                </>
              ) }

              { (data?.attachments && data.attachments.length > 0) && (
                <>
                  <FlexVerticalCenterGrid
                    item
                    xs={12}
                  >
                    <UploadedFiles attachments={data?.attachments} entityName="events" />
                  </FlexVerticalCenterGrid>
                  <GridDivider margin="0 0 24px" />
                </>
              ) }

              <Grid
                container
                spacing={3}
              >

                <FlexVerticalCenterGrid
                  item
                  xs={12}
                  sm={6}
                >
                  <DetailsPageSpacedLabel>Created By</DetailsPageSpacedLabel>

                  <FamilyMembersList
                    hideAllSelection
                    data={data?.creator ? [ data?.creator ] : []}
                  />

                  <DetailsPageCreatedDate>
                    { moment(data?.creationTime).format('MMM D, YYYY') }
                  </DetailsPageCreatedDate>
                </FlexVerticalCenterGrid>

                <FlexEndGrid
                  item
                  xs={12}
                  sm={6}
                >
                  <DetailsPageSpacedLabel>Tagged</DetailsPageSpacedLabel>

                  <FamilyMembersList
                    shifted
                    hideAllSelection
                    data={taggedMembers}
                  />
                </FlexEndGrid>
              </Grid>
            </DetailsPageFooter>
          </>
        ) }
      </DialogContent>
    </StyledUploadDialog>
  );
};

UploadEventModal.defaultProps = {
  disableBackdropClick: true,
};
