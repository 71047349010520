import { Grid }                                          from '@material-ui/core';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useHistory, useLocation }                       from 'react-router-dom';
import styled                                            from 'styled-components';
import { GridDivider }                                   from '../../../shared/components/layout/GridDivider';
import { useFetchSelectedFamily }                        from '../../../shared/components/SelectFamily/queries';
import { SelectFamily }                                  from '../../../shared/components/SelectFamily/SelectFamily';
import { queryParams }                                   from '../../../shared/functions';
import { useFetchFamilyMembers }                         from '../../../shared/queries/family';
import { JustifyGrid, ResponsivePageTitle }              from '../../../shared/styledComponents';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember }                                 from '../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMembersList }                             from '../../Dashboard/components/FamilyMembers/FamilyMembersList';

const Wrapper = styled.div<{ margin?: string }>`
  width: 100%;
  margin: ${ ({ margin }) => margin || '0' };
`;

const GridContainer = styled(JustifyGrid)`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    flex-direction: row;
  }
`;

interface ISummaryPageHead {
  hideDivider?: boolean;
  hideAllSelection?: boolean;
  margin?: string;
  title: string;
  needFiltration?: boolean;
  hideFamilySelector?: boolean;
  hideFamilyMembers?: boolean;
  membersList?: IFamilyMember[];
}

export const SummaryPageHead: FC<ISummaryPageHead> = ({
  hideAllSelection,
  hideFamilyMembers,
  hideFamilySelector,
  margin,
  title,
  needFiltration,
  hideDivider,
  children,
  membersList,
}) => {
  const { data: family } = useFetchSelectedFamily({ enabled: !hideFamilyMembers });
  const { data: familyMembers, isLoading: loadingMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id && !hideFamilyMembers && !membersList });
  const history = useHistory();
  const params = queryParams();
  const user = useContext(AuthorizationContext);
  const { pathname } = useLocation();
  const allowedFamilyMembers = !needFiltration ? familyMembers : familyMembers?.filter(user => user.viewAboutMe);

  const setSelectedMember = useCallback(
    (memberId = 'all') => {
      history.push(`${ pathname }${ memberId ? `?id=${ memberId }` : '' }`);
    }, [ history, pathname ]);

  useEffect(() => {
    if (familyMembers?.length && (!familyMembers?.find(member => member.id === user?.data?.id))) {
      setSelectedMember(familyMembers[0].id);
    }
  }, [ familyMembers, params.id, user?.data?.id, setSelectedMember ]);

  return (
    <Wrapper margin={margin}>
      <ResponsivePageTitle margin="10px 0 25px">
        <span>{ title }</span>

        { !hideFamilySelector && (
          <SelectFamily />
        ) }
      </ResponsivePageTitle>

      <Grid
        container
        spacing={3}
      >
        { !hideFamilyMembers && (
          <GridContainer
            item
            xs={12}
          >
            <FamilyMembersList
              shaded
              disabledForMembers
              hideAllSelection={hideAllSelection}
              data={membersList || allowedFamilyMembers}
              loading={loadingMembers}
              selected={params.id as string}
              onSelect={(id) => setSelectedMember(id)}
            />

            { children }
          </GridContainer>
        ) }

        { !hideDivider && (
          <GridDivider
            color="#d8d8d8"
            margin={'5px 0 30px 0'}
          />
        ) }
      </Grid>
    </Wrapper>
  );
};

