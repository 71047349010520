import { AxiosError, AxiosRequestConfig, AxiosResponse, Method }                from 'axios';
import { QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { Notistack }                                                            from '../../components/Notistack/Notistack';
import { formatBackEndErrors }                                                  from '../commonFunctions';
import { httpClient }                                                           from '../httpClient';

interface IMutationFnVariables<TMutationRequestData = void,
  TPathParams = void> {
  data?: TMutationRequestData;
  pathParams?: TPathParams;
}

export type TMutationFnVariables<T, P> = IMutationFnVariables<T, P> | void;

export type TCreateQueryOptions<TResponseData = unknown,
  TReturnData = TResponseData> = UseQueryOptions<AxiosResponse<TResponseData>, AxiosError, TReturnData>;

export type TCreateMutationOptions<TReturnData = unknown,
  TMutationRequestData = void,
  TPathParams = void> = UseMutationOptions<AxiosResponse<TReturnData>, AxiosError, TMutationFnVariables<TMutationRequestData, TPathParams>, unknown>;

interface ISuccessMessageCreatorFnParams<TResponseData, TRequestData> {
  data: TResponseData;
  payload?: TRequestData;
}

interface IErrorMessageCreatorFnParams<TRequestData> {
  error: AxiosError;
  payload?: TRequestData;
}

interface IStatusMessage<TResponseData, TRequestData = void> {
  success?: ((successMessageParams: ISuccessMessageCreatorFnParams<TResponseData, TRequestData>) => string) | string;
  error?: ((errorArgs: IErrorMessageCreatorFnParams<TRequestData>) => string) | string;
}

interface IRequestFnProps<T, P = unknown> {
  apiUrl: string;
  method?: Method;
  data?: P;
  config?: AxiosRequestConfig;
  messages?: IStatusMessage<T, P>;
}

interface ICreateQueryProps<TQueryFnData = unknown,
  TReturnData = TQueryFnData> {
  apiUrl: string;
  queryKey: QueryKey;
  config?: AxiosRequestConfig;
  options?: TCreateQueryOptions<TQueryFnData, TReturnData>;
  messages?: IStatusMessage<TQueryFnData>;
}

interface ICreateMutationProps<TMutationResponseData = unknown,
  TMutationRequestData = void,
  TPathParams = void> {
  apiUrl: string;
  method?: Method;
  config?: AxiosRequestConfig;
  options?: TCreateMutationOptions<TMutationResponseData, TMutationRequestData, TPathParams>;
  messages?: IStatusMessage<TMutationResponseData, TMutationFnVariables<TMutationRequestData, TPathParams>>;
}

const request = async <TResponse = unknown, TRequestData = void>({
  apiUrl,
  method,
  data,
  config,
  messages,
}: IRequestFnProps<TResponse, TRequestData>) => {
  try {
    const response = await httpClient<TResponse, TRequestData>(apiUrl, method, data, config);

    if (messages?.success) {
      Notistack.enqueueSnackbar(
        typeof messages.success === 'function' ?
          messages.success({ data: response.data, payload: data }) :
          messages.success,
        'success'
      );
    }

    return response;
  } catch (error: any) {
    if (messages?.error !== null) {
      if (typeof messages?.error === 'function') {
        Notistack.enqueueSnackbar(messages.error({ error, payload: data }), 'error');
      } else if (error?.indexOf('401') < 0) {
        const message = messages?.error
          || formatBackEndErrors(error)
          || `Something went wrong during:\n${ method } ${ apiUrl }`;
        Notistack.enqueueSnackbar(message, 'error');
      }
    }

    throw error;
  }
};

export const useCreateQuery = <TRequestFnData = unknown,
  TReturnData = TRequestFnData>({
  apiUrl,
  queryKey,
  config,
  options,
  messages,
}: ICreateQueryProps<TRequestFnData, TReturnData>) => {
  const queryFn = () => request<TRequestFnData>({ apiUrl, config, messages });

  return useQuery<AxiosResponse<TRequestFnData>,
  AxiosError,
  TReturnData>(
    queryKey,
    queryFn,
    {
      select: ({ data }: any) => (data?.data) as unknown as TReturnData,
      ...options,
    }
  );
};

export const useCreateMutation = <TMutationResponseData = unknown,
  TMutationRequestData = void,
  TPathParams = void>({
  apiUrl,
  method = 'POST',
  config,
  options,
  messages,
}: ICreateMutationProps<TMutationResponseData, TMutationRequestData, TPathParams>) => {
  const mutationFn = ({ data, pathParams }: TMutationFnVariables<TMutationRequestData, TPathParams> = {}) => {
    if (pathParams) {
      Object.keys(pathParams).forEach(key => {
        apiUrl = apiUrl.replaceAll(`{${ key }}`, `${ (pathParams as Record<string, unknown>)[key] }`);
      });
    }
    // @ts-ignore
    return request<TMutationResponseData, TMutationRequestData>({ apiUrl, method, data, config, messages });
  };

  return useMutation<AxiosResponse<TMutationResponseData>,
  AxiosError,
  TMutationFnVariables<TMutationRequestData, TPathParams>>(mutationFn, options);
};
