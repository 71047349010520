import React, { FC, memo }                  from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ITextInputProps, TextInput }       from './TextInput/TextInput';

interface IProps extends ITextInputProps {
  shrink?: boolean;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
  removeThousandSeparator?: boolean;
  onChange?: (event: { target: { value: unknown } }) => void;
}

const NumberInputBase: FC<IProps> = ({
  suffix,
  inputRef,
  decimalScale,
  removeThousandSeparator,
  onChange,
  ...props
}: any) => {
  const onValueChange = ({ floatValue }: NumberFormatValues) => {
    if (!onChange) return;

    onChange({
      target: {
        name  : props.name,
        value : floatValue,
      },
    });
  };

  const decimals = decimalScale ? decimalScale : suffix === '%' ? 2 : undefined;
  const thousandSeparator = removeThousandSeparator ? '' : true;

  return (
    <NumberFormat
      {...props}
      ref={inputRef}
      suffix={suffix}
      decimalScale={decimals}
      fixedDecimalScale={!!decimals}
      thousandSeparator={thousandSeparator}
      customInput={TextInput}
      onValueChange={onValueChange}
    />
  );
};

export const NumberInput = memo(NumberInputBase);
