import AddIcon       from '@material-ui/icons/Add';
import React, { FC } from 'react';
import styled        from 'styled-components';
import { AddEntity } from './styles';

interface IAddEntityControlProps {
  label?: string;

  onAdd(): void;
}

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #5071bb;
  margin-left: 15px;
`;

export const AddEntityControl: FC<IAddEntityControlProps> = ({ onAdd, label }) => (
  <Wrapper onClick={onAdd}>
    <AddEntity>
      <AddIcon />
    </AddEntity>

    { label && (
      <Label>
        { label }
      </Label>
    ) }
  </Wrapper>
);
