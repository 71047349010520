import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { EAppRoutes }      from '../../shared/constants/constants';
import { Schedule }        from './Schedule';

export const ScheduleRoutes = () => (
  <>
    <Route
      exact
      path="/"
    >
      <Redirect to={EAppRoutes.Schedule} />
    </Route>

    <Route
      path={`${ EAppRoutes.Schedule }/:eventMonth?`}
      component={Schedule}
    />
  </>
);
