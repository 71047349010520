import axios, { AxiosError, AxiosResponse }                     from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery, useMutation }                        from 'react-query';
import { queryClient }                                          from '../../../../../App';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../../../../Auth/shared/interfaces';
import { Notistack }                                            from '../../../../../shared/components/Notistack/Notistack';
import { EFamilyItemType, ITaggedItem }                         from '../MentionedEntities/shared';

export const ASSIGNED_ITEMS = 'ASSIGNED_ITEMS';
export const DELETE_ASSIGNED_ITEM = 'DELETE_ASSIGNED_ITEM';

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const loadAssignedItems = (familyUserId: string | undefined, familyItemType: EFamilyItemType, offset: number, limit: number) =>
  axios.get(`/api/v1/family-items/assignee/${ familyUserId }?${ qs.stringify({ offset, limit, familyItemType }) }`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<ITaggedItem>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch(onCatch);

export const useLoadAssignedItemsPage = (familyMemberId: string | undefined, type: EFamilyItemType, limit = 100) => useInfiniteQuery(
  [ ASSIGNED_ITEMS, familyMemberId, type ],
  ({ pageParam = 0 }) => loadAssignedItems(familyMemberId, type, pageParam, limit),
  {
    enabled          : !!familyMemberId,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });

export const deleteAssignedItem = (
  id?: string,
) =>
  axios.delete(`/api/v1/family-items/assignee/${ id }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useDeleteAssignedItem = () => useMutation(
  DELETE_ASSIGNED_ITEM,
  (id: string) => deleteAssignedItem(id),
  {
    onSuccess: data => {
      Notistack.enqueueSnackbar(`${ data?.message }`, 'success');
      queryClient.invalidateQueries([ ASSIGNED_ITEMS ]);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
    },
  }
);
