import {
  Dispatch,
  FC,
  SetStateAction,
}                          from 'react';
import styled, { css }     from 'styled-components';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
}                          from '@material-ui/core';
import { Modal }           from '../../../shared/components/modals/Modal';
import { EOccurrenceType } from './EventBadgeForPopover';

export const StyledDialogContent = styled(DialogContent)`&& {
  ${ ({ theme: { spacing } }) => css`
    padding: 0 ${ spacing(4) }px ${ spacing(2) }px;
  ` }
}`;

interface IChangeEventModalProps {
  open: boolean;
  title: string;
  value: string;
  dialogWidth?: string;
  submitButtonDisabled?: boolean;
  setValue: Dispatch<SetStateAction<EOccurrenceType>>;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export const ChangeEventModal: FC<IChangeEventModalProps> = ({
  open,
  title,
  value,
  dialogWidth,
  submitButtonDisabled,
  setValue,
  handleSubmit,
  handleCancel,
}) => (
  <Modal
    open={open}
    maxWidth="md"
    dialogWidth={dialogWidth}
    onClose={() => handleCancel()}
  >
    <DialogTitle>
      { title }
    </DialogTitle>

    <StyledDialogContent>
      <FormControl component="fieldset">
        <RadioGroup value={value} onChange={({ target: { value } }) => setValue(value as EOccurrenceType)}>
          <FormControlLabel value={EOccurrenceType.ThisEvent} control={<Radio />} label="This Event" />
          <FormControlLabel value={EOccurrenceType.AllEvents} control={<Radio />} label="All Events" />
        </RadioGroup>
      </FormControl>
    </StyledDialogContent>

    <DialogActions>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleCancel()}
      >
        Cancel
      </Button>

      <Button
        fullWidth
        autoFocus
        type="submit"
        color="primary"
        variant="contained"
        disabled={submitButtonDisabled}
        onClick={() => handleSubmit()}
      >
        OK
      </Button>
    </DialogActions>
  </Modal>
);
