import React, { FC }            from 'react';
import styled                   from 'styled-components';
import { IUploadImageResponse } from '../../../../Auth/shared/interfaces';
import {
  DocumentPreviewIcon,
  DocumentPreviewWrapper,
  DocumentThumbnail,
  FileDetails,
  FileName,
}                               from '../../../../shared/components/documents/DocumentPreview';
import { formatBytes }          from '../../../../shared/utils/commonFunctions';

const TaskImageDownloadWrapper = styled.div`
  margin-bottom: 30px;
`;

const DownloadLink = styled.span`
  color: #5071bb;
  cursor: pointer;
  
  &:hover {
    opacity: .7;
  }
`;

interface ITaskDetailsProps {
  image: IUploadImageResponse;
}

export const TaskImageDownload: FC<ITaskDetailsProps> = ({ image }) => {
  const name = image?.compactPath.split('/').reverse()?.[0];

  const onDownload = () => {
    window.open(image?.originalPath);
  };

  return (
    <TaskImageDownloadWrapper>
      <DocumentPreviewWrapper>
        <DocumentPreviewIcon />

        <DocumentThumbnail>
          <FileName>{ name }</FileName>
          <FileDetails>
            { formatBytes(image?.size) } • <DownloadLink onClick={onDownload}>Download</DownloadLink>
          </FileDetails>
        </DocumentThumbnail>
      </DocumentPreviewWrapper>
    </TaskImageDownloadWrapper>
  );
};
