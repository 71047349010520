import styled                                            from 'styled-components';
import { ChangeEvent, Component, FocusEvent, ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
}                                                         from '@material-ui/core';
import { WithStyles, withStyles }                         from '@material-ui/core/styles';
import classNames                                         from 'classnames';
import { TextInputWrapper }                               from '../../styledComponents';
import { selectStyles }                                   from './selectStyles';

const StyledMenuItem = styled(MenuItem)`
  max-width: 600px;
  white-space: normal;
  display: block;
`;

export interface ISelectProps<T> extends WithStyles<typeof selectStyles> {
  value: any;
  name?: string;
  options: T[];
  optionsToHide?: T[];
  helperText?: ReactNode;
  label?: string;
  error?: boolean;
  required?: boolean;
  shrink?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  displayError?: boolean;
  multiple?: boolean;
  multiline?: boolean;
  onOpen?: () => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (
    value: any,
    event?: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  renderValue?: (selected: any) => any;
}

export class SelectBase<T> extends Component<ISelectProps<T>> {
  static defaultProps = {
    shrink    : true,
    fullWidth : true,
  };

  shouldComponentUpdate(nextProps: ISelectProps<T>) {
    return nextProps.value !== this.props.value
      || nextProps.options !== this.props.options
      || nextProps.error !== this.props.error
      || nextProps.displayError !== this.props.displayError;
  }

  render() {
    const {
      value,
      name,
      label,
      options,
      optionsToHide,
      shrink,
      disabled,
      required,
      error,
      fullWidth,
      classes,
      helperText,
      displayError,
      multiple  = false,
      multiline = false,
      onOpen,
      onBlur,
      onChange,
      renderValue,
      ...restProps
    } = this.props;

    const valueIsMissing = !value && value !== 0 && value !== '';
    const errorRequired = displayError && required && valueIsMissing;
    const errorRequiredText = errorRequired && `${ label } is required`;

    const defaultValue = 'Select';
    const isDefaultValue = value === null || value === defaultValue;
    const selectClasses = classNames({ 'class-font-colour-gray': isDefaultValue });

    const optionsFormatted = (options || []).map(
      (option: any) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return {
            value : option === defaultValue ? '' : option,
            label : option,
          };
        } else if ((option as any)?.props) {
          return option;
        } else {
          const keys = Object.keys(option);

          return {
            value : option[keys[0]] === defaultValue ? '' : option[keys[0]],
            label : option[keys[1]],
          };
        }
      });

    const selectedValue = (typeof value !== 'undefined' && value !== null) ? value : '';

    const selectedLabel = optionsFormatted.find(item => item.value === value)?.label ?? '';

    const isOptionHidden = (option: any) => optionsToHide?.includes(option);

    return (
      <TextInputWrapper
        fullWidth={fullWidth}
        hasLabel={!!label}
      >
        <FormControl
          {...restProps}
          error={error || errorRequired}
          fullWidth={fullWidth}
          data-error={error}
        >
          { label && (
            <InputLabel
              shrink={shrink}
              htmlFor="select-input"
            >
              { label } { required && '*' }
            </InputLabel>
          ) }

          <MuiSelect
            displayEmpty
            multiple={multiple}
            multiline={multiline}
            renderValue={renderValue}
            name={name}
            value={selectedValue}
            disabled={disabled}
            variant="outlined"
            classes={{
              selectMenu : classes.input,
              icon       : disabled ? classes.hidden : classes.icon,
            }}
            className={selectClasses}
            inputProps={{ title: selectedLabel }}
            onOpen={() => onOpen?.()}
            onBlur={(event) => onBlur?.(event)}
            onChange={(event) => onChange?.(event.target.value, event)}
          >
            { (optionsFormatted || []).map(
              (option, index) => {
                if (typeof option === 'string') {
                  return (
                    <StyledMenuItem
                      key={index}
                      value={option}
                      data-title={option}
                      data-hidden={isOptionHidden(option)}
                      disabled={option === defaultValue}
                    >
                      { option }
                    </StyledMenuItem>
                  );
                } else if ((option as any)?.props) {
                  return <div key={index}>{ option }</div>;
                } else {
                  return (
                    <StyledMenuItem
                      key={index}
                      value={option.value}
                      data-title={option.label}
                      data-hidden={isOptionHidden(option.value)}
                      disabled={option.label === defaultValue}
                    >
                      { option.label }
                    </StyledMenuItem>
                  );
                }
              }
            ) }

            { !options?.length && (
              <StyledMenuItem
                disabled
                alignItems="center"
              >
                No Options Available
              </StyledMenuItem>
            ) }
          </MuiSelect>

          { (helperText || errorRequiredText) && (
            <FormHelperText>{ helperText || errorRequiredText }</FormHelperText>
          ) }
        </FormControl>
      </TextInputWrapper>
    );
  }
}

export const Select = withStyles(selectStyles)(SelectBase);
