import { MenuItem }                                                                from '@material-ui/core';
import React, { ForwardRefRenderFunction, forwardRef, memo, useContext, useState } from 'react';
import { useHistory }                                                              from 'react-router-dom';
import { AlertDialog }                                                             from '../../../../shared/components/modals/AlertDialog';
import {
  IContact,
  useDeleteContact,
}                                                                                  from '../../../../shared/components/modals/UploadContact/queries';
import { UploadContactModal }                                                      from '../../../../shared/components/modals/UploadContact/UploadContactModal';
import { EAppRoutes }                                                              from '../../../../shared/constants/constants';
import { isAdminRoles }                                                            from '../../../../shared/functions';
import { fullName }                                                                from '../../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../../shared/utils/withAuthorization/authorizationContext';

interface IContactMenuItemsProps {
  familyId?: string;
  contact?: IContact;
}

// eslint-disable-next-line react/display-name
const ContactMenuItemsBase: ForwardRefRenderFunction<HTMLDivElement, IContactMenuItemsProps> = ({
  familyId,
  contact,
}, _ref) => {
  const { mutate: deleteContact, isLoading: changingContactStatus } = useDeleteContact();
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ updateContactModalOpen, setUpdateContactModalOpen ] = useState(false);
  const currentUser = useContext(AuthorizationContext);
  const isCreator = currentUser?.data?.id === contact?.creator?.id;
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);
  const history = useHistory();

  const onDeleteContact = () => {
    deleteContact({
      id: contact?.id,
      familyId,
    }, {
      onSuccess: () => {
        setDeleteModalOpen(false);
        history.push(EAppRoutes.Dashboard);
      },
    });
  };

  return (
    <>
      <MenuItem onClick={() => setUpdateContactModalOpen(true)}>Edit</MenuItem>
      <MenuItem hidden={!(isCreator || isAdminRole)} onClick={() => setDeleteModalOpen(true)}>Delete</MenuItem>

      <AlertDialog
        dialogWidth="350px"
        open={deleteModalOpen}
        title="Are you sure you want to delete this contact?"
        content={`Contact "${ fullName(contact?.firstName, contact?.lastName) }"`}
        submitButtonDisabled={changingContactStatus}
        handleSubmit={() => onDeleteContact()}
        handleCancel={() => setDeleteModalOpen(false)}
      />

      { updateContactModalOpen && (
        <UploadContactModal
          familyId={familyId}
          contact={contact}
          open={updateContactModalOpen}
          onClose={() => setUpdateContactModalOpen(false)}
        />
      ) }
    </>
  );
};

export const ContactMenuItems = memo(forwardRef(ContactMenuItemsBase));
