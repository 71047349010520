import React, { FC }             from 'react';
import { InfiniteData }          from 'react-query';
import styled                    from 'styled-components';
import { documentTypes }         from '../../../../../shared/documentTypes';
import { convertToInfinitePage } from '../../../../../shared/utils/commonFunctions';
import { IFamilyMember }         from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { TaggedItemsSection }    from './components/TaggedItemsSection';
import { ITaggedItem }           from './shared';

type TEntityType = InfiniteData<{ items: ITaggedItem[]; totalCount: number; next: number }>;

interface IMyProfileFormProps {
  isAssignees?: boolean;
  familyId?: string;
  documents?: TEntityType;
  loadingDocuments: boolean;
  events?: TEntityType;
  loadingEvents: boolean;
  tasks?: TEntityType;
  loadingTasks?: boolean;
  contacts?: TEntityType;
  loadingContacts?: boolean;
  familyMembers?: IFamilyMember[];
}

const PanelsWrapper = styled.div`
  padding: 25px;
`;

export const MentionedEntities: FC<IMyProfileFormProps> = ({
  isAssignees,
  documents,
  loadingDocuments,
  events,
  loadingEvents,
  contacts,
  loadingContacts,
  tasks,
  loadingTasks,
  familyId,
  familyMembers,
}) => (
  <PanelsWrapper>
    <TaggedItemsSection
      title="Documents"
      isAssignees={isAssignees}
      loading={loadingDocuments}
      icon={documentTypes.documents.icon}
      data={convertToInfinitePage(documents)}
      familyMembers={familyMembers}
    />

    { !isAssignees && (
      <TaggedItemsSection
        title="Contacts"
        isAssignees={isAssignees}
        loading={loadingEvents}
        icon={documentTypes.contacts.icon}
        data={convertToInfinitePage(contacts)}
        familyMembers={familyMembers}
      />
    ) }

    <TaggedItemsSection
      title="Events"
      isAssignees={isAssignees}
      familyId={familyId}
      loading={loadingContacts}
      icon={documentTypes.events.icon}
      data={convertToInfinitePage(events)}
      familyMembers={familyMembers}
    />

    <TaggedItemsSection
      isLast
      title="Tasks"
      isAssignees={isAssignees}
      familyId={familyId}
      loading={!!loadingTasks}
      icon={documentTypes.tasks.icon}
      data={convertToInfinitePage(tasks)}
      familyMembers={familyMembers}
    />
  </PanelsWrapper>
);
