import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { EAppRoutes }      from '../../shared/constants/constants';
import { Activity }        from './Activity';

export const ActivityRoutes = () => (
  <>
    <Route
      exact
      path="/"
    >
      <Redirect to={EAppRoutes.Activity} />
    </Route>

    <Route
      path={EAppRoutes.Activity}
      component={Activity}
    />
  </>
);
