import { FC, useEffect, useRef, useState } from 'react';
import { DialogProps }                    from '@material-ui/core';
import { Clear }                           from '@material-ui/icons';
import { Formik, FormikProps }             from 'formik';
import { IFileShape }                      from '../../../../Auth/shared/interfaces';
import { EDocumentAccess }                 from '../../../constants/constants';
import { attachmentToFileShape }           from '../../../functions';
import { CloseModalIcon }                  from '../../../styledComponents';
import {
  StyledUploadDialog,
  UploadDialogContent,
  UploadDialogTitle,
}                                          from '../styles';
import {
  IDocument,
  useMarkDocumentAsViewed,
  useUpdateDocument,
  useUploadDocument,
}                                          from './queries';
import { UploadDocumentModalForm }         from './UploadDocumentModalForm';
import {
  IUploadDocument,
  addDocumentFormSchema,
}                                          from './validation';
import { LimitedModal }                    from '../UploadTask/LimitedModal';

interface IAlertDialogProps extends DialogProps {
  familyUserId?: string;
  familyId?: string;
  document?: IDocument;
  onClose(): void;
  onSuccess?(): void;
}

export const UploadDocumentModal: FC<IAlertDialogProps> = ({
  document,
  familyUserId,
  familyId,
  onClose,
  onSuccess,
  open,
}) => {
  const initFiles = document?.attachments ? attachmentToFileShape(document.attachments) : [];
  const formikRef = useRef<FormikProps<IUploadDocument>>(null);

  const [ files, setFiles ] = useState<IFileShape[]>(initFiles as IFileShape[]);
  const [isLimitCategory, setIsLimitCategory ] = useState(false);
  const { mutate: uploadDocument, isLoading: uploadingDocument } = useUploadDocument();
  const { mutate: updateDocument, isLoading: updatingDocument } = useUpdateDocument();
  const { mutate: markAsViewed } = useMarkDocumentAsViewed(familyUserId);

  useEffect(() => {
    if (document?.id) {
      markAsViewed(document.id);
    }
  }, [document?.id]);

  const initialValues: IUploadDocument = {
    id                 : document?.id,
    name               : document?.name || '',
    categoryId         : document?.category?.id || '',
    subCategoryId      : document?.subCategory?.id || '',
    familyId           : familyId as string,
    assigned           : document?.assigned || [],
    assignedPermission : document?.assignedPermission || EDocumentAccess.ViewAndEdit,
    description        : document?.description || '',
    tagged             : document?.tagged || [],
  };

  const upload = (values: IUploadDocument) => {
    if (document?.id) {
      const attachmentsId = files.filter(file => !!file.id).map(file => file.id) as string[];

      updateDocument({
        data: {
          ...values,
          attachmentsId,
        },
        files: files.filter(fileShape => !fileShape.id),
      }, {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
        onError: (error: any) => {
          if (error.response.data.ErrorDetails.Key === 'MaxDocumentsNumber') {
            setIsLimitCategory(true);
          }
        },
      });
    } else if (files) {
      uploadDocument({
        data  : values,
        files : files,
      }, {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
        onError: (error: any) => {
          if (error.response.data.ErrorDetails.Key === 'MaxDocumentsNumber') {
            setIsLimitCategory(true);
          }
        },
      });
    }
  };

  return (
    <StyledUploadDialog
      open={open}
      onClose={onClose}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        Add to Documents
      </UploadDialogTitle>

      <LimitedModal
        openFreeInfo={isLimitCategory}
        entityName="DocumentCategory"
        setOpenFreeInfo={setIsLimitCategory}
      />

      <UploadDialogContent>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={addDocumentFormSchema}
          onSubmit={(values) => upload(values)}
        >
          { (props) => (
            <UploadDocumentModalForm
              files={files}
              familyId={familyId}
              formikRef={formikRef}
              loading={uploadingDocument || updatingDocument}
              onChange={setFiles}
              {...props}
            />
          ) }
        </Formik>
      </UploadDialogContent>
    </StyledUploadDialog>
  );
};

UploadDocumentModal.defaultProps = {
  disableBackdropClick: true,
};
