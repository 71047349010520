import { Grid }                   from '@material-ui/core';
import React, { FC, useMemo }     from 'react';
import { Layout }                 from '../../../Layout/Layout';
import { NavigateBack }           from '../../../shared/components/NavigateBack';
import { useFetchSelectedFamily } from '../../../shared/components/SelectFamily/queries';
import { EAppRoutes }             from '../../../shared/constants/constants';
import { withQueryParams }        from '../../../shared/functions';
import { SummaryPageHead }        from '../shared/SummaryPageHead';
import { ContactsListDetailed }   from './ContactsListDetailed';

export const ContactsSummary: FC = () => {
  const { data: family } = useFetchSelectedFamily();

  const Page = (
    <>
      <NavigateBack
        title="My Family Core"
        to={withQueryParams(EAppRoutes.MyFamilyCore)}
      />

      <SummaryPageHead title="Contact Summary" />

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <ContactsListDetailed familyId={family?.id} />
        </Grid>
      </Grid>
    </>
  );

  return useMemo(() => (
    <Layout>
      { Page }
    </Layout>
  ), [ family?.id, window.location.search ]);
};
