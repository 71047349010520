import axios, { AxiosPromise, AxiosRequestConfig, Canceler, Method } from 'axios';

const httpClient = <T = any, P = any>(url: string, method: Method = 'GET', data?: P, config?: AxiosRequestConfig) => {
  const {
    token : cancelToken,
    cancel: cancelRequest,
  } = axios.CancelToken.source();

  const axiosConfig: AxiosRequestConfig = {
    url,
    method,
    ...config,
    cancelToken,
  };

  if (data) {
    if (method.toUpperCase() === 'GET') {
      axiosConfig.params = data;
    } else {
      axiosConfig.data = data;
    }
  }

  const request = axios(axiosConfig) as AxiosPromise<T> & { cancel: Canceler };

  request.cancel = () => {
    cancelRequest('Query was cancelled by the application');
  };

  return request;
};

export { httpClient };
