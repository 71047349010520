import { FC, useCallback }          from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { Field, useField }          from 'formik';
import { IOSSwitch }                from '../inputs/IOSSwitch';

interface IComponentProps {
  value?: string | boolean;
  label?: string;
  labelPlacement?: string;
  onChange?(value: any): void;
}

interface IFieldProps extends IComponentProps {
  name: string;
  disabled?: boolean;
  label?: string;
  handleFieldChange?: (value: any) => void;
}

export const SwitchComponent = ({ onChange, value, ...props }: IComponentProps) => {
  const isAndroid = false;
  const CMP = isAndroid ? Switch : IOSSwitch;

  return (
    <CMP
      {...props}
      value={value}
      checked={!!value}
      onChange={onChange}
    />
  );
};

export const SwitchField: FC<IFieldProps> = (
  {
    labelPlacement,
    onChange,
    ...props
  }) => {
  const [ field, meta, helpers ] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setValue(value !== 'true');

      onChange?.(value);
    },
    [onChange, setValue]
  );

  return (
    <FormControlLabel
      label={props.label}
      checked={!!value}
      labelPlacement={labelPlacement as any}
      control={
        <Field
          {...props}
          component={SwitchComponent}
          value={value}
          onChange={handleChange} // or here
        />
      }
    />
  );
};
