import React, { FC }                 from 'react';
import { FamilyMemberCircle }        from './FamilyMemberCircle';
import { FamilyMemberCircleWrapper } from './FamilyMembers';
import { CheckedBadge }              from './FamilyMembersList';

interface IAllMembersCircleProps {
  selected: boolean;
  onSelect?: () => void;
}

export const AllMembersCircle: FC<IAllMembersCircleProps> = ({ selected, onSelect }) => (
  <FamilyMemberCircleWrapper
    selected={selected}
    shaded={!selected}
    onClick={() => onSelect?.()}
  >
    <CheckedBadge />

    <FamilyMemberCircle
      color={'#e4572e'}
      initials={'ALL'}
    />
  </FamilyMemberCircleWrapper>
);
