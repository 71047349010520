import { FormikProps }            from 'formik';
import React, { FC, RefObject }   from 'react';
import { FormButton, FormFooter } from '../../../shared/styledComponents';
import { useFormikHelpers }       from '../../../shared/utils/hooks/useFormikHelpers';

interface IFormFooter {
  type?: 'submit' | 'reset' | 'button';
  dirty: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  message?: string;
  formikRef?: RefObject<FormikProps<any>>;
}

export const FormFooterActions: FC<IFormFooter> = ({
  type = 'button',
  dirty,
  isLoading,
  isDisabled,
  message = 'All changes have been saved',
  formikRef,
}) => {
  const { formValidator, formSubmitter } = useFormikHelpers();

  return (
    <FormFooter hidden={isDisabled}>
      <FormButton
        type={type}
        disabled={!dirty || isLoading}
        size="medium"
        color="primary"
        variant="contained"
        onClick={() => formikRef && formValidator(formikRef).then(() => formSubmitter(false, message, formikRef))}
      >
        { dirty ? `Sav${ isLoading ? 'ing' : 'e' } Changes` : 'Saved' }
      </FormButton>
    </FormFooter>
  );
};
