import React, { useState, useEffect }         from 'react';
import Paper                                  from '@mui/material/Paper';
import Table                                  from '@mui/material/Table';
import TableBody                              from '@mui/material/TableBody';
import TableContainer                         from '@mui/material/TableContainer';
import TableHead                              from '@mui/material/TableHead';
import TablePagination                        from '@mui/material/TablePagination';
import TableRow                               from '@mui/material/TableRow';
import { NoDataFound }                        from './NoDataFound';
import { TableCellWrapper, FreeColumWrapper } from '../../../Auth/ChangeSubscription/styles';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
}

const columnsAdmin: readonly Column[] = [
  { id: 'firstName', label: 'First Name', minWidth: 100 },
  { id: 'lastName', label: 'Last Name', minWidth: 100 },
  { id: 'middleName', label: 'Middle Name', minWidth: 100 },
  { id: 'country', label: 'Country', minWidth: 100 },
  { id: 'registered', label: 'Registered', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'phoneNumber', label: 'Phone Number', minWidth: 100 },
  { id: 'platformType', label: 'Platform Type', minWidth: 100 },
  { id: 'planType', label: 'Plan Type', minWidth: 100 },
];

const columnsUser: readonly Column[] = [
  { id: 'firstName', label: 'First Name', minWidth: 100 },
  { id: 'lastName', label: 'Last Name', minWidth: 100 },
  { id: 'middleName', label: 'Middle Name', minWidth: 100 },
  { id: 'country', label: 'Country', minWidth: 100 },
  { id: 'registered', label: 'Registered', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'phoneNumber', label: 'Phone Number', minWidth: 100 },
  { id: 'platformType', label: 'Platform Type', minWidth: 100 },
];

interface IData {
  firstName: string;
  lastName: string;
  middleName: string;
  country: string;
  registered: string;
  email: string;
  phoneNumber: string;
  platformType: string;
  planType: string;
}

interface UserDataTableProps {
  statisticsData: IData[];
  familyCreator?: boolean;
}

export const UserDataTable: React.FC<UserDataTableProps> = ({ statisticsData, familyCreator }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState(columnsAdmin);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getColum = () => {
    if (familyCreator) {
      setColumns(columnsAdmin);
    } else if (!familyCreator) {
      return setColumns(columnsUser);
    }
  };

  useEffect(() => {
    getColum();
  }, [familyCreator]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellWrapper key={column?.id} className="table-header" >
                  {column.label}
                </TableCellWrapper>
              ))}
            </TableRow>
          </TableHead>

          {statisticsData.length !== 0 && (
            <TableBody>
              {statisticsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow key={row.code} hover role="checkbox" tabIndex={-1}>
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <FreeColumWrapper key={column.id} align="left">
                          {value}
                        </FreeColumWrapper>
                      );
                    })}
                  </TableRow>
                ))
              }
            </TableBody>
          )}
        </Table>

        {statisticsData.length === 0 &&  <NoDataFound />}
      </TableContainer>

      <TablePagination
        component="div"
        count={statisticsData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 100]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
