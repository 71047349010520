import {  Button, DialogActions }           from '@material-ui/core';
import { FC }                               from 'react';
import styled                               from 'styled-components';
import { ReactComponent as BigAlertIcon }   from '../../../../images/icons/rounded/icon-alert-big.svg';
import { StyledDialogContent }              from '../../../shared/components/modals/AlertDialog';
import { Modal }                            from '../../../shared/components/modals/Modal';
import { TextBrandColor }                   from '../../../shared/styledComponents';
import { IFamilyMember }                    from '../../../shared/utils/withAuthorization/withAuthorization';

interface IUserDialogProps {
  submitButtonDisabled?: boolean;
  user?: IFamilyMember;
  nextPaymentDate?: string;
  isCancelSubscription?: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

const UserDialogActions = styled(DialogActions)`
  .MuiButtonBase-root:first-child {
    color: #e4572e;
    border-color: #e4572e;
  }
`;

const UserModal = styled(Modal)`
  text-align: center;
  font-family: Jost;

  .MuiButtonBase-root,
  .MuiIconButton-root {
    z-index: 1;
  }
  
  .MuiDialogActions-root {
    flex-wrap: wrap;
    justify-content: center;
    padding: 13px 32px 28px;
    
    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      padding: 13px 32px 38px;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      padding: 13px 32px 56px;
    }

    .MuiButtonBase-root {
      margin: 0 0 10px 0;
      width: 100%;

      ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
        margin: 0;
        width: auto;

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
`;

const MainText = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.14px;
  padding-top: 16px;
  line-height: 1.21;
  margin-bottom: 20px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    line-height: 1.31;
    font-size: 30px;
  }
`;

const UserDialogContent = styled(StyledDialogContent)`
  align-items: center;
  display: flex;
  flex-direction: column;

  svg {
    width: 73px;
    height: 73px;
    overflow: visible;
  }

  &.MuiDialogContent-root {
    padding: 48px 32px 0;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      padding: 38px 52px 0;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      padding: 68px 52px 0;
    }
  }

  .MuiBadge-badge {
    height: 33px;
    width: 46px;
    top: 18px;
  }

  .MuiBadge-root {
    div:last-child {
      border: 2px solid #e4572e;
    }
  }
`;

export const RestoreSubscription: FC<IUserDialogProps> = ({
  submitButtonDisabled,
  user,
  nextPaymentDate = null,
  isCancelSubscription,
  onClose,
  handleSubmit,
}) => {

  const restoreSubscription = (
    <MainText>
        Are you sure you want to <TextBrandColor> restore account</TextBrandColor>?
    </MainText>
  );

  const selfRestoreContent = (
    <>
      <BigAlertIcon />
      { restoreSubscription }
    </>
  );

  return (
    <UserModal
      open
      dialogWidth="423px"
      onClose={onClose}
    >
      <UserDialogContent>
        { selfRestoreContent }
      </UserDialogContent>

      <UserDialogActions>
        <Button
          color="primary"
          variant="outlined"
          disabled={submitButtonDisabled}
          onClick={() => handleSubmit()}
        >
           Restore account
        </Button>

        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={onClose}
        >
            Not Right Now
        </Button>
      </UserDialogActions>

    </UserModal>
  );
};
