import { Grid }                                                   from '@material-ui/core';
import { AxiosError }                                             from 'axios';
import { FastField, Form, Formik, FormikProps }                   from 'formik';
import React, { FC, useMemo, useRef }                             from 'react';
import { useMutation }                                            from 'react-query';
import { IErrorResponse }                                         from '../../../../../Auth/shared/interfaces';
import { Notistack }                                              from '../../../../../shared/components/Notistack/Notistack';
import { PasswordInputField }                                     from '../../../../../shared/formFields/PasswordInputField';
import { updateCurrentUserPassword }                              from '../../../../../shared/queries/settings';
import { FormSpacing, TextBrand }                                 from '../../../../../shared/styledComponents';
import { FormFooterActions }                                      from '../../../shared/FormFooterActions';
import { IChangePasswordRequest, changePasswordValidationSchema } from './validation';

export const ChangePasswordForm: FC = () => {
  const updateMutation = useMutation(
    'updateUserPassword',
    (data: IChangePasswordRequest) => updateCurrentUserPassword(data)
  );

  const formikRef = useRef<FormikProps<IChangePasswordRequest>>(null);

  const updatePassword = (formValues: IChangePasswordRequest) => updateMutation.mutate(formValues, {
    onSuccess: (data) => {
      formikRef?.current?.resetForm();
      Notistack.enqueueSnackbar(data?.message || 'Password was successfully updated.', 'success');
    },
    onError: (error: AxiosError<IErrorResponse> | any) => {
      const errorDetails = error && error.response && error.response.data.ErrorDetails;
      if (errorDetails) {
        const { Key, Message } = errorDetails;
        formikRef?.current?.setErrors({
          [Key]: Message,
        });
      }
    },
  });

  const initialValues = {
    oldPassword     : '',
    password        : '',
    confirmPassword : '',
  };

  const FormContent = ({ dirty }: FormikProps<IChangePasswordRequest>) => useMemo(() =>
    (<Form>
      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextBrand mb={5}>Update Password</TextBrand>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FastField
                  name="oldPassword"
                  label="Old Password"
                  component={PasswordInputField}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FastField
                  name="password"
                  label="New Password"
                  component={PasswordInputField}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FastField
                  name="confirmPassword"
                  label="Confirm New Password"
                  component={PasswordInputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        dirty={dirty}
        isLoading={updateMutation.isLoading}
        formikRef={formikRef}
      />
    </Form>), [ dirty, updateMutation.isLoading ]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={changePasswordValidationSchema}
      onSubmit={updatePassword}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
