import { WithStyles, withStyles }                                                     from '@material-ui/core/styles';
import { KeyboardTimePicker }                                                         from '@material-ui/pickers';
import classNames                                                                     from 'classnames';
import moment                                                                         from 'moment';
import { ForwardRefRenderFunction, ReactNode, forwardRef, memo, useEffect, useState } from 'react';
import styled                                                                         from 'styled-components';
import { ReactComponent as IconTime }                                                 from '../../../../images/icons/icon-time.svg';
import { IconRemove }                                                                 from '../../../modules/MyFamilyCore/shared/styles';
import {
  ITextInputOwnProps,
  TextInput,
}                                                                                     from '../../inputs/TextInput/TextInput';
import {
  DashedButtonIconLabel,
  DatePickerWrapper,
}                                                                                     from '../../styledComponents';
import {
  getMoment,
  timeRegex,
}                                                                                     from '../../utils/commonFunctions';
import { selectDateStyles }                                                           from '../SelectDate/selectDateStyles';
import { IEvent }                                                                     from '../../../modules/Schedule/queries';

const TimePicker = styled(KeyboardTimePicker)`
  display: none;
`;

export interface ISelectTimeProps
  extends ITextInputOwnProps, WithStyles<typeof selectDateStyles> {
  id?: string;
  schedule: Array<IEvent>;
  label: string;
  name?: string;
  value?: string | null;
  error?: boolean;
  helperText?: ReactNode;
  shrink?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (event: unknown) => void;
}

const SelectTimeBase: ForwardRefRenderFunction<HTMLDivElement, ISelectTimeProps> = ({
  schedule,
  name,
  label,
  value,
  classes,
  shrink = true,
  disabled,
  className,
  onChange,
  ...props
}, ref) => {
  const [ inputDate, setInputDate ] = useState<any>(null);
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(
    () => {
      const date = value ? getMoment(value) : null;
      setInputDate(date);
    },
    [ value ]
  );

  const updateDate = (momentValue: moment.Moment) => {
    const initialMoment = moment(value || undefined);
    const { hours, minutes } = momentValue.toObject();
    initialMoment.utc().set({ hours, minutes });

    return initialMoment.format('YYYY-MM-DDTHH:mm:ss');
  };

  const handleChange = (inputMoment: moment.Moment | null, inputValue?: string | null) => {
    setInputDate(inputValue);

    const isInputValid = !inputValue || timeRegex.test(inputValue);

    if (!onChange || !isInputValid) return;

    const updateValue = inputMoment ? updateDate(inputMoment) : null;

    onChange({ target: { value: updateValue, name } });
  };

  const onClear = (event: any) => {
    event.stopPropagation();
    onChange?.({ target: { value: null, name } });
  };

  return (
    <div
      ref={ref}
      className={classNames([ classes.root, className ])}
    >
      <DatePickerWrapper
        empty={!value}
        onClick={() => setIsOpen(!isOpen)}
      >
        <DashedButtonIconLabel>
          <IconTime />
          { inputDate && moment(inputDate).format('hh:mm A') }
        </DashedButtonIconLabel>

        { inputDate ? <IconRemove onClick={onClear} /> : 'Choose a Time' }
      </DatePickerWrapper>

      <TimePicker
        {...props}
        open={isOpen}
        label={label}
        value={inputDate}
        format="hh:mm A"
        placeholder="hh:mm A"
        margin="none"
        disabled={disabled}
        keyboardIcon={<IconTime />}
        InputLabelProps={{ shrink }}
        TextFieldComponent={TextInput as any}
        KeyboardButtonProps={{
          'aria-label': 'Time Picker',
        }}
        onChange={handleChange}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

const SelectTimeMemoized = memo(forwardRef(SelectTimeBase));

export const SelectTime = withStyles(selectDateStyles)(SelectTimeMemoized);
