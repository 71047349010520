import React,{ FC, useState, useCallback }  from 'react';
import { useLocation }                      from 'react-router-dom';

import { IUploadImageResponse, IFamily } from '../../../../Auth/shared/interfaces';
import { Breadcrumbs }                   from '../../../../shared/components/Breadcrumbs';
import { EAppRoutes }                    from '../../../../shared/constants/constants';
import { AvatarPicker }                  from '../../shared/AvatarPicker';
import { SettingsPageWrapper }           from '../../shared/SettingsPageWrapper';
import { AboutMePage }                   from './AboutMeForm/AboutMePage';
import { MyProfileForm }                 from './MyProfileForm/MyProfileForm';
import { IUserProfile }                  from './MyProfileForm/validation';

export interface IProfileProps {
  currentUser?          : IUserProfile;
  updateUserProfile?    : any;
  isUpdatingUserProfile?: boolean;
  selectedFamilyId      : string | undefined;
  families?             : IFamily[];
  selectedFamily?       : IFamily;
}

export const Profile: FC<IProfileProps> = ({ families, selectedFamily, currentUser, updateUserProfile, isUpdatingUserProfile, selectedFamilyId }) => {
  const [avatar, setAvatar] = useState<IUploadImageResponse | undefined>(currentUser?.avatar);
  const [color, setColor] = useState<string | undefined>(currentUser?.color);
  const { pathname } = useLocation();
  const isMyProfile = pathname === EAppRoutes.MyProfile;

  const handleUpdateAvatar = useCallback((data: IUploadImageResponse | undefined) => {
    if (isMyProfile) {
      setAvatar(data);
    }
  }, [isMyProfile]);

  const Sidebar = () => (
    <AvatarPicker
      avatar={avatar}
      color={color}
      families={families}
      selectedFamily={selectedFamily}
      currentUser={currentUser}
      updateUserProfile={updateUserProfile}
      isUpdatingUserProfile={isUpdatingUserProfile}
      onSuccess={handleUpdateAvatar}
    />
  );

  const MyProfileTab = (
    <MyProfileForm
      avatarId={avatar?.id}
      currentUser={currentUser}
      selectedFamilyId={selectedFamilyId}
      onColorChange={setColor}
    />
  );

  const AboutMeTab = (
    <AboutMePage />
  );

  const breadcrumbs = [{
    label     : 'My Profile',
    to        : `${EAppRoutes.MyProfile}`,
    component : MyProfileTab,
  }, {
    label     : 'About Me',
    to        : `${EAppRoutes.MyProfile}/about-me`,
    component : AboutMeTab,
  }];

  return (
    <SettingsPageWrapper sidebar={Sidebar}>
      <Breadcrumbs data={breadcrumbs} />
    </SettingsPageWrapper>
  );
};
