import { Grid }                           from '@material-ui/core';
import axios, { AxiosResponse }           from 'axios';
import { FC, useEffect, useState }        from 'react';
import { useMutation }                    from 'react-query';
import styled                             from 'styled-components';
import { ReactComponent as IconDocument } from '../../../images/icons/icon-document.svg';
import { IErrorResponse }                 from '../../Auth/shared/interfaces';
import { handleApiErrorResponse }         from '../../utils/apiHelpers';
import { processFileDownload }            from '../functions';
import { formatBytes }                    from '../utils/commonFunctions';
import { LoadingProgress }                from './modals/shared/LoadingProgress';
import { TProgressCallback }              from './modals/UploadDocument/queries';
import { IAttachment }                    from './modals/UploadDocument/validation';
import { Notistack }                      from './Notistack/Notistack';

export const DocumentPreviewWrapper = styled.div`
  width: 150px;
  flex-grow: 0;
  display: flex;
  border-radius: 6px;
  align-items: center;
  padding-bottom: 10px;
  margin-right: 25px;

  &:first-child {
    padding-left: 0;
  }
`;

export const DocumentThumbnail = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding-left: 4px;
`;

export const FileName = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: #272932;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const FileDetails = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  white-space: nowrap;
`;

export const DownloadButton = styled.span<{ disabled?: boolean }>`
  color: #5071bb;
  cursor: ${ ({ disabled }) => disabled ? 'disabled' : 'pointer' };
  opacity: ${ ({ disabled }) => disabled ? '.7' : '1' };
`;

export const DocumentPreviewIcon = styled(IconDocument)`
  width: 48px;
  height: 60px;
`;

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const downloadAttachment = (entityName: string, attachmentId: string, path: string, cb?: TProgressCallback) => {
  const name = path?.replace('files', '')?.split('.')?.[0];

  return axios.get(`/api/v1/${ entityName }/attachments/${ attachmentId }`, {
    onDownloadProgress: progressEvent => {
      const percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100);
      cb?.(percentCompleted);
    },
    responseType: 'blob',
  })
    .then(async ({ data }: AxiosResponse<Blob>) => processFileDownload(name, data))
    .catch(onCatch);
};

const ATTACHMENT_DOWNLOAD = 'ATTACHMENT_DOWNLOAD';
export const useDownloadAttachment = (entityName: string, attachmentId: string, path: string, cb?: TProgressCallback) => useMutation(
  ATTACHMENT_DOWNLOAD,
  () => downloadAttachment(entityName, attachmentId, path, cb),
  {
    retry     : 0,
    onSuccess : () => {
      Notistack.enqueueSnackbar('Load complete', 'success');
    },
    onError: handleApiErrorResponse,
  }
);

interface IAttachmentPreviewProps {
  attachment: IAttachment;
  entityName: string;
}

export const AttachmentPreview: FC<IAttachmentPreviewProps> = ({ attachment, entityName }) => {
  const [ displayProgress, setDisplayProgress ] = useState<boolean | null>(null);
  const [ downloadProgress, setDownloadProgress ] = useState(0);
  const { mutate: downloadAttachmentHandler, isLoading } = useDownloadAttachment(entityName, attachment.id, attachment.path, setDownloadProgress);

  const handleClose = () => {
    setDisplayProgress(false);
    setDownloadProgress(0);
  };

  useEffect(() => {
    let autoCloseTimeoutId: number;

    if (downloadProgress === 100) {
      // @ts-ignore
      autoCloseTimeoutId = setTimeout(handleClose, 1000);
    } else if (downloadProgress !== 0 && displayProgress === null) {
      setDisplayProgress(true);
    }

    return () => {
      clearTimeout(autoCloseTimeoutId);
    };
  }, [ downloadProgress ]);

  return (
    <>
      <DocumentPreviewWrapper>
        <DocumentPreviewIcon />

        <DocumentThumbnail>
          <FileName>{ attachment.path.split('/')[1] }</FileName>
          <FileDetails>{ formatBytes(attachment.documentSize) } •
            <DownloadButton
              disabled={isLoading}
              onClick={() => !isLoading && downloadAttachmentHandler()}
            >
              Download
            </DownloadButton>
          </FileDetails>
        </DocumentThumbnail>
      </DocumentPreviewWrapper>

      <LoadingProgress
        disableBackdropClick={false}
        open={!!displayProgress}
        value={downloadProgress}
        onClose={handleClose}
      />
    </>
  );
};

interface IUploadedFiles {
  attachments: IAttachment[];
  entityName: string;
}

export const UploadedFiles: FC<IUploadedFiles> = ({ attachments, entityName }) => {
  const acceptedFileItems = attachments.map((attachment, index) => (
    <AttachmentPreview
      key={index}
      entityName={entityName}
      attachment={attachment}
    />
  ));

  return (
    <Grid
      container
      spacing={2}
    >
      { acceptedFileItems }
    </Grid>
  );
};
