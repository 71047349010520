import React, { FC, useMemo }            from 'react';
import { Link, Route, useLocation }      from 'react-router-dom';
import styled                            from 'styled-components';
import { ReactComponent as ChevronLeft } from '../../../images/icons/icons-white-chevron-left.svg';
import { PageHeaderContainer }           from '../styledComponents';

interface IBreadcrumb {
  label: string;
  to: string;
  component: JSX.Element;
}

interface IBreadcrumbs {
  data: IBreadcrumb[];
}

const BreadcrumbChevronLeft = styled(ChevronLeft)`
  margin-left: -5px;
`;

const Breadcrumb = styled(Link)`
  display: flex;
  align-items: center;
  color: #547dd3;
  margin-right: 10px;
  font-weight: 500;
  font-family: Jost;
  font-size: 16px;

  &:last-child {
    color: #2c3f69;
  }
`;

export const Breadcrumbs: FC<IBreadcrumbs> = ({ data }) => {
  const { pathname } = useLocation();

  return useMemo(() => {
    const pages = data.slice().reverse();
    const current = pages.find((item) => pathname === item.to) || data[0];
    const main = data[0];
    const isFirstOne = !data.indexOf(current);

    return (
      <>
        { !isFirstOne && (
          <PageHeaderContainer>
            <Breadcrumb
              to={main.to}
            >
              <BreadcrumbChevronLeft />
              { main.label }
            </Breadcrumb>

            <Breadcrumb
              to={current.to}
            >
              <BreadcrumbChevronLeft />
              { current.label }
            </Breadcrumb>
          </PageHeaderContainer>
        ) }

        { pages?.map(page => (
          <Route
            key={page.to}
            exact
            path={page.to}
          >{ page?.component }</Route>
        )) }
      </>
    );
  }, [ pathname ]);
};
