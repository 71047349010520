import axios, { AxiosResponse }                                 from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery, useMutation }                        from 'react-query';
import { queryClient }                                          from '../../../../App';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../../../Auth/shared/interfaces';
import { ACTIVITIES, ACTIVITY_COUNTERS }                        from '../../../../modules/Activity/queries';
import { handleApiErrorResponse }                               from '../../../../utils/apiHelpers';
import { processFileDownload }                                  from '../../../functions';
import { CATEGORIES, ICategory, ISubCategory }                  from '../../../queries/categories';
import { useCreateQuery }                                       from '../../../utils/hooks/useReactQuery';
import { IFamilyMember }                                        from '../../../utils/withAuthorization/withAuthorization';
import { Notistack }                                            from '../../Notistack/Notistack';
import { IAttachment, IUploadDocument }                         from './validation';

export interface IDocument {
  id: string;
  name: string;
  category: ICategory;
  subCategory: ISubCategory;
  description: string;
  assignedPermission: string;
  creationTime: string;
  creator: IFamilyMember;
  assigned?: string[];
  tagged?: string[];
  attachments?: IAttachment[];
}

interface IDeleteDocumentRequest {
  id?: string;
  familyId?: string;
  securityCode?: string;
}

const DOCUMENT = 'DOCUMENT';
const DOCUMENTS = 'DOCUMENTS';
const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';
const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
const DOCUMENT_DOWNLOAD = 'DOCUMENT_DOWNLOAD';
const DOCUMENT_DELETE = 'DOCUMENT_DELETE';

export const useFetchDocumentDetails = (
  id?: string,
  familyId?: string
) => useCreateQuery<IDocument>({
  queryKey : [ DOCUMENT, id, familyId ],
  apiUrl   : `/api/v1/documents/${ id }?${ qs.stringify({ familyId }) }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!id && !!familyId,
  },
});

const loadDocuments = (
  familyId?: string,
  familyMemberId?: string,
  categoryId?: string,
  subCategoryId?: string,
  dateFilter?: string,
  offset = 0,
  limit  = 5
) => {
  const params = {
    familyId,
    familyMemberId,
    categoryId,
    subCategoryId,
    dateFilter,
    offset,
    limit,
  };

  return axios.get(`/api/v1/documents?${ qs.stringify(params) }`)
    .then(({
      data: {
        data: {
          items,
          totalCount,
        },
      },
    }: AxiosResponse<IRequestResponse<IPaginatedResponse<IDocument>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch(onCatch);
};

export const useLoadDocumentsPage = (
  familyId?: string,
  familyMemberId?: string,
  categoryId?: string,
  subCategoryId?: string,
  dateFilter?: string,
  limit?: number
) => useInfiniteQuery(
  [ DOCUMENTS, familyId, familyMemberId, categoryId, subCategoryId, dateFilter ],
  ({ pageParam: offset }) => loadDocuments(familyId, familyMemberId, categoryId, subCategoryId, dateFilter, offset, limit),
  {
    enabled          : !!familyId,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const onSuccess = (message: string, invalidateTypes = true) => {
  invalidateTypes && queryClient.invalidateQueries([ CATEGORIES ]);
  Notistack.enqueueSnackbar(message, 'success');
};

const documentUploadQueryParams = (data: IUploadDocument) => qs.stringify({
  id                            : data.id,
  name                          : data.name,
  familyId                      : data.familyId,
  categoryId                    : data.categoryId,
  subCategoryId                 : data.subCategoryId,
  description                   : data.description,
  assigned                      : data.assigned,
  assignedPermission            : data.assignedPermission,
  attachmentsId                 : data.attachmentsId,
  [data.tagged ? 'Tagged' : ''] : data.tagged || null,
}, { skipNulls: true });

const uploadDocument = (data: IUploadDocument, files: File[]) => {
  const queryParams = documentUploadQueryParams(data);
  const formData = new FormData();

  files?.forEach(file => {
    formData.append('Attachments', file);
  });

  return axios.post(`/api/v1/documents?${ queryParams }`, formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadDocument>>) => {
      queryClient.invalidateQueries([ DOCUMENTS ]);
      queryClient.invalidateQueries([ DOCUMENT, data?.id ]);
      return data;
    })
    .catch(onCatch);
};

export const useUploadDocument = () => useMutation(
  DOCUMENT_UPLOAD,
  ({
    data,
    files,
  }: { data: IUploadDocument; files: File[] }) => uploadDocument(data, files),
  {
    onSuccess: () => {
      queryClient.invalidateQueries([ DOCUMENTS ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
      onSuccess('A new document was successfully uploaded.', false);
    },
    onError: handleApiErrorResponse,
  }
);

const updateDocument = (data: IUploadDocument, files?: File[]) => {
  const queryParams = documentUploadQueryParams(data);
  const formData = new FormData();

  if (files) {
    files.forEach(file => formData.append('Attachments', file));
  }

  return axios.put(`/api/v1/documents?${ queryParams }`, files && formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadDocument>>) => data)
    .catch(onCatch);
};

export const useUpdateDocument = () => useMutation(
  DOCUMENT_UPDATE,
  ({
    data,
    files,
  }: { data: IUploadDocument; files?: File[] }) => updateDocument(data, files),
  {
    onSuccess: () => {
      onSuccess('Document was successfully updated.', false);
      queryClient.invalidateQueries([ DOCUMENT ]);
      queryClient.invalidateQueries([ DOCUMENTS ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
    },
    onError: handleApiErrorResponse,
  }
);

export type TProgressCallback = (progress: number) => void;

const downloadDocument = (id?: string, attachmentId?: string, path?: string, code?: string, familyId?: string, cb?: TProgressCallback) => {
  const name = path?.replace('files/', '')?.split('.')?.[0] || '';

  return axios.get(`/api/v1/Documents/attachments/${ attachmentId }?${ qs.stringify({ code }) }`, {
    onDownloadProgress: progressEvent => {
      const percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100);
      cb?.(percentCompleted);
    },
    responseType : 'blob',
    params       : {
      familyId,
      code,
    },
  }).then(async ({ data }: AxiosResponse<Blob>) => processFileDownload(name, data))
    .catch(onCatch);
};

export const useDownloadDocument = (id?: string, path?: string, familyId?: string, cb?: TProgressCallback) => useMutation(
  DOCUMENT_DOWNLOAD,
  ([ code, attachmentId ]: [ code: string, attachmentId?: string ]) => downloadDocument(id, attachmentId, path, code, familyId, cb),
  {
    retry     : 0,
    onSuccess : () => {
      onSuccess('Pin code accepted. Document is loaded.', false);
    },
  }
);

const deleteDocument = (id?: string, securityCode?: string, familyId?: string) => axios.delete('/api/v1/documents', {
  data: {
    id,
    familyId,
    securityCode,
  },
})
  .then(({ data }: AxiosResponse<Blob>) => data)
  .catch(onCatch);

export const useDeleteDocument = () => useMutation(
  DOCUMENT_DELETE,
  ({ id, securityCode, familyId }: IDeleteDocumentRequest) => deleteDocument(id, securityCode, familyId),
  {
    retry     : 0,
    onSuccess : () => {
      onSuccess('Pin code accepted. Document has been removed.', false);
      queryClient.invalidateQueries([ DOCUMENTS ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: handleApiErrorResponse,
  }
);

const markDocumentAsViewed = (familyUserId?: string, documentId?: string) => axios.post('/api/v1/documents/viewed', null, {
  params: {
    documentId,
    familyUserId,
  },
})
  .then(({ data }: AxiosResponse<Blob>) => data)
  .catch(onCatch);

export const useMarkDocumentAsViewed = (familyUserId?: string) => useMutation(
  DOCUMENT_DELETE,
  (documentId: string) => markDocumentAsViewed(familyUserId, documentId),
  {
    retry   : 0,
    onError : handleApiErrorResponse,
  },
);
