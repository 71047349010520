import axios, { AxiosResponse }                      from 'axios';
import { useMutation }                               from 'react-query';
import { queryClient }                               from '../../App';
import { IErrorResponse, IFamily, IRequestResponse } from '../../Auth/shared/interfaces';
import { CHATS }                                     from '../../modules/MessageCenter/queries';
import {
  IFamilyAccountRequest,
  IFamilyAccountResponse,
  IMemberAccountRequest,
}                                                    from '../../modules/Settings/components/ManageFamilyAccount/ManageAccountForm/validation';
import { handleApiErrorResponse }                    from '../../utils/apiHelpers';
import { Notistack }                                 from '../components/Notistack/Notistack';
import { TCreateQueryOptions, useCreateQuery }       from '../utils/hooks/useReactQuery';
import { IFamilyMember }                             from '../utils/withAuthorization/withAuthorization';

export enum EFamilyRole {
  Parent               = 'Parent',
  StepParent           = 'StepParent',
  LegalGuardian        = 'LegalGuardian',
  Child                = 'Child',
  CareTaker            = 'CareTaker',
  FamilyMember         = 'FamilyMember',
  Friend               = 'Friend',
  ProfessionalProvider = 'ProfessionalProvider'
}

export const familyRoles = [
  { value: EFamilyRole.Parent, label: 'Parent' },
  { value: EFamilyRole.StepParent, label: 'Step Parent' },
  { value: EFamilyRole.LegalGuardian, label: 'Legal Guardian' },
  { value: EFamilyRole.Child, label: 'Child' },
  { value: EFamilyRole.CareTaker, label: 'Care Taker' },
  { value: EFamilyRole.FamilyMember, label: 'Family Member' },
  { value: EFamilyRole.Friend, label: 'Friend' },
  { value: EFamilyRole.ProfessionalProvider, label: 'Professional Provider' },
];

export const FAMILIES_QUERY_KEY = 'families';
export const FAMILIES_ACCOUNT_QUERY_KEY = 'account';
export const MEMBER_ACCOUNT_QUERY_KEY = 'member-account';
export const FAMILY_USER_PERMISSIONS_KEY = 'permissions';
export const FAMILY_MEMBERS_KEY = 'familyMembers';
export const FAMILY_USER_KEY = 'FAMILY_USER_KEY';

export const FAMILY_USER_GET = `${ FAMILY_USER_KEY }/get`;
export const FAMILY_MEMBERS_GET = `${ FAMILY_MEMBERS_KEY }/get`;
export const FAMILIES_ACCOUNT_GET = `${ FAMILIES_ACCOUNT_QUERY_KEY }/get`;
export const FAMILIES_ACCOUNT_UPDATE = `${ MEMBER_ACCOUNT_QUERY_KEY }/update`;
export const MEMBER_ACCOUNT_GET = `${ MEMBER_ACCOUNT_QUERY_KEY }/get`;
export const MEMBER_ACCOUNT_UPDATE = `${ MEMBER_ACCOUNT_QUERY_KEY }/update`;

export const useFetchFamilies = (
  options?: TCreateQueryOptions<IFamily[]>
) => useCreateQuery<IFamily[]>({
  queryKey : [ FAMILIES_QUERY_KEY ],
  apiUrl   : '/api/v1/families',
  options  : {
    refetchOnWindowFocus: false,
    ...options,
  },
});

export const useFetchFamilyUser = (
  familyId?: string,
  options?: TCreateQueryOptions<IFamilyMember>
) => useCreateQuery<IFamilyMember>({
  queryKey : [ FAMILY_USER_GET, familyId ],
  apiUrl   : `/api/v1/families/${ familyId }/me`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
    ...options,
  },
});

export const useFetchFamilyMembers = (
  familyId?: string,
  options?: TCreateQueryOptions<IFamilyMember[]>
) => useCreateQuery<IFamilyMember[]>({
  queryKey : [ FAMILY_MEMBERS_GET, familyId ],
  apiUrl   : `/api/v1/families/${ familyId }/users`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
    ...options,
  },
});

export const useFetchFamilyAccount = (
  familyId?: string,
  options?: TCreateQueryOptions<IFamilyAccountResponse>
) => useCreateQuery<IFamilyAccountResponse>({
  queryKey : [ FAMILIES_ACCOUNT_GET, familyId ],
  apiUrl   : `/api/v1/families/${ familyId }/account`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
    ...options,
  },
});

export const useFetchMemberAccount = (
  familyId?: string,
  options?: TCreateQueryOptions<IMemberAccountRequest>
) => useCreateQuery<IMemberAccountRequest>({
  queryKey : [ MEMBER_ACCOUNT_GET, familyId ],
  apiUrl   : `/api/v1/families/${ familyId }/member-account`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
    ...options,
  },
});

const updateFamilyAccount = (data: IFamilyAccountRequest) =>
  axios.put(`/api/v1/families/${ data.id }/account`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamilyAccountRequest>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateFamilyAccount = () => useMutation(
  FAMILIES_ACCOUNT_UPDATE,
  (data: IFamilyAccountRequest) => updateFamilyAccount(data),
  {
    onSuccess: (data: IFamilyAccountRequest) => {
      queryClient.setQueryData('userAccount', data);
      queryClient.invalidateQueries(CHATS);
      Notistack.enqueueSnackbar('About was successfully updated.', 'success');
    },
    onError: handleApiErrorResponse,
  }
);

const updateMemberAccount = (data: IMemberAccountRequest) =>
  axios.put(`/api/v1/families/${ data.id }/member-account`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IMemberAccountRequest>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateMemberAccount = () => useMutation(
  MEMBER_ACCOUNT_UPDATE,
  (data: IMemberAccountRequest) => updateMemberAccount(data),
  {
    onSuccess: (data: IMemberAccountRequest) => {
      queryClient.setQueryData('userAccount', data);
      Notistack.enqueueSnackbar('About was successfully updated.', 'success');
    },
    onError: handleApiErrorResponse,
  }
);

const updateUserPlatform = (platform: string) =>
  axios.put(`api/v1/statistics/platform?userPlatform=${ platform }`, platform)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<string>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateUserPlatform = () => useMutation(
  (platform: string) => updateUserPlatform(platform),
  {
    onError: handleApiErrorResponse,
  }
);

