import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FieldProps }                 from 'formik';
import React, { FC }                  from 'react';
import { LabelText }                  from '../../Auth/shared/styles';

interface ICheckboxFieldOwnProps extends FieldProps {
  id?: string;
  wrap?: any;
  label: string;
  readOnly?: boolean;
}

export const CheckboxField: FC<ICheckboxFieldOwnProps> = ({
  form,
  wrap,
  field: {
    name,
    value,
    onBlur,
    onChange,
  },
  id,
  label,
  readOnly,
  ...props
}) => (
  <FormControlLabel
    label={<LabelText wrap={wrap}>{ label }</LabelText>}
    control={
      <Checkbox
        {...props}
        id={id || name}
        name={name}
        checked={!!value}
        onBlur={onBlur}
        {...(readOnly ? {} : { onChange })}
      />
    }
  />
);
