import { Moment }                 from 'moment';
import { addSpaceBeforeCapitals } from '../../utils/commonFunctions';

export type TRecurs = 'NoRecurrence' | 'Daily' | 'Weekly' | 'Monthly' | 'Yearly';

export type TRecursOrdinal = 'None' | 'First' | 'Second' | 'Third' | 'Fourth';

export enum ERecurrence {
  NoRecurrence = 'NoRecurrence',
  Daily        = 'Daily',
  Weekly       = 'Weekly',
  Monthly      = 'Monthly',
  Yearly       = 'Yearly',
}

export enum EAlertRecurs {
  Day    = 'Day',
  Hour   = 'Hour',
  Minute = 'Minutes',
  Month  = 'Month',
}

export enum ERecursOrdinal {
  First  = 'First',
  Second = 'Second',
  Third  = 'Third',
  Fourth = 'Fourth',
}

export const recurrenceOptions = [
  { id: ERecurrence.NoRecurrence, value: addSpaceBeforeCapitals(ERecurrence.NoRecurrence) },
  { id: ERecurrence.Daily, value: addSpaceBeforeCapitals(ERecurrence.Daily) },
  { id: ERecurrence.Weekly, value: addSpaceBeforeCapitals(ERecurrence.Weekly) },
  { id: ERecurrence.Monthly, value: addSpaceBeforeCapitals(ERecurrence.Monthly) },
  { id: ERecurrence.Yearly, value: addSpaceBeforeCapitals(ERecurrence.Yearly) },
  { id: '', value: 'Custom' },
];

export interface IRepeatOptions {
  recurs?: ERecurrence;
  recursInterval?: number;
  recursDay?: number | null;
  recursOrdinal?: TRecursOrdinal;
  recursWeekDays?: string[];
  recursCount?: number | null;
  recursStartDate?: string;
  recursEndDate?: string;
  startDate?: string;
  endDate?: string;
  recursStartTime?: string;
}

export const generateCalendar = (current: Moment) => {
  const startDay = current.clone().startOf('month').startOf('week');
  const endDay = current.clone().endOf('month').endOf('week');
  const calendar: Moment[][] = [];

  let week: Moment[] = [];
  for (let day = startDay.clone(); day.isBefore(endDay); day.add(1, 'day')) {
    week.push(day.clone());

    if (week.length === 7) {
      calendar.push(week);
      week = [];
    }
  }

  return calendar;
};
