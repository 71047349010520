import axios, { AxiosError, AxiosResponse } from 'axios';
import moment                               from 'moment';
import qs                                   from 'qs';
import { useMutation, useQuery }            from 'react-query';
import { queryClient }                      from '../../App';
import { IRequestResponse }                 from '../../Auth/shared/interfaces';
import { httpClient }                       from '../../shared/utils/httpClient';
import { handleApiErrorResponse }           from '../../utils/apiHelpers';
import { IFamilyUserAccount }               from '../Settings/components/ManageUsers/Account/queries';

const CHECK_INS = 'CHECK_INS';
const CHECK_IN_CREATE = 'CHECK_IN_CREATE';
const CHECK_IN_API_URL = '/api/v1/geolocation/checkin';
const GOOGLE_API = 'https://maps.googleapis.com/maps/api/geocode/json';

export interface ICheckinsResponse {
  items: ICheckin[];
  lastCheckins: ICheckin[];
}

export interface ICheckinAddress {
  name?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  familyId?: string;
}

export interface ICheckin extends ICheckinAddress {
  createdAt: string;
  id: string;
  updatedAt: string;
  updater: IFamilyUserAccount;
  creator: IFamilyUserAccount;
}

export type PlaceResult = google.maps.places.PlaceResult;

const createCheckIn = (checkIn: ICheckinAddress) => axios.post(CHECK_IN_API_URL, checkIn)
  .then(({ data: { data } }: AxiosResponse<IRequestResponse<ICheckin>>) => data);

export const convertCoordsToAddress = (lat: string, lng: string): Promise<PlaceResult> =>
  fetch(
    `${ GOOGLE_API }?latlng=${ lat },${ lng }&key=AIzaSyBIC1niOGZqHMpDtm6Asvxzy7-NDSRnifI`)
    .then(res => res.json())
    .then(address => address.results[0]
    );

export const useCreateCheckIn = () => useMutation(
  CHECK_IN_CREATE,
  (checkIn: ICheckinAddress) => createCheckIn(checkIn),
  {
    onSuccess: () => {
      queryClient.invalidateQueries(CHECK_INS);
    },
    onError: handleApiErrorResponse,
  }
);

export const useFetchCheckins = (
  familyId: string,
  familyUsers?: string[]
) => {
  // custom queryFn is used due to dynamic date params
  const queryFn = async () => {
    const endDate = moment().endOf('day').toISOString();
    const startDate = new Date((new Date()).getTime() - (24 * 60 * 60 * 1000)).toISOString();
    const params = { familyId, familyUsers, startDate, endDate };
    return httpClient<ICheckinsResponse, ICheckinsResponse>(`${ CHECK_IN_API_URL }?${ qs.stringify(params) }`);
  };

  return useQuery<AxiosResponse<ICheckinsResponse>, AxiosError, ICheckinsResponse>(
    [ CHECK_INS, familyId, familyUsers ],
    {
      select               : ({ data }: any) => (data?.data),
      refetchOnWindowFocus : false,
      enabled              : !!familyId && !!familyUsers,
      queryFn,
    }
  );
};
