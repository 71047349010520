import {
  AccordionProps,
  Fade,
  Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography as MuiTypography,
}                                           from '@material-ui/core';
import React, { FC, MouseEvent, ReactNode } from 'react';
import styled                               from 'styled-components';
import { ReactComponent as ChevronRight }   from '../../../images/icons/icons-white-chevron-right.svg';
import { TextBrand }                        from '../styledComponents';
import { GridDivider }                      from './layout/GridDivider';

const ExpansionPanelWrapper = styled(MuiAccordion)`&& {
  width            : 100%;
  box-shadow       : none;
  background-color : transparent;

  .MuiInputLabel-outlined {
    color: #000;
  }
  
  ${ ({ maxheight }: any) => maxheight && `max-height: ${ maxheight }` };

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
  }
}` as any;

const ExpansionPanelSummary = styled(MuiAccordionSummary)`&& {
  .MuiAccordionSummary-content {
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    &.Mui-expanded {
      margin: 12px 0;
    }
  }

  .MuiAccordionSummary-expandIcon {
    color: ${ ({ theme: { palette } }) => palette.primary.main };
  }
}` as any;

const ExpansionPanelDetails = styled(MuiAccordionDetails)`&& {
  display  : block;
  overflow : auto;

  // padding: ${ ({ padding }: any) => padding || '8px' };
  ${ ({ innerheight }: any) => innerheight && `height: ${ innerheight }` };
}` as any;

const Typography = styled(MuiTypography)`&& {
  &:first-child {
    font-weight: 500;
  }
  &:last-child {
    font-weight: 400;
  }
}`;

const ChevronTop = styled(ChevronRight)`
  transform: rotate(90deg);
`;

type MuiColor = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';

interface IProps extends AccordionProps {
  title: any;
  titleBgColor?: MuiColor;
  description?: ReactNode;
  forceDescription?: boolean;
  padding?: string;
  maxHeight?: string;
  innerHeight?: string;
  disableExpand?: boolean;
}

const onClickCapture = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
  event.stopPropagation();
};

export const ExpansionPanel: FC<IProps> = ({
  title,
  titleBgColor,
  description,
  padding,
  maxHeight,
  innerHeight,
  disableExpand,
  forceDescription,
  children,
  ...props
}) => (
  <ExpansionPanelWrapper
    square
    maxheight={maxHeight}
    {...props}
  >
    <ExpansionPanelSummary
      titlebgcolor={titleBgColor}
      {...!disableExpand && { expandIcon: <ChevronTop /> }}
      {...disableExpand && { onClickCapture }}
    >
      <TextBrand>
        { title }
      </TextBrand>

      <Fade in={forceDescription || props.expanded}>
        <Typography
          variant="body2"
          color="textPrimary"
          align="right"
        >
          { description }
        </Typography>
      </Fade>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails
      padding={padding}
      innerheight={innerHeight}
    >
      { children }
    </ExpansionPanelDetails>
  </ExpansionPanelWrapper>
);

ExpansionPanel.defaultProps = {
  defaultExpanded: true,
};

const FormGridWrapper = ({ children }: { children: any }) => (
  <Grid container>
    <Grid
      item
      xs={12}
    >
      <Grid
        container
        spacing={3}
      >
        { children }
      </Grid>
    </Grid>
  </Grid>
);

export const FormExpansionPanel = ({ title, children, last, expanded = true }: { title: any; children: any; expanded?: boolean; last?: boolean }) => (
  <FormGridWrapper key={expanded.toString()}>
    <ExpansionPanel
      defaultExpanded={expanded}
      title={title}
    >
      <Grid
        container
        spacing={3}
      >
        { children }
      </Grid>
    </ExpansionPanel>

    { !last && <GridDivider margin="0 0 20px" /> }
  </FormGridWrapper>
);
