import { Paper }               from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import styled, { css }         from 'styled-components';

const SidebarWrapper = styled.div`
  margin: 0;
  padding: 22px 22px 0;
  display: flex;
  align-items: center;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    align-items: center;
    padding: 32px;
    justify-content: flex-start;
    flex-direction: column;
    width: 230px;
  };
`;

const PageWrapper = styled.div<{ clear?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${ ({ clear }) => css`
    background-color: ${ clear ? 'transparent' : '#f7fafd' };

    ${ SidebarWrapper } {
      background-color: ${ clear ? 'transparent' : '#f7fafd' };
    }
  ` }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    flex-direction: row;
  };
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 24px 32px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.down('sm') } {
    padding: 24px 0;
   }
`;

export const ContentPaper = styled(Paper)`
  overflow: hidden;
  margin-bottom: 32px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.06);
`;

export interface ISettingsPageWrapperProps {
  sidebar?: FC;
  header?: FC;
  loading?: boolean;
  clear?: boolean;
}

export const SettingsPageWrapper: FC<ISettingsPageWrapperProps> = ({
  clear,
  sidebar: Sidebar,
  header : Header,
  children,
}) => {
  const Wrapper = clear ? Fragment : ContentPaper;

  return (
    <PageWrapper clear={clear}>
      { Sidebar && (
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
      ) }

      <ContentWrapper>
        { Header && <Header /> }

        <Wrapper>
          { children }
        </Wrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};
