import { useMemo }                            from 'react';
import { Grid }                               from '@material-ui/core';
import { theme }                              from '../../../../styles';
import { Layout }                             from '../../../Layout/Layout';
import { FullWidthGrid }                      from '../../../shared/components';
import { IRouterDrivenTab, RouterDrivenTabs } from '../../../shared/components/RouterDrivenTabs';
import { EAppRoutes }                         from '../../../shared/constants/constants';
import { ResponsivePageTitle }                from '../../../shared/styledComponents';
import { SettingsPageStyleWrapper }           from '../../../modules/Settings/Settings';
import { CreatorTabStatistics }               from './CreatorTabStatistics';

const baseUrl = EAppRoutes.AdminStatistics;

export const AdminStatistics = () => {
  const ByInviteTab = (
    <CreatorTabStatistics familyCreator />
  );

  const ByAccountCreationTab = (
    <CreatorTabStatistics />
  );

  const tabs: IRouterDrivenTab[] = [ {
    label     : 'Super Admin',
    link      : 'by-account-creation-statistics',
    color     : theme.colors.attention,
    component : ByInviteTab,
  }, {
    label     : 'Admin & Members',
    link      : 'by-invite-statistics',
    color     : theme.colors.attention,
    component : ByAccountCreationTab,
  } ];

  const Page = (
    <SettingsPageStyleWrapper>
      <ResponsivePageTitle>
        <span>Admin Center</span>
      </ResponsivePageTitle>

      <FullWidthGrid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
        >
          <RouterDrivenTabs
            baseURL={baseUrl}
            tabs={tabs}
          />
        </Grid>
      </FullWidthGrid>
    </SettingsPageStyleWrapper>
  );

  return useMemo(() =>
    (<Layout>
      { Page }
    </Layout>)
  , [ window.location.href ]);
};
