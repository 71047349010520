import { keyframes } from 'styled-components';

export const flickerFrames = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: .3; }
  100% { opacity: 1; }
`;

export const barberPoleFrames = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
`;
