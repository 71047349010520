import React, { FC }    from 'react';
import styled           from 'styled-components';
import { CategoryPill } from '../../../../shared/styledComponents';

const StatusPills = styled.span`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    flex-wrap: nowrap;
  }
`;

interface IContactPillsProps {
  role: string;
  category?: string;
}

export const ContactPills: FC<IContactPillsProps> = ({ role, category }) => (
  <StatusPills>
    <CategoryPill color="#8fc4ea">{ role }</CategoryPill>
    <CategoryPill>{ category }</CategoryPill>
  </StatusPills>
);
