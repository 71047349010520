import { Moment }           from 'moment';
import { generateCalendar } from '../../shared/components/modals/shared';
import { randomNumber }     from '../../shared/functions';
import { flattenArray }     from '../../shared/utils/commonFunctions';

const loadingColors = [
  '#54d3ba', '#f1ce1e', '#efa8be',
  '#7ab1d6', '#fe938c', '#88dd0f',
  '#00cecb', '#af83d9', '#00c4ff',
  '#b0caca', '#d4d4d9',
];

export const randomColor = () => loadingColors[randomNumber(0, loadingColors?.length - 1)];

export const generateRandomSchedule = (current: Moment) => {
  const calendar = generateCalendar(current);
  const recurrentId = Math.random();
  const recurrentColor = randomColor();

  const mapped = calendar.map((week) => week.map(day => {
    const events: any[] = [ {
      id     : recurrentId,
      title  : 'Loading...',
      allDay : true,
      color  : recurrentColor,
      date   : day.utc().toISOString(),
    }, Math.random() > .7 && {
      id     : Math.random(),
      title  : 'Loading...',
      allDay : true,
      color  : randomColor(),
      date   : day.utc().toISOString(),
    }, Math.random() > .4 && {
      id     : Math.random(),
      title  : 'Loading...',
      allDay : false,
      color  : randomColor(),
      date   : day.utc().toISOString(),
    } ].filter(event => !!event);

    return day.isSame(current, 'month') ? events : [];
  }));

  return flattenArray(mapped);
};
