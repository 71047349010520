import { Divider, Grid, Hidden }                  from '@material-ui/core';
import axios, { AxiosError }                      from 'axios';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import qs                                         from 'qs';
import React, { FC, useState }                    from 'react';
import { Link, useHistory }                       from 'react-router-dom';
import styled                                     from 'styled-components';
import PhoneInput                                 from 'react-phone-input-2';
import { ReactComponent as ImageGroup }           from '../../../images/family-group.svg';
import { LinkLikeText }                           from '../../shared/components/documents/DocumentPicker';
import { LogoBlue }                               from '../../shared/components/Logo';
import { TermsModal }                             from '../../shared/components/modals/Terms/TermsModal';
import { MuiButton }                              from '../../shared/components/MuiButton';
import { EAuthRoutes }                            from '../../shared/constants/constants';
import { CheckboxField }                          from '../../shared/formFields/CheckboxField';
import { PasswordInputField }                     from '../../shared/formFields/PasswordInputField';
import { TextInputField }                         from '../../shared/formFields/TextInputField';
import { TextBrandColor }                         from '../../shared/styledComponents';
import { IErrorResponse }                         from '../shared/interfaces';

import 'react-phone-input-2/lib/style.css';

import {
  AuthField,
  AuthSVGSceneHolder,
  PageHeader,
  PageHeaderText,
  ReverseGridContainer,
  SignInText,
  SignInUpPage,
  SignUpContainer,
  Terms,
  TextBlack,
}                                                 from '../shared/styles';
import { signUpFormSchema }                       from './validation';

interface ISignUpForm {
  fullName: string;
  email: string;
  phoneNumber?: string;
  password: string;
  confirmPassword: string;
  news: boolean;
}

const CheckboxGrid = styled(Grid)`&& {
  padding-top: 3px;
}`;

export const SignUpHeaderText = styled(PageHeaderText)`&& {
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: auto;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 100%;
    padding: 20px 0 0;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('lg') } {
    width: 100%;
    padding: 20px 0 0;
  }
}`;

const SignUpSvgContainer = styled(AuthSVGSceneHolder)`
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    margin: -25px 75px 0 0 !important;
  }
`;

const CheckBoxWrapper = styled.div`
  margin-left: -11px;
`;

export const SignUp: FC = () => {
  const history = useHistory();
  const [ readOnly, setReadOnly ] = useState(true);
  const [ termsModalOpened, setTermsModalOpened ] = useState(false);
  const [phone, setPhone] = useState('');

  const initialValues: ISignUpForm = {
    email           : '',
    phoneNumber     : '',
    password        : '',
    fullName        : '',
    confirmPassword : '',
    news            : true,
  };

  const handleSubmit = (values: ISignUpForm, formikActions: FormikHelpers<ISignUpForm>) => {
    const [ firstName, lastName ] = values.fullName.split(/\s+/);

    const formattedPhoneNumber = `+${phone}`;

    const signUpRequest = {
      email           : values.email,
      phoneNumber     : formattedPhoneNumber,
      password        : values.password,
      confirmPassword : values.confirmPassword,
      news            : values.news,
      firstName,
      lastName,
    };

    formikActions.setSubmitting(true);

    axios.post('/api/v1/users', signUpRequest)
      .then(() => {
        history.push({
          pathname : EAuthRoutes.EmailConfirmation,
          search   : qs.stringify({ email: values.email, familyName: lastName }),
        });
        formikActions.setSubmitting(false);
      })
      .catch((err: AxiosError<IErrorResponse>) => {
        const errorDetails = err && err.response && err.response.data.ErrorDetails;
        if (errorDetails) {
          const { Key, Message } = errorDetails;
          const key = [ 'lastName', 'firstName' ]
            .find(item => item === Key) ? 'fullName' : Key;

          formikActions.setErrors({
            [key]: Message,
          });
          formikActions.setSubmitting(false);
        }
      });
  };

  return (
    <SignInUpPage>
      <SignUpContainer maxWidth="md">
        <PageHeader mb="32px">
          <LogoBlue margin="0 0 50px" />

          <SignUpHeaderText centeredXs>
            Let’s Get <TextBrandColor>You</TextBrandColor> Started
          </SignUpHeaderText>
        </PageHeader>

        <ReverseGridContainer
          container
          spacing={3}
        >
          <Hidden smDown>
            <Grid
              item
              sm={7}
            >
              <SignUpSvgContainer>
                <ImageGroup />
              </SignUpSvgContainer>
            </Grid>
          </Hidden>

          <Grid
            item
            xs={12}
            sm={7}
            md={5}
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={signUpFormSchema}
              onSubmit={handleSubmit}
            >
              { ({
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Full Name"
                        name={'fullName'}
                        inputProps={{ maxLength: 100 }}
                        component={TextInputField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Email"
                        name={'email'}
                        readOnly={readOnly}
                        placeholder="you@example.com"
                        inputProps={{ maxLength: 100 }}
                        component={TextInputField}
                        onFocus={setReadOnly(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <FastField
                        name="phoneNumber"
                        render={({ field, form }: any) => (
                          <PhoneInput
                            {...field}
                            country={'us'}
                            inputStyle={{ width: '100%' }}
                            specialLabel=""
                            placeholder="Enter phone number"
                            onChange={(value) => {
                              setPhone(value);
                              form.setFieldValue(field.name, value);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Password"
                        name={'password'}
                        placeholder="At least 8 characters"
                        inputProps={{ maxLength: 50 }}
                        component={PasswordInputField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Confirm Password"
                        name={'confirmPassword'}
                        placeholder="At least 8 characters"
                        inputProps={{ maxLength: 50 }}
                        component={PasswordInputField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <CheckboxGrid
                      item
                      xs={12}
                    >
                      <CheckBoxWrapper>
                        <FastField
                          readOnly
                          label="It’s ok to send me emails about Family Core"
                          name="news"
                          component={CheckboxField}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </CheckBoxWrapper>
                    </CheckboxGrid>
                  </Grid>

                  <Terms align="left">
                    By creating an account you agree
                    to <LinkLikeText onClick={() => setTermsModalOpened(true)}>Terms <TextBlack>&</TextBlack> Conditions</LinkLikeText>.
                  </Terms>

                  <MuiButton
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                    margin="10px 0 0"
                  >
                    Sign Up
                  </MuiButton>

                  <Divider />

                  <SignInText>
                    Already have an account? <Link to={EAuthRoutes.SignIn}>Sign In</Link>
                  </SignInText>
                </Form>
              ) }
            </Formik>
          </Grid>
        </ReverseGridContainer>
      </SignUpContainer>

      <TermsModal
        open={termsModalOpened}
        onClose={() => setTermsModalOpened(false)}
      />
    </SignInUpPage>
  );
};
