import { EAuthRoutes } from '../shared/constants/constants';
import { withQueryParams } from '../shared/functions';
import {
  setAuthorizationToken,
} from '../shared/utils/setAuthorizationToken';
import { getAuthToken } from './authHelpers';

export interface ISubscriptionState {
  product: CdvPurchase.Product | null;
  receipt: CdvPurchase.Receipt | CdvPurchase.AppleAppStore.SKApplicationReceipt | CdvPurchase.GooglePlay.Receipt | null | any;
  transaction: CdvPurchase.Transaction | null;
  verifiedReceipt: CdvPurchase.VerifiedReceipt | null;
}

export const storageKey = 'subscription-state';
export const subscriptionName = 'net.hourglass.familycore.subscription';

export const resetSubscriptionState = () => {
  localStorage.removeItem(storageKey);
};

export const getSubscriptionState = () => {
  const DEFAULT_VALUES = {
    product         : null,
    receipt         : null,
    transaction     : null,
    verifiedReceipt : null,
  };

  const storedData = localStorage.getItem(storageKey);
  const subscription = (storedData ? JSON.parse(storedData) : DEFAULT_VALUES) as ISubscriptionState;

  return subscription;
};

const setSubscriptionState = (subscriptionData: Partial<ISubscriptionState>) => {
  const subscriptionState = getSubscriptionState();

  const textarea = window.document.getElementById(
    'app-store-receipt'
  ) as HTMLTextAreaElement;

  if (textarea) {
    const receipt = subscriptionData.transaction?.transactionId
      || subscriptionState.transaction?.transactionId
      || 'Transaction ID is not found';

    textarea.value = JSON.stringify(receipt);
  }

  localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...subscriptionState,
      ...subscriptionData,
    })
  );
};

const onPurchaseSuccess = () => {
  const token = getAuthToken();

  token && setAuthorizationToken(token);

  if (window.location.href.includes(EAuthRoutes.CodeConfirmation)) {
    window.location.href = '/#/';
  }

  if (window.location.href.includes(EAuthRoutes.Checkout)) {
    window.location.href = `/#${withQueryParams(EAuthRoutes.Checkout, {
      purchased: true,
    })}`;
  }
};

// TODO: delete console logs after billing updating deployed without issues
export const checkSubscription = () => {
  if (!window.CdvPurchase) {
    throw new Error('Purchase plugin is not available');
  }

  const { store, Platform, ProductType } = CdvPurchase;

  const appleProduct: CdvPurchase.IRegisterProduct = {
    id       : subscriptionName,
    platform : Platform.APPLE_APPSTORE,
    type     : ProductType.PAID_SUBSCRIPTION,
  };

  const androidProduct: CdvPurchase.IRegisterProduct = {
    id       : subscriptionName,
    platform : Platform.GOOGLE_PLAY,
    type     : ProductType.PAID_SUBSCRIPTION,
  };

  store.error((error) => {
    console.log(`ERROR ${error.code}: ${error.message}`);
  });

  store.register([appleProduct, androidProduct]);

  store
    .when()
    .productUpdated((product) => {
      console.log('PRODUCT, productUpdated', product);
      setSubscriptionState({ product });
    });

  store
    .when()
    .receiptUpdated((receipt) => {
      console.log('RECEIPT receiptUpdated', receipt);
      setSubscriptionState({ receipt });
    }
    );

  store.when().approved((transaction) => {
    console.log('TRANSACTION approved', transaction);
    transaction.verify();
  });

  store.when().unverified(({ payload }) => {
    console.log(
      `[Store] Unverified. Code: ${payload.code}. Status: ${payload.status}. Message: ${payload.message}`
    );
  });

  store.when().verified((verifiedReceipt) => {
    console.log(
      `[Store] Verified. ID: ${verifiedReceipt.id}. Owned: ${store.owned(
        subscriptionName
      )}`
    );

    setSubscriptionState({ verifiedReceipt });

    console.log('VERIFIED_RECEIPT verified', verifiedReceipt);

    if (store.owned(subscriptionName)) {
      onPurchaseSuccess();
    }

    verifiedReceipt.finish();
  });

  store.when().finished((transaction) => {
    console.log('TRANSACTION finished', transaction);
  });

  // https://github.com/j3k0/cordova-plugin-purchase/wiki/HOWTO:-Migrate-to-v13#storerefresh-is-now-storeinitialize-and-co
  store.initialize();
};
