import React, { FC }                      from 'react';
import { useHistory }                     from 'react-router-dom';
import styled                             from 'styled-components';
import { ReactComponent as ChevronRight } from '../../../images/icons/icons-white-chevron-right.svg';

const Wrapper = styled.div`
  display: flex;
  color: #547cd3;
  font-size: 20px;
  align-items: center;
  padding: 15px 0;

  &:hover {
    opacity: .8;
    cursor: pointer;
  }
`;

const ChevronLeft = styled(ChevronRight)`&& {
  transform: rotate(-180deg);
  margin-right: 10px;

  path {
    fill: #5071bb;
  }
}`;

interface INavigateBackProps {
  title: string;
  to: string;
}

export const NavigateBack: FC<INavigateBackProps> = ({ title, to }) => {
  const history = useHistory();

  return (
    <Wrapper onClick={() => history.push(to)}>
      <ChevronLeft />

      <span>{ title }</span>
    </Wrapper>
  );
};
