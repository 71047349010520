import React, { FC, useContext, useState }           from 'react';
import { useHistory }                                from 'react-router-dom';
import { useLoadTasksPage }                          from '../../../shared/components/modals/UploadTask/queries';
import { UploadTaskModal }                           from '../../../shared/components/modals/UploadTask/UploadTaskModal';
import { EAppRoutes }                                from '../../../shared/constants/constants';
import { documentTypes }                             from '../../../shared/documentTypes';
import { queryParams, shouldDisplayActionsMenu }     from '../../../shared/functions';
import { useFetchFamilyMembers }                     from '../../../shared/queries/family';
import { generateShortName }                         from '../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { NoActivityScreen, NoActivityScreenWrapper } from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                     from '../../Activity/ActivityRecordPreview';
import { EntitiesListDetailed, IEntityFilters }      from '../shared/EntitiesListDetailed';
import { EntityRecordAvatar }                        from '../shared/EntityRecordAvatar';
import { TaskAddedBy }                               from './components/TaskAddedBy';
import { TaskMenuItems }                             from './components/TaskMenuItems';
import { TaskStatus }                                from './components/TaskStatus';

interface ITasksListPreviewProps {
  familyId?: string;
}

export const TasksListDetailed: FC<ITasksListPreviewProps> = ({ familyId }) => {
  const history = useHistory();
  const currentUser = useContext(AuthorizationContext);
  const { data: familyMembers, isLoading: loadingFamilyMembers } = useFetchFamilyMembers(familyId, { enabled: !!familyId });
  const [ filters, setFilters ] = useState<IEntityFilters>();
  const params = queryParams();
  const familyMemberId = params?.id as string;

  const { data: documents, isFetching } = useLoadTasksPage(
    familyId,
    familyMemberId,
    filters?.status,
    filters?.categoryId,
    filters?.subCategoryId,
    filters?.dateFilter,
    !loadingFamilyMembers,
    100
  );

  const [ addTaskModalOpen, setAddTaskModalOpen ] = useState(false);
  const items = documents?.pages?.[0]?.items;

  const viewTask = (documentId?: string) => {
    history.push(`${ EAppRoutes.TasksSummary }/${ documentId }`);
  };

  return (
    <>
      <EntitiesListDetailed
        displayDateFilter
        displayStatusFilter
        title="Tasks"
        entityName="Task"
        familyId={familyId}
        loading={isFetching}
        onAdd={() => setAddTaskModalOpen(true)}
        onFilterChange={(filters) => setFilters(filters)}
      >
        { items?.length ? items.map((task, index, array) => {
          const shortName = generateShortName(task?.creator?.firstName, task?.creator?.lastName);

          const icon = (
            <EntityRecordAvatar
              badge={documentTypes.tasks.icon}
              member={task?.creator}
            />
          );

          return (
            <ActivityRecordPreview
              key={index}
              showMenu={shouldDisplayActionsMenu(task, currentUser)}
              icon={icon}
              label={<TaskStatus status={task?.status} />}
              description={`${ shortName } created task "${ task.title }"`}
              details={<TaskAddedBy task={task} />}
              isLast={index === array.length - 1}
              familyMembers={familyMembers}
              assigned={task?.assigned}
              menuItems={<TaskMenuItems
                task={task}
                familyId={familyId}
              />}
              onClick={() => viewTask(task?.id)}
            />
          );
        }) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.tasks.icon }
              There are no tasks available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListDetailed>

      { addTaskModalOpen && (
        <UploadTaskModal
          familyId={familyId}
          open={addTaskModalOpen}
          onClose={() => setAddTaskModalOpen(false)}
        />
      ) }
    </>
  );
};
