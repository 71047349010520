import { Grid, Typography }           from '@material-ui/core';
import moment                         from 'moment';
import React, {
  FC,
  useContext,
  useMemo,
  useState,
}                                     from 'react';
import styled                         from 'styled-components';
import { GridDivider }                from '../../../../shared/components/layout/GridDivider';
import { AlertDialog }                from '../../../../shared/components/modals/AlertDialog';
import { MuiButton }                  from '../../../../shared/components/MuiButton';
import { useFetchSelectedFamily }     from '../../../../shared/components/SelectFamily/queries';
import { Loader }                     from '../../../../shared/enhancers/withLoader';
import { isAdminRoles }               from '../../../../shared/functions';
import {
  FormSpacing,
  PageHeaderContainer,
}                                     from '../../../../shared/styledComponents';
import { fullName }                   from '../../../../shared/utils/commonFunctions';
import AuthorizationContext           from '../../../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember }              from '../../../../shared/utils/withAuthorization/withAuthorization';
import { IInvite }                    from '../../../Dashboard/AddMembers/PreRegistration/queries';
import { ContentPaper }              from '../../shared/SettingsPageWrapper';
import { useCancelInvitationRequest } from '../ManageUsers/Account/queries';
import {
  IMemberDetailsData,
  MemberDetails,
}                                     from '../ManageUsers/MentionedEntities/components/MemberDetails';
import {
  useAcceptInvitationRequest,
  useFetchIncomingRequests,
  useFetchPendingRequests,
  useSendInvitationReminder,
}                                     from './queries';

export interface IRequestsPageProps {
  familyMembers?: IFamilyMember[];
}

const RequestsContainer = styled.div`
  padding: 24px 10px;
  background-color: #f7fafd;
  height: calc(100% - 40px);

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 24px 32px;
  }
`;

export const Requests: FC = () => {
  const { data: family } = useFetchSelectedFamily();
  const { data: pendingRequests, isLoading: loadingPendingRequests } = useFetchPendingRequests(family?.id);
  const { data: incomingRequests, isLoading: loadingIncomingRequests } = useFetchIncomingRequests();

  const { mutate: acceptRequest } = useAcceptInvitationRequest();
  const { mutate: declineRequest } = useCancelInvitationRequest(false);
  const { mutate: cancelInvitation } = useCancelInvitationRequest();
  const { mutate: sendReminder } = useSendInvitationReminder();

  const user = useContext(AuthorizationContext);
  const isAdminRole = isAdminRoles(user?.data?.accountType);

  const [ inviteToDecline, setInviteToDecline ] = useState<IInvite | null>(null);
  const [ inviteToCancel, setInviteToCancel ] = useState<IInvite | null>(null);

  const handleCancel = () => {
    cancelInvitation(inviteToCancel?.familyUserId);
    setInviteToCancel(null);
  };

  const handleDecline = () => {
    declineRequest(inviteToDecline?.familyUserId);
    setInviteToDecline(null);
  };

  const memberDetails = (invite: IInvite): IMemberDetailsData => ({
    firstName : invite.firstName,
    lastName  : invite.lastName,
    color     : invite.color,
    avatar    : invite.avatar,
  });

  const Row = ({ invite, last, isPending }: { invite: IInvite; last?: boolean; isPending?: boolean }) => (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={7}
        lg={8}
      >
        <MemberDetails
          width="44px"
          data={memberDetails(invite)}
          subtitle={`${ invite?.family?.name } Family • ${ moment(invite?.createdAt).format('MMM D, YYYY') }`}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        lg={4}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <MuiButton
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => isPending ? setInviteToCancel(invite) : setInviteToDecline(invite)}
            >
              { isPending ? 'Cancel Request' : 'Decline' }
            </MuiButton>
          </Grid>

          <Grid
            item
            xs={6}
          >
            <MuiButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => isPending ? sendReminder(invite?.familyUserId) : acceptRequest(invite?.familyUserId)}
            >
              { isPending ? 'Send Reminder' : 'Connect' }
            </MuiButton>
          </Grid>
        </Grid>
      </Grid>

      { !last && (
        <GridDivider margin={'10px 0 20px'} />
      ) }
    </Grid>
  );

  const noRecords = (isPending?: boolean) => isPending ? 'You did not create any invite requests' : 'You do not have incoming invite requests';

  const Section = ({ title, data, isPending, loading }: { title: string; data?: IInvite[]; isPending?: boolean; loading: boolean }) => (
    <ContentPaper>
      <PageHeaderContainer>
        <Typography variant="h3">{ title }</Typography>
      </PageHeaderContainer>

      <FormSpacing>
        { loading ? <Loader /> : data?.length ? data?.map((invite, index, array) => (
          <Row
            key={index}
            last={index === array.length - 1}
            invite={invite}
            isPending={isPending}
          />
        )) : noRecords(isPending) }
      </FormSpacing>
    </ContentPaper>
  );

  return useMemo(() => (
    <RequestsContainer>
      <Section
        loading={loadingIncomingRequests}
        title="Requests"
        data={incomingRequests}
      />

      { isAdminRole &&
      <Section
        isPending
        title="Pending Users"
        data={pendingRequests}
        loading={loadingPendingRequests}
      />}

      <AlertDialog
        dialogWidth="350px"
        open={!!inviteToDecline}
        title="Are you sure you want to decline this invite request?"
        content={`Invitation from ${ fullName(inviteToDecline?.firstName, inviteToDecline?.lastName) } to join ${ inviteToDecline?.family?.name } family will be canceled.`}
        handleSubmit={() => handleDecline()}
        handleCancel={() => setInviteToDecline(null)}
      />

      <AlertDialog
        dialogWidth="350px"
        open={!!inviteToCancel}
        title="Are you sure you want to cancel this invite request?"
        content={`Invitation for ${ fullName(inviteToCancel?.firstName, inviteToCancel?.lastName) } to join ${ family?.name } family will be canceled.`}
        handleSubmit={() => handleCancel()}
        handleCancel={() => setInviteToCancel(null)}
      />
    </RequestsContainer>
  ), [ pendingRequests, incomingRequests, inviteToCancel, inviteToDecline ]);
};
