import { Geolocation }                                           from '@capacitor/geolocation';
import { Position }                                              from '@capacitor/geolocation/dist/esm/definitions';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled                                                    from 'styled-components';
import { Layout }                                                from '../../Layout/Layout';
import { AlertDialog }                                           from '../../shared/components/modals/AlertDialog';
import { useFetchSelectedFamily }                                from '../../shared/components/SelectFamily/queries';
import { EAccountStatus }                                        from '../../shared/constants/constants';
import { queryParams }                                           from '../../shared/functions';
import { useFetchFamilyMembers }                                 from '../../shared/queries/family';
import { isNative }                                              from '../../shared/utils/platform';
import AuthorizationContext                                      from '../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember }                                         from '../../shared/utils/withAuthorization/withAuthorization';
import { SummaryPageHead }                                       from '../MyFamilyCore/shared/SummaryPageHead';
import { CheckinHistoryTable }                                   from './components/CheckinHistoryTable';
import { CheckInMap }                                            from './components/CheckInMap';
import { CurrentUserCheckin }                                    from './components/CurrentUserCheckin';
import { useFetchCheckins }                                      from './queries';

const LocationWrapper = styled.div`
  width: 100%;

  .MuiDivider-root {
    margin: 0;
  }
`;

const LocateSection = styled.div`
  margin: 32px 0;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 32px 0 0;
  }
`;

export const Locate = (): ReactElement => {
  const params = queryParams();
  const { data: family } = useFetchSelectedFamily();
  const currentUser = useContext(AuthorizationContext);
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });

  const [ gpsDisabledModalOpen, setGpsDisabledModalOpen ] = useState(false);
  const [ position, setPosition ] = useState<Position>();

  useEffect(() => {
    (async () => {
      try {
        const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, maximumAge: 3600  });
        setPosition(position);
      } catch (error) {
        setGpsDisabledModalOpen(true);
      }
    })();
  }, []);

  const filteredMembers = familyMembers?.filter((member: IFamilyMember) =>
    member.locationAccess && (member.status === EAccountStatus.Accepted) && member.hasEmail
  );

  const { data, isLoading, refetch } = useFetchCheckins(
    family?.id || '',
    params.id ? [ params.id as string ] : [],
  );

  const currentUserCheckins = useFetchCheckins(
    family?.id || '',
    currentUser?.data?.id ? [ currentUser?.data?.id ] : undefined,
  );

  const userLastCheckin = currentUserCheckins.data?.items[0];

  const Page = (
    <>
      <LocationWrapper>
        <SummaryPageHead
          title="Locate"
          membersList={filteredMembers}
          hideAllSelection={!filteredMembers?.length}
        />

        <LocateSection>
          <CurrentUserCheckin
            position={position}
            user={currentUser?.data}
            checkin={userLastCheckin}
            familyId={family?.id}
            isLoading={currentUserCheckins.isLoading}
          />
        </LocateSection>

        <LocateSection>
          <CheckInMap
            position={position}
            checkins={data?.lastCheckins}
            lastCheckin={userLastCheckin}
            onReloadCheckins={refetch}
          />
        </LocateSection>

        <LocateSection>
          <CheckinHistoryTable
            checkins={data?.items?.length ? data?.items : data?.lastCheckins}
            isLoading={isLoading}
          />
        </LocateSection>
      </LocationWrapper>

      <AlertDialog
        dialogWidth="350px"
        title="Enable GPS"
        open={gpsDisabledModalOpen}
        content={`Please enable location permission in ${ isNative ? 'device' : 'browser' }  settings in order to use Check In feature.`}
        handleCancel={() => setGpsDisabledModalOpen(false)}
        cancelButtonText="Close"
      />
    </>
  );

  return (
    <Layout>
      { Page }
    </Layout>
  );
};
