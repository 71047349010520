import { Button, Grid, Popover }                                            from '@material-ui/core';
import React, { FC, MutableRefObject, useRef, useState }                    from 'react';
import { useHistory }                                                       from 'react-router';
import styled                                                               from 'styled-components';
import { ButtonAdd }                                                        from '../../../modules/Dashboard/components/FamilyDocuments/ButtonAdd';
import { TextInput }                                                        from '../../inputs/TextInput/TextInput';
import { ICategory, ISubCategory, useCreateCategory, useCreateSubCategory } from '../../queries/categories';
import { useFetchFamilies }                                                 from '../../queries/family';
import { useFetchSelectedFamily }                                           from '../../../shared/components/SelectFamily/queries';
import { IFamily }                                                          from '../../../Auth/shared/interfaces';
import { LimitedModal }                                                     from '../../../shared/components/modals/UploadTask/LimitedModal';

const StyledAdd = styled.div`
  gap: 7px;
  color: #6f90d9;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
`;

const UserInfo = styled.div`
  color: #2c3f69;
  font-size: 22px;
  font-family: Lato, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.6;
`;

const PopoverBg = styled.div`
  width: 245px;
  height: 116px;
  flex-grow: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
  padding: 15px;
  
  button {
    height: 32px;
  }
`;

const ChangeSubscriptionBtn = styled.button`
  width: 150px;
  height: 20px;
  flex-grow: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  margin: auto;
`;

interface IAddDocumentType {
  categoryId?: string;
  familyId?: string;
  isSubCategory?: boolean;
  isActivePayment?: boolean;
  outerRef?: MutableRefObject<any>;

  onSuccess(response: ICategory | ISubCategory): void;
}

export const CreateCategory: FC<IAddDocumentType> = ({
  categoryId,
  familyId,
  isSubCategory,
  isActivePayment,
  outerRef,
  onSuccess,
}) => {
  const menuAnchorRef = useRef(null);
  const history = useHistory();

  const [ categoryName, setCategoryName ] = useState('');
  const [ openFreeInfo, setOpenFreeInfo ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

  const { mutate: createDocumentType, isLoading: creatingDocumentType } = useCreateCategory();
  const { mutate: createDocumentSubCategory, isLoading: creatingDocumentSubCategory } = useCreateSubCategory();
  const { data: families, isLoading: loadingFamilies } = useFetchFamilies();
  const { data: family, isLoading: loadingFamily } = useFetchSelectedFamily();

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === family?.id);


  const handleClick = (event: any) => {
    if (matchingFamily && matchingFamily.isActivePayment === false) {
      event.stopPropagation();
      setOpenFreeInfo(true);
    } else {
      event.stopPropagation();
      setAnchorEl(outerRef?.current || menuAnchorRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSave = () => {
    const options = {
      onSuccess: (data: ICategory | ISubCategory) => {
        handleClose();
        onSuccess(data);
        setCategoryName('');
      },
    };

    if (isSubCategory && familyId) {
      if (categoryId) {
        createDocumentSubCategory({
          name: categoryName,
          familyId,
          categoryId,
        }, options);
      }
    } else if (familyId) {
      createDocumentType({
        name: categoryName,
        familyId,
      }, options);
    }
  };

  const saving = creatingDocumentType || creatingDocumentSubCategory;

  return (
    <>
      <StyledAdd onClick={handleClick}>
        <ButtonAdd
          size="24px"
          aria-describedby={id}
        />
        { isSubCategory ? 'Subcategory' : 'Category' }
      </StyledAdd>

      <div ref={menuAnchorRef} />

      <LimitedModal
        openFreeInfo={openFreeInfo}
        entityName="CreateCategory"
        setOpenFreeInfo={setOpenFreeInfo}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical   : 'bottom',
          horizontal : 'right',
        }}
        transformOrigin={{
          vertical   : 'top',
          horizontal : 'right',
        }}
        onClose={handleClose}
        onClick={(event) => event.stopPropagation()}
      >
        <PopoverBg>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextInput
                placeholder={`New ${ isSubCategory ? 'Subcategory' : 'Category' }`}
                value={categoryName || ''}
                onChange={({ target: { value } }) => setCategoryName(value)}
                onKeyDown={(event) => event.stopPropagation()}
              />
            </Grid>

            <Grid
              item
              xs={6}
            >
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={saving || !categoryName}
                onClick={onSave}
              >
                { saving ? 'Saving' : 'Save' }
              </Button>
            </Grid>
          </Grid>

        </PopoverBg>
      </Popover>
    </>
  );
};
