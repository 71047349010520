import * as Yup  from 'yup';
import { rules } from '../../../Auth/shared/rules';

export const repeatOptionsValidationSchema = Yup.object().shape({
  recurs          : rules.string,
  recursInterval  : rules.number,
  recursDay       : rules.number,
  recursOrdinal   : rules.string,
  recursWeekDays  : Yup.array().of(Yup.string()).nullable(),
  recursCount     : rules.number,
  recursStartDate : rules.string,
  recursEndDate   : rules.string,
});
