import { Button, Grid }                                            from '@material-ui/core';
import { FastField, Form, Formik, FormikProps }                    from 'formik';
import React, { FC, useEffect, useRef, useState }                  from 'react';
import { NavLink, Prompt }                                         from 'react-router-dom';
import styled                                                      from 'styled-components';
import { ReactComponent as ChevronRight }                          from '../../../../../../images/icons/icons-white-chevron-right.svg';
import { ColorPicker }                                             from '../../../../../shared/components/ColorPicker/ColorPicker';
import { GridDivider }                                             from '../../../../../shared/components/layout/GridDivider';
import { EAppRoutes, EGender, colors }                             from '../../../../../shared/constants/constants';
import { SelectDateField }                                         from '../../../../../shared/formFields/SelectDateField';
import { SelectField }                                             from '../../../../../shared/formFields/SelectField';
import { TextInputField }                                          from '../../../../../shared/formFields/TextInputField';
import { useFetchCurrentUserProfile, useUpdateCurrentUserProfile } from '../../../../../shared/queries/user';
import { FormSpacing, TextBrand }                                  from '../../../../../shared/styledComponents';
import { FormFooterActions }                                       from '../../../shared/FormFooterActions';
import {
  IInitialValues,
  IMyProfileForm,
  IUserProfile,
  myProfileValidationSchema }                                         from './validation';

const WideLink = styled(NavLink)`&& {
  width: 100%;
  color: #2c3f69;
  font-weight: 700;
  padding: 5px 0;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  justify-content: space-between;
}`;

export const FormButton = styled(Button)`&& {
  border-radius: 9px;
  background-color: #547cd3;
  float: right;
}`;

interface IMyProfileFormProps {
  avatarId?: string;
  currentUser?: IUserProfile;
  selectedFamilyId: string | undefined;
  onColorChange(color: string): void;
}

const SettingOptionTitle = styled(TextBrand)`
  font-weight: 500;
  font-family: 'Jost';
`;

const getInitialValues = (currentUser: IUserProfile) => ({
  ...currentUser,
  fullName : currentUser?.firstName && currentUser?.lastName ? `${ currentUser?.firstName } ${ currentUser?.lastName }` : '',
  color    : currentUser?.color ?? '#547cd3',
});

export const MyProfileForm: FC<IMyProfileFormProps> = ({
  avatarId,
  selectedFamilyId,
  onColorChange,
}) => {
  const formikRef = useRef<FormikProps<IInitialValues>>(null);
  const { data: userProfile, isLoading: loadingProfile, refetch } = useFetchCurrentUserProfile(selectedFamilyId);
  const { mutate: updateUserProfile, isLoading: updatingUserProfile } = useUpdateCurrentUserProfile();
  const [ actualFormValues, setActualFormValues ] = useState<IInitialValues>({});

  useEffect(()=> {
    if (userProfile) {
      const initialValues = getInitialValues(userProfile);
      setActualFormValues(initialValues);
    }
  }, [userProfile]);

  useEffect(() => {
    const formAvatarId = formikRef?.current?.values?.avatarId;

    if (avatarId !== formAvatarId) {
      formikRef.current?.setFieldValue('avatarId', avatarId);
      formikRef.current?.setFieldTouched('avatarId', true);
    }
  }, [ avatarId ]);

  const updateProfile = async () => {
    const formValues = formikRef.current?.values;
    const [ firstName, lastName ] = formValues?.fullName?.split(/\s+/) || [];

    await updateUserProfile({
      avatarId    : userProfile?.avatar?.id,
      color       : formValues?.color,
      firstName,
      lastName,
      gender      : formValues?.gender,
      dateOfBirth : formValues?.dateOfBirth,
      country     : userProfile?.country || 'US',
    } as IMyProfileForm, {
      onSuccess: () => {
        if (formValues) {
          setActualFormValues(formValues);
        }
      },
    });
  };

  const isFormTouched = (touched: any): boolean => !!(touched?.fullName || touched?.color || touched?.dateOfBirth || touched?.gender);

  const FormContent = ({
    values,
    dirty,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  }: FormikProps<IInitialValues>) => (
    <Form onSubmit={handleSubmit}>
      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FastField
              label="Full Name"
              name={'fullName'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FastField
              label="Date of Birth"
              name={'dateOfBirth'}
              maxDate={new Date()}
              component={SelectDateField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            md={4}
          >
            <FastField
              label="Gender"
              name={'gender'}
              options={[ EGender.Male, EGender.Female ]}
              component={SelectField}
            />
          </Grid>

          <GridDivider margin="10px 0" />

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
          >
            <span>Color Profile</span>

            <ColorPicker
              margin="15px 0 0"
              selectedColor={values?.color}
              setSelectedColor={(color: string) => {
                setFieldValue('color', color);
                onColorChange(color);
                setFieldTouched('color', true);
              }}
            />
          </Grid>

          <GridDivider margin="10px 0 0" />

          <Grid
            item
            xs={12}
          >
            <WideLink to={`${ EAppRoutes.Settings }/my-profile/about-me`}>
              <SettingOptionTitle>About Me</SettingOptionTitle>
              <ChevronRight />
            </WideLink>
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        dirty={dirty}
        isLoading={updatingUserProfile}
        formikRef={formikRef}
      />
      <Prompt
        when={isFormTouched(touched)}
        message={`Are you sure you want to leave this page?
Unsaved changes in the Customer Info would be lost.`}
      />
    </Form>
  );

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={actualFormValues}
      validationSchema={myProfileValidationSchema}
      onSubmit={updateProfile}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
