import { Tab, Tabs, withStyles }                                                                    from '@material-ui/core';
import React, { ChangeEvent, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  useHistory,
  useLocation,
}                                                                                                   from 'react-router-dom';
import styled
  from 'styled-components';
import AuthorizationContext
  from '../utils/withAuthorization/authorizationContext';
import { TFamilyAccountType }                                                                       from '../utils/withAuthorization/withAuthorization';

// HiddenTab is used to prevent console error when
// current route doesn't match any of the tabs values
const HiddenTab = styled(Tab)`&& {
  display: none;
}`;

export interface IRouterDrivenTab {
  link: string;
  label: ReactNode;
  icon?: JSX.Element;
  color?: string;
  component: JSX.Element;
  onClose?: (link: string) => void;
  roles?: TFamilyAccountType[];
}

interface IRouterDrivenTabsProps {
  tabs: IRouterDrivenTab[];
  baseURL: string;
  centered?: boolean;
}

const StyledTabs = withStyles(theme => ({
  indicator: {
    height          : 3,
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'center',
    backgroundColor : 'transparent',
    padding         : '0 15px',

    '& > div': {
      width           : '100%',
      height          : '3px',
      backgroundColor : '#fac05e',
    },
  },
}))((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    fontFamily : 'Jost',
    opacity    : '1',
    fontWeight : 600,

    '&:hover': {
      color: '#fff',
    },
    '&$selected': {
      color: '#fff',
    },
    '&:focus': {
      color: '#fff',
    },
  },
  wrapper: {
    fontSize      : 22,
    lineHeight    : '22px',
    letterSpacing : '1px',
    fontStretch   : 'normal',

    '@media (max-width: 768px)': {
      fontSize: 18,
    },
    '@media (max-width: 1024px)': {
      fontSize: 20,
    },
  },
  selected: {
    fontWeight: 'bold',
  },
}))((props: any) => <Tab disableRipple {...props} />);

export const RouterDrivenTabs: FC<IRouterDrivenTabsProps> = ({
  tabs,
  baseURL,
  centered = false,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const page = pathname.replace(`${ baseURL }/`, '');
  const user = useContext(AuthorizationContext);

  const [ filteredTabs, setFilteredTabs ] = useState<IRouterDrivenTab[]>([]);

  const currentTab = useMemo(
    () => [ ...tabs ].reverse().find(tab => page.indexOf(tab.link) >= 0),
    [ page, tabs ]
  );

  const handleChangeTab = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (_event: ChangeEvent<{}>, tabLink: string) => {
      push(`${ baseURL }/${ tabLink }`);
    },
    [ baseURL, push, pathname ]
  );

  const a11yProps = useCallback(
    (tabLink: string) => ({
      id              : `tab-${ tabLink }`,
      'aria-controls' : `tabpanel-${ tabLink }`,
    }),
    []
  );

  const tab = currentTab?.component;

  useEffect(() => {
    if (filteredTabs) {
      setFilteredTabs(tabs.filter(({ roles }) => !roles || !!roles?.find(role => role === user?.data?.accountType)));
    }
  }, [ tabs, user?.data?.accountType ]);

  useEffect(() => {
    if (filteredTabs && filteredTabs.length) {
      const map: { [key: string]: IRouterDrivenTab } = {};
      let isTabNameExists = false;
      let tabWithSameName: any = currentTab;

      filteredTabs.forEach(tab => {
        map[tab.link as string] = tab;

        if (!isTabNameExists && tab.label === currentTab?.label) {
          isTabNameExists = true;
          tabWithSameName = tab;
        }
      });

      const isTabExists = !!map[currentTab?.link as string];

      if (!isTabNameExists) {
        push(`${ baseURL }/${ filteredTabs[0]?.link }`);
      } else if (isTabNameExists && !isTabExists) {
        if (tabWithSameName?.link.indexOf('undefined') < 0) {
          push(`${ baseURL }/${ tabWithSameName?.link }`);
        }
      }
    }
  }, [ filteredTabs ]);

  return (
    <>
      <StyledTabs
        value={currentTab ? currentTab.link : ''}
        variant="scrollable"
        centered={centered}
        scrollButtons="on"
        TabIndicatorProps={{
          style: {
            height: '3px',
          },
        }}
        onChange={handleChangeTab}
      >
        <HiddenTab value="" />

        { filteredTabs.map(
          (tab, index) =>
            (<StyledTab
              {...a11yProps(tab.link)}
              key={`${ tab.link }-${ index }`}
              icon={tab.icon}
              value={tab.link}
              label={tab.label}
            />)
        ) }
      </StyledTabs>

      { tab }
    </>
  );
};
