import fileDownload                                      from "js-file-download";
import qs                                                from 'qs';
import { UseQueryResult }                                from 'react-query';
import moment                                            from 'moment';
import { ITask }                                         from '../modules/MyFamilyCore/tasks/queries';
import { IEvent }                                        from '../modules/Schedule/queries';
import { IUserProfile }                                  from '../modules/Settings/components/Profile/MyProfileForm/validation';
import { IDocument }                                     from './components/modals/UploadDocument/queries';
import { IAttachment }                                   from './components/modals/UploadDocument/validation';
import { EDocumentAccess }                               from './constants/constants';
import { addSpaceBeforeCapitals }                        from './utils/commonFunctions';
import { isNative }                                      from './utils/platform';
import { EFamilyAccountType, IFamilyMember, adminRoles } from './utils/withAuthorization/withAuthorization';

export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number,
) => {
  let timeout: any;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const randomNumber = (floor: number, ceil: number) =>
  Math.ceil(Math.random() * (ceil - floor) + floor);

export const shuffle = (array: any[]) => array.sort(() => Math.random() - 0.5);

export const camelize = (str: string) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, '');

export const filterMembersById = (members?: IFamilyMember[], ids?: string[]) => ids && members?.filter(member => ids?.indexOf(member?.id) >= 0);

export const orderMembers = (data?: IFamilyMember[]): IFamilyMember[] => {
  const superAdmins = data?.filter(member => member?.accountType === EFamilyAccountType.SuperAdmin);
  const admins = data?.filter(member => member?.accountType === EFamilyAccountType.Admin);
  const members = data?.filter(member => member?.accountType === EFamilyAccountType.Member);

  const allMembers: IFamilyMember[] = [ ...sortMembers(superAdmins), ...sortMembers(admins), ...sortMembers(members) ];
  return allMembers;
};

export const sortMembers = (members?: IFamilyMember[]): IFamilyMember[] => members ? members.sort((a, b) => `${ a.firstName } ${ a.lastName }` < `${ b.firstName } ${ b.lastName }` ? -1 : 1) as IFamilyMember[]
  : [];

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonth = (date: Date, months: number) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

export const memberType = (type?: string) => type && type === EFamilyAccountType.SuperAdmin ? addSpaceBeforeCapitals(type) : type;

export const isAdminRoles = (accountType?: string): boolean => !!(accountType && adminRoles.find(roleName => roleName === accountType));

export const isSuperAdmin = (accountType?: string): boolean => !!(accountType && EFamilyAccountType.SuperAdmin === accountType);

export const isMember = (accountType?: string): boolean => !!(accountType && EFamilyAccountType.Member === accountType);

export const removeTimeZoneOffset = (date?: Date) => {
  if (date) {
    const tzOffset = (date).getTimezoneOffset() * 60000;
    return (new Date(date.getTime() - tzOffset)).toISOString().slice(0, -1);
  }

  return '';
};

export const shouldDisplayActionsMenu = (entity?: IDocument | ITask | IEvent, currentUser?: UseQueryResult<IUserProfile | IFamilyMember>) => {
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);
  const isCreator = currentUser?.data?.id === entity?.creator?.id;
  const isAssignedToCurrentUser = !!entity?.assigned?.find(id => id === currentUser?.data?.id);
  const canEdit = isAssignedToCurrentUser && entity?.assignedPermission === EDocumentAccess.ViewAndEdit;
  return isAdminRole || isCreator || canEdit;
};

export const firstArrayItems = (qty: number, source?: IFamilyMember[]) => source && source.length >= qty ? source.slice(0, qty) : source;

export const pathName = () => window.location.hash.replace('#', '');

export const queryParamsString = () => window.location.href.split('?')?.[1] || '';

export const queryParams = (): any => qs.parse(queryParamsString());

export const withQueryParams = (route: string, extraParams: any = {}) => {
  const params = qs.stringify({
    ...queryParams(),
    ...extraParams,
  });

  return `${ route }?${ params }`;
};

export const attachmentToFileShape = (attachments: IAttachment[]) => attachments.map(attachment => ({
  id   : attachment.id,
  name : attachment.path.split('files/')[1],
  size : attachment.documentSize,
  type : attachment.path.split('.').reverse()?.[0],
}));

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export const processFileDownload = async (fileName: string, fileBlob: Blob) => {
  if (fileName && fileBlob) {
    const extension = fileBlob.type.split('/')[1];
    const nameWithExtension = `${ fileName }.${ extension }`;
    const options = { mimeType: fileBlob.type, name: nameWithExtension };

    if (isNative) {
      const base64 = await blobToBase64(fileBlob);

      (window as any).PreviewAnyFile.previewBase64(
        (status: string) => console.log("open status", status),
        (error: string) => console.error("open failed", error),
        base64, options
      );
    } else {
      fileDownload(fileBlob, nameWithExtension);
    }
  }
};

export const getCorrectUserTime = (dateUser: string, offset: number) => {
  if (dateUser && (!offset !== undefined && offset !== null)) {
    const eventDate = moment.utc(dateUser).utcOffset(offset).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DDTHH:mm:ss');

    return eventDate;
  };

  return dateUser;
};
