import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation }                      from 'react-query';
import { IRequestResponse }                 from '../../../Auth/shared/interfaces';
import { ITokens }                          from '../../interfaces/auth';
import { setAuthorizationToken }            from '../../utils/setAuthorizationToken';

export const getToken = (): ITokens | null => {
  const tokenString = localStorage.authToken;

  if (!tokenString) {
    return null;
  }

  return JSON.parse(tokenString);
};

const AUTHENTICATION_TOKEN_REFRESH = 'AUTHENTICATION_TOKEN_REFRESH';

export const updateAuthToken = () => {
  const token = getToken();

  return axios.put('/api/v1/sessions/refresh-token', { refreshToken: token?.refreshToken })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<ITokens>>) => setAuthorizationToken(data))
    .catch((error: AxiosError<ITokens>) => {
      throw error;
    });
};

export const useUpdateAuthToken = () => useMutation(
  AUTHENTICATION_TOKEN_REFRESH,
  () => updateAuthToken(),
  {
    retry: false,
  }
);
