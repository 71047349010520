import { CircularProgress, Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { SlideProps }                                                  from '@material-ui/core/Slide/Slide';
import { useFormikContext }                                            from 'formik';
import React, { FC }                                                   from 'react';
import styled                                                          from 'styled-components';

interface ICheckoutProcessingProps {
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref: any) {
  return (
    <Slide
      ref={ref}
      direction="up"
      {...props}
    />
  );
});

const DialogWrapper = styled.div`
  padding: 20px;
  font-family: Lato;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
`;

const DialogFooter = styled.div`
  margin: 20px 0 30px;
`;

export const CheckoutLoader: FC<ICheckoutProcessingProps> = ({ open, onClose }) => {
  const { setSubmitting } = useFormikContext();

  const handleClose = () => {
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog
      keepMounted
      open={open}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}
    >
      <DialogWrapper>
        <DialogTitle id="alert-dialog-slide-title">
          Crossing our t’s
          and dotting our i’s…
        </DialogTitle>

        <DialogContent>
          <CircularProgress size={26} />
        </DialogContent>

        <DialogFooter>
          You’re almost there!
        </DialogFooter>
      </DialogWrapper>
    </Dialog>
  );
};
