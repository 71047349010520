import { IconButton }          from '@material-ui/core';
import AddIcon                 from '@material-ui/icons/Add';
import React, { FC }           from 'react';
import { FamilyMemberWrapper } from '../FamilyMembers/FamilyMemberCircle';

export interface IButtonAdd {
  size?: string;
  onClick?(): void;
}

export const ButtonAdd: FC<IButtonAdd> = ({ onClick, size }) => (
  <FamilyMemberWrapper
    width={size}
    color="#6f90d9"
    onClick={onClick}
  >
    <IconButton
      color="inherit"
    >
      <AddIcon />
    </IconButton>
  </FamilyMemberWrapper>
);
