import React, { FC }   from 'react';
import styled, { css } from 'styled-components';
import { colors }      from '../../constants/constants';

const ColorsSelector = styled.div<{ margin?: string }>`
  margin: ${ ({ margin }) => margin };
  overflow: hidden;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`;

const ColorCircle = styled.div<{ size?: string; active: boolean; color?: string }>`
  width: ${ ({ size }) => size || '28px' };
  height: ${ ({ size }) => size || '28px' };
  border-radius: 100%;
  background-color: ${ ({ color }) => color };
  border: solid 2px transparent;
  cursor: pointer;
  
  ${ ({ active }) => active && css`
    border: solid 2px #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.21);
  ` };
`;

interface IColorPickerProps {
  size?: string;
  margin?: string;
  selectedColor?: string;

  setSelectedColor(color: string): void;
}

export const ColorPicker: FC<IColorPickerProps> = ({
  size,
  margin,
  selectedColor,
  setSelectedColor,
}) => (
  <ColorsSelector margin={margin}>
    { colors.map((color, index) => (
      <ColorCircle
        key={index}
        size={size}
        color={color}
        active={color === selectedColor}
        onClick={() => setSelectedColor(color)}
      />
    )) }
  </ColorsSelector>
);
