import { FC }          from 'react';
import styled, { css } from 'styled-components';
import { IconButton }  from '@material-ui/core';
import SendIcon        from '@material-ui/icons/Send';
import { TextInput }   from '../../../shared/inputs/TextInput/TextInput';

export const ChatFooter = styled.div<{ fixed?: number }>`
  display: flex;
  gap: 10px;
  align-items: center;

  ${ ({ fixed }) => fixed ? css`
    padding: 0;
  ` : css`
    padding: 28px 25px 28px 23px;
    box-shadow: 4px 0 5px 2px rgba(0, 0, 0, 0.1);
  ` };

  .MuiInputBase-input {
    height: 36px;
    padding: 10px 15px 12px;
    font-family: Lato;
    font-size: 15px;
  }
`;

export const ChatFooterForm = styled(ChatFooter)``;

const ButtonSend = styled(IconButton)`
  width: 28px;
  height: 28px;

  &.MuiButtonBase-root {
    background-color: #6f90d9;
  }

  &[disabled] {
    opacity: .5;
  }
`;

export const IconSend = styled(SendIcon)`
  width: 12px;
  fill: #fff;
`;

interface ISendMessageFormProps {
  isValid?: boolean;
  isSubmitting?: boolean;
  fixed?: boolean;
  inner?: boolean;
  message: string;
  sendingMessage: boolean;

  setMessage(msg: string): void;
  onSendMessage(evt: any): void;
}

export const SendMessageForm: FC<ISendMessageFormProps> = ({
  isValid,
  isSubmitting,
  fixed,
  inner,
  message,
  sendingMessage,
  setMessage,
}) => {
  const Wrapper = inner ? ChatFooter : ChatFooterForm;

  return (
    <Wrapper fixed={fixed ? 1 : 0}>
      <TextInput
        fullWidth
        placeholder="Enter Message"
        value={message || ''}
        inputProps={{ maxLength: 1024 }}
        onChange={({ target: { value } }) => setMessage(value)}
        onKeyDown={(event) => event.stopPropagation()}
      />

      <ButtonSend
        type="submit"
        size="medium"
        disabled={!isValid || !message || isSubmitting || sendingMessage}
      >
        <IconSend />
      </ButtonSend>
    </Wrapper>
  );
};
