import { Typography } from '@material-ui/core';
import styled         from "styled-components";

export const SettingsHeader = styled(Typography)`
  font-size: 18px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    font-size: 20px;
  }
`;

export const SettingsParagraph = styled.span`
  line-height: 1.57;
`;
