import { Grid }                           from '@material-ui/core';
import React, { FC, useContext, useMemo } from 'react';
import { Layout }                         from '../../Layout/Layout';
import { useFetchSelectedFamily }         from '../../shared/components/SelectFamily/queries';
import { isAdminRoles, queryParams }      from '../../shared/functions';
import { useFetchFamilyMembers }          from '../../shared/queries/family';
import AuthorizationContext               from '../../shared/utils/withAuthorization/authorizationContext';
import { AboutMemberPreview }             from './about/AboutMemberPreview';
import { ActivityListPreview }            from './activity/ActivityListPreview';
import { ContactsListPreview }            from './contacts/ContactsListPreview';
import { DocumentsListPreview }           from './documents/DocumentsListPreview';
import { EventsListPreview }              from './events/EventsListPreview';
import { SummaryPageHead }                from './shared/SummaryPageHead';
import { TasksListPreview }               from './tasks/TasksListPreview';

const FCGrid: FC = ({ children }) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    { children }
  </Grid>
);

export const MyFamilyCore: FC = () => {
  const { data: family, isLoading: loadingFamily } = useFetchSelectedFamily();
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });

  const params = queryParams();
  const familyMemberId = params?.id as string;
  const currentUser = familyMembers?.find(user => user.id === params.id);
  const user = useContext(AuthorizationContext);
  const isAdminRole = isAdminRoles(user?.data?.accountType);

  const Page = (
    <>
      <SummaryPageHead title="My Family Core" />

      <Grid
        container
        spacing={3}
      >
        { isAdminRole && familyMemberId && currentUser?.viewAboutMe && (
          <FCGrid>
            <AboutMemberPreview
              familyId={family?.id}
              familyMemberId={familyMemberId}
            />
          </FCGrid>
        ) }

        <FCGrid>
          <ActivityListPreview
            familyId={family?.id}
            familyMemberId={familyMemberId}
          />
        </FCGrid>

        <FCGrid>
          <EventsListPreview
            familyId={family?.id}
            familyMemberId={familyMemberId}
          />
        </FCGrid>

        <FCGrid>
          <DocumentsListPreview
            familyId={family?.id}
            familyMemberId={familyMemberId}
          />
        </FCGrid>

        <FCGrid>
          <TasksListPreview
            familyId={family?.id}
            familyMemberId={familyMemberId}
          />
        </FCGrid>

        <FCGrid>
          <ContactsListPreview
            familyId={family?.id}
            familyMemberId={familyMemberId}
          />
        </FCGrid>
      </Grid>
    </>
  );

  return useMemo(() =>
    (<Layout>
      { Page }
    </Layout>), [ window.location.search, loadingFamily, currentUser, family ]
  );
};
