import axios, { AxiosResponse }                   from 'axios';
import { useMutation }                            from 'react-query';
import { IRequestResponse, IRequestResponseData } from '../../Auth/shared/interfaces';
import { useCreateQuery }                         from '../utils/hooks/useReactQuery';


const MONTHLY_PAYMENT_PRICE = 'MONTHLY_PAYMENT_PRICE';
const SUBSCRIPTION_INFO = 'SUBSCRIPTION_INFO';

export interface ICardDisplayDetails {
  last4?: string;
  expMonth?: number;
  expYear?: number;
  brand?: string;
}

export interface PaymentData {
  cardToken: string | undefined;
  appStoreReceipt: string | undefined;
  googlePurchaseToken: string | undefined;
}

export const useFetchMonthlyPrice = (options?: any) =>
  useCreateQuery<IRequestResponse<number>>({
    queryKey : [MONTHLY_PAYMENT_PRICE],
    apiUrl   : '/api/v1/payments/monthly-price',
    options  : {
      refetchOnWindowFocus: false,
      ...options,
    },
  });

export const updateCurrentPaymentMethod = (cardToken: string) =>
  axios.put('/api/v1/payments/payment-method', { cardToken })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IRequestResponseData>>) => data);

export const createFamilyPaymentMethod = async (paymentData: PaymentData) => {
  const response = await axios.post('/api/v1/payments', paymentData);
  return response.data;
};
export const useUpdateCurrentPaymentMethod = () => useMutation(
  'updateCurrentPaymentMethod',
  (paymentMethodId: string) => updateCurrentPaymentMethod(paymentMethodId)
);

export const useFetchSubscriptionInfo = (options?: any) => useCreateQuery<{ nextPaymentDate: string }>({
  queryKey : [SUBSCRIPTION_INFO],
  apiUrl   : 'api/v1/payments/subscription-info',
  options  : {
    refetchOnWindowFocus: false,
    ...options,
  },
});
