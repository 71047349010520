import { Button, DialogContent, Grid, IconButton } from '@material-ui/core';
import React, { FC }                               from 'react';
import { NavLink }                                 from 'react-router-dom';
import styled                                      from 'styled-components';
import { ReactComponent as IconCheckMark }         from '../../../../../images/icons/selected-check.svg';
import { ReactComponent as IconClose }             from '../../../../../images/icons/times.svg';
import { theme }                                   from '../../../../../styles';
import { Modal }                                   from '../../../../shared/components/modals/Modal';
import { EAppRoutes }                              from '../../../../shared/constants/constants';
import { StyledDivider, TextBrand }                from '../../../../shared/styledComponents';
import { IInvite }                                 from './queries';

const StyledDialogTitle = styled.div`
  padding: 20px 10px 20px 24px;
  font-size: 18px;
  line-height: 32px;
  align-text: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Jost;
  font-weight: 500;

  div {
    display: flex;
    align-items: center;
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    padding: 28px 20px 32px;
    font-size: 24px;
  }
`;

export const BrandCapitalized = styled.span`
  color:  ${ theme.colors.warning };
  text-transform: capitalize;
`;

export const StyledDialogContent = styled(DialogContent)`&& {
  margin: auto;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  padding: 20px 24px;
  font-family: Jost;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    padding: 36px 32px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.27px;
    color: #272932;
    margin-bottom: 32px;
  }

  ul {
    list-style: disc;
    font-size: 16px;
    padding-left: 15px;
    line-height: 32px;
  }
}`;

const StyledModal = styled(Modal)`&& {
  .MuiPaper-root {
    margin: 10px;
    width: 695px;
    max-width: 100%;
  }

  .MuiButton-label {
    letter-spacing: normal;
    font-family: Jost;
  }
}`;

export const StyledDialogActions = styled.div`&& {
  width: 100%;
  padding: 32px 16px 40px;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    padding: 32px;
  }
}`;

const PageTitle = styled.div`&& {
  line-height: 1.3em;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.64px;
  color: #272932;
  margin-bottom: 40px;
  font-family: Jost;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    font-size: 32px;
    text-align: center;
  }
}`;

const IconCheck = styled(IconCheckMark)`
  margin-right: 20px;
`;

const StepLabel = styled(TextBrand)`
  font-size: 20px;
  font-weight: 500;
`;

const StepDescription = styled.span`
  letter-spacing: 0.23px;
  font-family: Lato;
`;

interface IPreRegistrationConfirmModalProps {
  byInvite?: boolean;
  member: IInvite | null;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export const PreRegistrationConfirmModal: FC<IPreRegistrationConfirmModalProps> = ({
  byInvite,
  member,
  handleSubmit,
  handleCancel,
}) => {
  const profileLink = `${ EAppRoutes.ManageUsersAccount }?id=${ member?.familyUserId || member?.id }`;

  return (
    <StyledModal
      hideCloseIcon
      open={!!member}
      maxWidth="md"
      onClose={() => handleCancel()}
    >
      <StyledDialogTitle>
        <div>
          <IconCheck />

          <span>
            { byInvite ? 'Invite Sent to ' : 'Account Created for ' }
            <BrandCapitalized>{ member?.firstName } { member?.lastName }</BrandCapitalized>!
          </span>
        </div>

        <IconButton
          color="inherit"
          onClick={handleCancel}
        >
          <IconClose />
        </IconButton>
      </StyledDialogTitle>

      <StyledDivider
        mt={0}
        mb={0}
      />

      <StyledDialogContent>
        <PageTitle>
          What Happens Next?
        </PageTitle>

        <Grid
          container
          spacing={3}
        >
          { byInvite ? (
            <>
              <Grid
                item
                xs={12}
                md={4}
              >
                <StepLabel mb={5}>Step 1</StepLabel>

                <StepDescription>
                  The user receives the invite email.
                </StepDescription>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                <StepLabel mb={5}>Step 2</StepLabel>

                <StepDescription>
                  Your invited user signs up with Family Core under your family core account or their own.
                </StepDescription>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                <StepLabel mb={5}>Step 3</StepLabel>

                <StepDescription>
                  That family/friend now is connected to you and your family.
                </StepDescription>
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
            >
              <p>
                Congratulations on creating a new account. Here are some next steps to best optimize your Family Core
                experience.
              </p>

              <ul>
                <li><NavLink to={profileLink}>Customize this users profile</NavLink></li>
                <li>Begin <NavLink to={'#'}>adding</NavLink> important documents, deadline and tasks</li>
              </ul>
            </Grid>
          ) }
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <NavLink to={profileLink}>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="outlined"
              >
                View Profile
              </Button>
            </NavLink>
          </Grid>

          <Grid
            item
            xs={6}
          >
            <Button
              fullWidth
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Got It!
            </Button>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </StyledModal>
  );
};
