import { FieldProps, getIn }                                     from 'formik';
import React, { FC, FocusEvent, FocusEventHandler, useCallback } from 'react';
import { TextInput }                                             from '../inputs/TextInput/TextInput';

interface ITextInputFieldProps extends FieldProps {
  id?: string;
  required?: boolean;
}

export const TextInputField: FC<ITextInputFieldProps> = ({
  form,
  field,
  required,
  ...props
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const trimmedValue = value.trim();

      form.setFieldValue(field.name, trimmedValue);

      field.onBlur({
        ...event,
        target: {
          name  : field.name,
          value : trimmedValue,
        },
      });
    },
    [ field, form ]
  );

  return (
    <TextInput
      {...field}
      {...props}
      id={props.id || field.name}
      required={!!required}
      error={touched && !!error}
      helperText={(touched && error) || ''}
      onBlur={handleBlur}
    />
  );
};
