import { Geolocation }                                                            from '@capacitor/geolocation';
import { Button, Icon, TextField }                                                from '@material-ui/core';
import { Clear }                                                                  from '@material-ui/icons';
import LocationOnIcon                                                             from '@material-ui/icons/LocationOn';
import React, { FC, useEffect, useState }                                         from 'react';
import styled                                                                     from 'styled-components';
import { ReactComponent as IconPinUser }                                          from '../../../../images/icons/icon-pin-user.svg';
import { Modal }                                                                  from '../../../shared/components/modals/Modal';
import { UploadDialogTitle }                                                      from '../../../shared/components/modals/styles';
import { Loader }                                                                 from '../../../shared/enhancers/withLoader';
import { CloseModalIcon }                                                         from '../../../shared/styledComponents';
import { NoActivityScreen }                                                       from '../../Activity/ActivityItems';
import { ICheckinAddress, PlaceResult, convertCoordsToAddress, useCreateCheckIn } from '../queries';

interface ICheckInModalProps {
  coords: any;
  familyId: string;
  onClose: () => void;
}

const StyledAddressInput = styled(TextField)`
  display        : flex;
  justify-content: center;
  padding        : 20px;

  .MuiFilledInput-root {
    background-color: #e7efff;
    border-radius   : 4px;
  }

  .MuiInputBase-root::before {
    border: none;
  }

  .MuiInputBase-input {
    padding: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CheckinButton = styled.div`
  padding: 20px;
`;

const EmptyLocation = styled(NoActivityScreen)`
  padding: 20px;
  line-height: 1.3;
  text-align: center;
`;

const LocationLoader = styled.div`
  margin: auto;
`;

export const CheckInModal: FC<ICheckInModalProps> = ({
  familyId,
  coords,
  onClose,
}) => {
  const [ location, setLocation ] = useState<ICheckinAddress>({ address: '' });
  const [ loading, setLoading ] = useState(true);
  const { mutate: createCheckIn, isLoading } = useCreateCheckIn();
  const service = new google.maps.places.PlacesService(document.createElement('div'));
  const [ isPermissionGranted, setIsPermissionGranted ] = useState(false);

  const onSubmit = () =>
    createCheckIn({
      ...location,
      familyId,
    }, {
      onSuccess: () => onClose(),
    });

  const initialize = async () => {
    try {
      const status = await Geolocation.checkPermissions();
      const granted = status.location === 'granted';
      setIsPermissionGranted(granted);

      const currentAddress = await convertCoordsToAddress(coords?.latitude.toString(), coords?.longitude.toString());
      const placeId = currentAddress.place_id;

      service.getDetails({
        placeId : placeId || '',
        fields  : [ 'name' ],
      }, (response: PlaceResult) => {
        setLoading(false);
        setLocation({
          name      : response?.name || '',
          latitude  : coords?.latitude.toString(),
          longitude : coords?.longitude.toString(),
          address   : currentAddress.formatted_address,
        });
      });
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const emptyLocation = (
    <EmptyLocation>
      <IconPinUser />
      You need to provide access to your device’s location information in order to use &apos;Check Ins&apos;.
    </EmptyLocation>
  );

  return (
    <Modal
      open
      hideCloseIcon
      dialogWidth="695px"
      dialogMinHeight="240px"
      onClose={onClose}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        Check In
      </UploadDialogTitle>
      { isPermissionGranted ? (
        loading ? (
          <LocationLoader>
            <Loader />
          </LocationLoader>
        ) : (
          <>
            <StyledAddressInput
              disabled
              variant="filled"
              value={location.address}
              InputProps={{
                startAdornment: (
                  <Icon color="secondary">
                    <LocationOnIcon />
                  </Icon>
                ),
              }}
            />

            { isLoading && <Loader /> }

            <CheckinButton>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!location.name || isLoading || loading}
                onClick={onSubmit}
              >
                Complete Check In
              </Button>
            </CheckinButton>
          </>
        )
      ) : emptyLocation }
    </Modal>
  );
};
