import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from '../../../Layout/Layout';
import { GridDivider } from '../../../shared/components/layout/GridDivider';
import { ProvidePinModal } from '../../../shared/components/modals/ProvidePinModal';
import {
  useDeleteDocument,
  useFetchDocumentDetails, useMarkDocumentAsViewed,
} from '../../../shared/components/modals/UploadDocument/queries';
import { NavigateBack } from '../../../shared/components/NavigateBack';
import { useFetchSelectedFamily } from '../../../shared/components/SelectFamily/queries';
import { EAppRoutes } from '../../../shared/constants/constants';
import { filterMembersById, shouldDisplayActionsMenu } from '../../../shared/functions';
import { useFetchFamilyMembers, useFetchFamilyUser } from '../../../shared/queries/family';
import { FlexEndGrid, FlexVerticalCenterGrid, TextBrandColor } from '../../../shared/styledComponents';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember } from '../../../shared/utils/withAuthorization/withAuthorization';
import { ActivityRecordPreview, RecordMessage } from '../../Activity/ActivityRecordPreview';
import { FamilyMembersList } from '../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { CategoryPills } from '../shared/CategoryPills';
import {
  DetailsPageCreatedDate,
  DetailsPageDescription,
  DetailsPageFooter,
  DetailsPageSpacedLabel,
  SummaryPageWrapper,
} from '../shared/styles';
import { DocumentIcon } from './components/DocumentIcon';
import { DocumentMenuItems } from './components/DocumentMenuItems';
import { DownloadDocument } from './DownloadDocument';

export const DocumentDetailsHeader = styled.div`&& {
  display: flex;
  margin: -10px -11px 10px;

  ${RecordMessage} {
    color: #2c3f69;
    margin-bottom: 0;
    padding-left: 5px;
  }
}`;

export const DocumentDetails: FC = () => {
  const { documentId } = useParams<{ documentId?: string }>();
  const { data: family } = useFetchSelectedFamily();
  const { data: user } = useFetchFamilyUser(family?.id);
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const { data: document, isFetching: loadingDocument } = useFetchDocumentDetails(documentId, family?.id);
  const currentUser = useContext(AuthorizationContext);
  const { mutate: deleteDocument } = useDeleteDocument();
  const { mutate: markAsViewed } = useMarkDocumentAsViewed(user?.id);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const assignedMembers = filterMembersById(familyMembers, document?.assigned);
  const taggedMembers = filterMembersById(familyMembers, document?.tagged);

  useEffect(() => {
    if (document?.id) {
      markAsViewed(document.id);
    }
  }, [document?.id]);

  const menuItems = (
    <DocumentMenuItems
      familyId={family?.id}
      document={document}
      familyUserId={user?.id}
    />
  );

  const Page = (
    <>
      <NavigateBack
        title="My Family Core"
        to={EAppRoutes.DocumentsSummary}
      />

      {document && (
        <SummaryPageWrapper>
          <DocumentDetailsHeader>
            <ActivityRecordPreview
              icon={<DocumentIcon />}
              menuItems={menuItems}
              description={document?.name}
              showMenu={shouldDisplayActionsMenu(document, currentUser)}
            />
          </DocumentDetailsHeader>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CategoryPills
                category={document?.category}
                subCategory={document?.subCategory}
              />
            </Grid>

            <GridDivider margin="5px 0 -5px" />

            <FlexVerticalCenterGrid
              item
              xs={12}
              sm={6}
            >
              <DetailsPageSpacedLabel>Assigned</DetailsPageSpacedLabel>

              <FamilyMembersList
                hideAllSelection
                data={assignedMembers}
              />
            </FlexVerticalCenterGrid>

            <GridDivider margin="0" />

            {document?.attachments?.map((attachment) => (
              <Grid
                key={attachment?.id}
                item
                xs={12}
                md={document?.attachments?.length && document?.attachments?.length > 1 ? 6 : 12}
              >
                <DownloadDocument
                  key={attachment.id}
                  familyId={family?.id}
                  document={document}
                  attachment={attachment}
                />
              </Grid>
            ))}
          </Grid>

          <DetailsPageFooter>
            {document?.description && (
              <>
                <TextBrandColor>Description</TextBrandColor>

                <DetailsPageDescription>
                  {document?.description}
                </DetailsPageDescription>
              </>
            )}

            <Grid
              container
              spacing={3}
            >
              <FlexVerticalCenterGrid
                item
                xs={12}
                md={6}
              >
                <DetailsPageSpacedLabel>Created By</DetailsPageSpacedLabel>

                <FamilyMembersList
                  hideAllSelection
                  data={[document?.creator as IFamilyMember]}
                />

                <DetailsPageCreatedDate>
                  {moment(document?.creationTime).format('MMM D, YYYY')}
                </DetailsPageCreatedDate>
              </FlexVerticalCenterGrid>

              {!!taggedMembers?.length && (
                <FlexEndGrid
                  item
                  xs={12}
                  md={6}
                >
                  <DetailsPageSpacedLabel>Tagged</DetailsPageSpacedLabel>

                  <FamilyMembersList
                    shifted
                    hideAllSelection
                    data={taggedMembers}
                  />
                </FlexEndGrid>
              )}
            </Grid>
          </DetailsPageFooter>
        </SummaryPageWrapper>
      )}

      <ProvidePinModal
        action="delete this document"
        open={deleteModalOpen}
        onSuccess={(securityCode: string, options) => deleteDocument({
          id       : document?.id,
          securityCode,
          familyId : family?.id,
        }, options)}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );

  return useMemo(() =>
    (<Layout loading={loadingDocument}>
      {Page}
    </Layout>), [currentUser, document, loadingDocument, deleteModalOpen, window.location.search]
  );
};
