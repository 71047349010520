import { useState }      from 'react';
import Cropper           from 'react-easy-crop';
import Cancel            from '@material-ui/icons/Cancel';
import CropIcon          from '@material-ui/icons/Crop';
import { Button, Modal } from '@material-ui/core';
import styled            from 'styled-components';
import getCroppedImg     from './utils';

const CropDetails = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const ButtonWrapper = styled(Button)`
  margin: 0 5px;
  height: 30px;
  width : 85px;
`;

interface CropEasyProps {
  photoURL: string;
  setOpenCrop: (arg0: boolean) => void;
  onCroppedImage: (arg0: Blob) => void;
  openCrop: boolean;
};

export interface ICroppedArea {
  height: number;
  width: number;
  x: number;
  y: number;
};

export const CropEasy = ({ photoURL, setOpenCrop, onCroppedImage, openCrop }: CropEasyProps) => {
  const [zoom, setZoom]         = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [crop, setCrop]         = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICroppedArea>(
    { height : 0,
      width  : 0,
      x      : 0,
      y      : 0,
    });

  const cropComplete = (croppedArea: ICroppedArea | null, croppedAreaPixels: ICroppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const result = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
      if (result.file) {
        onCroppedImage(result.file);
      } else {
        console.error('No file found in the result.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenCrop(false);
  };

  return (
    <Modal
      open={openCrop}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <>
        {photoURL &&
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />}

        <CropDetails>
          <ButtonWrapper
            variant="contained"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </ButtonWrapper>

          <ButtonWrapper
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Crop
          </ButtonWrapper>
        </CropDetails>
      </>
    </Modal>
  );
};
