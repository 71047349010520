import axios, { AxiosResponse }                      from 'axios';
import { useMutation }                               from 'react-query';
import { queryClient }                               from '../../../App';
import { IErrorResponse, IFamily, IRequestResponse } from '../../../Auth/shared/interfaces';
import { getAuthToken }                              from '../../../utils/authHelpers';
import { CATEGORIES }                                from '../../queries/categories';
import { FAMILY_USER_GET }                           from '../../queries/family';
import { TCreateQueryOptions, useCreateQuery }       from '../../utils/hooks/useReactQuery';

export const SELECTED_FAMILY_QUERY_KEY = 'selected-family';
export const SELECTED_FAMILY_GET = `${ SELECTED_FAMILY_QUERY_KEY }/get`;
export const SELECTED_FAMILY_UPDATE = `${ SELECTED_FAMILY_QUERY_KEY }/update`;

export const useFetchSelectedFamily = (
  options?: TCreateQueryOptions<IFamily>
) => useCreateQuery<IFamily>({
  queryKey : SELECTED_FAMILY_GET,
  apiUrl   : '/api/v1/families/default',
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!getAuthToken()?.accessToken,
    ...options,
  },
});

const updateSelectedFamily = (familyId: string) =>
  axios.put('api/v1/families/default', { familyId })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamily>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateSelectedFamily = (cb?: () => void) => useMutation(
  SELECTED_FAMILY_UPDATE,
  (familyId: string) => updateSelectedFamily(familyId),
  {
    onSuccess(data: IFamily) {
      queryClient.invalidateQueries(FAMILY_USER_GET);
      queryClient.invalidateQueries(SELECTED_FAMILY_GET);
      queryClient.invalidateQueries([ CATEGORIES ]);

      cb?.();
    },
  }
);
