import { WithStyles, withStyles }                                                            from '@material-ui/core/styles';
import { KeyboardDatePicker }                                                                from '@material-ui/pickers';
import classNames                                                                            from 'classnames';
import moment                                                                                from 'moment-timezone';
import React, { ForwardRefRenderFunction, ReactNode, forwardRef, memo, useEffect, useState } from 'react';
import { ReactComponent as IconDate }                                                        from '../../../../images/icons/icon-date.svg';
import {
  ITextInputOwnProps,
  TextInput,
}                                                                                            from '../../inputs/TextInput/TextInput';
import {
  dateRegex,
  getMoment,
}                                                                                            from '../../utils/commonFunctions';
import { selectDateStyles }                                                                  from './selectDateStyles';

export interface ISelectDateProps
  extends ITextInputOwnProps, WithStyles<typeof selectDateStyles> {
  id?: string;
  label: string;
  name?: string;
  value?: string | null;
  minDate?: string;
  maxDate?: string;
  error?: boolean;
  helperText?: ReactNode;
  shrink?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (event: unknown) => void;
}

const SelectDateBase: ForwardRefRenderFunction<HTMLDivElement, ISelectDateProps> = ({
  name,
  label,
  error,
  value,
  minDate,
  maxDate,
  shrink = true,
  classes,
  disabled,
  className,
  helperText,
  onChange,
  ...props
}, ref) => {
  const [ inputDate, setInputDate ] = useState<any>(null);
  useEffect(
    () => {
      const date = value ? getMoment(value) : null;
      setInputDate(date);
    },
    [ value ]
  );

  const updateDate = (momentValue: moment.Moment) => {
    const initialMoment = moment(value || undefined);
    const { years, months, date } = momentValue.toObject();

    return initialMoment.set({ years, months, date }).utc().toISOString();
  };

  const handleChange = (inputMoment: moment.Moment | null, inputValue?: string | null) => {
    setInputDate(inputValue);

    const isInputValid = !inputValue || dateRegex.test(inputValue);

    if (!onChange || !isInputValid) return;

    const updateValue = inputMoment ? updateDate(inputMoment) : null;

    onChange({ target: { value: updateValue, name } });
  };

  return (
    <div
      ref={ref}
      className={classNames([ classes.root, className ])}
    >
      <KeyboardDatePicker
        clearable
        label={label}
        error={!!error}
        value={inputDate}
        format="MM/DD/YYYY"
        placeholder="mm/dd/yyyy"
        minDate={minDate || '1900-01-01T00:00:00Z'}
        maxDate={new Date()}
        margin="none"
        helperText={helperText}
        keyboardIcon={<IconDate />}
        InputLabelProps={{ shrink }}
        TextFieldComponent={TextInput as any}
        animateYearScrolling={false}
        KeyboardButtonProps={{
          'aria-label': 'Date Picker',
        }}
        onChange={handleChange}
      />
    </div>
  );
};

const SelectDateMemoized = memo(forwardRef(SelectDateBase));

export const SelectDate = withStyles(selectDateStyles)(SelectDateMemoized);
