import * as Yup from 'yup';

export const addContactFormSchema = Yup.object().shape({
  categoryId    : Yup.string().required('Category is required'),
  tagged        : Yup.array().of(Yup.string()),
  familyId      : Yup.string().nullable(),
  title         : Yup.string().nullable(),
  firstName     : Yup.string().required('First Name is required').nullable(),
  lastName      : Yup.string().required('Last Name is required').nullable(),
  role          : Yup.string().required('Role is required').nullable(),
  officeAddress : Yup.string().nullable(),
  homeAddress   : Yup.string().nullable(),
  email         : Yup.string().email().nullable(),
  officeNumber  : Yup.string().phone().nullable(),
  homeNumber    : Yup.string().phone().nullable(),
  notes         : Yup.string().nullable(),
});
