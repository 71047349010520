import React, { FC }               from 'react';
import styled                      from 'styled-components';
import { ICategory, ISubCategory } from '../../../shared/queries/categories';
import { CategoryPill }            from '../../../shared/styledComponents';

interface IDocumentCategoryLabelsProps {
  category?: ICategory;
  subCategory?: ISubCategory;
}

const Wrapper = styled.span`
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`;

export const CategoryPills: FC<IDocumentCategoryLabelsProps> = ({ category, subCategory }) => (
  <Wrapper>
    <CategoryPill>
      { category?.name }
    </CategoryPill>

    { subCategory?.name && (
      <CategoryPill color="#969696">
        { subCategory?.name }
      </CategoryPill>
    ) }
  </Wrapper>
);
