export interface IUser {
  id: string;
  email: string;
  role: string;
  accountType?: string;
  firstName: string;
  lastName: string;
}

export interface ITokens {
  expireDate: string;
  type: string;
  accessToken: string;
  refreshToken: string;
  isPurchased: boolean;
  isTrialExpired: boolean;
}

export enum AuthResponseKeys {
  CREDENTIALS         = 'credentials',
  CREATE_FAMILY       = 'user/createFamily',
  CREATE_SUBSCRIPTION = 'user/createSubscription',
}

export interface IAuthResponseData {
  user: IUser;
  tokens: ITokens;
  key: AuthResponseKeys;
}

export interface IAuthResponse {
  data: IAuthResponseData;
}
