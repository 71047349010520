import { FormHelperText }       from '@material-ui/core';
import { useField }             from 'formik';
import moment                   from 'moment';
import { FC }                   from 'react';
import styled                   from 'styled-components';
import { removeTimeZoneOffset } from '../functions';
import { SelectDateRange }      from '../select/SelectDate/SelectDateRange';

interface IDateRangeFieldProps {
  startName: string;
  endName: string;
}

const Wrapper = styled.div`
  position: relative;

  .react-calendar__navigation__label {
    font-size: 15px;
  }

  .react-calendar__navigation__arrow {
    font-size: 18px;
  }
`;

const ErrorText = styled(FormHelperText)`
  color: red;
  bottom: -3px;
  left: 0;
`;

export const DateRangeField: FC<IDateRangeFieldProps> = ({
  startName,
  endName,
}) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [ _startField, startFieldMeta, startFieldHelpers ] = useField(startName);
  const { value: startValue, error: startFieldError, touched: startFieldTouched } = startFieldMeta;
  const { setValue: setStartValue, setTouched: setFirstTouched } = startFieldHelpers;

  const [ _endField, endFieldMeta, endFieldHelpers ] = useField(endName);
  const { value: endValue, error: endFieldError, touched: endFieldTouched } = endFieldMeta;
  const { setValue: setEndValue, setTouched: setSecondTouched } = endFieldHelpers;

  const displayError = (startFieldTouched && startFieldError) || (endFieldTouched && endFieldError);

  const onRangeChanged = (dates: Date[] | null) => {
    setStartValue(removeTimeZoneOffset(dates?.[0]));

    const startDate = moment(dates?.[0]);
    const endDate = moment(dates?.[1]);
    if (startDate.startOf('day').isSame(endDate.startOf('day'))) {
      setEndValue('');
    } else {
      setEndValue(removeTimeZoneOffset(dates?.[1]));
    }

    setFirstTouched(true);
    setSecondTouched(true);
  };

  return (
    <Wrapper>
      <SelectDateRange
        startDate={startValue}
        endDate={endValue}
        onRangeChange={onRangeChanged}
      />

      { displayError && (
        <ErrorText className="Mui-error">{ startFieldError || endFieldError }</ErrorText>
      ) }
    </Wrapper>
  );
};
