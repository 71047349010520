import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { FastField }                                  from 'formik';
import ReactInputVerificationCode                     from 'react-input-verification-code';
import styled                                         from 'styled-components';
import { FamilyMemberWrapper }                        from '../../modules/Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { LogoBlue }                                   from '../../shared/components/Logo';
import { ReactComponent as VerifiedIcon }             from '../../../images/icons/verified-symbol-icon.svg';


interface IPageHeader {
  mb?: string;
  mbMd?: string;
  color?: string;
  align?: string;
  margin?: string;
}

export const MenuAnchor = styled.div<{ right?: string; top?: string }>`
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
  top: ${ ({ top }) => top || '0' };
  right: ${ ({ right }) => right || '0' };
`;

export const SignUpContainer = styled(Container)`&& {
  width: 100%;
  margin: auto;
  padding: 28px;

  ${ LogoBlue } {
    margin-bottom: 25px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin: auto;
    max-width: 620px;

    ${ LogoBlue } {
      margin-bottom: 50px;
    }
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    max-width: 894px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('lg') } {
    max-width: 960px;
  }
}`;

export const Page = styled.div<{ tinted?: boolean }>`
  padding: 28px 32px;
  width: 100%;
  max-width: 960px;
  margin: auto;
`;

export const PageHeader = styled.div<IPageHeader>`
  color: ${ ({ color }) => color || '#2c3f69' };
  display: flex;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin: ${ ({ margin }) => margin || 'auto' };
  margin-bottom: ${ ({ mb }) => mb || '15px' };
  align-items: ${ ({ align }) => align || 'center' };
  justify-content: center;
  flex-direction: column;
  font-family: 'Jost';
  font-weight: 500;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: ${ ({ mbMd }: IPageHeader) => mbMd || '50px' };
  }
`;

interface IPageHeaderTextProps {
  centered?: boolean;
  centeredXs?: boolean;
  maxWidth?: string;
  padding?: string;
  color?: string;
  marginBottom?: string;
}

export const PageHeaderText = styled.div<IPageHeaderTextProps>`
  width: ${ ({ centered, centeredXs }) => centered || centeredXs ? 'auto' : '100%' };
  text-align: ${ ({ centered, centeredXs }) => centered || centeredXs ? 'center' : 'left' };
  max-width: ${ ({ maxWidth }) => maxWidth || '100%' };
  padding: ${ ({ padding }) => padding || 0 };
  font-family: Jost;
  font-size: 22px;
  font-weight: 600;
  color: ${ ({ color }) => color || '#2c3f69' };
  margin-bottom: ${ ({ marginBottom }) => marginBottom || 0 };

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: ${ ({ centered }) => centered ? 'auto' : '100%' };
    text-align: ${ ({ centered }) => centered ? 'center' : 'left' };
    font-size: 24px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    line-height: 52px;
    font-size: 48px;
    line-height: 1.08;
    letter-spacing: 2.5px;
  }
`;

export const SignInText = styled.div`
  font-family: 'Lato';
  margin-top: 28px;
  font-size: 16px;
`;

export const AuthField = styled(FastField)`&& {
}`;

export const Terms = styled(Typography)` && {
  margin: 15px 0 5px;
  font-family: 'Lato';
  font-weight: 600;
  font-size: 13px;
}`;

export const LabelText = styled(Typography)<{ wrap?: boolean }>` && {
  font-family: 'Lato';
  font-size: 14px;
  line-height: 1.3;

  ${ ({ wrap }: any): string => `white-space: ${ wrap ? 'wrap' : 'nowrap' }` };
}`;

export const TextBlack = styled.span`
  color: #000;
`;

export const TextError = styled.p`
  color: #f44336;
  margin-top: 25px;
`;

export const ProfilePage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfilePageHeader = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background: #6f90d9;
  padding: 16px 24px 16px 16px;
  white-space: nowrap;

  svg {
    width: 100px;
    
    ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
      width: 120px;
    }
  }
`;

export const ProfilePageContent = styled.div`
  background-color: #f7fafd;
  padding: 48px 0;

  hr {
    width: calc(100% + 20px);
    margin: 20px -10px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('xs') } {
    padding: 30px 0;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 48px 30px;
  }
`;

export const Instructions = styled.div<{ mbXs?: string; mbMd?: string; centered?: boolean; widthSm?: string; widthMd?: string }>`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.17px;
  text-align: ${ ({ centered }) => centered ? 'center' : 'left' };
  font-family: 'Lato';
  margin: auto;
  margin-bottom: ${ ({ mbXs }) => mbXs || '33px' };
  padding: 0 10px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    text-align: ${ ({ centered }) => centered ? 'center' : 'left' };
    width: ${ ({ widthSm }) => widthSm || 'auto' };
    padding: 0;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: 22px;
    line-height: 1.73em;
    letter-spacing: 0.23px;
    color: #081119;
    text-align: ${ ({ centered }) => !centered ? 'left' : 'center' };
    margin: ${ ({ centered }) => !centered ? '0' : 'auto' };
    margin-bottom: ${ ({ mbMd }) => mbMd || '55px' };
    width: ${ ({ widthSm, widthMd }) => widthMd || widthSm || 'auto' };
  }
`;

export const MultiButton = styled(Button)`&& {
  border: 2px solid #547cd3;
  border-radius: 9px;
  font-size: 16px;
  font-weight: 600;
  color: #547cd3;

  input[type="file"] {
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 0;
    position: absolute;
  }

  input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

  ${ FamilyMemberWrapper } {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer; 

    button {
      font-weight: 400;
      cursor: pointer; 
    }
  }
}`;

export const SignInUpPage = styled.div`
  height: calc(100vh - env(safe-area-inset-top, 0));
  background: #f9f9f9;
  overflow-x: hidden;
`;

export const WrapperLogo = styled(Grid)`
  position: relative;
`;

export const ReverseGridContainer = styled(Grid)`
  justify-content: center;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    flex-direction: row-reverse;
  }
`;

export const AuthSVGSceneHolder = styled.div`&& {
  height: 177px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg, img {
    max-height: 100%;
    max-width: 100%;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: 310px;
    height: 335px;
    margin: -30px auto 0;
    padding-right: 25px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 100%;
    height: 100%;
    margin: -50px 3px 0;
    padding-right: 0;
  }
}`;

export const LogoSpaced = styled(LogoBlue)`&& {
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 75px;
  }
}`;

export const CodeInputBox = styled(Paper)`
  display: flex;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 31px 61px 15px;
  border-radius: 12px;
  font-family: 'Lato';
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.06);
  margin: 20px auto;
  max-width: 310px;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    max-width: 100%;
  }
`;

export const CodeInput = styled(ReactInputVerificationCode)`&& {
  margin: 0;
}`;

export const SecurityCodeErrorMessage = styled.div<{ position?: string }>`
  color: red;
  bottom: 15px;
  font-size: 14px;
  position: ${ ({ position }) => position || 'absolute' };
`;

export const VerifiedIconWrapper = styled(VerifiedIcon)`
  width      : 35px;
  height     : 25px;
  margin-left: 5px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.only('xs') } {
    width : 20px;
    height: 15px;
  };
`;
