import React                               from 'react';
import { ReactComponent as IconContact }   from '../../images/icons/rounded/icon-contact.svg';
import { ReactComponent as IconDocuments } from '../../images/icons/rounded/icon-document.svg';
import { ReactComponent as IconEvent }     from '../../images/icons/rounded/icon-events.svg';
import { ReactComponent as IconLocation }  from '../../images/icons/rounded/icon-location.svg';
import { ReactComponent as IconPhoto }     from '../../images/icons/rounded/icon-photo.svg';
import { ReactComponent as IconTask }      from '../../images/icons/rounded/icon-task.svg';
import { ReactComponent as IconLogin }     from '../../images/icons/rounded/login-icon.svg';
import { EAppRoutes }                      from './constants/constants';

const createDocumentType = (name: string, familyItemType: string, icon: JSX.Element, isPublished: boolean, route?: string) => ({
  name,
  icon,
  route,
  familyItemType,
  isPublished,
});

enum EDocumentType {
  documents    = 'documents',
  contacts     = 'contacts',
  events       = 'events',
  checkIns     = 'checkIns',
  credentials  = 'credentials',
  tasks        = 'tasks',
  photoLibrary = 'photoLibrary',
}

export const documentTypes: { [key in EDocumentType]: any } = {
  documents    : createDocumentType('Documents', 'document', <IconDocuments />, true, EAppRoutes.DocumentsSummary),
  contacts     : createDocumentType('Contacts', 'contact', <IconContact />, true, EAppRoutes.ContactsSummary),
  events       : createDocumentType('Events', 'event', <IconEvent />, true, EAppRoutes.Schedule),
  checkIns     : createDocumentType('Check Ins', 'check in', <IconLocation />, true, EAppRoutes.Locate),
  credentials  : createDocumentType('Credentials', 'credentials', <IconLogin />, false),
  tasks        : createDocumentType('Tasks', 'task', <IconTask />, true, EAppRoutes.TasksSummary),
  photoLibrary : createDocumentType('Photo Library', 'photo-library', <IconPhoto />, false),
};

export const documentTypesList = Object.keys(documentTypes)
  .filter((key: any) => !!(documentTypes as any)[key].isPublished)
  .map((key: any) => (documentTypes as any)[key]);

