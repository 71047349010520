
import React, {  useEffect, useState } from 'react';
import { useHistory, useLocation }     from 'react-router-dom';

import {
  FormControl,
  Grid,
  Hidden,
  Link,
  MenuItem,
  Select,
}             from '@material-ui/core';
import styled from 'styled-components';


import { EAppRoutes, EAuthRoutes }                         from '../../shared/constants/constants';
import { LogoWhite }                                       from '../../shared/components/Logo';
import { useFetchFamilies }                                from '../../shared/queries/family';
import { AuthSVGSceneHolder, PageHeader, SignUpContainer } from '../shared/styles';
import { capitalizeFirstLetter }                           from '../../shared/utils/commonFunctions';
import { useFetchSelectedFamily, useUpdateSelectedFamily } from '../../shared/components/SelectFamily/queries';
import { MuiButton }                                       from '../../shared/components/MuiButton';
import { logout }                                          from '../../shared/utils/setAuthorizationToken';

import imageCheckout  from '../../../images/chose-your-plan-select-page.png';

interface ISelectFamilyProps {
  fullWidth?: boolean;
}

const StyledDropdown = styled(FormControl)<ISelectFamilyProps>`
  flex         : 1;
  width        : 100%;
  margin-bottom: 30px;

  .MuiSelect-outlined {
    border-radius   : 9px;
    background-color: #fafafa;
    box-shadow      : 0 0 8px 0 rgb(0 0 0 / 12%);
  }
  
  .MuiInputBase-root {
    margin-top: 0;
    max-width : ${ ({ fullWidth }) => fullWidth ? '100%' : '200px' };
    
    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      max-width: ${ ({ fullWidth }) => fullWidth ? '100%' : '260px' };
    }
  }
`;

const FamilyName = styled.div`
  overflow     : hidden;
  text-overflow: ellipsis;
`;

const StyledLogo = styled(LogoWhite)`
  height       : 50px;
  width        : 110px;
  margin-bottom: 40px;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    height       : 60px;
    width        : 130px;
    margin-bottom: 90px;
  }
`;

const WrapperGrid = styled(Grid)`
  justify-content: center;
`;

const CreateFamilyWrapperGrid = styled(Grid)`
  margin-top: 25px;
  display   : flex;
`;

const SelectPlanWrapper = styled.div`
  background-color: #5274be;
  height          : 100%;
`;

const TittleText = styled.p`
  margin-bottom  : 40px;
  display        : flex;
  justify-content: center;
`;

const TittleTextExpired = styled.p`
  margin-bottom  : 40px;
  display        : flex;
  justify-content: center;
  font-size      : 18px;
  text-align     : justify;
`;

const ErrorText = styled.p`
  display    : block;
  font-size  : 15px;
  line-height: 22px;
  font-weight: 600;
  color      : white;
  text-align : justify;
  margin     : 20px 0;
`;

const SelectPlanContainer = styled.div`&& {
  width     : 200px;
  text-align: center;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 0;
  }
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: auto;
    margin: 0;
    text-align: left;
  }
}`;

const HeaderContainer = styled(SignUpContainer)`&& {
  padding   : 28px;
  min-height: auto;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 28px 40px 60px;
  }
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 28px 0 40px;
  }
}`;

const CreateFamilyLink = styled(Link)`
  font-size     : 17px;
  color         : #2c3f69;
  letter-spacing: 0.4px;

  &:hover {
    color          : #1d2945;
    text-decoration: none;
  }
`;

export const SelectFamilyComponent = () => {
  const history      = useHistory();
  const { pathname } = useLocation();

  const { data: families }               = useFetchFamilies();
  const { data: selected, isLoading }    = useFetchSelectedFamily();

  const { mutate: updateSelectedFamily, isLoading: isLoadingSelect } = useUpdateSelectedFamily(() => {
    history.push({ pathname });
  });

  const [ isUser, setIsUser ]                     = useState(false);
  const [ page, setPage ]                         = useState('');
  const [ selectedFamilyId, setSelectedFamilyId ] = useState('');
  const [ cookieConsent, setCookieConsent ]       = useState<string | null>(null);

  useEffect(() => {
    const storedCookieConsent = localStorage.getItem('cookieConsent');
    setCookieConsent(storedCookieConsent);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedCookieConsent = localStorage.getItem('cookieConsent');
      setCookieConsent(storedCookieConsent);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!selected && !isLoading) {
      updateSelectedFamily(families?.[0]?.id || '');
    }
  }, [ isLoading ]);

  const handleSubmit = () => {
    families?.map((family) => {
      if (family?.id === selected?.id && !family?.isActivePayment && family?.isCreator) {
        setIsUser(false);

        return setPage(EAppRoutes.Dashboard);
      } else if (family?.id === selected?.id && !family?.isActivePayment && !family?.isCreator) {
        setIsUser(true);

        return setPage(EAppRoutes.Dashboard);
      } else if (family?.id === selected?.id && family?.isActivePayment){
        setIsUser(false);

        return setPage(EAppRoutes.Dashboard);
      } else return setPage(EAppRoutes.Dashboard);
    });
  };

  return (
    <SelectPlanWrapper>
      <HeaderContainer maxWidth="md">
        <PageHeader
          color="#fff"
          mb="0"
          mbMd="0"
        >
          <StyledLogo />

          <WrapperGrid container>
            <Grid
              item
              sm={6}
              md={5}
            >
              <SelectPlanContainer>
                {(families?.length !== 0) ? (
                  <>
                    <TittleText>Select family</TittleText>

                    <StyledDropdown
                      fullWidth
                      variant="outlined"
                    >
                      <Select
                        displayEmpty
                        value={selectedFamilyId}
                        onChange={(event: any) => {
                          setSelectedFamilyId(event.target.value);
                          updateSelectedFamily(event.target.value);
                          handleSubmit();
                        }}
                      >
                        <MenuItem value="">
                        Choose a family
                        </MenuItem>

                        {(families || []).map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                          >
                            <FamilyName>{capitalizeFirstLetter(item.name)} Family</FamilyName>
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledDropdown>

                    <MuiButton
                      fullWidth
                      disabled={!selectedFamilyId || isLoadingSelect || cookieConsent === null}
                      type="button"
                      margin="0 0 20px 0"
                      onClick={() => {
                        history.push(page);
                      }}
                    >
                      Submit
                    </MuiButton></>
                ) : (
                  <>
                    <TittleTextExpired>
                      Your account was previously cancelled and deleted. You can create a new family by restoring your previous email address or use a different email address.
                    </TittleTextExpired>

                    <MuiButton
                      fullWidth
                      type="button"
                      margin="0"
                      onClick={() => {
                        history.push(EAuthRoutes.Checkout);
                      }}
                    >
                      Same email
                    </MuiButton>

                    <MuiButton
                      fullWidth
                      type="button"
                      margin="10px 0"
                      onClick={() => {
                        history.push(EAuthRoutes.SignUp);
                      }}
                    >
                      Another email
                    </MuiButton>
                  </>
                )}

                <CreateFamilyWrapperGrid
                  item
                  xs={12}
                >
                  <CreateFamilyLink onClick={logout}>Log Out</CreateFamilyLink>
                </CreateFamilyWrapperGrid>
              </SelectPlanContainer>
            </Grid>

            <Hidden only={['xs']}>
              <Grid
                item
                sm={6}
                md={7}
              >
                <AuthSVGSceneHolder>
                  <img
                    src={imageCheckout}
                    alt="Checkout"
                  />
                </AuthSVGSceneHolder>
              </Grid>
            </Hidden>
          </WrapperGrid>
        </PageHeader>
      </HeaderContainer>
    </SelectPlanWrapper>
  );
};
