import axios, { AxiosResponse }                                 from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery }                                     from 'react-query';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../../Auth/shared/interfaces';
import { IEvent }                                               from '../../Schedule/queries';

export const EVENTS = 'EVENTS';

const loadEvents = (
  familyId?: string,
  assignee?: string,
  offset = 0,
  limit  = 5
) => {
  const params = {
    familyId,
    assignee,
    offset,
    limit,
  };

  return axios.get(`/api/v1/events?${ qs.stringify(params) }`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<any>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch((error: IErrorResponse) => {
      throw error;
    });
};

export const useLoadEventsPage = (
  familyId?: string,
  assignee?: string,
  limit?: number,
) => useInfiniteQuery(
  [ EVENTS, familyId, assignee ],
  ({ pageParam: offset }) => loadEvents(familyId, assignee, offset, limit),
  {
    enabled          : !!familyId,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });
