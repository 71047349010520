import { ActionSheet, ActionSheetButtonStyle }                                              from "@capacitor/action-sheet";
import { Camera, CameraResultType, CameraSource }                                           from "@capacitor/camera";
import type { Photo }                                                                       from "@capacitor/camera";
import { FilePicker, PickedFile }                                                           from "@capawesome/capacitor-file-picker";

export const b64toBlob = (base64: string = '', type = 'application/octet-stream') =>
  fetch(`data:${ type };base64,${ base64 }`).then(res => res.blob());

export const getDocument = async () => {
  const openPhotos = async () => await FilePicker.pickImages({
    readData : true,
    multiple : false,
  }).then(({ files }) => files?.[0]);

  const openFiles = async () => await FilePicker.pickFiles({
    readData : true,
    multiple : false,
  }).then(({ files }) => files?.[0]);

  const getPhoto = async () => await Camera.getPhoto({
    quality      : 90,
    allowEditing : false,
    webUseInput  : true,
    source       : CameraSource.Camera,
    resultType   : CameraResultType.Base64,
  });

  const documentPickOptions = [
    {
      title  : 'From Photos',
      action : openPhotos,
    },
    {
      title  : 'From Files',
      action : openFiles,
    },
    {
      title  : 'Take Picture',
      action : getPhoto,
    },
    {
      title : 'Cancel',
      type  : ActionSheetButtonStyle.Cancel,
    },
  ];

  const result = await ActionSheet.showActions({
    title   : 'Add Document',
    message : 'Select an option to perform',
    options : documentPickOptions.map(({ title }) => ({ title })),
  });

  const targetAction = documentPickOptions[result.index].action;

  type MediaFile = PickedFile | Photo | undefined;

  const media: MediaFile = await targetAction?.();

  if (!media) {
    return;
  }

  const fileName = ('name' in media ? media.name : '');
  const fromPathName = media.path ? media.path?.substring(media.path.lastIndexOf('/') + 1) : '';
  const fromExifName = 'exif' in media ? `${ media.exif?.DateTimeOriginal }` : '';
  const format = 'format' in media ? `.${ media.format }` : '';

  const name = (fileName || fromPathName || fromExifName || 'file') + format;
  const data = (media as PickedFile).data || (media as Photo).base64String;
  const mimeType = (media as PickedFile).mimeType || (media as Photo).format;
  const blob = await b64toBlob(data, mimeType);

  return {
    ...media,
    name,
    data,
    mimeType,
    blob,
  };
};
