import { Form }  from 'formik';
import { RadioGroup, FormControl, Grid } from '@material-ui/core';

import styled    from 'styled-components';
import { theme } from '../../../../styles';
import { Pill }  from '../../styledComponents';

const commonLabelRules = `
  display: flex;
  gap: 8px;
  
  i {
    font-style: italic;
    color: #939498;
  }
`;

export const UploadEntityFormSpacing = styled(Form)`&& {
  overflow-x: hidden;
  padding: 15px 25px 30px;

  .MuiInputLabel-outlined {
    font-size: 13px;
    color: ${ theme.colors.warning };
    font-weight: 600;
    ${ commonLabelRules }
  }
}`;

export const RadioGroupWrapper = styled(RadioGroup)`&& {
  display: flex;
  min-width: 127px;
  margin-bottom: 20px;
}`;

export const FormControlWrapper = styled(FormControl)`&& {
  display: flex;
}`;

export const ReportsBlockWrapper = styled('div')`&& {
  display: flex;
}`;

export const ApplyWrapper = styled(Grid)`&& {
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('lg') } {
    margin-top: -79px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin-left: -24px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-top: -79px;
  }
}`;

export const UploadEntityFormDate = styled(RadioGroup)`&& {
  padding: 15px 25px 30px;

  .MuiInputLabel-outlined {
    font-size: 13px;
    color: ${ theme.colors.warning };
    font-weight: 600;
    ${ commonLabelRules }
  }
}`;

export const UploadEntityFormLabel = styled.div<{ width?: string }>`&& {
  color: ${ theme.colors.warning };
  min-width: ${ ({ width }) => width || '65px' };
  font-weight: 600;
  font-size: 13px;
  margin-right: 20px;
  margin-bottom: 12px;
  ${ commonLabelRules }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    flex-direction: row;
    margin-bottom: 0;
  }
}`;

export const UploadFormLabel = styled.div`
  font-size: 22px;
  font-weight: 505;
  color: rgb(70, 108, 157);
  align-items: center;
  display: flex;
  margin: 8px 0px 0px -37px;
  width: 181px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
    font-size: 20px;
  }
`;

export const UploadEntityFormRow = styled.div`&& {
  display: flex;
  flex-direction: column;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    flex-direction: row;
    align-items: center;
  }
}`;

export const UploadEntityFormDropdownField = styled.div`
  width: 245px;
  max-width: 100%;
  margin: 0 0 20px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin: 0 20px 0 0;
  }
`;

export const StatusBadge = styled(Pill)<{ color: string; mb?: string }>`
  margin-bottom: ${ ({ mb }) => mb || '5px' };
  color: ${ ({ color }) => color };
  border: solid 1px ${ ({ color }) => color };
  background-color: rgba(196, 196, 196, 0);
`;
