import { Dialog, DialogProps } from '@material-ui/core';
import { Clear }               from '@material-ui/icons';
import { FC }                  from 'react';
import styled, { css }         from 'styled-components';
import { CloseModalIcon }      from '../../styledComponents';

const StyledDialog = styled(Dialog)`&& {
  margin: auto;

  .MuiDialog-paper {
    margin: 18px;
    transition : all 1s ease;

    ${ ({ width, minwidth }: any) => width && css`
      width     : ${ width };
      min-width : ${ minwidth };
    ` };

    ${ ({ height }: any) => height && css`
      height     : ${ height };
      max-height : ${ height };
    ` };

    ${ ({ minheight }: any) => minheight && `min-height: ${ minheight }` };
  }
}` as any;

interface IAlertDialogProps extends DialogProps {
  onClose: () => void;
  dialogWidth?: string;
  dialogMinWidth?: string;
  dialogHeight?: string;
  dialogMinHeight?: string;
  hideCloseIcon?: boolean;
}

export const Modal: FC<IAlertDialogProps> = ({
  children,
  dialogWidth,
  dialogMinWidth,
  dialogHeight,
  dialogMinHeight,
  hideCloseIcon,
  onClose,
  ...props
}) => (
  <StyledDialog
    width={dialogWidth}
    minwidth={dialogMinWidth}
    height={dialogHeight}
    minheight={dialogMinHeight}
    onClose={onClose}
    {...props}
  >
    { children }

    { !hideCloseIcon && (
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>
    ) }
  </StyledDialog>
);

Modal.defaultProps = {
  disableBackdropClick: true,
};
