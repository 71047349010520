import { Hidden }               from '@material-ui/core';
import React, { FC }            from 'react';
import styled                   from 'styled-components';
import { IUploadImageResponse } from '../../../../../../Auth/shared/interfaces';
import { generateInitials }     from '../../../../../../shared/utils/commonFunctions';
import { FamilyMemberCircle }   from '../../../../../Dashboard/components/FamilyMembers/FamilyMemberCircle';

const MemberBlockContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MemberBlockDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  color: #272932;
  font-size: 14px;

  strong {
    font-weight: 700;
    color: #272932;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  span {
    font-weight: 700;
    font-size: 16px;
    color: #5071bb;
    margin-bottom: 10px;
  }

  div {
    line-height: 0.71;
    color: #8d91a5;
    white-space: nowrap;
  }
`;

export interface IMemberDetailsData {
  avatar: IUploadImageResponse;
  color: string;
  firstName: string;
  lastName: string;
}

export interface IMemberDetailsProps {
  data?: IMemberDetailsData;
  width?: string;
  title?: string;
  subtitle?: string;
  hideAvatarOnSmallScreen?: boolean;
}

export const MemberDetails: FC<IMemberDetailsProps> = ({
  width,
  data,
  title,
  subtitle,
  hideAvatarOnSmallScreen,
}) => {
  const MemberCircle = (
    <FamilyMemberCircle
      width={width || '38px'}
      fontSize="13px"
      image={data?.avatar?.compactPath}
      color={data?.color}
      initials={generateInitials(data?.firstName, data?.lastName)}
    />
  );

  return (
    <MemberBlockContainer>
      { hideAvatarOnSmallScreen
        ? <Hidden xsDown>{ MemberCircle }</Hidden>
        : MemberCircle }

      <MemberBlockDetails>
        <strong>{ `${ data?.firstName } ${ data?.lastName }` }</strong>
        <span>{ title }</span>
        <div>{ subtitle }</div>
      </MemberBlockDetails>
    </MemberBlockContainer>
  );
};
