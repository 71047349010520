import { IconButton, MenuItem, Menu as MuiMenu } from '@material-ui/core';
import MoreHorizIcon                             from '@material-ui/icons/MoreHoriz';
import React, { FC, useRef }                     from 'react';
import styled                                    from 'styled-components';
import { MenuAnchor }                            from '../../../../../../Auth/shared/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MenuButton = styled(IconButton)`
  margin: 0 0 0 15px;
`;

interface ITaggedItemControlsProps {
  id: string;
  isAssignees?: boolean;

  onRemove(): void;

  onView(): void;
}

export const TaggedItemControls: FC<ITaggedItemControlsProps> = ({
  id,
  isAssignees,
  onRemove,
  onView,
}) => {
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const menuAnchorRef = useRef(null);
  const handleMenuClick = () => {
    setAnchorEl(menuAnchorRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnRemove = () => {
    handleMenuClose();
    onRemove();
  };

  const handleOnView = () => {
    handleMenuClose();
    onView();
  };

  return (
    <Wrapper>
      <MenuButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuAnchor
          ref={menuAnchorRef}
          color="#fff"
          top="70px"
          right="150px"
        />
        <MoreHorizIcon />
      </MenuButton>

      <MuiMenu
        keepMounted
        id={`row-menu-${ id }`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleOnRemove}>Remove { isAssignees ? 'Assignment' : 'Tag' }</MenuItem>
        <MenuItem onClick={handleOnView}>View</MenuItem>
      </MuiMenu>
    </Wrapper>
  );
};
