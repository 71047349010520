import { Button }            from '@material-ui/core';
import React, { FC }         from 'react';
import { useHistory }        from 'react-router-dom';
import styled                from 'styled-components';
import { Modal }             from '../../../shared/components/modals/Modal';
import { EAppRoutes }        from '../../../shared/constants/constants';
import { documentTypesList } from '../../../shared/documentTypes';
import { StyledDivider }     from '../../../shared/styledComponents';

const WelcomeDialogTitle = styled.div`
  padding: 15px;
  font-size: 32px;
  line-height: 1.31;
  margin-top: 40px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #272932;
  
  span {
    color: #e4572e;
  }
`;

const WelcomeDialogText = styled.div`
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.64px;
  text-align: center;
  max-width: 300px;
  margin: auto;
`;

const WelcomeDialogLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.64px;
`;

const WelcomeDialogDocumentTypes = styled.div`
  display: flex;
  padding: 0 32px;
  flex-wrap: wrap;
  gap: 15px 20px;
  margin-bottom: 50px;
  justify-content: space-between;
  padding: 0 50px;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 0 32px 0 20px;
  }
`;

const WelcomeDialogDocumentType = styled.div`
  width: 125px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StyledModal = styled(Modal)`&& {
  .MuiPaper-root {
    width: 400px;
  }
}`;

export const StyledDialogActions = styled.div`&& {
  width: 230px;
  display: flex;
  margin: 0 auto;
  padding-bottom: 30px;
  flex-direction: column;
  
  button {
    margin-bottom: 10px;
  }
}`;

interface ITipsModalProps {
  open: boolean;
  handleCancel: () => void;
}

export const TipsModal: FC<ITipsModalProps> = ({
  open,
  handleCancel,
}) => {
  const history = useHistory();

  const onSubmitClick = () => {
    history.push(EAppRoutes.MyProfile);
  };

  const onCancelClick = () => {
    history.push(EAppRoutes.Dashboard);
    handleCancel();
  };

  return (
    <StyledModal
      open={open}
      maxWidth="md"
      onClose={onCancelClick}
    >
      <WelcomeDialogTitle>
        Welcome to<br />
        <span>Family Core</span>!
      </WelcomeDialogTitle>

      <WelcomeDialogText>
        Your one stop shop to manage your family's dynamics and daily details
      </WelcomeDialogText>

      <StyledDivider mt={32} mb={38} />

      <WelcomeDialogLabel>
        Start Exploring Today:
      </WelcomeDialogLabel>

      <WelcomeDialogDocumentTypes>
        { documentTypesList.map((item) => (
          <WelcomeDialogDocumentType key={item.name}>
            { item.icon }

            { item.name }
          </WelcomeDialogDocumentType>
        )) }
      </WelcomeDialogDocumentTypes>

      <WelcomeDialogLabel>
        Your Next Steps...
      </WelcomeDialogLabel>

      <StyledDialogActions>
        <Button
          type="submit"
          size="medium"
          color="primary"
          variant="contained"
          onClick={onSubmitClick}
        >
          Customize Your Profile
        </Button>

        <Button
          size="medium"
          color="primary"
          onClick={onCancelClick}
        >
          <strong>I’ll Set Up Later</strong>
        </Button>
      </StyledDialogActions>
    </StyledModal>
  );
};
