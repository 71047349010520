import { Switch, withStyles } from '@material-ui/core';
import React                  from 'react';

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width   : 42,
    height  : 26,
    padding : 0,
    margin  : theme.spacing(1),
  },
  switchBase: {
    padding     : 1,
    '&$checked' : {
      transform    : 'translateX(16px)',
      color        : theme.palette.common.white,
      '& + $track' : {
        backgroundColor : '#547cd3',
        opacity         : 1,
        border          : 'none',
      },
    },
    '&$checked $thumb': {
      border: '3px solid #547cd3',
    },
    '&$focusVisible $thumb': {
      color  : '#547cd3',
      border : '6px solid #fff',
    },
  },
  thumb: {
    width     : 24,
    height    : 24,
    border    : `4px solid ${ theme.palette.grey[400] }`,
    boxShadow : 'unset',
  },
  track: {
    borderRadius    : 26 / 2,
    border          : `4px solid ${ theme.palette.grey[400] }`,
    backgroundColor : theme.palette.grey[50],
    opacity         : 1,
    transition      : theme.transitions.create([ 'background-color', 'border' ]),
  },
  checked      : {},
  focusVisible : {},
}))(({ classes, ...props }: any) => (
  <Switch
    disableRipple
    focusVisibleClassName={classes.focusVisible}
    classes={{
      root       : classes.root,
      switchBase : classes.switchBase,
      thumb      : classes.thumb,
      track      : classes.track,
      checked    : classes.checked,
    }}
    {...props}
  />
));
