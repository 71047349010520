import React, { ChangeEvent, FC, useCallback } from 'react';
import InputMask                               from 'react-input-mask';

const unMaskRegex = new RegExp(/[^\d.+]/, 'g');
const unMask = (str: string) => str.replace(unMaskRegex, '');

export const PhoneNumberMask: FC<any> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const unmasked = unMask(event.target.value);

      const newValue =
              unmasked && unmasked !== '+1'
                ? unmasked
                : '';

      onChange({
        ...event,
        target: {
          name  : event.target.name,
          value : newValue,
        },
      });
    },
    [ onChange ]
  );

  let mask = '+1 (999) 999-9999';

  if (value?.length > unMask(mask).length) {
    mask += '9'.repeat(value.length - unMask(mask).length);
  }

  return (
    <InputMask
      {...props}
      mask={mask}
      value={value}
      onChange={handleChange}
    />
  );
};
