import { Divider }                                                      from '@material-ui/core';
import React, { FC }                                                    from 'react';
import styled                                                           from 'styled-components';
import { Loader }                                                       from '../../../shared/enhancers/withLoader';
import { StyledList }                                                   from '../../Activity/ActivityItems';
import { AddEntityControl }                                             from './AddEntityControl';
import { SummaryHeader, SummaryListCount, SummaryListWrapper, ViewAll } from './styles';

const ViewAllWithShadow = styled(ViewAll)`
  box-shadow: 0px -6px 8px 0px rgb(0 0 0 / 6%);
`;

export interface IEntitiesListPreviewProps {
  children: any;
  title: string;
  count?: number;
  loading?: boolean;
  showViewAll?: boolean;
  onAdd?(): void;
  onViewAll(): void;
}

export const EntitiesListPreview: FC<IEntitiesListPreviewProps> = ({
  children,
  count,
  title,
  loading,
  showViewAll,
  onViewAll,
  onAdd,
}) => (
  <SummaryListWrapper>
    <SummaryHeader variant="h5">
      <span>
        { title } <SummaryListCount>{ count }</SummaryListCount>
      </span>

      { onAdd && (
        <AddEntityControl onAdd={onAdd} />
      ) }
    </SummaryHeader>

    <Divider />

    <StyledList>
      { loading ? <Loader /> : children }
    </StyledList>

    { showViewAll && (
      <ViewAllWithShadow
        variant="h5"
        onClick={onViewAll}
      >
          View All
      </ViewAllWithShadow>
    ) }
  </SummaryListWrapper>
);
