import { FC, useRef, useState }  from 'react';
import { DialogProps }          from '@material-ui/core';
import { Clear }                 from '@material-ui/icons';
import { Formik, FormikProps }   from 'formik';
import { IFileShape }            from '../../../../Auth/shared/interfaces';
import { ITask }                 from '../../../../modules/MyFamilyCore/tasks/queries';
import { EDocumentAccess }       from '../../../constants/constants';
import { attachmentToFileShape } from '../../../functions';
import { CloseModalIcon }        from '../../../styledComponents';
import { ERecurrence }           from '../shared';
import {
  StyledUploadDialog,
  UploadDialogContent,
  UploadDialogTitle,
}                                from '../styles';
import {
  useUpdateTask,
  useUploadTask,
}                                from './queries';
import { UploadTaskModalForm }   from './UploadTaskModalForm';
import {
  IUploadTask,
  addTaskFormSchema,
}                                from './validation';

interface IAlertDialogProps extends DialogProps {
  familyId?: string;
  task?: ITask;
  onClose: () => void;
}

export const UploadTaskModal: FC<IAlertDialogProps> = ({
  task,
  familyId,
  onClose,
  ...props
}) => {
  const initFiles = task?.attachments ? attachmentToFileShape(task.attachments) : [];
  const formikRef = useRef<FormikProps<IUploadTask>>(null);

  const [ files, setFiles ] = useState<IFileShape[]>(initFiles as IFileShape[]);
  const { mutate: uploadTask, isLoading: uploadingTask } = useUploadTask();
  const { mutate: updateTask, isLoading: updatingTask } = useUpdateTask();

  const initialValues: ITask = {
    id                 : task?.id,
    image              : task?.image,
    title              : task?.title || '',
    assigned           : task?.assigned || [],
    assignedPermission : task?.assignedPermission || EDocumentAccess.ViewAndEdit,
    location           : task?.location || '',
    description        : task?.description || '',
    tagged             : task?.tagged || [] as string[],
    familyId           : familyId as string,
    categoryId         : task?.category?.id || '',
    recurs             : task?.recurs || ERecurrence.NoRecurrence,
    recursInterval     : task?.recursInterval,
    recursDay          : task?.recursDay || null,
    recursOrdinal      : task?.recursOrdinal,
    recursWeekDays     : task?.recursWeekDays,
    recursCount        : task?.recursCount || null,
    recursStartDate    : task?.recursStartDate || '',
    recursEndDate      : task?.recursEndDate || '',
    recursStartTime    : task?.recursStartTime || '',
  };

  const upload = (values: ITask) => {
    if (task?.id) {
      const attachmentsId = files.filter(file => !!file.id).map(file => file.id) as string[];

      updateTask({
        data: {
          ...values,
          attachmentsId,
        },
        files: files.filter(fileShape => !fileShape.id),
      }, {
        onSuccess: onClose,
      });
    } else {
      uploadTask({
        data: values,
        files,
      }, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <StyledUploadDialog
      onClose={onClose}
      {...props}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        { task ? 'Edit' : 'Add' } Task
      </UploadDialogTitle>

      <UploadDialogContent>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={addTaskFormSchema}
          onSubmit={(values) => upload(values)}
        >
          { (props) => (
            <UploadTaskModalForm
              files={files}
              familyId={familyId}
              formikRef={formikRef}
              loading={uploadingTask || updatingTask}
              onChange={setFiles}
              {...props}
            />
          ) }
        </Formik>
      </UploadDialogContent>
    </StyledUploadDialog>
  );
};

UploadTaskModal.defaultProps = {
  disableBackdropClick: true,
};
