import { Badge, Button, Divider, Grid, IconButton } from '@material-ui/core';
import styled, { css }                              from 'styled-components';
import { theme }                                    from '../../styles';

export const TextBrand = styled.span<{ mb?: number; size?: string }>`
  display: block;
  font-size: ${ ({ size }) => size || '18px' };
  line-height: 22px;
  font-weight: 600;
  color: ${ theme.colors.warning };
  margin-bottom: 15px;
`;

export const TextCoupon = styled.span<{ mb?: number; size?: string }>`
  display: block;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: #76a578;
  margin-bottom: ${ ({ mb }) => mb || 0 }px;
  margin-top: -15px;
`;

export const ErrorTextMessage = styled.span<{ mb?: number; size?: string }>`
  display: block;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: ${ theme.colors.warning };
  margin-bottom: 10px;
  margin-top: -15px;
  text-align: justify;
`;

export const TextLabel = styled.div`
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: normal;
  color: #2c3f69;
  margin-bottom: 12px;
`;

export const TextBrandColor = styled.span<{ mb?: string }>`
  color: #e4572e;
  margin-bottom: ${ ({ mb }) => mb || 0 };
`;

export const StyledBadge = styled(Badge).attrs(() => ({ overlap: 'rectangular' }))`&& {
 .MuiBadge-badge {
    min-width         : 17px;
    height            : 17px;
    right             : 6px;
    top               : 6px;
    font-variant-caps : small-caps;
  }
}`;

export const ResponsivePageTitle = styled.div<{
  margin?: string;
  marginSm?: string;
  marginMd?: string;
}>`
  display: flex;
  color: #2c3f69;
  font-size: 20px;
  font-weight: 500;
  font-family: Jost;
  margin: ${ ({ margin }) => margin || '0 0 20px' };
  align-items: center;
  gap: 32px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: ${ ({ marginSm }) => marginSm || '10px 0 30px' };
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 28px;
    margin: ${ ({ marginMd }) => marginMd || '0 10px 30px' };
  }
`;

export const FormSpacing = styled.div<{ small?: boolean; disabled?: boolean }>`&& {
  padding: ${ ({ small }) => small ? 0 : 15 }px;
  opacity: ${ ({ disabled }) => disabled ? .5 : 1 };
  pointer-events: ${ ({ disabled }) => disabled ? 'none' : 'normal' };
  font-size: 14px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    padding: ${ ({ small }) => small ? 15 : 30 }px;
  }
}`;

export const StyledDivider = styled(Divider)<{ mb?: number; mt?: number }>`&& {
  margin-top: ${ ({ mt }) => mt !== undefined ? mt : 25 }px;
  margin-bottom: ${ ({ mb }) => mb !== undefined ? mb : 25 }px;
}`;

export const FormFooter = styled.div<{ hidden?: boolean }>`
  margin-top: 25px;
  padding: 26px 24px;
  margin: 0 -10px;
  background-color: #f9f9f9;
  overflow: hidden;
  box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.05);
  display: ${ ({ hidden }) => !hidden ? 'inherit' : 'none' };

  button {
    min-width: 150px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    margin-top: 75px;
  }
`;

export const TextInputWrapper = styled.div<{ hasLabel?: boolean; hasValue?: boolean; fullWidth?: boolean }>`&& {
  width: ${ ({ fullWidth }) => fullWidth ? '100%' : 'auto' };

  .MuiOutlinedInput-root {
    margin-top: ${ ({ hasLabel }) => hasLabel ? 30 : 0 }px;
    background: ${ ({ hasValue }) => hasValue ? '#fafafa' : '#e7efff' };
    border: 1px solid ${ ({ hasValue }) => hasValue ? '#e1e1e1' : 'transparent' }
  }
}`;

export const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0;
  padding: 11px 16px;
  min-height: 59px;
  height: auto;
  border-radius: 9px;
  background-color: #f9f9f9;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 1.3em;
  flex-wrap: wrap;
  position: relative;

  span {
    font-size: 12px;
    font-weight: 400;
  }

  a {
    font-size: 12px;
    font-weight: 400;
    color: #547cd3;
  }
`;

export const FlexVerticalCenterGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const FlexVerticalEndGrid = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;

export const FlexCenterGrid = styled(FlexVerticalCenterGrid)`
  justify-content: center;
`;

export const FlexEndGrid = styled(Grid)<{ align?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: ${ ({ align }) => align ? align : 'center' };
`;

export const JustifyGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageHeaderContainer = styled.div<{ hideBottomBorder?: boolean }>`
  display: flex;
  align-items: center;
  padding: 22px;
  border-bottom: ${ ({ hideBottomBorder }) => !hideBottomBorder && '1px solid #eee' };

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    padding: 22px 30px;
  }
`;

export const Pill = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  border-radius: 9px;
  text-transform: uppercase;
  font-size: 10px;
  font-family: 'JOST';
  font-weight: 600;
  letter-spacing: 0.25px;
  padding: 2px 10px;
  line-height: normal;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 12px;
  }
`;

export const CategoryPill = styled(Pill)`
  color: #fff;
  background-color: ${ ({ color }) => color || '#c4c4c4' };
`;

export const Flex = styled.div<{ align?: string; justify?: string; flex?: number }>`
  display: flex;
  width: 100%;
  flex: ${ ({ flex }) => flex };
  align-items: ${ ({ align }) => align };
  justify-content: ${ ({ justify }) => justify };
`;

export const CloseModalIcon = styled(IconButton)`&& {
  top      : 0;
  right    : 0;
  position : absolute;
  margin   : ${ ({ theme: { spacing } }) => spacing(1) }px;
  padding  : ${ ({ theme: { spacing } }) => spacing(1) }px;

  svg {
    width: 30px;
    height: 30px;
    color: #0000004f;
  }
}`;

export const DashedButton = styled.div`
  gap: 10px;
  height: 40px;
  display: flex;
  padding: 9px 18px;
  border-radius: 9px;
  align-items: center;
  justify-content: space-between;
  border: dashed 1px #6f90d9;
  cursor: pointer;
  color: #5071bb;
  font-weight: 700;
`;

export const DatePickerWrapper = styled(DashedButton)<{ empty?: boolean }>`
  font-weight: 400;
  border: none;
  color: #000;
  background-color: rgba(238, 238, 238, 0.47);

  ${ ({ empty }) => empty && css`
    color: #5071bb;
    font-weight: 700;
    background-color: #fff;
    border: dashed 1px #6f90d9;
    justify-content: flex-start;
  ` }
`;

export const DashedButtonIconLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

export const FormButton = styled(Button)`&& {
  border-radius: 9px;
  background-color: #547cd3;
  float: right;
  
  &:hover {
    background-color: #4b6ebb;
  }
}`;

export const ShiftedFormGroup = styled(Grid)`
  padding-left: 30px;
  padding-right: 0;
  width: 100%;
`;
