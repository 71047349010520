import { Button, Grid, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { FC }                                           from 'react';
import { NavLink }                                             from 'react-router-dom';
import styled                                                  from 'styled-components';
import { documentTypes }                                       from '../../../../shared/documentTypes';
import { useFetchActivityCounters }                            from '../../../Activity/queries';

const ActivitiesIconWrapper = styled.span`
  display: flex;
  margin-right: 15px;
`;

const DocumentsCount = styled.div`
  color: #5071bb;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  font-weight: 700;
  margin-right: 25px;
`;

const DocumentListItem = styled(Button)`&& {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0;
  padding: 10px 8px;
  text-transform: none;

  &:last-child {
    border-bottom: none;
  }
}`;

const GridContainer = styled(Grid)`&& {
  padding: 0 0 15px 0;
}`;

const GridItem = styled(Grid)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const MenuLink = styled(NavLink)<{ last?: number }>`&& {
  display: flex;
  margin: 0 -32px;
  width: calc(100% + 55px);

  button {
    padding: 10px 40px 0;

    .MuiButton-label {
      padding-bottom: 15px;
      border-bottom: ${ ({ last }) => last ? 'none' : '1px solid #e9e9e9' };
    }

  }

  .MuiTypography-root {
    font-size: 16px;
    letter-spacing: normal;
  }
}`;

interface IListItemProps {
  icon: JSX.Element;
  name: string;
  count?: number;
  to: string;
  last?: number;
}

const ListItem = ({ to, icon, name, count, last }: IListItemProps) => (
  <GridItem
    item
    xs={12}
    sm={4}
    md={12}
  >
    <MenuLink
      to={to}
      last={last}
      activeStyle={{ backgroundColor: '#eee' }}
    >
      <DocumentListItem>
        <ActivitiesIconWrapper>
          { icon }
        </ActivitiesIconWrapper>

        <ListItemText primary={name} />

        <ListItemSecondaryAction>
          <DocumentsCount>{ count }</DocumentsCount>
        </ListItemSecondaryAction>
      </DocumentListItem>
    </MenuLink>
  </GridItem>
);

interface IActivitiesMenuProps {
  familyId?: string;
  familyMemberId?: string;
}

export const ActivitiesMenu: FC<IActivitiesMenuProps> = ({ familyId, familyMemberId }) => {
  const { data: counters } = useFetchActivityCounters(familyId, familyMemberId);

  return (
    <GridContainer
      container
      spacing={4}
    >
      <ListItem
        count={counters?.documentsCount}
        to={documentTypes.documents.route}
        name={documentTypes.documents.name}
        icon={documentTypes.documents.icon}
      />

      <ListItem
        count={counters?.contactsCount}
        to={documentTypes.contacts.route}
        name={documentTypes.contacts.name}
        icon={documentTypes.contacts.icon}
      />

      <ListItem
        count={counters?.eventsCount}
        to={documentTypes.events.route}
        name={documentTypes.events.name}
        icon={documentTypes.events.icon}
      />

      <ListItem
        count={counters?.checkinCount}
        to={documentTypes.checkIns.route}
        name={documentTypes.checkIns.name}
        icon={documentTypes.checkIns.icon}
      />

      <ListItem
        count={counters?.taskCount}
        to={documentTypes.tasks.route}
        name={documentTypes.tasks.name}
        icon={documentTypes.tasks.icon}
      />
    </GridContainer>
  );
};
