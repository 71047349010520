import { DialogProps, LinearProgress, LinearProgressProps, Typography } from '@material-ui/core';
import React                                                            from 'react';
import styled                                                           from 'styled-components';
import { Modal }                                                        from '../Modal';

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
`;

const Progress = styled(LinearProgress)`
  width: 100%;
`;

const Value = styled(Typography)`
  min-width: 35px;
`;

interface ILoadingProgressProps extends DialogProps {
  value: number;

  onClose(): void;
}

export const LoadingProgress = (props: ILoadingProgressProps & LinearProgressProps) => {
  const percents = `${ Math.round(props.value) }%`;

  return (
    <Modal
      hideCloseIcon
      dialogWidth="350px"
      {...props}
    >
      <ProgressWrapper>
        <Progress variant="determinate" {...props} />

        <Value variant="body2">{ percents }</Value>
      </ProgressWrapper>
    </Modal>
  );
};
