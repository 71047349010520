import axios, { AxiosResponse }             from 'axios';
import qs                                   from 'qs';
import { useMutation }                      from 'react-query';
import { queryClient }                      from '../../../../App';
import { IErrorResponse, IRequestResponse } from '../../../../Auth/shared/interfaces';
import { ACTIVITIES, ACTIVITY_COUNTERS }    from '../../../../modules/Activity/queries';
import { EVENTS }                           from '../../../../modules/MyFamilyCore/events/queries';
import { SCHEDULE }                         from '../../../../modules/Schedule/queries';
import { handleApiErrorResponse }           from '../../../../utils/apiHelpers';
import { removeTimeZoneOffset }             from '../../../functions';
import { CATEGORIES }                       from '../../../queries/categories';
import { Notistack }                        from '../../Notistack/Notistack';
import { IRepeatOptions }                   from '../shared';
import { IAttachment }                      from '../UploadDocument/validation';

export interface IUploadEvent extends IRepeatOptions {
  id?: string;
  title: string;
  assigned: string[] | null;
  assignedPermission: string | null;
  tagged: string[] | null;
  description: string;
  familyId: string;
  categoryId: string;
  alertInterval: number;
  alarmRecurs: string;
  allDay: boolean;
  location: string;
  color: string;
  attachments?: IAttachment[];
  attachmentsId?: string[];
  offset?: number;
  eventExceptionDays?: string[];
  eventUpdatedDays?: string[];
}

export interface IUploadEventPayload {
  data: IUploadEvent;
  files?: File[] | null;
}

export const EVENT_UPLOAD = 'EVENT_UPLOAD';
export const EVENT_UPDATE = 'EVENT_UPDATE';

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const onSuccess = (message: string, invalidateTypes = true) => {
  invalidateTypes && queryClient.invalidateQueries([ CATEGORIES ]);
  Notistack.enqueueSnackbar(message, 'success');
};

const eventUploadQueryParams = (data: IUploadEvent) => qs.stringify(data, {
  serializeDate : (date) => removeTimeZoneOffset(date),
  skipNulls     : true,
});

const uploadEvent = (data: IUploadEvent, files: File[] | null) => {
  const queryParams = eventUploadQueryParams(data);
  const formData = new FormData();

  if (files) {
    files.forEach(file => formData.append('Attachments', file));
  }

  return axios.post(`/api/v1/events?${ queryParams }`, formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadEvent>>) => data)
    .catch(onCatch);
};

export const useUploadEvent = () => useMutation(
  EVENT_UPLOAD,
  ({
    data,
    files,
  }: { data: IUploadEvent; files: File[] | null }) => uploadEvent(data, files),
  {
    onSuccess: () => {
      onSuccess('A new event was successfully created.', false);
      queryClient.invalidateQueries([ EVENTS ]);
      queryClient.invalidateQueries([ SCHEDULE ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: handleApiErrorResponse,
  }
);

const updateEvent = (data: IUploadEvent, files?: File[] | null) => {
  const queryParams = eventUploadQueryParams(data);
  const formData = new FormData();

  if (files) {
    files.forEach(file => formData.append('Attachments', file));
  }

  return axios.put(`/api/v1/events?${ queryParams }`, files && formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadEvent>>) => data)
    .catch(onCatch);
};

export const useUpdateEvent = () => useMutation(
  EVENT_UPDATE,
  ({
    data,
    files,
  }: IUploadEventPayload) => updateEvent(data, files),
  {
    onSuccess: () => {
      onSuccess('Event was successfully updated.', false);
      queryClient.invalidateQueries([ EVENTS ]);
      queryClient.invalidateQueries([ SCHEDULE ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
    },
    onError: handleApiErrorResponse,
  }
);
