import axios, { AxiosResponse }                                 from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery, useMutation }                        from 'react-query';
import { queryClient }                                          from '../../../../App';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../../../Auth/shared/interfaces';
import { ACTIVITIES, ACTIVITY_COUNTERS }                        from '../../../../modules/Activity/queries';
import { handleApiErrorResponse }                               from '../../../../utils/apiHelpers';
import { CATEGORIES, ICategory }                                from '../../../queries/categories';
import { useCreateQuery }                                       from '../../../utils/hooks/useReactQuery';
import { IFamilyMember }                                        from '../../../utils/withAuthorization/withAuthorization';
import { Notistack }                                            from '../../Notistack/Notistack';

export interface IContact {
  id?: string;
  creationTime?: string;
  familyId?: string;
  title: string;
  firstName: string;
  lastName: string;
  categoryId: string;
  role: string;
  officeAddress: string;
  homeAddress: string;
  email: string;
  officeNumber: string;
  homeNumber: string;
  notes: string;
  tagged?: string[];

  category?: ICategory;
  creator?: IFamilyMember;
}

interface IDeleteContactRequest {
  id?: string;
  familyId?: string;
}

const CONTACT = 'CONTACT';
const CONTACTS = 'CONTACTS';
const CONTACT_UPLOAD = 'CONTACT_UPLOAD';
const CONTACT_UPDATE = 'CONTACT_UPDATE';
const CONTACT_DELETE = 'CONTACT_DELETE';

export const useFetchContactDetails = (
  id?: string,
) => useCreateQuery<IContact>({
  queryKey : [ CONTACT, id ],
  apiUrl   : `/api/v1/contacts/${ id }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!id,
  },
});

const loadContacts = (
  familyId?: string,
  familyMemberId?: string,
  role?: string,
  categoryId?: string,
  offset = 0,
  limit  = 5
) => {
  const params = {
    familyId,
    familyMemberId,
    role,
    categoryId,
    offset,
    limit,
  };

  return axios.get(`/api/v1/contacts/list?${ qs.stringify(params) }`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<IContact>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch(onCatch);
};

export const useLoadContactsPage = (
  familyId?: string,
  familyMemberId?: string,
  role?: string,
  categoryId?: string,
  limit?: number
) => useInfiniteQuery(
  [ CONTACTS, familyId, familyMemberId, role, categoryId ],
  ({ pageParam: offset }) => loadContacts(familyId, familyMemberId, role, categoryId, offset, limit),
  {
    enabled          : !!familyId,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const onSuccess = (message: string, invalidateTypes = true) => {
  invalidateTypes && queryClient.invalidateQueries([ CATEGORIES ]);
  Notistack.enqueueSnackbar(message, 'success');
};

const uploadContactBody = (data: IContact, familyId?: string) => ({
  familyId      : familyId || data.familyId,
  id            : data?.id || '',
  creationTime  : data?.creationTime || '',
  title         : data?.title || '',
  firstName     : data?.firstName || '',
  lastName      : data?.lastName || '',
  categoryId    : data?.categoryId || '',
  role          : data?.role || '',
  officeAddress : data?.officeAddress || '',
  homeAddress   : data?.homeAddress || '',
  email         : data?.email || '',
  officeNumber  : data?.officeNumber || '',
  homeNumber    : data?.homeNumber || '',
  notes         : data?.notes || '',
  tagged        : data?.tagged || [],
});

const uploadContact = (data: IContact) => axios.post('/api/v1/contacts', data)
  .then(({ data: { data } }: AxiosResponse<IRequestResponse<IContact>>) => data)
  .catch(onCatch);

export const useUploadContact = (familyId?: string) => useMutation(
  CONTACT_UPLOAD,
  (data: IContact) => uploadContact(uploadContactBody(data, familyId)),
  {
    onSuccess: () => {
      onSuccess('A new contact was successfully uploaded.', false);
      queryClient.invalidateQueries([ CONTACTS ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: handleApiErrorResponse,
  }
);

const updateContact = (data: IContact) => axios.put('/api/v1/contacts', data, { params: { id: data?.id } })
  .then(({ data: { data } }: AxiosResponse<IRequestResponse<IContact>>) => data)
  .catch(onCatch);

export const useUpdateContact = () => useMutation(
  CONTACT_UPDATE,
  (data: IContact) => updateContact(data),
  {
    onSuccess: () => {
      onSuccess('Contact was successfully updated.', false);
      queryClient.invalidateQueries([ CONTACT ]);
      queryClient.invalidateQueries([ CONTACTS ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
    },
    onError: handleApiErrorResponse,
  }
);

const deleteContact = (id?: string, familyId?: string) => axios.delete('/api/v1/contacts', {
  params: {
    id,
    familyId,
  },
})
  .then(({ data }: AxiosResponse<Blob>) => data)
  .catch(onCatch);

export const useDeleteContact = () => useMutation(
  CONTACT_DELETE,
  ({ id, familyId }: IDeleteContactRequest) => deleteContact(id, familyId),
  {
    retry     : 0,
    onSuccess : () => {
      onSuccess('Contact has been removed.', false);
      queryClient.invalidateQueries([ CONTACTS ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: handleApiErrorResponse,
  }
);
