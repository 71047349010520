import React                              from 'react';
import { Redirect, Route }                from 'react-router-dom';
import { EAppRoutes }                     from '../../../shared/constants/constants';
import { Authorized, EFamilyAccountType } from '../../../shared/utils/withAuthorization/withAuthorization';
import { AddMembers }                     from './AddMembers';

export const AddMembersRoutes = () => (
  <>
    <Route
      exact
      path=""
    >
      <Redirect to={`${ EAppRoutes.AddMembers }/by-invite`} />
    </Route>

    <Route
      path={`${ EAppRoutes.AddMembers }/:tab?`}
    >
      <Authorized
        redirect={EAppRoutes.Dashboard}
        accountTypes={[ EFamilyAccountType.SuperAdmin ]}
      >
        <AddMembers />
      </Authorized>
    </Route>
  </>
);
