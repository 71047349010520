import { useField }             from 'formik';
import React, { FC, useEffect } from 'react';
import styled, { css }          from 'styled-components';
import { RepeatOptionsLabel }   from '../RepeatOptions';

const Week = styled.div`
  display: flex;
  gap: 11px;
  margin: 0 0 20px;
`;

const WeekDay = styled.div<{ selected?: boolean }>`
  width: 26px;
  height: 26px;
  font-family: Lato;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px;
  border-radius: 100%;
  cursor: pointer;
 
  &:hover {
    opacity: .9;
  }
  
  ${ ({ selected }) => selected && css`
    color: #fff;
    background-color: #8fadf1;
  ` }
`;

interface ISelectWeekDayProps {
  name: string;
  multiple?: boolean;
}

export const weekDays = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ].map(i => i.toUpperCase());

export const SelectWeekDay: FC<ISelectWeekDayProps> = ({ name, multiple }) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [ field, meta, helpers ] = useField(name);

  const { value = [] }: { value: string[] } = meta;
  const { setValue } = helpers;

  const onSelect = (day: string) => {
    if (multiple && value) {
      const alreadySelected = value?.find((name: string) => name === day);

      if (alreadySelected) {
        setValue(value.filter((id: string) => id !== day));
      } else {
        setValue([ ...value, day ].filter(i => i.length > 1));
      }
    } else {
      setValue([ day ]);
    }
  };

  useEffect(() => () => {
    setValue([]);
  }, []);

  return (
    <>
      <RepeatOptionsLabel>On these days</RepeatOptionsLabel>

      <Week>
        { weekDays.map(day => (
          <WeekDay
            key={day}
            selected={value && !!value.length && value?.indexOf(day) >= 0}
            onClick={() => onSelect(day)}
          >
            { day[0] }
          </WeekDay>
        )) }
      </Week>
    </>
  );
};
