import { CircularProgress, Divider, Typography }                                  from '@material-ui/core';
import { AxiosResponse }                                                          from 'axios';
import qs                                                                         from 'qs';
import React, { FC, useEffect, useState }                                         from 'react';
import ReactInputVerificationCode                                                 from 'react-input-verification-code';
import { useHistory }                                                             from 'react-router-dom';
import styled, { css }                                                            from 'styled-components';
import { queryClient }                                                            from '../../App';
import { useAcceptInvitationRequest }                                             from '../../modules/Settings/components/Requests/queries';
import { CircularProgressWithLabel }                                              from '../../shared/components/progress/CircularWithLabel';
import { EAuthRoutes }                                                            from '../../shared/constants/constants';
import { queryParams }                                                            from '../../shared/functions';
import { IAuthResponse, IAuthResponseData }                                       from '../../shared/interfaces/auth';
import { TextBrandColor }                                                         from '../../shared/styledComponents';
import {
  setAuthorizationToken,
  setUserDetails,
}                                                                                 from '../../shared/utils/setAuthorizationToken';
import { Instructions, LogoSpaced, Page, PageHeader, PageHeaderText, SignInText } from '../shared/styles';
import { useResendEmail, useSendConfirmationEmail }                               from './queries';

const Resend = styled.a` && {
  float: right;
}`;

const Header = styled(PageHeader)`&& {
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin-bottom: 20px;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 32px;
  }
}`;

const CodeInstructions = styled(Instructions)`
  width: 100%;
  max-width: 470px;
  margin: 0 auto 35px;
  text-align: center;
  line-height: 24px;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    max-width: 600px;
    line-height: 32px;
    font-size: 20px;
  }
`;

const Loader = styled.div`
  color: #95a0b4;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    width: 26px;
    height: 26px;
    margin-right: 13px;
  }
`;

const ErrorMessage = styled(Typography)`
  color: #e42e2e;
`;

const MessageContainer = styled.div`
  min-height: 24px;
`;

const VerificationCodeWrapper = styled.div<{ error: boolean }>`&& {
  width: 300px;
  margin: auto;

  ${ ({ error }) => error && css`
    .ReactInputVerificationCode__item {
      box-shadow: inset 0 0 0 2px #e42e2e !important;
      background-color: #fff;
    }
  ` }
}`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Confirmation: FC = () => {
  const history = useHistory();
  const params = queryParams();
  const [ code, setCode ] = useState<string>('');
  const [ error, setError ] = useState<boolean>(false);
  const [ codeResent, setCodeResent ] = useState<boolean>(false);

  const isSignUpEmailConfirmation = window.location.href.includes(EAuthRoutes.EmailConfirmation);
  const isEmailChangeConfirmation = window.location.href.includes(EAuthRoutes.EmailChangeConfirmation);

  const { mutate: acceptInvitationRequest } = useAcceptInvitationRequest();
  const { mutate: sendConfirmation, isLoading: isSendingEmail } = useSendConfirmationEmail(params?.email, isSignUpEmailConfirmation, isEmailChangeConfirmation);
  const { mutate: resendEmail, isLoading: isResendingEmail } = useResendEmail(isEmailChangeConfirmation);

  const totalCountdown = 10;

  useEffect(() => {
    if (params?.code) {
      setCode(params.code as string);
    }
  }, [ params ]);

  const [ displayRedirectProgress, setRedirectProgress ] = useState(false);
  const [ progress, setProgress ] = React.useState(100);

  const startRedirectProcedure = () => {
    setRedirectProgress(true);
    const updateInterval = 100;

    const timer = setInterval(() => {
      const tick = (1000 / updateInterval) / totalCountdown;
      setProgress((prevProgress) => (prevProgress > 0 ? prevProgress - tick : 0));
    }, updateInterval);

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if (displayRedirectProgress && progress === 0) {
      history.push(EAuthRoutes.SignIn);
    }
  }, [ progress, displayRedirectProgress ]);

  const onTokenReceived = (data: IAuthResponseData) => {
    setUserDetails(data?.user);
    setAuthorizationToken(data?.tokens)
      .then(() => {
        if (isSignUpEmailConfirmation) {
          history.push({
            pathname : EAuthRoutes.ChangeSubscription,
            search   : qs.stringify({ familyName: params?.familyName }),
          });
        } else if (params?.familyUserId) {
          acceptInvitationRequest(params?.familyUserId as string);
        } else {
          window.location.href = '/';
        }
      });
  };

  const onSendEmail = (code: string) => sendConfirmation(code, {
    onSuccess : ({ data: { data } }: AxiosResponse<IAuthResponse>) => onTokenReceived(data),
    onError   : () => {
      const shouldRedirectToLogin = !isSignUpEmailConfirmation && params?.code;

      if (shouldRedirectToLogin) {
        startRedirectProcedure();
      }

      setError(true);
    },
  });

  const onResendEmail = () => resendEmail(params?.email, {
    onSuccess: () => {
      setCode('');
      setCodeResent(true);
    },
    onError: () => {
      startRedirectProcedure();
      setError(true);
    },
  });

  const onCodeChange = (value: string) => {
    setCode(value);
    setError(false);

    if (value.length === 6) {
      onSendEmail(value);
    } else {
      queryClient.invalidateQueries('confirmCode');
    }
  };

  const errorMessage = () => displayRedirectProgress ? (
    <ErrorContainer>
      <ErrorMessage
        align="center"
        variant="body1"
      >
        Looks like the link we sent is expired. You will be automatically redirected to the login page.
      </ErrorMessage>

      <CircularProgressWithLabel
        size={120}
        value={progress}
        unit="sec"
        scale={totalCountdown}
      />
    </ErrorContainer>
  ) : (
    <ErrorMessage
      align="center"
      variant="body1"
    >
      Invalid code please try again
    </ErrorMessage>
  );

  return (
    <Page>
      <Header>
        <LogoSpaced margin="0 0 40px" />

        <PageHeaderText centered>Check your <TextBrandColor>email</TextBrandColor> for a code.</PageHeaderText>
      </Header>

      <CodeInstructions mbXs="50px">
        We’ve sent a confirmation link to { params?.email }. The link expires shortly, so please use it soon.
      </CodeInstructions>

      <VerificationCodeWrapper error={error}>
        <Typography>Enter 6 code</Typography>

        <ReactInputVerificationCode
          length={6}
          placeholder={''}
          value={code}
          onChange={onCodeChange}
        />

        <MessageContainer>
          { (isSendingEmail || isResendingEmail) ? (
            <Loader>
              <CircularProgress size={24} />
              Processing
            </Loader>
          ) : error ? errorMessage() : codeResent && (
            <Typography
              align="center"
              variant="body1"
              color="primary"
            >
              A new code was sent to { params?.email }
            </Typography>
          ) }
        </MessageContainer>

        <Divider />

        <SignInText>
          <Resend onClick={onResendEmail}>Resend Code</Resend>
        </SignInText>
      </VerificationCodeWrapper>
    </Page>
  );
};
