import * as Yup from 'yup';

export interface ICreateChatRoomRequest {
  roomId?: string;
  initialMessage: string;
  senderMessageFamilyUserId?: string;
  roomName: string;
  familyUsers: string[];
}

export const createChatRoomSchema = Yup.object().shape({
  initialMessage : Yup.string(),
  roomName       : Yup.string()
    .required('Group Name is required'),
});
