import { Badge, Box, ListItem }                         from '@material-ui/core';
import { FC, useContext }                               from 'react';
import styled, { css }                                  from 'styled-components';
import { firstArrayItems }                              from '../../../shared/functions';
import { StyledBadge }                                  from '../../../shared/styledComponents';
import { addSpaceBeforeCapitals, datePassed, fullName } from '../../../shared/utils/commonFunctions';
import AuthorizationContext                             from '../../../shared/utils/withAuthorization/authorizationContext';
import { FamilyMembersList }                            from '../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { SubLabel }                                     from '../Message';
import { IChat }                                        from '../queries';

export const UserMessagesBadge = styled(StyledBadge)`
 .MuiBadge-badge {
    position: relative;
    top: 15px;
    right: 15px;
 }
`;

const StyledListItem = styled(ListItem)<{ unread?: number }>`&& {
  gap: 15px;
  padding: 10px 40px 10px 18px;
  align-items: center;
  height: 64px;
  align-items: center;
  position: relative;

  ${ ({ unread }) => unread && unread > 0 && css`
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 13px;
      height: 13px;
      flex-grow: 0;
      border-radius: 20px;
      background-color: #5071bb;
    }
  ` }
}
`;

const MessagePreview = styled.div`
  color: #5072bb;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2px;
`;

const RecordWrapper = styled.div`
  overflow: hidden;
`;

const ChatRoomsWrapper = styled.div<{ fullWidth?: boolean }>`
  padding: 9px 0 15px 0;
  max-height: 320px;
  overflow: auto;
  width: 100%;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    max-height: calc(100% - 39px);
  }

  ${ ({ fullWidth }) => !fullWidth && css`
    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      max-width: 280px;
    }
  ` }
`;

const ChatName = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #272932;
  font-size: 14px;
  line-height: 16px;
`;

const ChatPreview = styled(SubLabel)`
  flex-wrap: wrap;
`;

interface IChatRoomsProps {
  chatRooms?: IChat[];
  selected?: string;
  fullWidth?: boolean;

  onSelect?(id: string): void;
}

export const ChatRooms: FC<IChatRoomsProps> = ({
  chatRooms,
  selected,
  onSelect,
  fullWidth,
}) => {
  const currentUser = useContext(AuthorizationContext);

  const generateChatName = (room: IChat) => {
    const roomMembers = room.users
      .filter(user => user.id !== currentUser?.data?.id)
      .map(user => fullName(user.firstName, user.lastName))
      .join(', ');

    return room?.name || roomMembers;
  };

  const badge = (count = 0) => (
    <UserMessagesBadge
      color="secondary"
      badgeContent={count > 0 ? count : 0}
    />
  );

  return (
    <ChatRoomsWrapper fullWidth={fullWidth}>
      { chatRooms?.map((room) => {
        const users = room.users.filter(user => user.id !== currentUser?.data?.id);

        if (!users.length && currentUser?.data) {
          users.push(currentUser?.data);
        }

        return (
          <StyledListItem
            key={room.id}
            button
            selected={selected === room.id}
            alignItems="flex-start"
            unread={room.unreadMessagesCount}
            onClick={() => onSelect?.(room.id)}
          >
            <Badge
              overlap="rectangular"
              badgeContent={badge(room.unreadMessagesCount)}
              aria-controls="user-menu"
              aria-haspopup="true"
            >
              <FamilyMembersList
                noWrap
                shifted
                hideAllSelection
                data={firstArrayItems(2, users)}
              />
            </Badge>

            <RecordWrapper>
              <ChatName>
                <b>{ generateChatName(room) }</b>
              </ChatName>

              <Box height={5} />

              <ChatPreview whiteSpace="nowrap">
                { room?.messages?.length ? (
                  <>
                    <MessagePreview>{ room.messages[0].text }</MessagePreview> • { datePassed(room.messages[0].timestamp, true) }
                  </>
                ) : addSpaceBeforeCapitals(currentUser?.data?.familyRole) }
              </ChatPreview>
            </RecordWrapper>
          </StyledListItem>
        );
      }) }
    </ChatRoomsWrapper>
  );
};
