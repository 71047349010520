import { Button, Grid }                                    from '@material-ui/core';
import AccessTimeIcon                                      from '@material-ui/icons/AccessTime';
import { FastField, Form, Formik, FormikProps }            from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Prompt, useHistory, useLocation }                 from 'react-router-dom';
import styled                                              from 'styled-components';
import { PageHeader }                                      from '../../../../../Auth/shared/styles';
import { VerifiedIconWrapper }                             from '../../../../../Auth/shared/styles';
import { IFamily }                                         from '../../../../../Auth/shared/interfaces';
import { GridDivider }                                     from '../../../../../shared/components/layout/GridDivider';
import { AlertDialog }                                     from '../../../../../shared/components/modals/AlertDialog';

import {
  useFetchSelectedFamily,
  useUpdateSelectedFamily,
}                                                          from '../../../../../shared/components/SelectFamily/queries';
import {
  EAccountStatus,
  EAppRoutes,
}                                                          from '../../../../../shared/constants/constants';
import { Loader }                                          from '../../../../../shared/enhancers/withLoader';
import { SelectField }                                     from '../../../../../shared/formFields/SelectField';
import { TextInputField }                                  from '../../../../../shared/formFields/TextInputField';
import { isSuperAdmin }                                    from '../../../../../shared/functions';
import {
  familyRoles,
  useFetchFamilies,
}                                                          from '../../../../../shared/queries/family';
import {
  FlexCenterGrid,
  FormSpacing,
  PageHeaderContainer,
}                                                          from '../../../../../shared/styledComponents';
import {
  fullName,
  generateInitials,
}                                                          from '../../../../../shared/utils/commonFunctions';
import { logout }                                          from '../../../../../shared/utils/setAuthorizationToken';
import {
  Authorized,
  EFamilyAccountType,
  IFamilyMember,
  familyAccountTypes,
}                                                          from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMemberCircle }                              from '../../../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { DeleteUserDialog }                                from '../../../shared/DeleteUserDialog';
import { FormFooterActions }                               from '../../../shared/FormFooterActions';
import { SettingsHeader }                                  from '../../../shared/styles';
import {
  IFamilyUserAccount,
  useCancelInvitationRequest,
  useDeleteUserAccount,
  useFetchUserAccount,
  useUpdateUserAccount,
}                                                          from './queries';
import { userAccountValidationSchema }                     from './validation';

interface IMyProfileFormProps {
  memberId?: string;
  familyMembers?: IFamilyMember[];

  userDataChanged: () => void;
}

const ResponsiveButton = styled(Button)<{ width?: string }>`&& {
  width: 100%;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    max-width: 100%;
    width: ${ ({ width }) => !width ? '330px' : width };
  }
}`;

const PendingRequestLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 17px 0 0;
  font-style: italic;
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 700;
  opacity: .52;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridContainer = styled(Grid)`
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 5px 0 0 33px;
  }
`;

const AvatarWrapper = styled.div`
  margin: 15px 0 45px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 0;
  }
`;

const AccountHeaderSpacing = styled.div`
  margin-top: 20px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;

  &.div {
    background-color: #6f90d9;
  }
`;

export const AccountForm: FC<IMyProfileFormProps> = ({
  memberId,
  familyMembers,
  userDataChanged,
}) => {
  const { data: family } = useFetchSelectedFamily();
  const { data: families } = useFetchFamilies();
  const { data: userAccount, isLoading: loadingUserAccount } = useFetchUserAccount(family?.id, memberId);

  const { mutate: updateUserAccount } = useUpdateUserAccount(memberId, family?.id);
  const { mutate: cancelInvitation } = useCancelInvitationRequest();
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily();
  const { mutate: deleteUserAccount, isSuccess: deleteUserSuccess } = useDeleteUserAccount(memberId, family?.id);

  const formikRef = useRef<FormikProps<IFamilyUserAccount>>(null);
  const [ closeAccountDialogOpen, setCloseAccountDialogOpen ] = useState(false);
  const [ cancelInvitationDialogOpen, setCancelInvitationDialogOpen ] = useState(false);
  const [ deletedUserId, setDeletedUserId ] = useState<string>();
  const [ actualFormValues, setActualFormValues ] = useState<IFamilyUserAccount>(userAccount as IFamilyUserAccount);
  const history = useHistory();
  const { pathname } = useLocation();

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === family?.id);

  const updateProfile = (formValues: IFamilyUserAccount) => {
    const { familyRole, accountType } = formValues;
    updateUserAccount({
      familyRole,
      accountType,
    }, {
      onSuccess: () => {
        setActualFormValues(formValues);
        userDataChanged();
      },
    });
  };

  const selectNextFamilyMember = () => {
    // Selecting an id of the next member to open in the 'Manage Family' view.
    let nextMemberId = familyMembers?.[0]?.id;

    familyMembers?.forEach((member, index, array) => {
      if (member.id === memberId) {
        const nextIndex = index === array.length - 1 ? index - 1 : index + 1;
        nextMemberId = array[nextIndex]?.id;
      }
    });

    history.push(`${ EAppRoutes.ManageUsersAccount }?id=${ nextMemberId }`);
  };

  const onCloseAccountSubmit = () => {
    setDeletedUserId(memberId);
    deleteUserAccount();
    setCloseAccountDialogOpen(false);
    selectNextFamilyMember();
  };

  const onCancelInviteDialogSubmit = () => {
    cancelInvitation(memberId);
    setCancelInvitationDialogOpen(false);
    selectNextFamilyMember();
  };

  const isPendingInvitation = (status: string) => status === EAccountStatus.Pending;

  useEffect(() => {
    setActualFormValues(userAccount as IFamilyUserAccount);
  }, [ userAccount ]);

  useEffect(() => {
    if (deleteUserSuccess && !familyMembers?.find(member => member.id !== memberId)) {
      if (families && families.length > 1) {
        const newFamily = families.filter(iteratedFamily => iteratedFamily.id !== family?.id);
        updateSelectedFamily(newFamily[0]?.id);
        history.push(EAppRoutes.MyProfile);
      } else if (memberId === deletedUserId) {
        logout();
      }
    }
  }, [ deleteUserSuccess ]);

  const FormContent = ({ dirty, values, handleSubmit }: FormikProps<any>) => useMemo(() => (
    <Form onSubmit={handleSubmit}>
      <PageHeaderContainer>
        <SettingsHeader variant="h3">Account</SettingsHeader>
      </PageHeaderContainer>

      <FormSpacing>
        { loadingUserAccount ? <Loader /> : (
          <PageLayout>
            <AvatarWrapper>
              <FamilyMemberCircle
                width={'120px'}
                fontSize="32px"
                color={values?.color}
                image={values?.status === EAccountStatus.Pending ? undefined : values?.avatar?.compactPath}
                pending={isPendingInvitation(values?.status)}
                initials={generateInitials(values?.firstName, values?.lastName)}
              />
            </AvatarWrapper>

            <GridContainer
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <AccountHeaderSpacing>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={8}
                    >
                      <PageHeaderWrapper>
                        <PageHeader
                          align="flex-start"
                          mb="5px"
                          mbMd="5px"
                          margin="0"
                        >
                          { fullName(values?.firstName, values?.lastName) }
                        </PageHeader>
                        { matchingFamily?.isActivePayment && <VerifiedIconWrapper /> }
                      </PageHeaderWrapper>

                      { isPendingInvitation(values?.status) && (
                        <PendingRequestLabel>
                          <AccessTimeIcon />

                          Pending Request
                        </PendingRequestLabel>
                      ) }
                    </Grid>

                    { isPendingInvitation(values?.status) && (
                      <FlexCenterGrid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                      >
                        <ResponsiveButton
                          size="medium"
                          color="primary"
                          variant="contained"
                          onClick={() => setCancelInvitationDialogOpen(true)}
                        >
                          Cancel Request
                        </ResponsiveButton>
                      </FlexCenterGrid>
                    ) }
                  </Grid>
                </AccountHeaderSpacing>
              </Grid>

              <GridDivider margin="0 0 5px" />

              { values?.hasEmail && <Grid
                item
                xs={12}
                md={5}
              >
                { isSuperAdmin(values?.accountType) ? (
                  <FastField
                    disabled
                    label="Member Type"
                    name={'accountType'}
                    value={'Super Admin'}
                    component={TextInputField}
                  />
                ) : (
                  <FastField
                    label="Member Type"
                    name={'accountType'}
                    options={familyAccountTypes}
                    component={SelectField}
                  />
                ) }
              </Grid> }

              <Grid
                item
                xs={12}
                md={5}
              >
                <FastField
                  label="Family Role"
                  name={'familyRole'}
                  options={familyRoles}
                  component={SelectField}
                />
              </Grid>

              <Authorized accountTypes={[ EFamilyAccountType.Admin, EFamilyAccountType.SuperAdmin ]}>
                { !isSuperAdmin(values?.accountType) && !isPendingInvitation(values?.status) && (
                  <>
                    <GridDivider margin="0 0 5px" />

                    <Grid
                      item
                      xs={12}
                    >
                      <ResponsiveButton
                        color="primary"
                        variant="outlined"
                        width="200px"
                        onClick={() => setCloseAccountDialogOpen(true)}
                      >
                        Close User Account
                      </ResponsiveButton>
                    </Grid>
                  </>
                ) }
              </Authorized>
            </GridContainer>
          </PageLayout>
        ) }
      </FormSpacing>

      <AlertDialog
        dialogWidth="350px"
        open={cancelInvitationDialogOpen}
        title="Are you sure you want to cancel this invite request?"
        content={`Are you sure you want to cancel invitation for ${ values?.firstName } ${ values?.lastName } to join ${ family?.name } family?`}
        handleSubmit={onCancelInviteDialogSubmit}
        handleCancel={() => setCancelInvitationDialogOpen(false)}
      />

      { closeAccountDialogOpen &&
      <DeleteUserDialog
        handleSubmit={onCloseAccountSubmit}
        user={userAccount}
        onClose={() => setCloseAccountDialogOpen(false)}
      />}

      <FormFooterActions
        dirty={dirty}
        isLoading={false}
        formikRef={formikRef}
      />

      <Prompt
        when={dirty}
        message={`Are you sure you want to leave this page?
Unsaved changes on the Manage Family tab would be lost.`}
      />
    </Form>
  ), [
    dirty,
    memberId,
    values?.color,
    pathname,
    closeAccountDialogOpen,
    cancelInvitationDialogOpen,
  ]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={actualFormValues}
      validationSchema={userAccountValidationSchema}
      onSubmit={updateProfile}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
