import React, { FC, useMemo }         from 'react';
import { useLocation }                from 'react-router-dom';
import { useFetchSelectedFamily }     from '../../../shared/components/SelectFamily/queries';
import { Loader }                     from '../../../shared/enhancers/withLoader';
import { useFetchCurrentUserProfile } from '../../../shared/queries/user';
import { IFamilyMember }              from '../../../shared/utils/withAuthorization/withAuthorization';
import { SettingsPageWrapper }        from './SettingsPageWrapper';
import { WithAvatarPicker }           from './withAvatarPicker';

export interface ISettingsPageWithAvatar {
  familyMember?: IFamilyMember;
  loading?: boolean;
  clear?: boolean;
}

export const SettingsPageWithAvatar: FC<ISettingsPageWithAvatar> = ({
  familyMember,
  children,
  loading,
  clear,
}) => {
  const { data: family } = useFetchSelectedFamily();
  const { data: currentUser, isLoading } = useFetchCurrentUserProfile(family?.id, !familyMember);
  const { pathname } = useLocation();

  const WithAvatar = WithAvatarPicker(({
    avatarPicker: AvatarPicker,
    userDetails : UserDetails,
  }) => {
    const Sidebar = () => (
      <>
        <AvatarPicker color={familyMember?.color || currentUser?.color} />
        <UserDetails />
      </>
    );

    return loading ? <Loader /> : (
      <SettingsPageWrapper
        clear={clear}
        sidebar={Sidebar}
      >
        { children }
      </SettingsPageWrapper>
    );
  });

  // eslint-disable-next-line react/display-name
  return useMemo(() =>
    <WithAvatar currentUser={familyMember || currentUser} />
  , [ pathname, clear, isLoading, familyMember ]);
};
