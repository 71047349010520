import { Grid }                                            from '@material-ui/core';
import { FastField, Form, Formik, FormikProps }            from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Prompt }                                          from 'react-router-dom';
import { FormExpansionPanel }                              from '../../../../../shared/components/ExpansionPanel';
import { EGender, bloodTypes }                             from '../../../../../shared/constants/constants';
import { states }                                          from '../../../../../shared/constants/states';
import { countries }                                       from '../../../../../shared/countryCodes';
import { PhoneInputField }                                 from '../../../../../shared/formFields/PhoneInputField';
import { SelectDateField }                                 from '../../../../../shared/formFields/SelectDateField';
import { SelectField }                                     from '../../../../../shared/formFields/SelectField';
import { TextInputField }                                  from '../../../../../shared/formFields/TextInputField';
import { ZipCodeInputField }                               from '../../../../../shared/formFields/ZipCodeInputField';
import { FormSpacing }                                     from '../../../../../shared/styledComponents';
import { FormFooterActions }                               from '../../../shared/FormFooterActions';
import { IAboutUserForm, aboutMeValidationSchema }         from './validation';

interface IAboutMeFormProps {
  data?: IAboutUserForm;
  isLoading: boolean;
  isDisabled?: boolean;
  onUpdate?: (formValues: IAboutUserForm, options?: any) => void;
}

export const AboutMeForm: FC<IAboutMeFormProps> = ({ data, onUpdate, isLoading, isDisabled }) => {
  const formikRef = useRef<FormikProps<IAboutUserForm>>(null);
  const [ actualFormValues, setActualFormValues ] = useState<IAboutUserForm>({ ...data } as IAboutUserForm);

  const submitForm = (formValues: IAboutUserForm) => {
    onUpdate && onUpdate(formValues, {
      onSuccess: () => {
        if (formValues) {
          setActualFormValues(formValues);
        }
      },
    });
  };

  useEffect(() => {
    if (data) {
      setActualFormValues(data);
    }
  }, [ data ]);

  const FormContent = ({ values, dirty, handleChange }: FormikProps<IAboutUserForm>) => useMemo(() => (<Form>
    <fieldset
      key={isDisabled as any}
      disabled={isDisabled}
    >
      <FormSpacing>
        <FormExpansionPanel title="Basic Info">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Nickname"
              name={'nickname'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Middle Name"
              name={'middleName'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Contact Email"
              name={'contactEmail'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Phone Number"
              name={'phoneNumber'}
              component={PhoneInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Date of Birth"
              name={'dateOfBirth'}
              maxDate={new Date()}
              disabled={isDisabled}
              component={SelectDateField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Gender"
              name={'gender'}
              options={[ EGender.Male, EGender.Female ]}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Address"
              name={'address'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Address 2"
              name={'address2'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="City"
              name={'city'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="State"
              name={'state'}
              options={states}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Zip"
              name={'zip'}
              component={ZipCodeInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Country"
              name={'country'}
              options={countries}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Attributes">
          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Height"
              placeholder="4’11"
              name={'height'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Weight"
              placeholder="110"
              name={'weight'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Hair Color"
              placeholder="Dark Brown"
              name={'hairColor'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FastField
              label="Eye Color"
              placeholder="Dark Brown"
              name={'eyeColor'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Sizes">
          <Grid
            item
            xs={6}
            sm={2}
          >
            <FastField
              label="Shirt"
              placeholder="6"
              name={'shirt'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={2}
          >
            <FastField
              label="Pants"
              placeholder="7"
              name={'pants'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={2}
          >
            <FastField
              label="Shoes/ Sneakers"
              placeholder="8"
              name={'shoes'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={2}
          >
            <FastField
              label="Dress"
              placeholder="6"
              name={'dress'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          { values.gender === EGender.Female && (
            <Grid
              item
              xs={6}
              sm={2}
            >
              <FastField
                label="Bra"
                name={'bra'}
                inputProps={{ maxLength: 100 }}
                component={TextInputField}
              />
            </Grid>
          ) }

          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              name={'sizeNotes'}
              label="Notes"
              inputProps={{ maxLength: 400 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="School">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="School Name"
              name={'schoolName'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Address"
              name={'schoolAddress'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Address 2"
              name={'schoolAddress2'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            sm={2}
          >
            <FastField
              label="City"
              name={'schoolCity'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            sm={2}
          >
            <FastField
              label="State"
              name={'schoolState'}
              options={states}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>

          <Grid
            item
            sm={2}
          >
            <FastField
              label="Zip"
              name={'schoolZip'}
              component={ZipCodeInputField}
            />
          </Grid>

          <Grid
            item
            sm={4}
          >
            <FastField
              label="School Level"
              placeholder="Undergraduate"
              name={'schoolLevel'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Medical">
          <Grid
            item
            sm={4}
          >
            <FastField
              label="Blood Type"
              name={'bloodType'}
              options={bloodTypes}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              label="Allergies"
              name={'allergies'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'medicalNote'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Identification">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Country of Citizenship"
              name={'citizenship'}
              options={countries}
              disabled={isDisabled}
              component={SelectField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Likes">
          <Grid
            item
            xs={12}
          >
            <FastField
              label="Favorite Foods"
              name={'favoriteFoods'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              label="Favorite Colors"
              name={'favoriteColors'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              label="Favorite Activities"
              name={'favoriteActivities'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'favoriteNote'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Dislikes">
          <Grid
            item
            xs={12}
          >
            <FastField
              label="Add Foods"
              name={'dislikeFoods'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'dislikeNote'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel title="Wishlist">
          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'wishlist'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>

        <FormExpansionPanel
          last
          title="Additional Notes"
        >
          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'adminNote'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>
        </FormExpansionPanel>
      </FormSpacing>
    </fieldset>

    <FormFooterActions
      dirty={dirty}
      isLoading={isLoading}
      isDisabled={isDisabled}
      formikRef={formikRef}
    />
    <Prompt
      when={dirty}
      message={`Are you sure you want to leave this page?
        Unsaved changes in the Customer Info would be lost.`}
    />
  </Form>), [ values.gender, dirty, isLoading, isDisabled ]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={actualFormValues}
      validationSchema={aboutMeValidationSchema}
      onSubmit={submitForm}
    >
      { (props) => <FormContent {...props} /> }
    </Formik>
  );
};
