import axios                            from 'axios';
import { useMutation }                  from 'react-query';
import { queryClient }                  from '../../App';
import { IErrorResponse }               from '../../Auth/shared/interfaces';
import { Notistack }                    from '../../shared/components/Notistack/Notistack';
import { useCreateQuery }               from '../../shared/utils/hooks/useReactQuery';
import { handleApiErrorResponse }       from '../../utils/apiHelpers';
import { INotificationPreferencesForm } from './NotificationPreferences';

const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';
const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';

export const useFetchNotificationPreferences = (familyUserId?: string) => useCreateQuery<INotificationPreferencesForm>({
  queryKey : [ NOTIFICATION_SETTINGS ],
  apiUrl   : `/api/v1/notification-settings/${ familyUserId }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyUserId,
  },
});

const updateNotificationPreferences = (data: INotificationPreferencesForm, familyUserId?: string) => axios.post(`/api/v1/notification-settings/${ familyUserId }`, data)
  .then(() => {
    queryClient.invalidateQueries([ NOTIFICATION_SETTINGS ]);
    return data;
  })
  .catch((error: IErrorResponse) => {
    throw error;
  });

export const useUpdateNotificationPreferences = (familyUserId?: string) => useMutation(
  UPDATE_NOTIFICATION_SETTINGS,
  (data: INotificationPreferencesForm) => updateNotificationPreferences(data, familyUserId),
  {
    onSuccess: () => {
      Notistack.enqueueSnackbar('Preferences were successfully updated.', 'success', false);
      queryClient.invalidateQueries([ NOTIFICATION_SETTINGS, familyUserId ]);
    },
  }
);
