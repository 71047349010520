import { Grid }                               from '@material-ui/core';
import React, { FC, useEffect }               from 'react';
import styled                                 from 'styled-components';
import { theme }                              from '../../../styles';
import { Layout }                             from '../../Layout/Layout';
import { FullWidthGrid }                      from '../../shared/components';
import { IRouterDrivenTab, RouterDrivenTabs } from '../../shared/components/RouterDrivenTabs';
import { useFetchSelectedFamily }             from '../../shared/components/SelectFamily/queries';
import { SelectFamily }                       from '../../shared/components/SelectFamily/SelectFamily';
import {
  useFetchCurrentUserProfile,
  useUpdateCurrentUserProfile }               from '../../shared/queries/user';
import { ResponsivePageTitle }                from '../../shared/styledComponents';
import { useFetchFamilies }                   from '../../shared/queries/family';
import { adminRoles, memberRole }             from '../../shared/utils/withAuthorization/withAuthorization';
import { ManageFamilyAccount }                from './components/ManageFamilyAccount/ManageFamilyAccount';
import { ManageMemberAccount }                from './components/ManageMemberAccount/ManageMemberAccount';
import { ManageUsers }                        from './components/ManageUsers/ManageUsers';
import { Profile }                            from './components/Profile/Profile';
import { Requests }                           from './components/Requests/Requests';

export const SettingsPageStyleWrapper = styled.div`&& {
  width: 100%;
  height: calc(100% - 70px);

  .MuiTabs-root {
    margin-bottom: 0;
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    height: calc(100% - 48px);

    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }
}`;

export const PageTitle = styled.span`&& {
  font-family: Jost;
  font-size: 28px;
  font-weight: 500;
}`;

export const SettingsPageContent = styled(FullWidthGrid)`
  height: 100%;
`;

const baseUrl = '/settings';

export const Settings: FC = () => {
  const { data: selectedFamily, isLoading: loadingFamily } = useFetchSelectedFamily();
  const { data: userProfile, isLoading: loadingProfile, refetch } = useFetchCurrentUserProfile(selectedFamily?.id);
  const { mutate: updateUserProfile, isLoading: isUpdatingUserProfile } = useUpdateCurrentUserProfile();
  const { data: families } = useFetchFamilies();

  useEffect(() => {
    if (isUpdatingUserProfile) {

      return () => {
        refetch();
      };
    }
  }, [isUpdatingUserProfile]);

  const MyProfileTab = (
    <Profile
      currentUser={userProfile}
      families={families}
      selectedFamily={selectedFamily}
      updateUserProfile={updateUserProfile}
      isUpdatingUserProfile={isUpdatingUserProfile}
      selectedFamilyId={selectedFamily?.id}
    />
  );

  const ManageAccountTab = (
    <ManageFamilyAccount />
  );

  const ManageMemberAccountTab = (
    <ManageMemberAccount />
  );

  const ManageUsersTab = (
    <ManageUsers />
  );

  const RequestsTab = (
    <Requests />
  );

  const tabs: IRouterDrivenTab[] = [ {
    label     : 'MY PROFILE',
    link      : 'my-profile',
    color     : theme.colors.attention,
    component : MyProfileTab,
  }, {
    label     : 'MANAGE ACCOUNT',
    link      : 'manage-account',
    color     : theme.colors.attention,
    roles     : adminRoles,
    component : ManageAccountTab,
  }, {
    label     : 'MANAGE ACCOUNT',
    link      : 'member-account',
    color     : theme.colors.attention,
    roles     : memberRole,
    component : ManageMemberAccountTab,
  }, {
    label     : 'MANAGE FAMILY',
    link      : 'manage-family',
    color     : theme.colors.attention,
    roles     : adminRoles,
    component : ManageUsersTab,
  }, {
    label     : 'REQUESTS',
    link      : 'requests',
    color     : theme.colors.attention,
    roles     : adminRoles,
    component : RequestsTab,
  }, {
    label     : 'REQUESTS',
    link      : 'requests',
    color     : theme.colors.attention,
    roles     : memberRole,
    component : RequestsTab,
  } ];

  const SettingsPage = (
    <SettingsPageStyleWrapper>
      <ResponsivePageTitle>
        <PageTitle>Settings</PageTitle>

        <SelectFamily />
      </ResponsivePageTitle>

      <SettingsPageContent
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
        >
          <RouterDrivenTabs
            baseURL={baseUrl}
            tabs={tabs}
          />
        </Grid>
      </SettingsPageContent>
    </SettingsPageStyleWrapper>
  );

  return (
    <Layout loading={loadingFamily || loadingProfile}>
      { SettingsPage }
    </Layout>
  );
};
