import { IconButton }                                            from '@material-ui/core';
import { makeStyles }                                            from '@material-ui/core/styles';
import { Close }                                                 from '@material-ui/icons';
import { SnackbarKey, SnackbarProvider, SnackbarProviderProps } from 'notistack';
import React, { FC, useRef }                                     from 'react';

const MAX_SNACK = 3;

export const useSnackbarStyles = makeStyles(
  ({ palette }) => ({
    root: {
      top   : 10,
      right : 20,
    },
    contentRoot: {
      backgroundColor: '#fff !important',
    },
    variantSuccess: {
      backgroundColor: '#0dc695 !important',
    },
    variantError: {
      backgroundColor: `${ palette.error.dark } !important`,
    },
    variantInfo: {
      backgroundColor: `${ palette.info.main } !important`,
    },
    variantWarning: {
      backgroundColor: `${ palette.warning.main } !important`,
    },
  })
);

export const NotistackProvider: FC<SnackbarProviderProps> = (props) => {
  const notistackRef = useRef<SnackbarProvider>(null);
  const classes = useSnackbarStyles();
  const portal = document.querySelector('body') as HTMLElement;

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      classes={classes}
      maxSnack={MAX_SNACK}
      domRoot={portal}
      action={(key) => (
        <IconButton
          size="small"
          onClick={onClickDismiss(key)}
        >
          <Close htmlColor="#fff" />
        </IconButton>
      )}
      {...props}
    />
  );
};
