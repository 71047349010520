import axios, { AxiosResponse }                                 from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery }                                     from 'react-query';
import { queryClient }                                          from '../../App';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../Auth/shared/interfaces';
import { useCreateQuery }                                       from '../../shared/utils/hooks/useReactQuery';
import { IFamilyMember }                                        from '../../shared/utils/withAuthorization/withAuthorization';

export interface IActivity {
  id: string;
  date: string;
  tagged: string[];
  message: string;
  isDeleted: boolean;
  familyUser: IFamilyMember;
  actionType: string;
  entityType: string;
}

export interface IActivityCounters {
  taskCount: number;
  eventsCount: number;
  checkinCount: number;
  contactsCount: number;
  documentsCount: number;
}

export const ACTIVITIES = 'ACTIVITIES';
export const ACTIVITY_COUNTERS = 'ACTIVITY_COUNTERS';

const onCatch = (error: IErrorResponse) => {
  throw error;
};

export const useFetchActivityCounters = (
  familyId?: string,
  familyUserId?: string,
) => useCreateQuery<IActivityCounters>({
  queryKey : [ ACTIVITY_COUNTERS, familyId, familyUserId ],
  apiUrl   : `/api/v1/Activities/count?${ qs.stringify({ familyId, familyUserId }) }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId,
  },
});

const loadActivities = (
  familyId?: string,
  familyUserId?: string,
  familyItemType?: string,
  offset = 0,
  limit = 5
) => {

  const currentLimit = offset === 0 ? 10 : limit;

  const params = {
    familyId,
    familyUserId,
    familyItemType,
    offset,
    limit: currentLimit,
  };

  return axios.get(`/api/v1/activities?${qs.stringify(params)}`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<IActivity>>>) => ({
      items,
      totalCount,
      next: offset + currentLimit,
    }))
    .catch(onCatch);
};

export const useLoadActivitiesPage = (
  familyId?: string,
  familyUserId?: string,
  familyItemType?: string,
  offset?: number,
  limit = 5
) => useInfiniteQuery(
  [ACTIVITIES, familyId, familyUserId, familyItemType],
  ({ pageParam = 0 }) => loadActivities(familyId, familyUserId, familyItemType, pageParam, limit),
  {
    enabled          : !!familyId,
    getNextPageParam : (lastPage) => {
      if (lastPage.items.length < limit) {
        return undefined;
      }
      return lastPage.next;
    },
    onSuccess: data => {
      queryClient.setQueryData('userActivities', data);
    },
  }
);
