import styled                              from 'styled-components';
import { Box, IconButton }                 from '@material-ui/core';
import { Close }                           from '@material-ui/icons';
import { RecordLabel }                     from '../../modules/Activity/ActivityRecordPreview';
import {
  EVENT_TYPE,
  PlatformNotificationType,
}                                          from '../../shared/constants/constants';
import { ReactComponent as IconContact }   from '../../../images/icons/rounded/icon-contact.svg';
import { ReactComponent as IconEvent }     from '../../../images/icons/rounded/icon-events.svg';
import { ReactComponent as IconLocation }  from '../../../images/icons/rounded/icon-location.svg';
import { ReactComponent as IconDocuments } from '../../../images/icons/rounded/icon-document.svg';
import { ReactComponent as IconTask }      from '../../../images/icons/rounded/icon-task.svg';


const IconClose = styled(Close)`&& {
  color: #ccc;
  font-size: 20px;
}`;

const CloseButton = styled(IconButton)`&& {
  align-self: flex-start;
  margin: -15px -22px 0 0;
}`;

const MessageNotificationWrapper = styled.div`
  width: 100%;
  width: calc(100vw - 72px);
  display: flex;
  padding: 10px 0;
  left: -15px;
  position: relative;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: 50vw;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 40vw;
  }
`;

const MessageBody = styled.div`
  cursor: pointer;
  transition: ease .3s opacity;
  width: calc(100% - 22px);

  &:hover {
    opacity: .9;
  }
`;

const MessageNotificationText = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageData = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: calc(100% - 56px);
  padding-left: 30px;

  svg {
    position: absolute;
    top: 5px;
    left: 0;
  }
`;

interface INotifications {
  type: PlatformNotificationType;
  data: any;
  onOpen?(): void;
  onClose?(): void;
}

export const Notifications: React.FC<INotifications> = ({
  type,
  data,
  onOpen,
  onClose,
}) => {
  const textOfNotification = () => {
    switch (type) {
      case(PlatformNotificationType.Checkin):
        return `${ data.FullNameCheckedUser } check in location: ${ data.Address }`;
      case(PlatformNotificationType.EventAdded):
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.EventDeleted):
      case(PlatformNotificationType.EventReminder):
        return `${ data.Title } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.TaskReminder):
        return `${ data?.Title ?? '' } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.EventUpdated):
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.DocumentAdded):
        console.log('data.Name add', data.Name);
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.DocumentUpdated):
        console.log('data.Name update', data.Name);
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.DocumentDeleted):
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.DocumentAdded):
        return `${ data.Name } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.TaskAdded):
        return `${ data?.Title ?? '' } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.ContactAdded):
        return `${ data?.Name ?? '' } ${ data?.Description ?? '' }`;
      case(PlatformNotificationType.GeolocationDisabled):
        return `For ${ data?.UserToUpdate ?? '' }`;
      case(PlatformNotificationType.AboutMeUpdated):
        return `For ${ data?.FullName ?? '' }`;
      case(PlatformNotificationType.DocumentViewed):
        return `${ data?.WhoViewed ?? '' } viewed ${ data?.Name ?? ''  }`;
    }
  };

  const notifIcon = () => {
    switch (type) {
      case(PlatformNotificationType.Checkin):
        return (<IconLocation />);
      case(PlatformNotificationType.EventAdded):
        return (<IconEvent />);
      case(PlatformNotificationType.EventReminder):
        return (<IconEvent />);
      case(PlatformNotificationType.TaskReminder):
        return (<IconTask />);
      case(PlatformNotificationType.EventUpdated):
        return (<IconEvent />);
      case(PlatformNotificationType.DocumentViewed):
      case(PlatformNotificationType.DocumentAdded):
        return (<IconDocuments />);
      case(PlatformNotificationType.DocumentUpdated):
        return (<IconDocuments />);
      case(PlatformNotificationType.DocumentDeleted):
        return (<IconDocuments />);
      case(PlatformNotificationType.TaskAdded):
        return (<IconTask />);
      case(PlatformNotificationType.ContactAdded):
        return (<IconLocation />);
      case(PlatformNotificationType.GeolocationDisabled):
        return (<IconContact />);
      case(PlatformNotificationType.AboutMeUpdated):
        return (<IconContact />);
    }
  };

  return (
    <MessageNotificationWrapper>
      <MessageBody onClick={onOpen}>
        {/*<EntityRecordAvatar member={ message?.user }/>*/ }
        <MessageData>
          {notifIcon()}
          <RecordLabel>
            <b>{ EVENT_TYPE[type] }</b>
          </RecordLabel>

          <Box height={5} />

          <MessageNotificationText>
            { textOfNotification() }
          </MessageNotificationText>
        </MessageData>
      </MessageBody>

      <CloseButton
        color="inherit"
        onClick={onClose}
      >
        <IconClose />
      </CloseButton>
    </MessageNotificationWrapper>
  );
};
