import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import TableCell from '@mui/material/TableCell';

export const Wrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
`;

export const TableCellWrapper = styled(TableCell)`
  font-size: 20px;
  font-weight: 505;
  background: #dddee4;
  color: rgb(70, 108, 157);
  height: 45px;

  && {
    font-size: 20px;
    font-weight: 505;
    background: #dddee4;
    color: rgb(70, 108, 157);
  }
`;

export const PremiumTableCellWrapper = styled(TableCell)`
  background: rgb(250, 192, 94);
  height: 45px;

  && {
    font-size: 20px;
  font-weight: 505;
  color: rgb(70, 108, 157);
  }
`;

export const PremiumColumWrapper = styled(TableCell)`
  background: rgb(250, 192, 94);
  height: 45px;

  && {
    font-size: 13px;
    font-weight: 505;
  }
`;

export const FreeColumWrapper = styled(TableCell)`
  height: 45px;

  && {
    font-size: 13px;
    font-weight: 505;
  }
`;

export const PremiumCellWrapper = styled(TableCell)`
  height: 45px;

  && {
    font-size: 13px;
    font-weight: 600;
    height: 45px;
  }
`;

export const BlockWrapper = styled(Grid)`
  box-shadow: 0 4px 30px #00000026;
  border-radius: 16px;
  padding: 3.077rem 0;
  display: flex;
  height: 220px;
  flex-direction: column;
  align-items: center;
  width: 204px;
  margin-bottom: 20px;
  background: #fac05e;
`;

export const FreeBlockWrapper = styled(Grid)`
  box-shadow: 0 4px 30px #00000026;
  display: flex;
  justify-content: space-around;
  border-radius: 16px;
  padding: 3.077rem 0;
  display: flex;
  height: 220px;
  width: 204px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const FreeBlock = styled(Grid)`
  margin: -19px auto 29px;
  font-size: 16px;
  justify-content: center;
`;

export const InfoBlockWrapper = styled(Grid)`
  max-height: 300px;
`;

export const BlockName = styled('div')`
  font-weight: 700;
  font-size: 29px;
  line-height: 64px;
  text-align: center;
  color: #466c9d;
  margin-bottom: 8px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    font-size: 25px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
    font-size: 20px;
  }
`;

export const InfoBlockName = styled('div')`
  font-weight: 700;
  font-size: 29px;
  line-height: 64px;
  color: #466c9d;
  margin-bottom: 35px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    font-size: 25px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
    font-size: 20px;
  }
`;

export const FreeBlockName = styled('div')`
  font-weight: 700;
  font-size: 29px;
  line-height: 64px;
  text-align: center;
  color: #466c9d;
  margin-bottom: 7px;
  margin-top: -10px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    font-size: 25px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
    font-size: 20px;
  }
`;

export const BlockPrice = styled('div')`
  margin: 0 auto 18px;
  font-size: 16px;
  justify-content: center;
`;

export const EmptyBlockWrapper = styled('div')`
  height: 14px;
`;

export const BlockInfoHeader = styled('p')`
  font-weight: 700;
  font-size: 25px;
  color: #466c9d;
  margin-bottom: 5px;
`;

export const BlockInfoText = styled('p')`
  font-size: 15px;
`;

export const CloseIconWrapper = styled(Close)`
  && {
    fill: red;
  }
`;

export const CheckIconWrapper = styled(Check)`
  && {
    fill: green;
  }
`;
