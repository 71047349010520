import { styled } from '@mui/material';

import { ReactComponent as ReactLogoBasket } from '../../../../images/no-data-table.svg';

export const Wrapper = styled('div')`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  margin        : 20px 0;
`;

export const Title = styled('p')`
  font   : ${({ theme }) => theme.typography.h5};
  margin : 0; 
`;

export const ReactLogoBasketWrapper = styled(ReactLogoBasket)`
  height: 300px;
`;
