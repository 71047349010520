import moment        from 'moment';
import React, { FC } from 'react';
import styled        from 'styled-components';

interface IEventIconProps {
  date?: string;
}

const Wrapper = styled.div`
  color: #e4572e;
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  align-items: center;
  font-weight: 700;
  font-family: Jost;
  font-size: 14px;
`;

const EventDate = styled.div`
  font-size: 28px;
`;

export const EventIcon: FC<IEventIconProps> = ({ date }) => {
  const eventDate = moment.utc(date);

  return (
    <Wrapper>
      <EventDate>
        { eventDate.format('DD') }
      </EventDate>

      { eventDate.format('MM') }
    </Wrapper>
  );
};
