import React, { FC }          from 'react';
import styled                 from 'styled-components';
import { datePassed }         from '../../shared/utils/commonFunctions';
import { EntityRecordAvatar } from '../MyFamilyCore/shared/EntityRecordAvatar';
import { IMessage }           from './queries';

const MessageRow = styled.div`
  gap: 10px;
  display: flex;
`;

const MessageWrapper = styled(MessageRow)<{ isSender?: boolean }>`
  align-items: center;
  padding: 10px 24px;
  width: 100%;
`;

const MessageBody = styled(MessageRow)`
  align-items: center;
  align-items: center;
  font-size: 14px;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  max-width: calc(100% - 66px);
`;

const MessageBackground = styled.div<{ isSender?: boolean }>`
  padding: 13px 17px;
  line-height: 1.3;
  border-radius: 14px;
  color: ${ ({ isSender }) => isSender ? '#fff' : '#000' };
  background-color: ${ ({ isSender }) => isSender ? '#6f90d9' : 'rgba(141, 145, 165, 0.1)' };
  max-width: 100%;
  word-break: break-word;
  white-space: break-spaces;
`;

export const SubLabel = styled.div<{ whiteSpace?: string }>`
  color: #6c7289;
  font-size: 12px;
  line-height: 1.3;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: ${ ({ whiteSpace }) => whiteSpace };
  padding-left: 5px;
`;

export const AvatarWrapper = styled.div`
  align-self: flex-start;
`;

interface IMessageProps {
  data: IMessage;
  isSender?: boolean;
}

export const Message: FC<IMessageProps> = ({ data, isSender }) => (
  <MessageRow>
    <MessageWrapper>
      <AvatarWrapper>
        <EntityRecordAvatar member={data?.user} />
      </AvatarWrapper>

      <MessageBody>
        <MessageBackground isSender={isSender}>
          { data?.text }
        </MessageBackground>

        <SubLabel>{ datePassed(data?.timestamp, true) }</SubLabel>
      </MessageBody>
    </MessageWrapper>
  </MessageRow>
);
