import axios, { AxiosResponse }             from 'axios';
import { useMutation }                      from 'react-query';
import { queryClient }                      from '../../App';
import { IErrorResponse, IRequestResponse } from '../../Auth/shared/interfaces';
import { Notistack }                        from '../../shared/components/Notistack/Notistack';
import { familyCoreConfig }                 from '../../shared/constants/constants';
import { MEMBER_ACCOUNT_UPDATE }            from '../../shared/queries/family';
import { useCreateQuery }                   from '../../shared/utils/hooks/useReactQuery';
import { IFamilyMember }                    from '../../shared/utils/withAuthorization/withAuthorization';
import { handleApiErrorResponse }           from '../../utils/apiHelpers';
import { ICreateChatRoomRequest }           from './components/CreateChatRoom/validation';

export interface IMessage {
  id: string;
  text: string;
  timestamp: string;
  roomId?: string;
  user?: IFamilyMember & { familyUserId?: string };
}

export interface IChat {
  id: string;
  name?: string;
  type: string;
  unreadMessagesCount: number;
  messages: IMessage[];
  users: IFamilyMember[];
  creator?: IFamilyMember;
}

export const CHATS = 'CHATS';
export const CHAT_ROOMS = 'CHAT_ROOMS';
const UPDATE_CHAT_ROOM = 'UPDATE_CHAT_ROOM';
const DELETE_CHAT_ROOM = 'UPDATE_CHAT_ROOM';

export const useFetchChatsList = (familyUserId?: string) => useCreateQuery<IChat[]>({
  queryKey : [ CHATS, familyUserId ],
  apiUrl   : '/api/v1/chat/user-chats/all',
  options  : {
    enabled              : !!familyUserId && familyCoreConfig.isChatEnabled,
    refetchOnWindowFocus : false,
  },
  config: {
    params: {
      familyUserId,
    },
  },
});

export const useFetchChatById = (chatId?: string) => useCreateQuery<IChat>({
  queryKey : [ CHAT_ROOMS, chatId ],
  apiUrl   : `/api/v1/Chat/user-chats/${ chatId }`,
  options  : {
    enabled              : !!chatId,
    refetchOnWindowFocus : true,
  },
});

const fetchChatDataById = (chatId?: string) =>
  axios
    .get(`/api/v1/Chat/user-chats/${chatId}`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<string>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useFetchChatDataById = (chatId?: string) =>
  useMutation(
    'FETCH_CHAT_DATA',
    () => fetchChatDataById(chatId),
    {
      onError: handleApiErrorResponse,
    }
  );

const sendChatMessage = (message: string, familyUserId?: string, roomId?: string) =>
  axios.post('/api/v1/Chat/user-chats/message', { familyUserId, roomId, message })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<string>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useSendChatMessage = (familyUserId?: string, roomId?: string, options?: any) => useMutation(
  MEMBER_ACCOUNT_UPDATE,
  (message: string) => sendChatMessage(message, familyUserId, roomId),
  {
    onError: handleApiErrorResponse,
    ...options,
  }
);

const markMessageAsRead = (messageId?: string, familyUserId?: string, roomId?: string) =>
  axios.post(`/api/v1/Chat/user-chats/message/read/${ messageId }`, { familyUserId, roomId })
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<string>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useMarkMessageAsRead = (familyUserId?: string, roomId?: string) => useMutation(
  MEMBER_ACCOUNT_UPDATE,
  (messageId?: string) => markMessageAsRead(messageId, familyUserId, roomId),
  {
    onError   : handleApiErrorResponse,
    onSuccess : () => {
      queryClient.invalidateQueries([ CHATS, familyUserId ]);
    },
  }
);

const updateChatRoom = (data: ICreateChatRoomRequest) => axios.put('/api/v1/Chat/user-chats/room/update', data)
  .then(() => {
    queryClient.invalidateQueries([ CHAT_ROOMS, data?.roomId ]);
    return data;
  })
  .catch((error: IErrorResponse) => {
    throw error;
  });

export const useUpdateChatRoom = (familyUserId?: string) => useMutation(
  UPDATE_CHAT_ROOM,
  (data: ICreateChatRoomRequest) => updateChatRoom(data),
  {
    onSuccess: () => {
      Notistack.enqueueSnackbar('Chat was successfully updated.', 'success', false);
      queryClient.invalidateQueries([ CHATS, familyUserId ]);
    },
    onError: handleApiErrorResponse,
  }
);

const deleteChatRoom = (roomId?: string, familyId?: string) => axios.delete('/api/v1/Chat/user-chats/room', { data: { roomId, familyId } })
  .then(({ data: { data } }: AxiosResponse<IRequestResponse<IChat>>) => data)
  .catch((error: IErrorResponse) => {
    throw error;
  });

export const useDeleteChatRoom = (familyId?: string, familyUserId?: string) => useMutation(
  DELETE_CHAT_ROOM,
  (roomId?: string) => deleteChatRoom(roomId, familyId),
  {
    onSuccess: () => {
      Notistack.enqueueSnackbar('Chat was successfully deleted.', 'success', false);
      queryClient.invalidateQueries([ CHATS, familyUserId ]);
    },
    onError: handleApiErrorResponse,
  }
);
