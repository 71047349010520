import { FormikProps }          from 'formik';
import React, { FC, RefObject } from 'react';
import { FormButton }           from '../../../styledComponents';
import { useFormikHelpers }     from '../../../utils/hooks/useFormikHelpers';

interface IModalFooter {
  disabled?: boolean;
  message?: string;
  formikRef: RefObject<FormikProps<any>>;
}

export const ModalFooterActions: FC<IModalFooter> = ({
  disabled,
  message = 'All changes have been saved',
  formikRef,
  children,
}) => {
  const { formValidator, formSubmitter } = useFormikHelpers();

  const onSubmit = () => {
    formikRef && formValidator(formikRef)
      .then(() => formSubmitter(false, message, formikRef));
  };

  return (
    <FormButton
      fullWidth
      type="submit"
      color="primary"
      variant="contained"
      disabled={disabled}
      onClick={onSubmit}
    >
      { children }
    </FormButton>
  );
};
