import * as Yup  from 'yup';
import { rules } from '../../../../../Auth/shared/rules';

export interface IChangePasswordRequest {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword     : rules.password,
  password        : rules.password,
  confirmPassword : Yup.string()
    .required('Confirm your password')
    .oneOf([ Yup.ref('password'), null ], 'Passwords must match'),
});
