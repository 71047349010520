import { FC }                   from 'react';
import {
  IconButton,
  Popover,
}                               from '@material-ui/core';
import { Clear }                from '@material-ui/icons';
import { Moment }               from 'moment';
import styled                   from 'styled-components';
import { IEvent }               from '../queries';
import { EventBadgeForPopover } from './EventBadgeForPopover';

interface IDayEventsProps {
  day: Moment | null;
  open: boolean;
  anchorEl: HTMLDivElement | null;
  familyId?: string;
  events: IEvent[];

  handleClose(): void;
}

const PopoverHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Jost;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c3f69;
  margin-bottom: 10px;
`;

const CloseButton = styled(IconButton)`
  color: #afafaf;
  margin: -10px -15px;

  .MuiSvgIcon-root {
    font-size: 32px;
  }
`;

const PopoverWrapper = styled.div`
  padding: 15px 20px;
  width: 250px;
`;

const PopoverBody = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  flex-direction: column;
`;

export const DayEvents: FC<IDayEventsProps> = ({
  familyId,
  day,
  open,
  events,
  anchorEl,
  handleClose,
}) => {
  const eventsList = events.map((event, index) => (
    <EventBadgeForPopover
      key={index}
      day={day}
      event={event}
      familyId={familyId}
      handleClose={handleClose}
    />
  ));

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical   : 'bottom',
        horizontal : 'center',
      }}
      transformOrigin={{
        vertical   : 'top',
        horizontal : 'right',
      }}
      onClose={handleClose}
    >
      <PopoverWrapper>
        <PopoverHead>
          { day?.format('ddd DD') }
          <CloseButton
            aria-label="close modal window"
            onClick={handleClose}
          >
            <Clear />
          </CloseButton>
        </PopoverHead>

        <PopoverBody>
          { eventsList }
        </PopoverBody>
      </PopoverWrapper>
    </Popover>
  );
};
