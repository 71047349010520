import styled                             from 'styled-components';
import { ReactComponent as SVGLogoBlue }  from '../../../images/logo/Horizantal Full Logo Dark.svg';
import { ReactComponent as SVGLogoWhite } from '../../../images/logo/Horizantal Full Logo Light.svg';

interface ILogoProps {
  margin?: string;
  width?: string;
  height?: string;
}

const common = `
  position: relative;
  height: 50px;
`;

export const LogoBlue = styled(SVGLogoBlue)<ILogoProps>`
  ${ common };
  margin: ${ ({ margin }) => margin || 0 };
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    height: ${ ({ height }) => height || '60px' };
    width: ${ ({ width }) => width || '130px' };
    max-height: 100%;
  }
`;

export const LogoWhite = styled(SVGLogoWhite)<ILogoProps>`
  ${ common };
  margin: ${ ({ margin }) => margin || 0 };
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    height: ${ ({ height }) => height || '60px' };
    width: ${ ({ width }) => width || '130px' };
    max-height: 100%;
  }
`;
