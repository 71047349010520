import axios, { AxiosError, AxiosResponse }                                from 'axios';
import { useMutation }                                                     from 'react-query';
import { queryClient }                                                     from '../../../../App';
import { IErrorResponse, IFamily, IRequestResponse, IUploadImageResponse } from '../../../../Auth/shared/interfaces';
import { Notistack }                                                       from '../../../../shared/components/Notistack/Notistack';
import { FAMILY_MEMBERS_GET }                                              from '../../../../shared/queries/family';
import { IInviteMemberRequest }                                            from './validation';

export const ADD_MEMBER_BY_INVITE = 'ADD_MEMBER_BY_INVITE';

export interface IInvite {
  id: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  userType: string;
  familyRole: string;
  family: IFamily;
  status: string;
  familyUserId: string;
  avatar: IUploadImageResponse;
  color: string;
}

const addMemberByInvite = (data: IInviteMemberRequest) =>
  axios.post('/api/v1/invites', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IInvite>>) => data);

const onSuccessPreRegistration = () => {
  queryClient.invalidateQueries([ FAMILY_MEMBERS_GET ]);
};

const onInvitationReject = (error: AxiosError<IErrorResponse>) => {
  const errorDetails = error?.response?.data?.ErrorDetails?.Message;
  Notistack.enqueueSnackbar(errorDetails, 'error');
};

export const useAddMemberByInvite = () =>
  useMutation(ADD_MEMBER_BY_INVITE, (data: IInviteMemberRequest) => addMemberByInvite(data), {
    onSuccess : onSuccessPreRegistration,
    onError   : onInvitationReject,
  });

const addMemberByAccountCreation = (data: IInviteMemberRequest) =>
  axios.post('/api/v1/invites/account', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IInvite>>) => data);

export const useAddMemberByAccountCreation = () =>
  useMutation(ADD_MEMBER_BY_INVITE, (data: IInviteMemberRequest) => addMemberByAccountCreation(data), {
    onSuccess : onSuccessPreRegistration,
    onError   : onInvitationReject,
  });

