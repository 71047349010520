import qs                                      from 'qs';
import React, { FC, useEffect, useState }      from 'react';
import { useHistory }                          from 'react-router-dom';
import styled                                  from 'styled-components';
import { CreateButton, CreateIcon }            from '../../Layout/components/DynamicMessageCenter';
import { Layout }                              from '../../Layout/Layout';
import { useFetchSelectedFamily }              from '../../shared/components/SelectFamily/queries';
import { EAppRoutes }                          from '../../shared/constants/constants';
import { queryParams }                         from '../../shared/functions';
import { useFetchFamilyUser }                  from '../../shared/queries/family';
import { Flex, StyledDivider }                 from '../../shared/styledComponents';
import { SummaryPageHead }                     from '../MyFamilyCore/shared/SummaryPageHead';
import { ChatRooms }                           from './components/ChatRooms';
import { ChatWindow }                          from './components/ChatWindow';
import { CreateChatRoomModal }                 from './components/CreateChatRoom/CreateChatRoomModal';
import { useFetchChatById, useFetchChatsList } from './queries';

const ChatRoomsHeader = styled.div`
  padding: 10px 18px 0;
  font-size: 20px;
  min-width: 280px;
`;

const PageWrapper = styled.div`
  display: flex;
  width: calc(100% + 64px);
  padding: 0 16px 16px;
  margin: 32px -32px 0;
  background-color: #f7fafd;
  flex-direction: column;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 24px 0;
    flex-direction: row;
    height: calc(100% - 90px);
    min-height: 420px;
  }
`;

export const MessageCenter: FC = () => {
  const { data: family } = useFetchSelectedFamily();
  const { data: user } = useFetchFamilyUser(family?.id);
  const { data: chatRooms } = useFetchChatsList(user?.id);
  const [ createGroupModalOpen, setCreateGroupModalOpen ] = useState(false);

  const history = useHistory();
  const params = queryParams();
  const selectedChat = params?.id as string;

  const { data: chat, isLoading: loadingChat } = useFetchChatById(selectedChat);

  const selectChat = (id: string) => {
    history.push({
      pathname : EAppRoutes.MessageCenter,
      search   : qs.stringify({ id }),
    });
  };

  useEffect(() => {
    if (chatRooms?.length && !chatRooms.find(room => room?.id === selectedChat)) {
      selectChat(chatRooms[0].id);
    }
  }, [ chatRooms ]);

  const Page = (
    <>
      <SummaryPageHead
        hideDivider
        hideFamilyMembers
        title="Message Center"
      />

      <PageWrapper>
        <div>
          <ChatRoomsHeader>
            <Flex align="center" justify="space-between">
              Chats

              <CreateButton
                size="medium"
                aria-haspopup="true"
                aria-controls="activity-record-menu"
                onClick={() => setCreateGroupModalOpen(true)}
              >
                <CreateIcon />
              </CreateButton>
            </Flex>

            <StyledDivider mb={0} mt={15} />
          </ChatRoomsHeader>

          <ChatRooms
            chatRooms={chatRooms}
            selected={selectedChat}
            onSelect={(id) => selectChat(id)}
          />
        </div>

        <ChatWindow
          chat={chat}
          loading={loadingChat}
          familyUser={user}
          familyId={family?.id}
          chatId={selectedChat}
        />
      </PageWrapper>

      { createGroupModalOpen && (
        <CreateChatRoomModal
          familyId={family?.id}
          open={createGroupModalOpen}
          onSuccess={selectChat}
          onClose={() => setCreateGroupModalOpen(false)}
        />
      ) }
    </>
  );

  return (
    <Layout>
      { Page }
    </Layout>
  );
};
