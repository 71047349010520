import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FC }                                                     from 'react';
import styled, { css }                                                   from 'styled-components';
import { Modal }                                                         from './Modal';

export const StyledDialogTitle = styled(DialogTitle)`&& {
  ${ ({ hidecloseicon, theme: { spacing } }: any) =>
    !hidecloseicon && css`
      margin-right: ${ spacing(2) }px;
    ` }
}` as any;

export const StyledDialogContent = styled(DialogContent)`&& {
  ${ ({ theme: { spacing } }) => css`
    padding: 0 ${ spacing(4) }px ${ spacing(2) }px;
  ` }
  text-align: justify;
}`;

interface IAlertDialogProps {
  open: boolean;
  title: string;
  content: JSX.Element | string;
  fullWidth?: boolean;
  dialogWidth?: string;
  dialogHeight?: string;
  hideCloseIcon?: boolean;
  hideCancelButton?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  submitButtonDisabled?: boolean;
  handleSubmit?: () => Promise<void> | void | undefined;
  handleCancel: () => void;
  handleClose?: () => void;
}

export const AlertDialog: FC<IAlertDialogProps> = ({
  open,
  title,
  content,
  fullWidth,
  dialogWidth,
  dialogHeight,
  hideCloseIcon,
  hideCancelButton,
  cancelButtonText,
  submitButtonText,
  submitButtonDisabled,
  handleSubmit,
  handleCancel,
  handleClose,
}) => {
  const onClose = handleClose ?? handleCancel;

  return (
    <Modal
      open={open}
      maxWidth="md"
      hideCloseIcon={hideCloseIcon}
      fullWidth={fullWidth}
      dialogWidth={dialogWidth}
      dialogHeight={dialogHeight}
      onClose={() => onClose()}
    >
      <StyledDialogTitle
        id="alert-dialog-title"
        hidecloseicon={hideCloseIcon ? 1 : 0}
      >
        { title }
      </StyledDialogTitle>

      <StyledDialogContent>
        { typeof content === 'string' ? (
          <Typography
            variant="subtitle1"
            style={{ whiteSpace: 'pre-line' }}
          >
            { content }
          </Typography>
        ) : (
          <>{ content }</>
        ) }
      </StyledDialogContent>

      { (!hideCancelButton || handleSubmit) && (
        <DialogActions>
          { !hideCancelButton && (
            <Button
              color="primary"
              onClick={() => handleCancel()}
            >
              { cancelButtonText || 'Cancel' }
            </Button>
          ) }

          { handleSubmit && (
            <Button
              autoFocus
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              disabled={submitButtonDisabled}
              onClick={() => handleSubmit()}
            >
              { submitButtonText || 'Continue' }
            </Button>
          ) }
        </DialogActions>
      ) }
    </Modal>
  );
};
