import { Grid }                   from '@material-ui/core';
import React, { FC }              from 'react';
import { Layout }                 from '../../Layout/Layout';
import { useFetchSelectedFamily } from '../../shared/components/SelectFamily/queries';
import { queryParams }            from '../../shared/functions';
import { ActivitiesMenu }         from '../Dashboard/components/FamilyTabs/ActivitiesMenu';
import { ActivityPageHeader }     from '../MyFamilyCore/shared/styles';
import { SummaryPageHead }        from '../MyFamilyCore/shared/SummaryPageHead';
import { ActivityItems }          from './ActivityItems';

export const Activity: FC = () => {
  const { data: family } = useFetchSelectedFamily();
  const params = queryParams();
  const familyMemberId = params?.id;

  const Activities = (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={3}
      >
        <ActivityPageHeader>Summary</ActivityPageHeader>

        <ActivitiesMenu
          familyId={family?.id}
          familyMemberId={familyMemberId}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
      >
        <ActivityItems
          familyId={family?.id}
          familyMemberId={familyMemberId}
        />
      </Grid>
    </Grid>
  );

  const Page = (
    <>
      <SummaryPageHead title="Activity" />

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          { Activities }
        </Grid>
      </Grid>
    </>
  );

  return (
    <Layout>
      { Page }
    </Layout>
  );
};
