declare const window: {
  AppConfig: {
    apiUrl: string;
    stripeKey: string;
  };
};

const config = window.AppConfig;

export default config;
