import * as Yup  from 'yup';
import { rules } from '../shared/rules';

const USPaymentSchema = {
  coupon: Yup.string()
    .max(10, 'Must be no more than 10 digits'),
  address: Yup.string()
    .required('Address is required'),
  city: Yup.string()
    .required('City is required'),
  state: Yup.string()
    .required('State is required'),
  zip: Yup.string()
    .required('Zip code is required'),
};

const nonUSPaymentSchema = {
  email: rules.email,
};

export const checkOutFormSchema = (outsideUS: boolean, isInAppPurchase: boolean) => {
  if (isInAppPurchase) {
    return Yup.object().shape({});
  }

  if (outsideUS) {
    return Yup.object().shape(nonUSPaymentSchema);
  }

  return Yup.object().shape(USPaymentSchema);
};
