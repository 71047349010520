import { FC, useContext }                               from 'react';
import { DialogProps }                                 from '@material-ui/core';
import { Clear }                                        from '@material-ui/icons';
import { Formik }                                       from 'formik';
import { StyledUploadDialog, UploadDialogTitle }        from '../../../../shared/components/modals/styles';
import { CloseModalIcon }                               from '../../../../shared/styledComponents';
import { fullName }                                     from '../../../../shared/utils/commonFunctions';
import AuthorizationContext                             from '../../../../shared/utils/withAuthorization/authorizationContext';
import { IChat, useUpdateChatRoom }                     from '../../queries';
import { CreateChatRoomModalForm }                      from './CreateChatRoomModalForm';
import { useCreateChatRoom }                            from './queries';
import { ICreateChatRoomRequest, createChatRoomSchema } from './validation';

interface ICreateChatRoomModalProps extends DialogProps {
  chat?: IChat;
  familyId?: string;
  onClose: () => void;
  onSuccess: (chatId: string) => void;
}

export const CreateChatRoomModal: FC<ICreateChatRoomModalProps> = ({
  chat,
  onClose,
  onSuccess,
  familyId,
  ...props
}) => {
  const currentUser = useContext(AuthorizationContext);
  const { mutate: createChatRoom, isLoading: creatingChatRoom } = useCreateChatRoom(currentUser?.data?.id);
  const { mutate: updateChatRoom, isLoading: updatingChatRoom } = useUpdateChatRoom(currentUser?.data?.id);
  const recipientFullName = fullName(chat?.users?.[0]?.firstName, chat?.users?.[0]?.lastName);

  const familyUsers = currentUser?.data?.id && chat?.users?.map(user => user.id) || [];
  familyUsers?.push(currentUser?.data?.id as string);

  const initialValues: ICreateChatRoomRequest = {
    familyUsers,
    roomId                    : chat?.id,
    senderMessageFamilyUserId : currentUser?.data?.id,
    initialMessage            : '',
    roomName                  : chat?.name || recipientFullName,
  };

  const submit = (form: ICreateChatRoomRequest) => {

    const handleSuccess = (chat: IChat) => {
      onClose();
      onSuccess(chat?.id);
    };

    if (chat?.id) {
      updateChatRoom(form, { onSuccess: onClose });
    } else {
      createChatRoom(form, { onSuccess: handleSuccess });
    }
  };

  return (
    <StyledUploadDialog
      onClose={onClose}
      {...props}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        { chat?.id ? 'Edit Chat' : 'New Chat' }
      </UploadDialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={createChatRoomSchema}
        onSubmit={submit}
      >
        { ({ submitForm }) => (
          <CreateChatRoomModalForm
            familyId={familyId}
            loading={creatingChatRoom || updatingChatRoom}
            sendingMessage={creatingChatRoom || updatingChatRoom}
            onSendMessage={() => submitForm()}
          />
        ) }
      </Formik>
    </StyledUploadDialog>
  );
};
