import axios, { AxiosResponse }             from 'axios';
import { useMutation }                      from 'react-query';
import { queryClient }                      from '../../../../App';
import { IErrorResponse, IRequestResponse } from '../../../../Auth/shared/interfaces';
import { Notistack }                        from '../../../../shared/components/Notistack/Notistack';
import { handleApiErrorResponse }           from '../../../../utils/apiHelpers';
import { CHATS, IChat }                     from '../../queries';
import { ICreateChatRoomRequest }           from './validation';

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const CREATE_CHAT_ROOM = 'CREATE_CHAT_ROOM';

const createChatRoom = (data: ICreateChatRoomRequest) => axios.post('/api/v1/Chat/user-chats/room', data)
  .then(({ data: { data } }: AxiosResponse<IRequestResponse<IChat>>) => data)
  .catch(onCatch);

export const useCreateChatRoom = (familyUserId?: string) => useMutation(
  CREATE_CHAT_ROOM,
  (data: ICreateChatRoomRequest) => createChatRoom(data),
  {
    onSuccess: () => {
      Notistack.enqueueSnackbar('A new chat was successfully created.', 'success', false);
      queryClient.invalidateQueries([ CHATS, familyUserId ]);
    },
    onError: handleApiErrorResponse,
  }
);
