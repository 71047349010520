import createStyles from '@material-ui/core/styles/createStyles';
// import { Theme }    from '@material-ui/core';

export const selectStyles = (/* theme: Theme */) => createStyles({
  input: {
    lineHeight : 1.3,
    display    : 'block',
    '&:focus'  : {
      background: 'inherit',
    },
  },
  icon: {
    right: 0,
  },
  hidden: {
    display: 'none',
  },
});
