import * as Yup                from 'yup';
import { ICardDisplayDetails } from '../../../../../shared/queries/payments';

export enum EPaymentPlatformType {
  Unknown = 'Unknown',
  Stripe  = 'Stripe',
  Apple   = 'Apple',
  Google  = 'Google'
}

export interface IMemberAccountRequest {
  id: string;
  familyName:string;
  familyRole: string;
  timeZoneOffset: string;
  chatAccess: boolean;
  coupon?: IMemberCoupon;
  viewAboutMe: boolean;
  geoLocationAccess: string[];
  aboutMeAccess: string[];
}
export interface IMemberCoupon {
  amount_off: null | string | number;
  applies_to: null | string | number;
  created: number;
  currency:  null | string | number;
  duration: string;
  duration_in_months?: any;
  id: string;
  livemode: boolean;
  max_redemptions: null | number;
  metadata: any;
  name: string;
  object: string;
  percent_off: number;
  redeem_by: null | string | number;
  times_redeemed: number;
  valid: boolean;
}

export interface IFamilyAccountRequest extends IMemberAccountRequest {
  familyName: string;
  familyRole: string;
}

export interface IFamilyAccountResponse extends IFamilyAccountRequest {
  card?: ICardDisplayDetails;
  paymentPlatformType: EPaymentPlatformType;
}

const genericRule = Yup.string()
  .trim()
  .nullable();

export const familyAccountValidationSchema = Yup.object().shape({
  id                : genericRule,
  familyName        : genericRule,
  familyRole        : genericRule,
  timeZoneOffset    : genericRule,
  chatAccess        : Yup.boolean().nullable(),
  viewAboutMe       : Yup.boolean().nullable(),
  geoLocationAccess : Yup.array().of(Yup.string()).nullable(),
});
