import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory }                                 from 'react-router-dom';
import { useLoadDocumentsPage }                       from '../../../shared/components/modals/UploadDocument/queries';
import { UploadDocumentModal }                        from '../../../shared/components/modals/UploadDocument/UploadDocumentModal';
import { EAppRoutes }                                 from '../../../shared/constants/constants';
import { documentTypes }                              from '../../../shared/documentTypes';
import { shouldDisplayActionsMenu }                   from '../../../shared/functions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { NoActivityScreen, NoActivityScreenWrapper }  from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                      from '../../Activity/ActivityRecordPreview';
import { EntitiesListPreview }                        from '../shared/EntitiesListPreview';
import { DocumentAddedBy }                            from './components/DocumentAddedBy';
import { DocumentIcon }                               from './components/DocumentIcon';
import { DocumentMenuItems }                          from './components/DocumentMenuItems';

interface IDocumentsListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

export const DocumentsListPreview: FC<IDocumentsListPreviewProps> = ({ familyId, familyMemberId }) => {
  const [ addDocumentModalOpen, setAddDocumentModalOpen ] = useState(false);
  const [ showViewAllSection, setShowViewAllSection ] = useState(false);
  const { data: documents, isFetching } = useLoadDocumentsPage(familyId, familyMemberId);
  const currentUser = useContext(AuthorizationContext);
  const items = documents?.pages?.[0]?.items;
  const history = useHistory();

  const onViewAll = () => {
    history.push(`${ EAppRoutes.DocumentsSummary }${ familyMemberId ? `?id=${ familyMemberId }` : '' }`);
  };

  const viewDocument = (documentId?: string) => {
    history.push(`${ EAppRoutes.DocumentsSummary }/${ documentId }`);
  };

  useEffect(() => {
    setShowViewAllSection(!!(documents?.pages?.[0]?.totalCount && documents?.pages?.[0]?.totalCount > 0));
  }, [ documents?.pages?.[0]?.totalCount ]);

  return (
    <>
      <EntitiesListPreview
        showViewAll={showViewAllSection}
        title="Documents"
        count={documents?.pages?.[0]?.totalCount}
        loading={isFetching}
        onViewAll={onViewAll}
        onAdd={() => setAddDocumentModalOpen(true)}
      >
        { items?.length ? items?.map((document, index, array) => (
          <ActivityRecordPreview
            key={index}
            showMenu={shouldDisplayActionsMenu(document, currentUser)}
            icon={<DocumentIcon />}
            description={document.name}
            details={<DocumentAddedBy document={document} />}
            isLast={index === array.length - 1}
            menuItems={<DocumentMenuItems
              document={document}
              familyId={familyId}
              familyUserId={familyMemberId}
            />}
            onClick={() => viewDocument(document?.id)}
          />
        )) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.documents.icon }
              There are no documents available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListPreview>

      { addDocumentModalOpen && (
        <UploadDocumentModal
          familyId={familyId}
          familyUserId={familyMemberId}
          open={addDocumentModalOpen}
          onClose={() => setAddDocumentModalOpen(false)}
        />
      ) }
    </>
  );
};
