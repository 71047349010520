import { IconButton, InputAdornment }       from '@material-ui/core';
import VisibilityOffOutlinedIcon            from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon               from '@material-ui/icons/VisibilityOutlined';
import React, { FC, useCallback, useState } from 'react';
import { theme }                            from '../../../styles';
import { ITextInputProps, TextInput }       from './TextInput/TextInput';

export const PasswordInput: FC<ITextInputProps> = (props) => {
  const [ showPassword, setShowPassword ] = useState(false);

  const toggleShowPassword = useCallback(
    () => {
      setShowPassword(!showPassword);
    },
    [ showPassword ]
  );

  return (
    <TextInput
      {...props}
      ref={null}
      fullWidth
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={event => event.preventDefault()}
            >
              { showPassword
                ? <VisibilityOffOutlinedIcon htmlColor={theme.colors.grey} />
                : <VisibilityOutlinedIcon htmlColor={theme.colors.grey} /> }
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
