import React                          from 'react';
import { ITextInputProps, TextInput } from '../TextInput/TextInput';
import { ZipCodeMask }                from './ZipCodeMask';

export const ZipCodeInput = (props: ITextInputProps) => (
  <TextInput
    {...props}
    fullWidth
    InputProps={{ inputComponent: ZipCodeMask }}
  />
);
