import { FC }  from "react";
import { useHistory } from 'react-router-dom';
import { Button, Hidden } from "@material-ui/core";
import { useQueryClient } from 'react-query';

import { DataTable } from "./DataTable";

import { LogoBlue }    from "../../shared/components/Logo";
import { EAuthRoutes } from "../../shared/constants/constants";

import { ReactComponent as ImageGroup } from '../../../images/family-group.svg';

import {
  PageHeader,
  SignUpContainer,
  AuthSVGSceneHolder,
} from "../shared/styles";

import {
  Wrapper,
  BlockWrapper,
  FreeBlock,
  FreeBlockName,
  InfoBlockName,
  FreeBlockWrapper,
  BlockName,
  EmptyBlockWrapper,
  BlockPrice,
  InfoBlockWrapper,
} from "./styles";

export const ChangeSubscription: FC = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const createSubscription = (link: string) => {
    history.push({ pathname: link });
  };

  return (
    <SignUpContainer maxWidth="md">
      <PageHeader mb="20px">
        <LogoBlue />
      </PageHeader>

      <Wrapper container sm={12} md={12} lg={12} spacing={3}>
        <>
          <FreeBlockWrapper item sm={12} md={3} lg={3}>
            <FreeBlockName>Free Plan</FreeBlockName>
            <EmptyBlockWrapper />

            <Button
              fullWidth
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => {
                queryClient.setQueryData('SubscriptionPlan', 'Free');
                createSubscription(EAuthRoutes.CreateFamily);
              }}
            >
              Select
            </Button>
          </FreeBlockWrapper>

          <BlockWrapper item sm={12} md={3} lg={3}>
            <BlockName>Premium Plan</BlockName>
            <BlockPrice>$4.99 / month</BlockPrice>
            <BlockPrice>4-week Free Trial</BlockPrice>

            <Button
              fullWidth
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => createSubscription(EAuthRoutes.Checkout)}
            >
              Select
            </Button>
          </BlockWrapper>
        </>

        <Hidden smDown>
          <InfoBlockWrapper
            item
            sm={5}
            md={5}
            lg={5}
          >
            <AuthSVGSceneHolder>
              <ImageGroup />
            </AuthSVGSceneHolder>
          </InfoBlockWrapper>
        </Hidden>
      </Wrapper>
      <>
        <InfoBlockName>What do you get?</InfoBlockName>

        <DataTable />
      </>
    </SignUpContainer>
  );
};
