import { FormHelperText }         from '@material-ui/core';
import { useField }               from 'formik';
import React, { FC, useRef }              from 'react';
import styled                     from 'styled-components';
import { FamilyMembersList }      from '../../modules/Dashboard/components/FamilyMembers/FamilyMembersList';
import { useFetchSelectedFamily } from '../components/SelectFamily/queries';
import { isAdminRoles }           from '../functions';
import { useFetchFamilyMembers }  from '../queries/family';
import { IFamilyMember }          from '../utils/withAuthorization/withAuthorization';

interface IFamilyMembersSelectorProps {
  name: string;
  multiple?: boolean;
  hideAllSelection?: boolean;
  needFiltration?: boolean;
  onlyAccepted?: boolean;

  filterFunction?: (familyMembers: IFamilyMember[]) => IFamilyMember[];
}

const Wrapper = styled.div`
  position: relative;
`;

const ErrorText = styled(FormHelperText)`
  color: red;
  bottom: -3px;
  width: 137px;
  left: 0;
`;

export const FamilyMembersSelector: FC<IFamilyMembersSelectorProps> = (props) => {
  const previosValue = useRef<string[]>([]);
  const { data: family } = useFetchSelectedFamily({ staleTime: Infinity });
  const { data: familyMembers, isLoading: loadingMembers } = useFetchFamilyMembers(family?.id, {
    enabled   : !!family?.id,
    staleTime : Infinity,
  });

  let allowedFamilyMembers = familyMembers;
  if (props.needFiltration) {
    allowedFamilyMembers = allowedFamilyMembers?.filter(user => isAdminRoles(user?.accountType));
  }
  if (props.onlyAccepted) {
    allowedFamilyMembers = allowedFamilyMembers?.filter((familyMember) => familyMember.status === 'Accepted' && familyMember.hasEmail);
  }

  if (props.filterFunction) {
    allowedFamilyMembers = props.filterFunction(allowedFamilyMembers || []);
  }

  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [ field, meta, helpers ] = useField(props.name);

  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const onSelect = (familyMemberId: string) => {
    if (familyMemberId) {
      if (props.multiple && value) {
        const alreadySelected = value?.find((id: string) => id === familyMemberId);

        if (alreadySelected) {
          setValue(value.filter((id: string) => id !== familyMemberId));
        } else {
          setValue([ ...value, familyMemberId ]);
        }
      } else {
        setValue([ ...value, familyMemberId ]);
      }
    } else {
      if (allowedFamilyMembers?.length === value.length)
      {
        setValue([...previosValue.current]);
        previosValue.current = [];

      } else {
        previosValue.current = [...value];
        setValue(allowedFamilyMembers?.map(member => member.id));
      }
    }
  };

  return (
    <Wrapper>
      <FamilyMembersList
        shaded
        showAllBubbleForMembers
        filledArrayForAllSelection
        selected={value}
        loading={loadingMembers}
        data={allowedFamilyMembers}
        hideAllSelection={props.hideAllSelection}
        onSelect={onSelect}
      />

      { touched && error && (
        <ErrorText className="Mui-error">{ error }</ErrorText>
      ) }
    </Wrapper>
  );
};
