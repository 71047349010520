import * as Yup  from 'yup';
import { rules } from '../../../../../Auth/shared/rules';

export interface IChangeEmailRequest {
  email: string;
  password: string;
}

export const changeEmailValidationSchema = Yup.object().shape({
  email    : rules.email,
  password : rules.password,
});
