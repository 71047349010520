import * as Yup from 'yup';

export const rules = {
  string : Yup.string().nullable(),
  number : Yup.number().nullable(),
  email  : Yup.string()
    .email('Please provide a valid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .test('len', 'Password must be from 8 to 50 symbols', (val: any) => val && val.length > 7),
  securityCode: Yup.string()
    .required('Security code is required')
    .test('len', 'Security code must be 4 symbols', (val: any) => val && val.length === 4),
  dateOfBirth: Yup.date()
    .nullable()
    .required('Date of Birth is required'),
};
