import { FormControl, MenuItem, Select }                   from '@material-ui/core';
import React, { FC, useEffect, useMemo }                   from 'react';
import { useHistory, useLocation }                         from 'react-router-dom';
import styled                                              from 'styled-components';
import { useFetchFamilies }                                from '../../queries/family';
import { capitalizeFirstLetter }                           from '../../utils/commonFunctions';
import { useFetchSelectedFamily, useUpdateSelectedFamily } from './queries';

interface ISelectFamilyProps {
  fullWidth?: boolean;
}

const StyledDropdown = styled(FormControl)<ISelectFamilyProps>`
  flex: 1;
  width: 100%;
 
  .MuiSelect-outlined {
    border-radius: 9px;
    background-color: #fafafa;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
  }
  
  .MuiInputBase-root {
    margin-top: 0;
    max-width: ${ ({ fullWidth }) => fullWidth ? '100%' : '200px' };
    
    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      max-width: ${ ({ fullWidth }) => fullWidth ? '100%' : '260px' };
    }
  }
`;

const FamilyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectFamily: FC<ISelectFamilyProps> = ({ fullWidth }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: families } = useFetchFamilies();
  const { data: selected, isLoading } = useFetchSelectedFamily();
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily(() => {
    history.push({ pathname });
  });

  useEffect(() => {
    if (!selected && !isLoading) {
      updateSelectedFamily(families?.[0]?.id || '');
    }
  }, [ isLoading ]);

  return useMemo(() => (
    <StyledDropdown
      fullWidth={fullWidth}
      variant="outlined"
    >
      <Select
        displayEmpty
        fullWidth={fullWidth}
        value={selected?.id || ''}
        onChange={(event: any) => updateSelectedFamily(event.target.value)}
      >
        { families && families.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            <FamilyName>{ capitalizeFirstLetter(item.name) } Family</FamilyName>
          </MenuItem>
        )) }
      </Select>
    </StyledDropdown>
  ), [ selected, families ]);
};
