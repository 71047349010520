import React, { FC }                from 'react';
import { PageHeaderContainer }      from '../../../../../shared/styledComponents';
import { IFamilyMember }            from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { SettingsHeader }           from '../../../shared/styles';
import { MentionedEntities }        from '../MentionedEntities/MentionedEntities';
import { EFamilyItemType }          from '../MentionedEntities/shared';
import { useLoadAssignedItemsPage } from './queries';

interface IMyProfileFormProps {
  familyId?: string;
  memberId?: string;
  familyMembers?: IFamilyMember[];
}

export const AssignedItems: FC<IMyProfileFormProps> = ({
  familyId,
  memberId,
  familyMembers,
}) => {
  const { data: documents, isFetching: loadingDocuments } = useLoadAssignedItemsPage(memberId, EFamilyItemType.Document);
  const { data: events, isFetching: loadingEvents } = useLoadAssignedItemsPage(memberId, EFamilyItemType.Event);
  const { data: tasks, isFetching: loadingTasks } = useLoadAssignedItemsPage(memberId, EFamilyItemType.Task);

  return (
    <div>
      <PageHeaderContainer>
        <SettingsHeader variant="h3">Assigned Items </SettingsHeader>
      </PageHeaderContainer>

      <MentionedEntities
        isAssignees
        documents={documents}
        loadingDocuments={loadingDocuments}
        events={events}
        loadingEvents={loadingEvents}
        tasks={tasks}
        loadingTasks={loadingTasks}
        familyId={familyId}
        familyMembers={familyMembers}
      />
    </div>
  );
};
