import { Typography }                                   from '@material-ui/core';
import DeleteIcon                                       from '@material-ui/icons/Delete';
import PhotoCameraIcon                                  from '@material-ui/icons/PhotoCamera';
import CropIcon                                         from '@material-ui/icons/Crop';
import axios, { AxiosResponse }                         from 'axios';
import { FC, useMemo, useState }                        from 'react';
import { useMutation }                                  from 'react-query';
import styled                                           from 'styled-components';
import { FileSpecificationMessage }                     from '../../../../styles/components';
import { AvatarSectionWrapper }                         from '../../../Auth/CustomizeProfile';
import { IRequestResponse, IUploadImageResponse }       from '../../../Auth/shared/interfaces';
import { AvatarSection, AvatarWrapper, StyledProgress } from '../../../shared/components/AvatarSection';
import { AlertDialog }                                  from '../../../shared/components/modals/AlertDialog';
import { fileTypeMessage }                              from '../../../shared/constants/constants';
import { memberType }                                   from '../../../shared/functions';
import { StyledBadge }                                  from '../../../shared/styledComponents';
import { generateInitials }                             from '../../../shared/utils/commonFunctions';
import { uploadPhoto }                                  from '../../../shared/utils/fileUpload';
import { FamilyMemberWrapper }                          from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { IMyProfileForm, IUserProfile }                 from '../components/Profile/MyProfileForm/validation';
import { isNative }                                     from '../../../shared/utils/platform';
import { getDocument }                                  from '../../../shared/utils/getDocument';
import { CropEasy }                                     from './CropEasy';
import { VerifiedIconWrapper }                          from '../../../Auth/shared/styles';
import { IFamily }                                      from '../../../Auth/shared/interfaces';

const Badge = styled(StyledBadge).attrs(() => ({ overlap: 'rectangular' }))`&& {
  .MuiBadge-badge {
    width             : 23px;
    min-height        : 23px;
    border-radius     : 100%;
    right             : 13px;
    bottom            : -13px;
    top               : auto !important;
    
    svg {
      width: 12px;
    }
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 12px;
  };
  
  ${ StyledProgress } {
    left: -9px;
    height: 90px;
  }
}`;

const BadgeDelete = styled(Badge)`&& {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 35px;
  opacity: 1;
  transition: ease .3s opacity;

  .MuiBadge-badge {
    background-color  : #ff0000;
    
    svg {
      width: 14px;
    }
  }
}`;

const BadgeCrop = styled(Badge)`&& {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: ease .3s opacity;

  .MuiBadge-badge {
    background-color: #0e991a;
    
    svg {
      width: 14px;
    }
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.only('xs') } {
    right: 0;
    bottom: -1px;
  };

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    right: 0;
    bottom: -10px;
  };
}`;

const AvatarContainer = styled(AvatarSectionWrapper)`{
  width: auto;
  height: auto;
  padding: 0;
  
  &:hover {
    ${ BadgeDelete } {
      opacity: 1;
    }
  }

  &:hover {
    ${ BadgeCrop } {
      opacity: 1;
    }
  }
  
  ${ FamilyMemberWrapper } {
    margin: 0;
  } 
  
  ${ AvatarWrapper } {
    width: auto;
  }
}`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 25px;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    align-items: center;
    padding: 0 5px;
  };
  
  .MuiTypography-root {
    ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
      font-size: 24px;
      letter-spacing: 0.48px;
    };

    ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
      text-align: center;
      align-items: center;
    };
  }
`;

export const UserRole = styled.div`
  padding: 9px 14px;
  border-radius: 25px;
  background-color: #fac05e;
  font-size: 10px;
  font-weight: 600;
  min-height: 18px;
  min-width: 85px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: 14px;
  };
`;

export const CameraIcon = styled(PhotoCameraIcon)`&& {
  color: #fff;
  width: 10px;
}`;

export const AvatarBadge = styled(StyledBadge)`&& {
  .MuiBadge-badge {
    width: 23px;
    min-height: 23px;
    border-radius: 100%;
    right: 20px;
    bottom: -5px;
    top: auto !important;
    
    svg {
      width: 12px;
    }

    ${ ({ theme: { breakpoints } }: any) => breakpoints.only('xs') } {
      right: 12px;
      bottom: -10px;
    };
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 12px;
  };
  
  ${ StyledProgress } {
    left: -9px;
    height: 90px;
  }
}`;

const TrashIcon = styled(DeleteIcon)`&& {
  color: #fff;
  width: 10px;
}`;

const CropUserIcon = styled(CropIcon)`&& {
  color: #fff;
  width: 10px;
}`;

const LabelWrapper = styled('label')`&& {
  cursor: pointer;
}`;

const CameraWrapper = styled('button')`&& {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 90px;
  display: flex;
  margin: auto;
  position: absolute;
  background: none;
  border: none;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 150px;
  }
}`;

interface IWithAvatarPickerProps {
  color?: string;
  families?: IFamily[];
  selectedFamily?: IFamily;
  avatar?: IUploadImageResponse;
  currentUser?: IUserProfile;
  updateUserProfile?: (data: IMyProfileForm, options?: { onSuccess?: () => void }) => void;
  isUpdatingUserProfile?: boolean;

  onSuccess?(data: IUploadImageResponse): void;
}

interface IImageAvatar {
  lastModified: number;
  lastModifiedDate: any;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export const AvatarPicker: FC<IWithAvatarPickerProps> = ({ families, selectedFamily, color, avatar, currentUser, updateUserProfile, isUpdatingUserProfile, onSuccess }) => {
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ imageAvatar, setImageAvatar ] = useState<IImageAvatar | string | any>('');
  const [ uploadPercentage, setUploadPercentage ] = useState(0);
  const [ file, setFile ] = useState<string | Blob>();
  const [ imagePreviewUrl, setImagePreviewUrl ] = useState<string | undefined>(avatar?.compactPath || currentUser?.avatar?.compactPath);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);

  const [selectedImg, setSelectedImg] = useState<string>('');
  const [openCrop, setOpenCrop] = useState(false);

  const matchingFamily = families?.find((family: IFamily) => family.id === selectedFamily?.id);

  const resetImage = () => {
    setFile('');
    setImageAvatar('');
    setImagePreviewUrl(undefined);
  };

  const fullName = `${ currentUser?.firstName } ${ currentUser?.lastName }`;

  const uploadMutation = useMutation('uploadAvatar', () => {
    if (file) {
      const formData = new FormData();

      formData.append('File', file);

      return axios.post('/api/v1/images', formData, {
        params: {
          ImageType: 'Avatar',
        },
        onUploadProgress: (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percent < 100 ? percent : 0);
        },
      });
    }

    return new Promise((resolve) => resolve({ data: { data: null } })) as any;
  }, {
    onSuccess: ({ data: { data } }: AxiosResponse<IRequestResponse<IUploadImageResponse>>) => {
      updateUserAvatar(data);
    },
  });

  const uploadPhotoToMemory = (event: any) => {
    const uploadCallback = () => uploadMutation.mutate();

    uploadPhoto(
      event,
      resetImage,
      setFile,
      setImagePreviewUrl,
      setErrorMessage,
      uploadCallback
    );
  };

  const updateUserAvatar = (data?: IUploadImageResponse) => {
    if (updateUserProfile) {
      updateUserProfile({
        avatarId: data?.id,
        ...currentUser,
      } as IMyProfileForm, {
        onSuccess: () => {
          onSuccess?.(data as IUploadImageResponse);
          if (!data) {
            resetImage();
            setIsDeleteDialogOpen(false);
          }
        },
      });
    }
  };

  const takePicture = async () => {
    const document = await getDocument();

    if (!document) {
      return;
    }

    const newImageFile = new File([ document.blob ], document.name, {
      type         : document.mimeType,
      lastModified : Date.now(),
    });

    if (newImageFile) {
      setFile(newImageFile);
      setImageAvatar(newImageFile);
      uploadMutation.mutate(imageAvatar);
    }
  };

  const AvatarPickerElement = () => (
    <AvatarBadge
      badgeContent={onSuccess}
      aria-controls="user-menu"
      aria-haspopup="true"
    >
      <AvatarContainer>
        {imagePreviewUrl ? (
          <BadgeDelete
            id="deleteIcon"
            color="primary"
            badgeContent={<TrashIcon />}
            onClick={() => setIsDeleteDialogOpen(true)}
          />
        ) : null}

        {imagePreviewUrl ? (
          <BadgeCrop
            id="cropIcon"
            color="primary"
            badgeContent={<CropUserIcon />}
            onClick={() => {
              setOpenCrop(true);
              setSelectedImg(imagePreviewUrl);
            }}
          />
        ) : null}

        <LabelWrapper>
          <AvatarSection
            color={color || currentUser?.color}
            image={imagePreviewUrl || avatar?.compactPath}
            initials={generateInitials(currentUser?.firstName, currentUser?.lastName)}
            uploadPercentage={uploadPercentage}
          />

          {(onSuccess && !isNative) ? (
            <input
              id="avatar-upload"
              type="file"
              onChange={uploadPhotoToMemory}
            />
          ) : null}
        </LabelWrapper>
      </AvatarContainer>

      {isNative ? <CameraWrapper onClick={() => takePicture()} /> : null}
    </AvatarBadge>
  );

  const UserDetailsElement = () => (
    <UserDetails>
      <Typography variant="h3">{fullName} {matchingFamily?.isActivePayment && <VerifiedIconWrapper />}</Typography>
      <UserRole>{memberType(currentUser?.accountType)}</UserRole>

      {errorMessage ? (
        <FileSpecificationMessage error={!!errorMessage}>
          {errorMessage || fileTypeMessage}
        </FileSpecificationMessage>
      ) : null}
    </UserDetails>
  );

  const handleCroppedImage = (croppedFile: IImageAvatar | string | any) => {
    setFile(croppedFile);
    setImageAvatar(croppedFile);
    uploadMutation.mutate(croppedFile);
  };

  return useMemo(() => (
    <>
      <AvatarPickerElement />
      <UserDetailsElement />
      <AlertDialog
        dialogWidth="350px"
        open={isDeleteDialogOpen}
        title="Delete avatar"
        content="Are you sure you want to delete avatar?"
        handleSubmit={updateUserAvatar}
        handleCancel={() => setIsDeleteDialogOpen(false)}
      />
      {openCrop && <CropEasy
        photoURL={selectedImg}
        openCrop={openCrop}
        setOpenCrop={setOpenCrop}
        onCroppedImage={handleCroppedImage}
      />}
    </>
  ), [ currentUser?.firstName, currentUser?.lastName, errorMessage, imagePreviewUrl, isUpdatingUserProfile, isDeleteDialogOpen, openCrop, matchingFamily ]);
};

