import { Popover }     from '@material-ui/core';
import { useField }    from 'formik';
import React, { FC }   from 'react';
import styled          from 'styled-components';
import { ColorPicker } from './ColorPicker';

const ColorButton = styled.button`
  height: 40px;
  width: 67px;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  
  &:hover {
    cursor: pointer;
    opacity: .9;
  }
`;

const ColorCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${ ({ color }) => color };
`;

const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  opacity: .54;
  border-style: solid;
  border-width: 5px 4.5px 0 4.5px;
  border-color: #010101 transparent transparent transparent;
`;

const PickerWrapper = styled(Popover)`&& {
  .MuiPopover-paper {
    width: 340px;
    padding: 15px;
    margin-top: 10px;
  }
}`;

interface IColorPickerDropdownProps {
  name: string;
}

export const ColorPickerDropdown: FC<IColorPickerDropdownProps> = ({ name }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);

  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [ field, meta, helpers ] = useField(name);

  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSelect = (selectedColor: string) => {
    setValue(selectedColor);
    handleClose();
  };

  return (
    <div>
      <ColorButton type="button" onClick={handleClick}>
        <ColorCircle color={value} />
        <Triangle />
      </ColorButton>

      <PickerWrapper
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical   : 'bottom',
          horizontal : 'right',
        }}
        transformOrigin={{
          vertical   : 'top',
          horizontal : 'center',
        }}
        onClose={handleClose}
      >
        <ColorPicker
          size="22px"
          selectedColor={value}
          setSelectedColor={onSelect}
        />
      </PickerWrapper>
    </div>
  );
};
