import { FC } from 'react';
import { FastField, FormikProps } from 'formik';
import { Grid, Radio, FormControlLabel, FormControl } from '@material-ui/core';

import { DateRangeField } from '../../formFields/DateRangeField';
import { ModalFooterActions } from '../modals/shared/ModalFooterActions';
import { IUploadEvent } from '../modals/UploadEvent/queries';

import {
  UploadEntityFormDate,
  UploadEntityFormRow,
  UploadFormLabel,
  RadioGroupWrapper,
  ReportsBlockWrapper,
  ApplyWrapper,
} from '../documents/styles';

interface IAddEventModalFormProps {
  formikRef: React.RefObject<FormikProps<IUploadEvent>>;
  values: any;
  setFieldValue: any;
}

export const DatePickerStatistics: FC<IAddEventModalFormProps> = ({
  formikRef, values, setFieldValue,
}) => (
  <UploadEntityFormDate>
    <Grid item xs={12}>
      <UploadEntityFormRow>
        <Grid container spacing={2}>
          <ReportsBlockWrapper>
            <Grid item xs={12} sm={4}>
              <UploadFormLabel>
                Select Date
              </UploadFormLabel>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FastField
                startName="startDate"
                endName="endDate"
                component={DateRangeField}
              />
            </Grid>
          </ReportsBlockWrapper>

          <ReportsBlockWrapper>
            <FormControl component="fieldset">
              <Grid container spacing={2}>
                <Grid item xs={5} sm={4}>
                  <UploadFormLabel>
                    User Type
                  </UploadFormLabel>
                </Grid>

                <Grid item xs={7} md={5} sm={3}>
                  <RadioGroupWrapper
                    aria-label="user-type"
                    name="userType"
                    value={values.isDeleted ? 'deleted' : 'active'}
                    onChange={(event) => {
                      const isDeleted = event.target.value === 'deleted';
                      setFieldValue('isDeleted', isDeleted);
                    }}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio />}
                      label="Active Users"
                    />
                    <FormControlLabel
                      value="deleted"
                      control={<Radio />}
                      label="Deleted Users"
                    />
                  </RadioGroupWrapper>
                </Grid>
              </Grid>
            </FormControl>
          </ReportsBlockWrapper>
        </Grid>
        <ApplyWrapper
          item
          xs={11}
          sm={4}
        >
          <ModalFooterActions formikRef={formikRef}>
            Apply
          </ModalFooterActions>
        </ApplyWrapper>
      </UploadEntityFormRow>
    </Grid>
  </UploadEntityFormDate>
);
