import { useEffect, useState } from 'react';

import { HubConnection } from '@microsoft/signalr';

import { setupHubConnection } from '../Header';
import { IFamily }            from '../../Auth/shared/interfaces';
import { useFetchFamilyUser } from '../../shared/queries/family';
import { getAuthToken }       from '../../utils/authHelpers';
import { IMessage }           from '../../modules/MessageCenter/queries';

interface INotificationsHubConnectionHookArgs {
  family?                           : IFamily;
  onReceiveNotification             : (data: any) => void;
  onReceiveChatMessage              : (message: IMessage) => void;
  onReceiveChatCreationNotification : (message: IMessage) => void;
};

export const useNotificationsHubConnection = ({
  family,
  onReceiveNotification,
  onReceiveChatMessage,
  onReceiveChatCreationNotification,
}: INotificationsHubConnectionHookArgs) => {
  const authToken = getAuthToken();

  const [notificationsHub, setNotificationsHub] = useState<HubConnection>();

  const { data: user } = useFetchFamilyUser(family?.id);

  const setupNotificationsHub = () => {
    const hubConnection = setupHubConnection('/hubs/platform', authToken.accessToken);

    setNotificationsHub(hubConnection);
    console.log('Notifications hub connection is ready.');
  };

  const joinPlatform = async (userId: string) => {
    if (!notificationsHub) {
      throw new Error('Cannot join a platform, notifications hub connection is not found.');
    }

    notificationsHub.on('ReceiveBroadCastNotification', (data: any) => onReceiveNotification(data));
    notificationsHub.on('ReceiveChatMessageNotification', (message: IMessage) => onReceiveChatMessage(message));
    notificationsHub.on('ReceiveChatCreationNotification', (message: IMessage) => onReceiveChatCreationNotification(message));
    console.log('Subscribed on ReceiveBroadCastNotification event.');

    notificationsHub.onclose(() => {
      setNotificationsHub(undefined);
      console.log('Set notifications hub connection to undefined.');
    });

    console.log('Launching the notifications hub connection.');
    await notificationsHub.start();
    await notificationsHub.invoke('JoinPlatform', userId);

    console.log('Platform is joined.');
  };

  useEffect(() => {
    if (user?.id && !notificationsHub) {
      setupNotificationsHub();
    }
  }, [user, notificationsHub]);

  useEffect(() => {
    if (user?.id && notificationsHub) {
      joinPlatform(user.id);
    }

    return () => {
      if (notificationsHub) {
        notificationsHub.stop()
          .then(() => console.log('Notifications hub connection was stopped.'))
          .catch((error) => console.error(error));
      }
    };
  }, [notificationsHub]);
};
