import { Divider, Grid }    from '@material-ui/core';
import React                from 'react';
import styled               from 'styled-components';
import { ContactReference } from './ContactReference';

export const SectionHeader = styled.div<{ dark?: boolean; isSubItem?: boolean; isFirstElement?: boolean }>`
  font-family: Jost;
  font-size: ${ ({ dark }) => dark ? '16px' : '18px' };
  font-weight: 500;
  line-height: 1.5;
  color: ${ ({ dark }) => dark ? '#2c3f69' : '#e4572e' };
  margin-top: ${ ({ isFirstElement }) => isFirstElement ? '55px' : '0' };

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: ${ ({ dark }) => dark ? '18px' : '24px' };
  }
`;

const BottomDivider = styled(Divider)`
  margin: 10px 0 20px;
`;

const TitleSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const GridWrapper = styled(Grid)<{ isSubItem?: boolean }>`
  margin-left: ${ ({ isSubItem }) => isSubItem ? '-5px' : 'none' };
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

interface IPlatformSelector {
  title: string;
  subtitle?: string;
  fieldName: string;
  displayLabels?: boolean;
  isSubItem?: boolean;
  allowed: TContactPlatform[];
  onChange?: (option: TContactPlatform) => void;
  last?: boolean;
}

export enum EPlatform {
  email    = 'email',
  mobile   = 'push',
  inApp    = 'platform',
  all      = 'all',
}

export type TContactPlatform = 'email' | 'push' | 'platform' | 'all' | any;

export const PlatformSelector = ({
  title,
  subtitle,
  fieldName,
  isSubItem,
  displayLabels,
  allowed,
  onChange,
  last,
}: IPlatformSelector) => (
  <Grid
    container
    spacing={3}
  >
    <TitleSection
      item
      xs={12}
      sm={3}
      md={5}
      lg={4}
    >
      <SectionHeader dark isSubItem={isSubItem} isFirstElement={title === 'New Members Join'}>{ title }</SectionHeader>
      <Subtitle>{ subtitle }</Subtitle>
    </TitleSection>

    {(title !== 'Reminders') && (
      <GridWrapper
        item
        xs={12}
        sm={9}
        md={7}
        lg={8}
        isSubItem={isSubItem}
      >
        <Grid
          container
          spacing={3}
          justify="space-between"
        >
          <Grid
            item
            xs={2}
          >
            <ContactReference
              displayLabels={displayLabels}
              fieldName={fieldName}
              option={EPlatform.email}
              allowed={allowed}
              onChange={onChange}
            />
          </Grid>

          <Grid
            item
            xs={2}
          >
            <ContactReference
              displayLabels={displayLabels}
              fieldName={fieldName}
              option={EPlatform.mobile}
              allowed={allowed}
            />
          </Grid>

          <Grid
            item
            xs={2}
          >
            <ContactReference
              displayLabels={displayLabels}
              fieldName={fieldName}
              option={EPlatform.inApp}
              allowed={allowed}
            />
          </Grid>

          <Grid
            item
            xs={2}
          >
            <ContactReference
              displayLabels={displayLabels}
              fieldName={fieldName}
              option={EPlatform.all}
              allowed={allowed}
            />
          </Grid>
        </Grid>
      </GridWrapper>
    )}

    { !last && (
      <Grid
        item
        xs={12}
      >
        {(title !== 'Reminders') && (
          <BottomDivider />
        )}
      </Grid>
    ) }
  </Grid>
);
