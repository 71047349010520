import { Paper, Popper, Typography }    from '@material-ui/core';
import React, { FC, useRef, useState }  from 'react';
import styled                           from 'styled-components';
import { datePassed, generateInitials } from '../../../shared/utils/commonFunctions';
import { FamilyMemberCircle }           from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { ICheckin }                     from '../queries';

interface ICheckinMapMarkerProps {
  lng?: string;
  lat?: string;
  mapContainer?: HTMLElement | null;
  checkin: ICheckin;
}

const MarkerWrapper = styled.div<{ selected: boolean }>`
  width: 38px;

  span {
    filter: ${ ({ selected }) => selected ? 'saturate(1)' : 'saturate(0.5)' }
  }
`;

const StyledPopper = styled(Popper)`&&{
  margin-top: 10px;
  z-index   : 1;

  .MuiPaper-root {
    padding: 8px 16px;
    width  : 200px;
  }
}`;

const MarkerArrow = styled.span`
  position     : absolute;
  font-size    : 7px;
  width        : 0;
  height       : 0;
  border-left  : 1em solid transparent;
  border-right : 1em solid transparent;
  border-bottom: 1em solid white;
  margin-top   : -6px;
  left         : 93px;
`;

const AddressName = styled(Typography)`
  color        : #547cd3;
  font-weight  : bold;
`;

const LastCheckinLabel = styled.span`
  font-size: 12px;
  color: ${ ({ theme: { colors } }) => colors.darkGrey };
`;

export const CheckinMapMarker: FC<ICheckinMapMarkerProps> = ({
  mapContainer,
  checkin,
}) => {
  const user = checkin.creator || checkin.updater;
  const tooltipArrowRef = useRef<HTMLSpanElement | null>(null);
  const [ tooltipAnchor, setTooltipAnchor ] = useState<Element | null>(null);

  const onMarkerTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    tooltipAnchor ? setTooltipAnchor(null) : setTooltipAnchor(event.currentTarget);
  };

  return (
    <>
      <MarkerWrapper
        selected={!!tooltipAnchor}
        onMouseOver={(event) => setTooltipAnchor(event.currentTarget)}
        onMouseOut={() => setTooltipAnchor(null)}
        onTouchEnd={onMarkerTouchEnd}
      >
        <FamilyMemberCircle
          color={user.color}
          image={user.avatar?.compactPath}
          initials={generateInitials(user.firstName, user.lastName)}
        />
      </MarkerWrapper>

      { tooltipAnchor && (
        <StyledPopper
          open
          anchorEl={tooltipAnchor}
          container={mapContainer}
          modifiers={{
            arrow: {
              element: tooltipArrowRef.current,
            },
            preventOverflow: {
              boundariesElement: 'window',
            },
          }}
        >
          <MarkerArrow ref={tooltipArrowRef} />

          <Paper>
            <LastCheckinLabel>
              Last check in at { datePassed(checkin.createdAt, true) }
            </LastCheckinLabel>
            <AddressName variant="subtitle1">
              { checkin.name }
            </AddressName>
            <Typography variant="subtitle2">{ checkin.address }</Typography>
          </Paper>
        </StyledPopper>
      ) }
    </>
  );
};
