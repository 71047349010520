import { Divider }                                                                            from '@material-ui/core';
import axios                                                                                  from 'axios';
import { Form, Formik, FormikHelpers }                                                        from 'formik';
import React, { FC, useState }                                                                from 'react';
import { Link }                                                                               from 'react-router-dom';
import styled                                                                                 from 'styled-components';
import { LogoBlue }                                                                           from '../../shared/components/Logo';
import { MuiButton }                                                                          from '../../shared/components/MuiButton';
import { EAuthRoutes }                                                                        from '../../shared/constants/constants';
import { TextInputField }                                                                     from '../../shared/formFields/TextInputField';
import { FormFieldWrapper }                                                                   from '../CreateFamily/CreateFamily';
import { IErrorResponse }                                                                     from '../shared/interfaces';
import { AuthField, Instructions, PageHeader, PageHeaderText, SignInUpPage, SignUpContainer } from '../shared/styles';
import { forgotPasswordSchema }                                                               from './validation';

const Message = styled.div`
  color: #0dc695;
  text-align: center;
  margin: 25px 0 20px;
  font-weight: 700;
`;

interface IForgotPasswordForm {
  email: string;
}

export const ForgotPassword: FC = () => {
  const [ emailSent, setEmailSent ] = useState(false);
  const initialValues: IForgotPasswordForm = {
    email: '',
  };

  const handleSubmit = ({ email }: IForgotPasswordForm, formikActions: FormikHelpers<IForgotPasswordForm>) => {
    formikActions.setSubmitting(true);

    axios.post('/api/v1/verifications/password', { email })
      .then(() => {
        formikActions.setSubmitting(false);
        setEmailSent(true);
      })
      .catch((err: IErrorResponse) => {
        formikActions.setSubmitting(false);
        throw err;
      });
  };

  return (
    <SignInUpPage>
      <SignUpContainer maxWidth="md">
        <PageHeader
          mb="25px"
          mbMd="20px"
        >
          <LogoBlue margin="0 0 50px" />

          <PageHeaderText centered>Forgot Password</PageHeaderText>
        </PageHeader>

        { emailSent ? (
          <>
            <Message>Email Sent!</Message>

            <Instructions centered>
              Check your email for instructions to continue.
            </Instructions>
          </>
        ) : (
          <>
            <Instructions
              centered
              widthSm="380px"
              widthMd="500px"
            >
              Enter your email address below and we&apos;ll email you how to create a new password.
            </Instructions>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              { ({
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormFieldWrapper centered>
                    <AuthField
                      label="Email"
                      name={'email'}
                      placeholder="you@example.com"
                      inputProps={{ maxLength: 100 }}
                      component={TextInputField}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <MuiButton
                      fullWidth
                      type="submit"
                      margin="34px 0 0"
                      disabled={isSubmitting}
                    >
                      Email Me
                    </MuiButton>

                    <Divider />

                    Return to <Link to={EAuthRoutes.SignIn}>Login</Link>
                  </FormFieldWrapper>
                </Form>
              ) }
            </Formik>
          </>
        ) }
      </SignUpContainer>
    </SignInUpPage>
  );
};
