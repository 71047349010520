export const timeZones = [
  {
    value : '-12:00:00',
    label : 'GMT-12	International Date Line West (IDLW)',
  }, {
    value : '-11:00:00',
    label : 'GMT-11	Nome Time (NT)',
  }, {
    value : '-10:00:00',
    label : 'GMT-10	Hawaii Standard Time (HST)',
  }, {
    value : '-09:00:00',
    label : 'GMT-9	Alaska Standard Time (AKST)',
  }, {
    value : '-08:00:00',
    label : 'GMT-8	Pacific Standard Time (PST)',
  }, {
    value : '-07:00:00',
    label : 'GMT-7	Mountain Standard Time (MST)',
  }, {
    value : '-06:00:00',
    label : 'GMT-6	Central Standard Time (CST)',
  }, {
    value : '-05:00:00',
    label : 'GMT-5	Eastern Standard Time (EST)',
  }, {
    value : '-04:00:00',
    label : 'GMT-4	Atlantic Standard Time (AST)',
  }, {
    value : '-03:00:00',
    label : 'GMT-3	Argentina Time (ART)',
  }, {
    value : '-02:00:00',
    label : 'GMT-2	Azores Time (AT)',
  }, {
    value : '-01:00:00',
    label : 'GMT-1	West Africa Time (WAT)',
  }, {
    value : '00:00:00',
    label : 'GMT+0	Greenwich Mean Time (GMT)',
  }, {
    value : '01:00:00',
    label : 'GMT+1	Central European Time (CET)',
  }, {
    value : '02:00:00',
    label : 'GMT+2	Eastern European Time (EET)',
  }, {
    value : '03:00:00',
    label : 'GMT+3	Moscow Time (MSK)',
  }, {
    value : '04:00:00',
    label : 'GMT+4	Armenia Time (AMT)',
  }, {
    value : '05:00:00',
    label : 'GMT+5	Pakistan Standard Time (PKT)',
  }, {
    value : '06:00:00',
    label : 'GMT+6	Omsk Time (OMSK)',
  }, {
    value : '07:00:00',
    label : 'GMT+7	Kranoyask Time (KRAT)',
  }, {
    value : '08:00:00',
    label : 'GMT+8	China Standard Time (CST)',
  }, {
    value : '09:00:00',
    label : 'GMT+9	Japan Standard Time (JST)',
  }, {
    value : '10:00:00',
    label : 'GMT+10	Eastern Australia Standard Time (AEST)',
  }, {
    value : '11:00:00',
    label : 'GMT+11	Sakhalin Time (SAKT)',
  }, {
    value : '12:00:00',
    label : 'GMT+12	New Zealand Standard Time (NZST)',
  },
];
