import { FieldProps, getIn }                                        from 'formik';
import React, { ForwardRefRenderFunction, forwardRef, useCallback } from 'react';
import { ISelectTimeProps, SelectTime }                             from '../select/SelectTime/SelectTime';

interface ISelectDateFieldProps
  extends FieldProps, ISelectTimeProps {
  id?: string;
}

const SelectTimeFieldBase: ForwardRefRenderFunction<HTMLDivElement, ISelectDateFieldProps> = ({
  form,
  field,
  schedule,
  ...props
}, ref) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const handleChange = useCallback(
    (event) => {
      field.onChange(event);
      form.setFieldTouched(field.name, true);
    },
    [ form, field ]
  );

  return (
    <SelectTime
      schedule={schedule}
      {...field}
      {...props}
      ref={ref}
      id={props.id || field.name}
      error={touched && !!error}
      helperText={(touched && error) || ''}
      onChange={handleChange}
    />
  );
};

export const SelectTimeField = forwardRef(SelectTimeFieldBase);
