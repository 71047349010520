import React, { FC }               from 'react';
import { Breadcrumbs }             from '../../../../shared/components/Breadcrumbs';
import { EAppRoutes }              from '../../../../shared/constants/constants';
import { SettingsPageWithAvatar }  from '../../shared/SettingsPageWithAvatar';
import { ChangeEmailForm }         from '../ManageFamilyAccount/ChangeEmailForm/ChangeEmailForm';
import { ChangePasswordForm }      from '../ManageFamilyAccount/ChangePasswordForm/ChangePasswordForm';
import { ChangePinForm }           from '../ManageFamilyAccount/ChangePinForm/ChangePinForm';
import { IUserProfile }            from '../Profile/MyProfileForm/validation';
import { ManageMemberAccountForm } from './ManageMemberAccountForm/ManageMemberAccountForm';

export interface IMyProfileProps {
  currentUser?: IUserProfile;
}

const MANAGE_ACCOUNT_ROUTE = `${ EAppRoutes.Settings }/member-account`;
export const CHANGE_MEMBER_EMAIL_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-email`;
export const CHANGE_MEMBER_PASSWORD_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-password`;
export const CHANGE_MEMBER_PIN_ROUTE = `${ MANAGE_ACCOUNT_ROUTE }/change-pin`;

export const ManageMemberAccount: FC = () => {
  const breadcrumbs = [ {
    label     : 'Manage Account',
    to        : MANAGE_ACCOUNT_ROUTE,
    component : <ManageMemberAccountForm />,
  }, {
    label     : 'Update Email',
    to        : CHANGE_MEMBER_EMAIL_ROUTE,
    component : <ChangeEmailForm />,
  }, {
    label     : 'Update Password',
    to        : CHANGE_MEMBER_PASSWORD_ROUTE,
    component : <ChangePasswordForm />,
  }, {
    label     : 'Change Pin',
    to        : CHANGE_MEMBER_PIN_ROUTE,
    component : <ChangePinForm />,
  } ];

  return (
    <SettingsPageWithAvatar loading={false}>
      <Breadcrumbs data={breadcrumbs} />
    </SettingsPageWithAvatar>
  );
};
