import { IconButton }                                               from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState }     from 'react';
import { NavLink, useHistory }                                      from 'react-router-dom';
import styled                                                       from 'styled-components';
import { ReactComponent as IconCreate }                             from '../../../images/icons/icon-create.svg';
import { ReactComponent as IconChat }                               from '../../../images/main-chat-icon.svg';
import { ChatRooms }                                                from '../../modules/MessageCenter/components/ChatRooms';
import { CreateChatRoomModal }                                      from '../../modules/MessageCenter/components/CreateChatRoom/CreateChatRoomModal';
import { IChat }                                                    from '../../modules/MessageCenter/queries';
import { SelectFamily }                                             from '../../shared/components/SelectFamily/SelectFamily';
import { EAppRoutes }                                               from '../../shared/constants/constants';
import { Loader }                                                   from '../../shared/enhancers/withLoader';
import { StyledBadge }                                              from '../../shared/styledComponents';
import { IFamilyMember }                                            from '../../shared/utils/withAuthorization/withAuthorization';
import { IconButtonSpaced }                                         from '../Header';
import { FloatingChat }                                             from './FloatingChat';
import { SystemPopover, SystemPopoverContent, SystemPopoverHeader } from './SystemPopover';

interface IDynamicMessageCenterProps {
  user?: IFamilyMember;
  chatRooms?: IChat[];
  chat?: IChat;
  familyId?: string;
  loadingChat?: boolean;
  loadingRooms?: boolean;
  chatOpened: boolean;
  selectedChat: string;

  setSelectedChat(chatId: string): void;
  setChatOpened(isOpened: boolean): void;
}

export const CreateButton = styled(IconButton)`&& {
  margin: -10px -10px -15px;
}`;

export const CreateIcon = styled(IconCreate)`&& {
  position: relative;
  top: -2px;
  right: -2px;
}`;

const FamilySelectorWrapper = styled.div`
  padding: 20px 20px 10px;
`;

const EnableChatText = styled.div`
  padding: 20px;
  line-height: 1.3;
`;

const DMCFooter = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 20px 25px 22px;
  box-shadow: 4px 0 5px 2px rgba(0, 0, 0, 0.1);
`;

export const DynamicMessageCenter: FC<IDynamicMessageCenterProps> = ({
  user,
  chatRooms,
  selectedChat,
  chat,
  loadingChat,
  loadingRooms,
  familyId,
  setSelectedChat,
  chatOpened,
  setChatOpened,
}) => {
  const [ popperAnchorEl, setPopperAnchorEl ] = useState<HTMLButtonElement | null>(null);
  const [ createGroupModalOpen, setCreateGroupModalOpen ] = useState(false);
  const history = useHistory();

  const unreadMessages = useMemo(() => chatRooms?.reduce((acc, curr) => {
    if (curr?.id === selectedChat) {
      return acc;
    }

    if (curr?.id !== selectedChat && !chatOpened) {
      acc += curr.unreadMessagesCount;
    } else {
      acc += curr.unreadMessagesCount;
    };

    return acc;
  }, 0), [chatRooms, selectedChat, chatOpened]);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopperAnchorEl(null);
  };

  const openChat = (chatId: string) => {
    handleClose();

    if (window.location.href.includes(EAppRoutes.MessageCenter)) {
      history.push(`${ EAppRoutes.MessageCenter }?id=${ chatId }`);
    } else {
      setSelectedChat(chatId);
    }
  };

  const openCreationChatModal = useCallback(() => {
    setCreateGroupModalOpen(true);
    handleClose();
  }, []);

  useEffect(() => {
    const anchor = popperAnchorEl;
    handleClose();
    setPopperAnchorEl(anchor);
  }, [ loadingRooms ]);

  return (
    <>
      <IconButtonSpaced
        color="inherit"
        onClick={handleClick}
      >
        <StyledBadge
          color="secondary"
          badgeContent={unreadMessages}
        >
          <IconChat />
        </StyledBadge>
      </IconButtonSpaced>

      <SystemPopover
        key={loadingRooms ? 1 : 0}
        anchorEl={popperAnchorEl}
        onClose={handleClose}
      >
        <div>
          <SystemPopoverHeader>
            <span>
              Message Center
            </span>

            <CreateButton
              size="medium"
              aria-haspopup="true"
              aria-controls="activity-record-menu"
              onClick={openCreationChatModal}
            >
              <CreateIcon />
            </CreateButton>
          </SystemPopoverHeader>

          <FamilySelectorWrapper>
            <SelectFamily fullWidth />
          </FamilySelectorWrapper>
        </div>

        <SystemPopoverContent>
          { loadingRooms ? (
            <Loader minHeight="190px" />
          ) : user?.chatAccess ? (
            <ChatRooms
              fullWidth
              chatRooms={chatRooms}
              onSelect={(id) => openChat(id)}
            />
          ) : (<EnableChatText>Enable <b>Chat Access</b> in the Account Profile to view chats.</EnableChatText>) }
        </SystemPopoverContent>

        <DMCFooter>
          <NavLink to={EAppRoutes.MessageCenter}>
            View All Messages
          </NavLink>
        </DMCFooter>
      </SystemPopover>

      { selectedChat && (
        <FloatingChat
          chat={chat}
          loading={loadingChat}
          familyUser={user}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          chatOpened={chatOpened}
          setChatOpened={setChatOpened}
        />
      ) }

      { createGroupModalOpen && (
        <CreateChatRoomModal
          familyId={familyId}
          open={createGroupModalOpen}
          onSuccess={openChat}
          onClose={() => setCreateGroupModalOpen(false)}
        />
      ) }
    </>
  );
};
