import React, { FC } from 'react';
import styled        from 'styled-components';
import { TextBrand } from '../../../../shared/styledComponents';

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  gap: 20px;
  width: ${ ({ width }) => width };
`;

const Icon = styled.div`
  width: 44px;
  height: 44px;
  min-width: 44px;
`;

const Details = styled.div`
  line-height: 1.5;
  overflow-wrap: anywhere;
`;

const TextOverflow = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const None = styled.div`
  color: #8d91a5;
`;

interface IContactDetailsItemProps {
  icon?: JSX.Element;
  title: string;
  description?: string;
  fullWidth?: boolean;
  ellipsis?: boolean;
}

export const ContactDetailsItem: FC<IContactDetailsItemProps> = ({
  icon,
  title,
  description,
  ellipsis,
  fullWidth,
}) => (
  <Wrapper width={fullWidth ? '100%' : '220px'}>
    <Icon>{ icon }</Icon>

    <Details>
      <TextBrand mb={5} size="14px">{ title }</TextBrand>

      { description ? ellipsis ? <TextOverflow>{ description }</TextOverflow> : description : <None>None</None> }
    </Details>
  </Wrapper>
);
