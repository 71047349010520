import moment                             from 'moment';
import React, { FC, useEffect, useState } from 'react';
import styled                             from 'styled-components';
import { Select }                         from '../../../select/Select/Select';
import { IRepeatOptionsForm }             from '../RepeatOptionsForm';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const MonthlyOrdinalOptions: FC<IRepeatOptionsForm> = ({ form }) => {
  const [ ordinal, setOrdinal ] = useState(0);
  const [ ordinalOptions, setOrdinalOptions ] = useState<any[]>([]);

  const day = moment(new Date());
  const dayNumber = day.date();
  const dayName = day.format('dddd');
  const weekNumber = Math.floor(dayNumber / 7);

  const weekNumberWord = weekNumber >= 0 && weekNumber <= 3
    ? [ 'First', 'Second', 'Third', 'Fourth' ][weekNumber] : '';

  const resetAllValues = () => {
    form?.setFieldValue('recursDay', null);
    form?.setFieldValue('recursOrdinal', null);
    form?.setFieldValue('recursWeekDays', []);
  };

  useEffect(() => {
    if (ordinal) {
      form?.setFieldValue('recursDay', null);
      form?.setFieldValue('recursOrdinal', weekNumberWord);
      form?.setFieldValue('recursWeekDays', [ dayName.toUpperCase() ]);
      setOrdinal(1);
    } else {
      form?.setFieldValue('recursDay', dayNumber);
      form?.setFieldValue('recursOrdinal', null);
      form?.setFieldValue('recursWeekDays', []);
      setOrdinal(0);
    }
  }, [ ordinal ]);

  useEffect(() => {
    setOrdinalOptions([ {
      id    : 0,
      value : `Monthly on day ${ dayNumber }`,
    }, {
      id    : 1,
      value : `Monthly on the ${ weekNumberWord?.toLowerCase() } ${ dayName }`,
    } ]);
  }, [ form?.values?.recurs ]);

  useEffect(() => () => {
    resetAllValues();
  }, []);

  return (
    <Wrapper>
      <Select
        fullWidth
        options={ordinalOptions}
        value={ordinal}
        onChange={setOrdinal}
      />
    </Wrapper>
  );
};
