import { ListItemAvatar }     from '@material-ui/core';
import React                  from 'react';
import styled                 from 'styled-components';
import IconDocument           from '../../../../../images/icons/documents-icon@3x.png';
import { FamilyMemberCircle } from '../../../Dashboard/components/FamilyMembers/FamilyMemberCircle';

const Wrapper = styled(ListItemAvatar)`&& {
  min-width: 55px;
}`;

export const DocumentIcon = () => (
  <Wrapper>
    <FamilyMemberCircle
      color={'#6f90d9'}
      image={IconDocument}
    />
  </Wrapper>
);
