import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
  CloseIconWrapper,
  CheckIconWrapper,
  TableCellWrapper,
  PremiumTableCellWrapper,
  PremiumCellWrapper,
  PremiumColumWrapper,
  FreeColumWrapper,
} from './styles';

const rows = [
  {
    id          : 1,
    features    : 'Secure Document storage and sharing',
    freePlan    : 'Up to 8 documents per category per family',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 2,
    features    : 'Create custom Categories (Docs, Events, Tasks, Contacts)',
    freePlan    : <CloseIconWrapper />,
    premiumPlan : <CheckIconWrapper />,
  },
  {
    id          : 3,
    features    : 'Shared Calendar',
    freePlan    : 'Up to 15 events per month per family',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 4,
    features    : 'Messaging',
    freePlan    : 'Unlimited',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 5,
    features    : 'Geolocation Check-In',
    freePlan    : 'Unlimited',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 6,
    features    : 'Emergency Contacts and information',
    freePlan    : 'Unlimited',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 7,
    features    : 'Task and chore management',
    freePlan    : 'Unlimited',
    premiumPlan : 'Unlimited',
  },
  {
    id          : 8,
    features    : 'New features soon to come',
    freePlan    : <CloseIconWrapper />,
    premiumPlan : <CheckIconWrapper />,
  },
];

export const DataTable = () => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCellWrapper className="table-header">Features</TableCellWrapper>
          <TableCellWrapper align="left">Free Plan</TableCellWrapper>
          <PremiumTableCellWrapper align="left">Premium Plan</PremiumTableCellWrapper>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.features}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <PremiumCellWrapper component="th" scope="row">
              {row.features}
            </PremiumCellWrapper>
            <FreeColumWrapper align="left">{row.freePlan}</FreeColumWrapper>
            <PremiumColumWrapper align="left">{row.premiumPlan}</PremiumColumWrapper>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

