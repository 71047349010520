import { ListItemAvatar }                            from '@material-ui/core';
import React, { FC, useEffect, useState }            from 'react';
import { useHistory }                                from 'react-router-dom';
import { useLoadContactsPage }                       from '../../../shared/components/modals/UploadContact/queries';
import { UploadContactModal }                        from '../../../shared/components/modals/UploadContact/UploadContactModal';
import { EAppRoutes }                                from '../../../shared/constants/constants';
import { documentTypes }                             from '../../../shared/documentTypes';
import { fullName, generateInitials }                from '../../../shared/utils/commonFunctions';
import { NoActivityScreen, NoActivityScreenWrapper } from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                     from '../../Activity/ActivityRecordPreview';
import { FamilyMemberCircle }                        from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { EntitiesListPreview }                       from '../shared/EntitiesListPreview';
import { ContactMenuItems }                          from './components/ContactMenuItems';

interface IContactsListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

export const ContactsListPreview: FC<IContactsListPreviewProps> = ({ familyId, familyMemberId }) => {
  const [ addContactModalOpen, setAddContactModalOpen ] = useState(false);
  const [ showViewAllSection, setShowViewAllSection ] = useState(false);
  const { data: contacts, isFetching } = useLoadContactsPage(familyId, familyMemberId);
  const items = contacts?.pages?.[0]?.items;
  const history = useHistory();

  const onViewAll = () => {
    history.push(`${ EAppRoutes.ContactsSummary }${ familyMemberId ? `?id=${ familyMemberId }` : '' }`);
  };

  const viewContact = (contactId?: string) => {
    history.push(`${ EAppRoutes.ContactsSummary }/${ contactId }`);
  };

  useEffect(() => {
    setShowViewAllSection(!!(contacts?.pages?.[0]?.totalCount && contacts?.pages?.[0]?.totalCount > 0));
  }, [ contacts?.pages?.[0]?.totalCount ]);

  return (
    <>
      <EntitiesListPreview
        showViewAll={showViewAllSection}
        title="Contacts"
        count={contacts?.pages?.[0]?.totalCount}
        loading={isFetching}
        onViewAll={onViewAll}
        onAdd={() => setAddContactModalOpen(true)}
      >
        { items?.length ? items?.map((contact, index, array) => {
          const icon = (
            <ListItemAvatar>
              <FamilyMemberCircle
                color={contact?.creator?.color}
                image={contact?.creator?.avatar?.compactPath}
                initials={generateInitials(contact?.creator?.firstName, contact?.creator?.lastName)}
              />
            </ListItemAvatar>
          );

          return (
            <ActivityRecordPreview
              key={index}
              showMenu
              icon={icon}
              description={fullName(contact?.firstName, contact?.lastName)}
              details={<span>{ contact?.title }</span>}
              isLast={index === array.length - 1}
              menuItems={<ContactMenuItems
                contact={contact}
                familyId={familyId}
              />}
              onClick={() => viewContact(contact?.id)}
            />
          );
        }) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.contacts.icon }
              There are no contacts available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListPreview>

      { addContactModalOpen && (
        <UploadContactModal
          familyId={familyId}
          open={addContactModalOpen}
          onClose={() => setAddContactModalOpen(false)}
        />
      ) }
    </>
  );
};
