import { Geolocation }                     from '@capacitor/geolocation';
import { Position }                        from '@capacitor/geolocation/dist/esm/definitions';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import LocationOnIcon                      from '@material-ui/icons/LocationOn';
import React, { FC, useEffect, useState }  from 'react';
import styled                              from 'styled-components';
import { AlertDialog }                     from '../../../shared/components/modals/AlertDialog';
import { Loader }                          from '../../../shared/enhancers/withLoader';
import { datePassed, generateInitials }    from '../../../shared/utils/commonFunctions';
import { isNative }                        from '../../../shared/utils/platform';
import { IFamilyMember }                   from '../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMemberCircle }              from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { ICheckin }                        from '../queries';
import { CheckInModal }                    from './CheckInModal';

const EmptyCheckinNote = styled(Typography)`
  font-family: Lato;
  font-weight: bold;
  align-self : center;
`;

const CheckinBase = styled(Paper)`
  padding: 14px 15px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 14px 15px 14px 24px;
  }
`;

const CheckinContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const CheckinContent = styled(Grid)`
  padding: 0 14px;
`;

const CheckinInfo = styled(Grid)`
  padding-bottom: 10px;
  flex-basis: auto;

  .MuiTypography-root:first-child {
    font-size: 15px;
    line-height: 18px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 0;
    flex-basis: calc(100% - 121px);
  }
`;

const CheckinAddress = styled(Typography)`
  color: #8d91a5;
  font-size: 12px;
  padding-top: 2px;
  line-height: 16px;
`;

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    width: 100%;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      width: auto;
    }
  }
`;

interface ICurrentUserCheckinProps {
  familyId?: string;
  checkin?: ICheckin;
  user?: IFamilyMember;
  isLoading: boolean;
  position?: Position;
}

export const CurrentUserCheckin: FC<ICurrentUserCheckinProps> = ({
  familyId,
  checkin,
  isLoading,
  position,
  user,
}) => {
  const [ checkInModalOpen, setCheckInModalOpen ] = useState(false);
  const [ gpsDisabledModalOpen, setGpsDisabledModalOpen ] = useState(false);

  const emptyCheckin = (
    isLoading ? <Loader /> : (
      <EmptyCheckinNote variant="h3">
        Keep your family in the loop, add your first check in!
      </EmptyCheckinNote>
    )
  );

  const lastCheckinContent = (
    checkin ? (
      <div>
        <Typography variant="body2">
          Checked in { datePassed(checkin?.createdAt || checkin?.updatedAt, true) }
        </Typography>

        <CheckinAddress variant="subtitle1">
          { `${ checkin?.address }` }
        </CheckinAddress>
      </div>
    ) : emptyCheckin
  );

  return (
    <CheckinBase>
      <CheckinContainer container>
        <CheckinInfo item>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <FamilyMemberCircle
                width="38px"
                color={user?.color}
                image={user?.avatar?.compactPath}
                initials={generateInitials(user?.firstName, user?.lastName)}
              />
            </Grid>

            <CheckinContent item xs>
              { lastCheckinContent }
            </CheckinContent>
          </Grid>
        </CheckinInfo>

        <ButtonWrapper item xs>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LocationOnIcon />}
            disabled={!position?.coords?.latitude}
            onClick={() => setCheckInModalOpen(true)}
          >
            Check In
          </Button>
        </ButtonWrapper>
      </CheckinContainer>

      { checkInModalOpen && (
        <CheckInModal
          familyId={familyId || ''}
          coords={position?.coords}
          onClose={() => setCheckInModalOpen(false)}
        />
      ) }

      <AlertDialog
        dialogWidth="350px"
        title="Enable GPS"
        open={gpsDisabledModalOpen}
        content={`Please enable location permission in ${ isNative ? 'device' : 'browser' }  settings in order to use Check In feature.`}
        handleCancel={() => setGpsDisabledModalOpen(false)}
        cancelButtonText="Close"
      />
    </CheckinBase>
  );
};
