import * as Yup from 'yup';

export const notificationPreferencesValidationSchema = Yup.object().shape({
  muteAllNotifications: Yup.boolean().nullable(),

  remindersMyTasks   : Yup.boolean().nullable(),
  remindersAllTasks  : Yup.boolean().nullable(),
  remindersMyEvents  : Yup.boolean().nullable(),
  remindersAllEvents : Yup.boolean().nullable(),
  remindersBirthday  : Yup.boolean().nullable(),

  generalDailySummaries       : Yup.boolean().nullable(),
  generalIncomingChatMessages : Yup.boolean().nullable(),
  generalAboutMeUpdates       : Yup.boolean().nullable(),

  familyNotifyMeForAllUpdates          : Yup.boolean().nullable(),
  familyNotifyMeForOnlyTaggedUpdates   : Yup.boolean().nullable(),
  familyNotifyMeForCompleteTasks       : Yup.boolean().nullable(),
  familyNotifyMeForCheckin             : Yup.boolean().nullable(),
  familyNotifyMeForDisabledGeolocation : Yup.boolean().nullable(),

  contactGeneralMarketing    : Yup.array().of(Yup.string()).nullable(),
  contactDailySummaries      : Yup.array().of(Yup.string()).nullable(),
  contactNewMembersJoin      : Yup.array().of(Yup.string()).nullable(),
  contactReminders           : Yup.array().of(Yup.string()).nullable(),
  contactNewItemsAdded       : Yup.array().of(Yup.string()).nullable(),
  contactUpdatesAndDeletions : Yup.array().of(Yup.string()).nullable(),
  contactDocumentsViewAlert  : Yup.array().of(Yup.string()).nullable(),
  contactIncomingChatMessage : Yup.array().of(Yup.string()).nullable(),

  news: Yup.boolean().nullable(),
});
