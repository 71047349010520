import React, { FC }   from 'react';
import { StatusBadge } from '../../../../shared/components/documents/styles';
import { ETaskStatus } from '../../../../shared/constants/constants';

interface ITaskStatusProps {
  status?: string;
  mb?: string;
}

export const TaskStatus: FC<ITaskStatusProps> = ({ status = ETaskStatus.Open, mb }) => {
  const color = {
    [ETaskStatus.Open]     : '#5071bb',
    [ETaskStatus.Added]    : '#5071bb',
    [ETaskStatus.Changed]  : '#f1a226',
    [ETaskStatus.Complete] : '#0dc695',
    [ETaskStatus.Deleted]  : '#747474',
    [ETaskStatus.Overdue]  : '#dc3505',
  };

  return (
    <StatusBadge color={color[status as ETaskStatus]} mb={mb}>
      { status }
    </StatusBadge>
  );
};
