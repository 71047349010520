import { IconButton }                     from '@material-ui/core';
import React, { FC }                      from 'react';
import styled                             from 'styled-components';
import { ReactComponent as IconDocument } from '../../../../images/icons/icon-document.svg';
import { IconRemove }                     from '../../../modules/MyFamilyCore/shared/styles';
import { formatBytes }                    from '../../utils/commonFunctions';

export const DocumentPreviewWrapper = styled.div`
  width: 100%;
  flex-grow: 0;
  display: flex;
  margin: 0 14px 0 0;
  border-radius: 6px;
  align-items: center;
  border: solid 1px #e9e9e9;
  background-color: #fafafa;
  padding: 10px 0 10px 10px;
`;

export const DocumentThumbnail = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 76px);
  padding-left: 10px;
`;

export const FileName = styled.div`
  font-size: 12px;
  padding-bottom: 5px;
  color: #272932;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FileDetails = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #979797;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DocumentPreviewIcon = styled(IconDocument)`
  width: 35px;
  height: 40px;
`;

interface IDocumentPreviewProps {
  file?: File;

  onRemove?(): void;
}

export const DocumentPreview: FC<IDocumentPreviewProps> = ({ file, onRemove }) => (
  <DocumentPreviewWrapper>
    <DocumentPreviewIcon />

    <DocumentThumbnail>
      <FileName>{ file?.name }</FileName>
      <FileDetails>{ file?.type?.split('/')?.reverse()[0] } • { formatBytes(file?.size) }</FileDetails>
    </DocumentThumbnail>

    { onRemove && (
      <IconButton
        color="inherit"
        onClick={() => onRemove && onRemove()}
      >
        <IconRemove />
      </IconButton>
    ) }
  </DocumentPreviewWrapper>
);
