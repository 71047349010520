import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { FastField, FormikProps }                    from 'formik';
import React, { FC, useEffect, useMemo, useState }   from 'react';
import { NumberInputField }                          from '../../../formFields/NumberInputField';
import { SelectDateField }                           from '../../../formFields/SelectDateField';
import { IRepeatOptions }                            from '../../modals/shared';
import { RepeatOptionsLabel }                        from '../RepeatOptions';

export const EndsOn: FC<{ form: FormikProps<IRepeatOptions> }> = ({ form }) => {
  const { recursEndDate, recursCount } = form?.values;
  const [ radioButton, setRadioButton ] = useState(0);

  useEffect(() => {
    if (!recursCount && !recursEndDate) {
      setRadioButton(0);
    } else if (recursEndDate && !recursCount) {
      setRadioButton(1);
    } else {
      setRadioButton(2);
    }
  }, []);

  useEffect(() => () => {
    form?.setFieldValue('recursCount', null);
  }, []);

  const onNeverClick = () => {
    form?.setFieldValue('recursEndDate', null);
    setTimeout(() => form?.setFieldValue('recursCount', null));
    setRadioButton(0);
  };

  const onOnDateClick = () => {
    // form?.setFieldValue('recursEndDate', new Date());
    setTimeout(() => form?.setFieldValue('recursCount', null));
    setRadioButton(1);
  };

  const onAfterClick = () => {
    form?.setFieldValue('recursEndDate', null);
    setTimeout(() => form?.setFieldValue('recursCount', 1));
    setRadioButton(2);
  };

  useEffect(() => {
    if (!radioButton) {
      onNeverClick();
    } else if (radioButton === 1) {
      onOnDateClick();
    } else {
      onAfterClick();
    }
  }, [ radioButton ]);

  return useMemo(() => (
    <RadioGroup value={radioButton}>
      <RepeatOptionsLabel>Ends On</RepeatOptionsLabel>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControlLabel
            label="Never"
            value={0}
            control={<Radio />}
            onClick={onNeverClick}
          />
        </Grid>

        <Grid item xs={8} />

        <Grid item xs={4}>
          <FormControlLabel
            label="On"
            value={1}
            control={<Radio />}
            onClick={onOnDateClick}
          />
        </Grid>

        <Grid item xs={8}>
          <FastField
            name={(radioButton === 1) && "recursEndDate"}
            component={SelectDateField}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControlLabel
            label="After"
            value={2}
            control={<Radio />}
            onClick={onAfterClick}
          />
        </Grid>

        <Grid item xs={8}>
          <FastField
            key={recursCount}
            decimals={0}
            name="recursCount"
            placeholder="13 occurrences"
            allowNegative={false}
            component={NumberInputField}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  ), [ radioButton ]);
};
