import { Badge, ListItemAvatar } from '@material-ui/core';
import { FC }                    from 'react';
import { EAccountStatus }        from '../../../shared/constants/constants';
import { generateInitials }      from '../../../shared/utils/commonFunctions';
import { IFamilyMember }         from '../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMemberCircle }    from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';

interface IEntityRecordAvatarProps {
  badge?: JSX.Element | string | number;
  member?: IFamilyMember;
  label?: string;
}

export const EntityRecordAvatar: FC<IEntityRecordAvatarProps> = ({
  badge,
  member,
  label,
}) => {
  const initials = label || generateInitials(member?.firstName, member?.lastName);

  return (
    <ListItemAvatar>
      <Badge
        overlap="rectangular"
        badgeContent={badge}
        aria-controls="user-menu"
        aria-haspopup="true"
      >
        <FamilyMemberCircle
          color={member?.color}
          image={member?.avatar?.compactPath}
          initials={initials}
          outlined={member?.status === EAccountStatus.Pending}
        />
      </Badge>
    </ListItemAvatar>
  );
};
