import React, { FC, useEffect, useState } from 'react';
import { useIsFetching }                  from 'react-query';
import { InfiniteData }                   from 'react-query/types/core/types';
import { useHistory }                     from 'react-router-dom';
import { queryClient }                    from '../../../App';
import { EAppRoutes }                     from '../../../shared/constants/constants';
import { ActivityItems }                  from '../../Activity/ActivityItems';
import { EntitiesListPreview }            from '../shared/EntitiesListPreview';

interface IEventsListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

export const ActivityListPreview: FC<IEventsListPreviewProps> = ({ familyId, familyMemberId }) => {
  const history = useHistory();
  const [ showViewAllSection, setShowViewAllSection ] = useState(false);
  const [ activitiesList, setActivitiesList ] = useState<InfiniteData<any>>();

  // How many queries matching the posts prefix are fetching?
  const isFetchingPosts = useIsFetching('ACTIVITIES');

  useEffect(() => {
    const activities: any = queryClient.getQueryData('userActivities');
    setActivitiesList(activities);
  }, [ isFetchingPosts ]);

  useEffect(() => {
    setShowViewAllSection(!!(activitiesList?.pages?.[0]?.totalCount && activitiesList?.pages?.[0]?.totalCount > 0));
  }, [ activitiesList, activitiesList?.pages?.[0]?.totalCount, familyMemberId ]);

  const onViewAll = () => {
    history.push(`${ EAppRoutes.Activity }/all${ familyMemberId ? `?id=${ familyMemberId }` : '' }`);
  };

  return (
    <EntitiesListPreview
      showViewAll={showViewAllSection}
      title="Most Recent Activity"
      onViewAll={onViewAll}
    >
      <ActivityItems
        isPreviewPage
        familyId={familyId}
        familyMemberId={familyMemberId}
      />
    </EntitiesListPreview>
  );
};
