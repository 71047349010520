import React, { FC, useState }                                      from 'react';
import styled                                                       from 'styled-components';
import { ReactComponent as IconAdd }                                from '../../../images/main-add-icon.svg';
import { AddToFamilyCore }                                          from '../../modules/Dashboard/components/FamilyDocuments/AddToFamilyCore';
import { IconButtonSpaced }                                         from '../Header';
import { SystemPopover, SystemPopoverContent, SystemPopoverHeader } from './SystemPopover';

const Content = styled(SystemPopoverContent)`
  padding: 4px 20px 26px;
`;

interface IAddToFamilyCorePopoverProps {
  familyId?: string;
  familyMemberId?: string;
}

export const AddToFamilyCorePopover: FC<IAddToFamilyCorePopoverProps> = ({ familyId, familyMemberId }) => {
  const [ popoverAnchorEl, setPopperAnchorEl ] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopperAnchorEl(null);
  };

  return (
    <>
      <IconButtonSpaced
        color="inherit"
        onClick={handleClick}
      >
        <IconAdd />
      </IconButtonSpaced>

      <SystemPopover
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
      >
        <SystemPopoverHeader>
          Add to Family Core
        </SystemPopoverHeader>

        <Content>
          <AddToFamilyCore
            familyId={familyId}
            familyMemberId={familyMemberId}
          />
        </Content>
      </SystemPopover>
    </>
  );
};
