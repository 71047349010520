import React                          from 'react';
import { ITextInputProps, TextInput } from '../TextInput/TextInput';
import { PhoneNumberMask }            from './PhoneNumberMask';

export const PhoneInput = (props: ITextInputProps) => (
  <TextInput
    {...props}
    type="tel"
    InputProps={{ inputComponent: PhoneNumberMask }}
  />
);
