import 'core-js';
import React                 from 'react';
import ReactDOM              from 'react-dom';
import './app/shared/utils/yupMethods';
import App                   from './app/App';
import { isNative }          from './app/shared/utils/platform';
import { checkSubscription } from './app/utils/subscription';

const startApp = () => {
  if (isNative) {
    document.addEventListener('deviceready', checkSubscription);
  }

  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
};

startApp();

