import { useField }                        from 'formik';
import React, { FC }                       from 'react';
import styled                              from 'styled-components';
import { ReactComponent as IconCheckMark } from '../../../../images/icons/selected-check.svg';
import { EPlatform, TContactPlatform }     from './PlatformSelector';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Unchecked = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 20px;
  border: solid 1px #979797;
  background-color: rgba(196, 196, 196, 0);
`;

const IconHolder = styled.div<{ disabled: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: ${ ({ disabled }) => disabled ? .2 : 1 };

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.div<{ displayLabels?: boolean }>`
  font-size: 14px;
  line-height: 1.57;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: rgba(39, 41, 50, 0.7);
  width : 98px;
  text-align: center;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    font-size: 18px;
    margin-bottom: 40px;
    display: ${ ({ displayLabels }) => displayLabels ? 'block' : 'none' }
  }
`;

interface IContactReferenceProps {
  option: TContactPlatform;
  allowed: TContactPlatform[];
  fieldName: string;
  onChange?: (option: TContactPlatform) => void;
  displayLabels?: boolean;
}

export const ContactReference: FC<IContactReferenceProps> = ({
  option,
  fieldName,
  displayLabels,
  onChange,
  allowed,
}) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [ field, meta, helpers ] = useField(fieldName);

  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const isAllowed = option === EPlatform.all || allowed.includes(option);
  const restOptions = allowed.filter((el: string) => el !== EPlatform.all);
  const allSelected = restOptions.length === value.length;

  const toggleSelection = (option: TContactPlatform) => {
    if (!isAllowed) {
      return;
    }

    const optionIndex = value.indexOf(option);

    if (option === EPlatform.all) {
      setValue(!allSelected ? restOptions : []);
    } else if (optionIndex >= 0) {
      setValue(value.filter((el: TContactPlatform) => el !== option));
    } else {
      setValue([ ...value, option ]);
    }

    !!onChange && onChange(option);
  };

  const isOptionChecked = (isAllowed && allSelected) || value.includes(option);

  return (
    <Wrapper>
      <Label displayLabels={displayLabels}>{ option }</Label>

      <IconHolder
        disabled={!isAllowed}
        onClick={() => toggleSelection(option)}
      >
        { isOptionChecked ? <IconCheckMark /> : <Unchecked /> }
      </IconHolder>
    </Wrapper>
  );
};
