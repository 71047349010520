import { Popover }   from '@material-ui/core';
import React, { FC } from 'react';
import styled        from 'styled-components';

interface ISystemPopoverProps {
  anchorEl: any;

  onClose(): void;
}

const SystemPopoverWrapper = styled(Popover)`&& {

}`;

export const SystemPopoverContent = styled.div`
  width: 380px;
  max-height: 340px;
  max-width: 100%;
  overflow: auto;
`;

export const SystemPopoverHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: Jost;
  color: #2c3f69;
  display: flex;
  padding: 20px 20px 0;
  align-items: center;
  justify-content: space-between;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 24px;
  }
`;

export const SystemPopover: FC<ISystemPopoverProps> = ({ anchorEl, onClose, children }) => anchorEl && (
  <SystemPopoverWrapper
    elevation={3}
    open={!!anchorEl}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical   : 'bottom',
      horizontal : 'center',
    }}
    transformOrigin={{
      vertical   : 'top',
      horizontal : 'right',
    }}
    onClose={onClose}
  >
    { children }
  </SystemPopoverWrapper>
);
