import { IFamilyMember } from '../../../../../shared/utils/withAuthorization/withAuthorization';

export interface ITaggedItem {
  id: string;
  familyItemType: string;
  name: string;
  familyName: string;
  taggedItemsOption: string;
  users: string[];
  createdBy: IFamilyMember;
  entityId: string;
}

export enum EFamilyItemType {
  Event    = 'Event',
  Contact  = 'Contact',
  Document = 'Document',
  Task     = 'Task'
}
