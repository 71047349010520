import styled from 'styled-components';

interface IFaderProps {
  visible: boolean;
}

export const FadeContainer = styled.div<IFaderProps>`
  transition: opacity .5s ease;
  opacity: ${ ({ visible }) => visible ? 1 : 0 };
`;
