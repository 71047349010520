import {
  Dialog,
  DialogContent,
  DialogTitle,
}                from '@material-ui/core';
import styled    from 'styled-components';
import { theme } from '../../../../styles';

export const StyledUploadDialog = styled(Dialog)`
  && {
    margin: auto;

    .MuiDialog-paper {
      max-height: calc(100% - 65px);
      transition : all 1s ease;
      width      : 695px;
      min-height : 365px;
      max-width  : 100%;
      margin     : 10px;

      ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
        margin: 32px;
      }
    }
  }
` as any;

export const UploadDialogTitle = styled(DialogTitle)`
  && {
    padding: 20px;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    font-family: 'Jost';
    font-weight: 500;
    color: ${ theme.colors.info };
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const UploadDialogContent = styled(DialogContent)`
  && {
    padding: 0;
  }
`;
