import { Dialog, DialogProps }                from '@material-ui/core';
import { Clear }                               from '@material-ui/icons';
import React, { FC, useState }                 from 'react';
import { NavLink }                             from 'react-router-dom';
import styled                                  from 'styled-components';
import IconLock                                from '../../../../images/icons/password-lock-icon.svg';
import { CodeInput, SecurityCodeErrorMessage } from '../../../Auth/shared/styles';
import { CHANGE_PIN_ROUTE }                    from '../../../modules/Settings/components/ManageFamilyAccount/ManageFamilyAccount';
import { CloseModalIcon }                      from '../../styledComponents';
import { CircularProgressWithLabel }           from '../progress/CircularWithLabel';

const PinCodeIcon = styled.img`
  margin: 0 auto 20px;
  width: 75px;
`;

const StyledDialog = styled(Dialog)`&& {
  margin: auto;

  .MuiDialog-paper {
    font-size      : 14px;
    transition     : all 1s ease;
    width          : 511px;
    min-height     : 457px;
    max-width      : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }
}` as any;

const DialogTitle = styled.div`
  margin: 0 0 24px;
  font-family: Jost;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #2c3f69;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 32px;
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 1.3em;
  text-align: center;
`;

interface IAlertDialogProps extends DialogProps {
  action?: string;
  attachmentId?: string;
  progress?: number;

  onSuccess(pin: string, options: any): void;

  onClose(): void;
}

export const ProvidePinModal: FC<IAlertDialogProps> = ({
  onSuccess,
  onClose,
  action,
  progress,
  ...props
}) => {
  const [ pinCode, setPinCode ] = useState('');
  const [ validationMessage, setValidationMessage ] = useState('');

  const checkPinCode = (code: string) => {
    if (code && code.length === 4) {
      onSuccess(code, {
        onError: () => {
          setValidationMessage('Invalid pin please try again');
        },
      });
    }
  };

  return (
    <StyledDialog
      onClose={onClose}
      {...props}
    >
      <PinCodeIcon src={IconLock} />

      <DialogTitle>
        Please Enter Your Pin
      </DialogTitle>

      <span>
        Enter your 4 digit code to { action || 'download and view' }
      </span>

      <CodeInput
        length={4}
        placeholder={''}
        value={pinCode}
        onChange={(value) => {
          setPinCode(value);
          setValidationMessage('');
          checkPinCode(value);
        }}
      />

      { !!progress && (progress < 100) && (
        <CircularProgressWithLabel
          unit=""
          margin="5px"
          fontSize="20px"
          value={progress}
        />
      ) }

      <ErrorWrapper>
        { validationMessage && (
          <SecurityCodeErrorMessage position="relative">{ validationMessage }</SecurityCodeErrorMessage>
        ) }
      </ErrorWrapper>

      <span>
        Forgot your pin? <NavLink to={CHANGE_PIN_ROUTE}>Reset Pin</NavLink>
      </span>

      <CloseModalIcon
        aria-label="close modal window"
        onClick={onClose}
      >
        <Clear />
      </CloseModalIcon>

    </StyledDialog>
  );
};
