import axios, { AxiosResponse }                from 'axios';
import { useMutation, useQueryClient }         from 'react-query';
import { IErrorResponse, IRequestResponse }    from '../../../../../Auth/shared/interfaces';
import { Notistack }                           from '../../../../../shared/components/Notistack/Notistack';
import { TCreateQueryOptions, useCreateQuery } from '../../../../../shared/utils/hooks/useReactQuery';
import { CHATS }                               from '../../../../MessageCenter/queries';
import { IFamilyUserPermissions }              from '../../ManageFamilyAccount/ManageAccountForm/ManageAccountForm';

export const FAMILY_USER_PERMISSIONS_GET = 'FAMILY_USER_PERMISSIONS_GET';
export const FAMILY_USER_PERMISSIONS_UPDATE = 'FAMILY_USER_PERMISSIONS_UPDATE';

export const useFetchUserPermissions = (
  familyUserId?: string,
  options?: TCreateQueryOptions<IFamilyUserPermissions>
) => useCreateQuery<IFamilyUserPermissions>({
  queryKey : [ FAMILY_USER_PERMISSIONS_GET, familyUserId ],
  apiUrl   : `/api/v1/family-users-permissions/${ familyUserId }`,
  options  : {
    enabled              : !!familyUserId,
    refetchOnWindowFocus : false,
    ...options,
  },
});

export const updateUserPermissions = (
  data: IFamilyUserPermissions,
  familyUserId?: string,
) =>
  axios.put(`/api/v1/family-users-permissions/${ familyUserId }`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamilyUserPermissions>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateUserPermissions = (
  familyUserId?: string,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    FAMILY_USER_PERMISSIONS_UPDATE,
    (
      data: IFamilyUserPermissions
    ) => updateUserPermissions(data, familyUserId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CHATS);
        Notistack.enqueueSnackbar('User permissions were successfully updated.', 'success');
      },
    }
  );
};
