import qs                                                    from 'qs';
import React, { FC, useEffect, useState }                    from 'react';
import { NavLink, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import styled                                                from 'styled-components';
import { useFetchSelectedFamily }                            from '../../../../shared/components/SelectFamily/queries';
import { EAppRoutes }                                        from '../../../../shared/constants/constants';
import { queryParams, withQueryParams }                      from '../../../../shared/functions';
import { useFetchFamilyMembers }                             from '../../../../shared/queries/family';
import { IFamilyMember }                                     from '../../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMembersList }                                 from '../../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { SettingsPageWrapper }                               from '../../shared/SettingsPageWrapper';
import { AboutUser }                                         from './About/About';
import { AccountForm }                                       from './Account/AccountForm';
import { AssignedItems }                                     from './AssignedItems/AssignedItems';
import { PermissionsForm }                                   from './PermissionsForm/PermissionsForm';
import { TaggedItems }                                       from './TaggedItems/TaggedItems';

const SidebarNavigation = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  width: calc(100% + 40px);
  gap: 30px;
  white-space: nowrap;
  margin: 0 -20px -30px;
  padding: 0 20px;
  overflow: auto;
  position: relative;
  z-index: 1;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    flex-direction: column;
    width: 100%;
    margin: 0;
    gap: 0;
    padding: 0;
  }
`;

const SidebarWrapper = styled.div`
  width: 100%;
  line-height: 22px;
  align-self: flex-start;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    display: block;
  }
`;

const SidebarHeader = styled.div`
  font-family: Jost;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 1.3;
  color: #e4572e;
  margin-bottom: 9px;
  text-transform: capitalize;
`;

const PageHeader = styled.div`
  background: #f7fafd;
  padding: 30px 20px 5px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 30px 30px 5px 260px;
  }
`;

const StyledNavLink = styled(NavLink)<{ active: string }>`&& {
  display: block;
  padding: 20px 0;
  font-family: Jost;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #2c3f69;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    border-bottom: 1px solid #e9e9e9;
  }

  &.active {
    color: #547cd3;
  }

  &:last-child {
    border: none;
  }
}`;

const ManageUsersPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ManageUsers: FC = () => {
  const { data: family } = useFetchSelectedFamily();
  const { data: familyMembers, isLoading: loadingMembers, refetch: refetchFamilyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const [ selectedMember, setSelectedMember ] = useState<IFamilyMember>();
  const params = queryParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const withId = (route: string, id?: string) => withQueryParams(route, { id });

  const sidebar = [ {
    name : 'Account',
    to   : withId(EAppRoutes.ManageUsersAccount, params.id),
  }, {
    name : 'About',
    to   : withId(EAppRoutes.ManageUsersAbout, params.id),
  }, {
    name : 'Permissions',
    to   : withId(EAppRoutes.ManageUsersPermissions, params.id),
  }, {
    name : 'Tagged Items',
    to   : withId(EAppRoutes.ManageUsersTaggedItems, params.id),
  }, {
    name : 'Assigned Items',
    to   : withId(EAppRoutes.ManageUsersAssignedItems, params.id),
  } ];

  const refresh = (id: string) => {
    history.push({
      search: qs.stringify({
        id,
        ...queryParams(),
      }),
    });
  };

  useEffect(() => {
    if (familyMembers?.length) {
      if (!params.id) {
        setSelectedMember(familyMembers[0]);
        refresh(familyMembers[0]?.id);
      } else if (selectedMember?.id !== params.id) {
        const member = familyMembers?.find(member => member.id === params.id);
        if (member) {
          setSelectedMember(member);
          refresh(member?.id);
        }
      }
    }
  }, [ loadingMembers, params ]);

  const selectMember = (member?: IFamilyMember) => {
    if (member) {
      setSelectedMember(member);

      if (params.id) {
        if (!member.viewAboutMe && pathname.indexOf('/about') > 0) {
          history.push(withId(EAppRoutes.ManageUsersAccount, member?.id));
        } else {
          history.push(withId(pathname, member?.id));
        }
      }
    }
  };

  const Sidebar = () => (
    <SidebarWrapper>
      <SidebarHeader>
        { selectedMember?.firstName } { selectedMember?.lastName }
      </SidebarHeader>

      <SidebarNavigation>
        { sidebar.map((item, index) => !(item.name === 'About' && !selectedMember?.viewAboutMe) && (
          <StyledNavLink
            key={index}
            to={item.to}
            active={pathname === item.to ? 'true' : 'false'}
          >
            { item.name }
          </StyledNavLink>
        )) }
      </SidebarNavigation>
    </SidebarWrapper>
  );

  return (
    <ManageUsersPageWrapper>
      <PageHeader>
        <FamilyMembersList
          shaded
          hideAllSelection
          disabledForMembers
          data={familyMembers}
          loading={loadingMembers}
          selected={selectedMember?.id}
          onSelect={(value) => selectMember(familyMembers?.find(member => member.id === value))}
        />
      </PageHeader>

      <SettingsPageWrapper sidebar={Sidebar}>
        <Route
          // key={ selectedMember?.id }
          path={EAppRoutes.ManageUsersAccount}
        >
          <AccountForm
            memberId={selectedMember?.id}
            familyMembers={familyMembers}
            userDataChanged={() => refetchFamilyMembers()}
          />
        </Route>

        <Route path={EAppRoutes.ManageUsersAbout}>
          <AboutUser
            member={selectedMember}
            familyId={family?.id}
          />
        </Route>

        <Route path={EAppRoutes.ManageUsersPermissions}>
          <PermissionsForm memberId={selectedMember?.id} />
        </Route>

        <Route path={EAppRoutes.ManageUsersTaggedItems}>
          <TaggedItems
            familyId={family?.id}
            memberId={selectedMember?.id}
            familyMembers={familyMembers}
          />
        </Route>

        <Route path={EAppRoutes.ManageUsersAssignedItems}>
          <AssignedItems
            familyId={family?.id}
            memberId={selectedMember?.id}
            familyMembers={familyMembers}
          />
        </Route>

        <Route
          exact
          path={`${ EAppRoutes.ManageUsers }`}
        >
          <Redirect to={EAppRoutes.ManageUsersAccount} />
        </Route>
      </SettingsPageWrapper>
    </ManageUsersPageWrapper>
  );
};
