import React, { ChangeEvent, FocusEvent } from 'react';
import MaskedInput                        from 'react-text-mask';

export const ZipCodeMask = ({
  onChange,
  inputRef,
  ...other
}: any) => {
  const zipCodeMask = [ /\d/, /\d/, /\d/, /\d/, /\d/, (other.value?.trim()?.length > 5 ? '-' : /\d/), /\d/, /\d/, /\d/, /\d/ ];
  const placeholderChar = '\u2000';

  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const reverseValue = value.split('').reverse().join('');

    event.target.value = reverseValue.slice(reverseValue.search(/\d/), reverseValue.length)
      .split('').reverse().join('');
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onChange({
      ...event,
      target: {
        name  : other.name,
        value : value || null,
      },
    });
  };

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask
      mask={zipCodeMask}
      placeholderChar={placeholderChar}
      guide={false}
      onChange={onInputChange}
      onFocus={onFocus}
    />
  );
};
