import { IconButton, MenuItem, Menu as MuiMenu, Typography } from '@material-ui/core';
import MoreHorizIcon                                         from '@material-ui/icons/MoreHoriz';
import React, { FC, useRef }                                 from 'react';
import { useHistory }                                        from 'react-router-dom';
import styled                                                from 'styled-components';
import { theme }                                             from '../../../../../styles';
import { MenuAnchor, VerifiedIconWrapper }                   from '../../../../Auth/shared/styles';
import { EAppRoutes }                                        from '../../../../shared/constants/constants';
import { isAdminRoles, isSuperAdmin }                        from '../../../../shared/functions';
import {
  IFamilyMember,
  VisibleForAdminRoles,
}                                                            from '../../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMembersList }                                 from './FamilyMembersList';

export const FamilySection = styled.div`
  color: ${ theme.colors.white };
  background-color: ${ theme.colors.info };
  border-radius: 12px;
  height: 100%;
  padding: 19px 16px 13px;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  text-transform: uppercase;
`;

export const FamilySectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0;
  min-height: 48px;
`;

export const CheckedFamilyMemberBadge = styled.div`
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 100%;
  background-color: #0dc695;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px #00000036;

  svg {
    width: 12px;
    fill: #fff;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 17px;
    height: 17px;

    svg {
      fill: #fff;
    }
  }
`;

export const FamilyMemberCircleWrapper = styled.div<{ allowed?: boolean; selected?: boolean; shifted?: boolean; shaded?: boolean }>`
  margin: ${ ({ shifted }) => shifted ? '0 -15px' : '0' };
  position: relative;
  z-index: 1;
  opacity: ${ ({ shaded, shifted }) => shaded && !shifted ? '0.6' : '1' };

  &:hover {
    cursor: ${ ({ allowed }) => allowed ? 'pointer' : 'default' };
  }

  ${ CheckedFamilyMemberBadge } {
    display: ${ ({ selected }) => selected ? 'flex' : 'none' }
  }
`;

export const FamilySectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconButtonWhite = styled(IconButton)`&& {
  svg {
    fill: #fff;
  }
}`;

export const MembersCount = styled.div`&& {
  font-size: 15px;
  text-transform: none;
  margin-bottom: 10px;
  font-weight: normal;
  letter-spacing: 0.88px;
}`;

const FamilyName = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
`;

export interface IFamilyMembersProps {
  name?: string;
  loading?: boolean;
  data?: IFamilyMember[];
  user?: IFamilyMember;
  isActivePayment?:boolean;
}

export const FamilyMembers: FC<IFamilyMembersProps> = ({
  name,
  data,
  loading,
  user,
  isActivePayment,
}) => {
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const isAdminRole = isAdminRoles(user?.accountType);
  const history = useHistory();
  const menuAnchorRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(menuAnchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (route: string) => {
    history.push(route);
    handleClose();
  };

  return (
    <FamilySection>
      <FamilySectionHeader>
        <FamilyName variant="h5">{ name } Family { isActivePayment && <VerifiedIconWrapper /> }</FamilyName>

        <VisibleForAdminRoles>
          <IconButtonWhite
            size="small"
            aria-haspopup="true"
            aria-controls="family-menu"
            onClick={handleClick}
          >
            <MenuAnchor
              ref={menuAnchorRef}
              color="#fff"
              top="50px"
              right="120px"
            />
            <MoreHorizIcon />
          </IconButtonWhite>
        </VisibleForAdminRoles>
      </FamilySectionHeader>

      <MembersCount>
        { data?.length } Member{ data?.length === 1 ? '' : 's' }
      </MembersCount>

      <FamilySectionContent>
        <FamilyMembersList
          hideAllSelection
          data={data}
          loading={loading}
          onSelect={isAdminRole ? (id) => goTo(`${ EAppRoutes.ManageUsersAccount }?id=${ id }`) : undefined}
        />
      </FamilySectionContent>

      <MuiMenu
        keepMounted
        id="family-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => goTo(`${ EAppRoutes.ManageUsersAccount }?id=${ data?.[0]?.id }`)}>
          Manage Family
        </MenuItem>

        { isSuperAdmin(user?.accountType) && (
          <MenuItem onClick={() => goTo(EAppRoutes.AddMembers)}>Add Member</MenuItem>
        ) }
      </MuiMenu>
    </FamilySection>
  );
};
