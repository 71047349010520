import { Grid, Hidden }       from '@material-ui/core';
import React, { FC }          from 'react';
import { GridDivider }        from '../../../../../../shared/components/layout/GridDivider';
import { filterMembersById }  from '../../../../../../shared/functions';
import { FlexEndGrid }        from '../../../../../../shared/styledComponents';
import { IFamilyMember }      from '../../../../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMembersList }  from '../../../../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { ITaggedItem }        from '../shared';
import { MemberDetails }      from './MemberDetails';
import { TaggedItemControls } from './TaggedItemContols';

interface ITaggedItemRowProps {
  data: ITaggedItem;
  last?: boolean;
  isAssignees?: boolean;
  familyMembers?: IFamilyMember[];

  onRemove(id: string): void;

  onView(): void;
}

export const TaggedItemRow: FC<ITaggedItemRowProps> = ({
  data,
  last,
  isAssignees,
  familyMembers,
  onRemove,
  onView,
}) => {
  const ItemsActions = (
    <TaggedItemControls
      id={data?.id}
      isAssignees={isAssignees}
      onRemove={() => onRemove(data?.entityId || '')}
      onView={onView}
    />
  );

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={7}
        sm={5}
      >
        <MemberDetails
          hideAvatarOnSmallScreen
          data={data?.createdBy}
          title={data?.name}
          subtitle={`${ data.users?.length } Member${ data.users?.length === 1 ? '' : 's' } ${ isAssignees ? 'Assigned' : 'Tagged' }`}
        />
      </Grid>

      <Hidden smUp>
        <FlexEndGrid
          item
          xs={5}
        >
          { ItemsActions }
        </FlexEndGrid>
      </Hidden>

      <FlexEndGrid
        item
        sm={7}
      >
        <FamilyMembersList
          shifted
          noWrap
          fixed
          justify="flex-end"
          data={filterMembersById(familyMembers, data?.users)}
        />

        <Hidden xsDown>
          { ItemsActions }
        </Hidden>
      </FlexEndGrid>

      { !last && (
        <GridDivider margin={'10px 0 20px'} />
      ) }
    </Grid>
  );
};
