import { Grid }                                                                   from '@material-ui/core';
import { AxiosError }                                                             from 'axios';
import { FastField, Form, Formik, FormikProps }                                   from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState }                        from 'react';
import { useLocation }                                                            from 'react-router-dom';
import { IErrorResponse }                                                         from '../../../../Auth/shared/interfaces';
import { GridDivider }                                                            from '../../../../shared/components/layout/GridDivider';
import { useFetchSelectedFamily }                                                 from '../../../../shared/components/SelectFamily/queries';
import { SelectDateField }                                                        from '../../../../shared/formFields/SelectDateField';
import { SelectField }                                                            from '../../../../shared/formFields/SelectField';
import { TextInputField }                                                         from '../../../../shared/formFields/TextInputField';
import {
  EFamilyRole,
  familyRoles,
  useFetchFamilyMembers,
}                                                                                 from '../../../../shared/queries/family';
import { FormSpacing }                                                            from '../../../../shared/styledComponents';
import {
  EFamilyAccountType,
  familyAccountTypes,
}                                                                                 from '../../../../shared/utils/withAuthorization/withAuthorization';
import { ETaggedItemsOptions }                                                    from '../../../Settings/components/ManageFamilyAccount/ManageAccountForm/ManageAccountForm';
import { PermissionsFormFields }                                                  from '../../../Settings/components/ManageUsers/PermissionsForm/PermissionsFormFields';
import { FormFooterActions }                                                      from '../../../Settings/shared/FormFooterActions';
import { PreRegistrationConfirmModal }                                            from './PreRegistrationConfirmModal';
import { IInvite, useAddMemberByAccountCreation, useAddMemberByInvite }           from './queries';
import { IInviteMemberForm, IInviteMemberRequest, createAccountValidationSchema } from './validation';

interface IPreRegistrationFormProps {
  byInvite?: boolean;
}

export const PreRegistrationForm: FC<IPreRegistrationFormProps> = ({ byInvite }) => {
  const { pathname } = useLocation();
  const formikRef = useRef<FormikProps<IInviteMemberForm>>(null);
  const { mutate: sendInviteRequest, isLoading: sendingInvite } = useAddMemberByInvite();
  const { mutate: createAccountRequest, isLoading: creatingAccount } = useAddMemberByAccountCreation();

  const { data: family } = useFetchSelectedFamily();
  const [ memberProfile, setMemberProfile ] = useState<IInvite | null>(null);
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [ pathname ]);

  const initialValues = {
    userType          : EFamilyAccountType.Member,
    familyRole        : EFamilyRole.Parent,
    taggedItemsOption : ETaggedItemsOptions.ViewOnly,
    chatAccess        : true,
    geoLocationAccess : familyMembers?.map(member => member.id) || [],
    editAboutMe       : true,
    aboutMeAccess     : familyMembers?.map(member => member.id) || [],
  };

  const onSubmit = (formValues: IInviteMemberForm) => {
    const [ firstName, lastName ] = formValues?.fullName?.split(/\s+/) || [];

    const request = byInvite ? sendInviteRequest : createAccountRequest;

    request({
      firstName,
      lastName,
      familyId          : family?.id,
      email             : formValues?.email,
      dateOfBirth       : formValues?.dateOfBirth,
      userType          : formValues?.userType,
      familyRole        : formValues?.familyRole,
      joinFamily        : formValues?.joinFamily,
      taggedItemsOption : formValues?.taggedItemsOption,
      chatAccess        : formValues?.chatAccess,
      editAboutMe       : formValues?.editAboutMe,
      geoLocationAccess : formValues?.geoLocationAccess,
      aboutMeAccess     : formValues?.aboutMeAccess,
    } as IInviteMemberRequest, {
      onSuccess: (data) => {
        formikRef?.current?.resetForm();
        setMemberProfile(data);
      },
      onError: (error: AxiosError<IErrorResponse> | any) => {
        const errorDetails = error?.response?.data?.ErrorDetails;

        if (errorDetails) {
          const { Key, Message } = errorDetails;
          formikRef?.current?.setErrors({
            [Key]: Message,
          });
        }
      },
    });
  };

  const FormContent = ({
    dirty,
    handleSubmit,
  }: FormikProps<IInviteMemberForm>) => useMemo(() =>
    (<Form onSubmit={handleSubmit}>
      <FormSpacing>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={10}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={4}
              >
                <FastField
                  label="Full Name"
                  name={'fullName'}
                  inputProps={{ maxLength: 100 }}
                  component={TextInputField}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                { byInvite ? (
                  <FastField
                    label="Email"
                    name={'email'}
                    inputProps={{ maxLength: 100 }}
                    component={TextInputField}
                  />
                ) : (
                  <FastField
                    label="Date of Birth"
                    name={'dateOfBirth'}
                    maxDate={new Date()}
                    component={SelectDateField}
                  />
                ) }
              </Grid>

              <GridDivider margin="10px 0" />

              { byInvite && (
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <FastField
                    label="Member Type"
                    name="userType"
                    options={familyAccountTypes}
                    component={SelectField}
                  />
                </Grid>
              ) }

              <Grid
                item
                xs={12}
                md={4}
              >
                <FastField
                  label="Family Role"
                  name={'familyRole'}
                  options={familyRoles}
                  component={SelectField}
                />
              </Grid>

              {/* will be implemented in the second phase */ }
              {/* { byInvite && (
                <FlexEndGrid
                  item
                  xs={ 12 }
                  md={ 4 }
                >
                  <SwitchField
                    label="Option to Join Family Group"
                    labelPlacement="start"
                    name="joinFamily"
                  />
                </FlexEndGrid>
              ) } */ }

              <GridDivider margin="10px 0" />

              <Grid
                item
                xs={12}
              >
                <PermissionsFormFields byInvite={byInvite} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSpacing>

      <FormFooterActions
        dirty={dirty}
        isLoading={sendingInvite || creatingAccount}
        formikRef={formikRef}
      />
    </Form>), [ dirty, sendingInvite, creatingAccount, byInvite ]);

  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues as IInviteMemberForm}
        validationSchema={createAccountValidationSchema(byInvite)}
        onSubmit={onSubmit}
      >
        { (props) => <FormContent {...props} /> }
      </Formik>

      <PreRegistrationConfirmModal
        byInvite={byInvite}
        member={memberProfile}
        handleSubmit={() => setMemberProfile(null)}
        handleCancel={() => setMemberProfile(null)}
      />
    </>
  );
};
