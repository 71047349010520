import axios, { AxiosResponse }                                 from 'axios';
import qs                                                       from 'qs';
import { useInfiniteQuery, useMutation }                        from 'react-query';
import { queryClient }                                          from '../../../../App';
import { IErrorResponse, IPaginatedResponse, IRequestResponse } from '../../../../Auth/shared/interfaces';
import { ACTIVITIES, ACTIVITY_COUNTERS }                        from '../../../../modules/Activity/queries';
import { ITask }                                                from '../../../../modules/MyFamilyCore/tasks/queries';
import { handleApiErrorResponse }                               from '../../../../utils/apiHelpers';
import { ETaskStatus }                                          from '../../../constants/constants';
import { CATEGORIES }                                           from '../../../queries/categories';
import { Notistack }                                            from '../../Notistack/Notistack';
import { IUploadTask }                                          from './validation';

export const TASK = 'TASK';
export const TASKS = 'TASKS';
export const TASK_UPLOAD = 'TASK_UPLOAD';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASK_CHANGE_STATUS = 'TASK_CHANGE_STATUS';

const loadTasks = (
  familyId?: string,
  familyMemberId?: string,
  status?: string,
  categoryId?: string,
  subCategoryId?: string,
  dateFilter?: string,
  offset = 0,
  limit  = 5
) => {
  const params = {
    familyId,
    assignee: [ familyMemberId ],
    status,
    categoryId,
    subCategoryId,
    dateFilter,
    offset,
    limit,
  };

  return axios.get(`/api/v1/tasks?${ qs.stringify(params) }`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<ITask>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch(onCatch);
};

export const useLoadTasksPage = (
  familyId?: string,
  familyMemberId?: string,
  status?: string,
  categoryId?: string,
  subCategoryId?: string,
  dateFilter?: string,
  loadedFamilyMembers?: boolean,
  limit?: number,
) => useInfiniteQuery(
  [ TASKS, familyId, familyMemberId, status, categoryId, subCategoryId, dateFilter ],
  ({ pageParam: offset }) => loadTasks(familyId, familyMemberId, status, categoryId, subCategoryId, dateFilter, offset, limit),
  {
    enabled          : !!familyId && loadedFamilyMembers,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });

const onCatch = (error: IErrorResponse) => {
  throw error;
};

const onSuccess = (message: string, invalidateTypes = true) => {
  invalidateTypes && queryClient.invalidateQueries([ CATEGORIES ]);
  Notistack.enqueueSnackbar(message, 'success');
};

const taskUploadQueryParams = ({ attachments, ...data }: ITask) => qs.stringify(data, { skipNulls: true });

const uploadTask = (data: ITask, files: File[] | null) => {
  const queryParams = taskUploadQueryParams({
    ...data,
    offset: -(new Date()).getTimezoneOffset(),
  });

  const formData = new FormData();

  files?.forEach(file => {
    formData.append('Attachments', file);
  });

  return axios.post(`/api/v1/tasks?${ queryParams }`, formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadTask>>) => data)
    .catch(onCatch);
};

export const useUploadTask = () => useMutation(
  TASK_UPLOAD,
  ({
    data,
    files,
  }: { data: ITask; files: File[] | null }) => uploadTask(data, files),
  {
    onSuccess: () => {
      onSuccess('A new task was successfully created.', false);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
      queryClient.invalidateQueries([ ACTIVITIES ]);
      queryClient.invalidateQueries([ TASKS ]);
    },
    onError: handleApiErrorResponse,
  }
);

const updateTask = (data: ITask, files?: File[] | null) => {
  const queryParams = taskUploadQueryParams(data);
  const formData = new FormData();

  if (files) {
    files.forEach(file => formData.append('Attachments', file));
  }

  return axios.put(`/api/v1/tasks?${ queryParams }`, files && formData)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadTask>>) => {
      queryClient.invalidateQueries([ ACTIVITIES ]);
      queryClient.invalidateQueries([ TASKS ]);
      queryClient.invalidateQueries([ TASK, data?.id ]);
      return data;
    })
    .catch(onCatch);
};

export const useUpdateTask = () => useMutation(
  TASK_UPDATE,
  ({
    data,
    files,
  }: { data: ITask; files?: File[] | null }) => updateTask(data, files),
  {
    onSuccess: () => {
      onSuccess('Task was successfully updated.', false);
    },
    onError: handleApiErrorResponse,
  }
);

const changeTaskStatus = (status: ETaskStatus, taskId?: string) => {
  const queryParams = qs.stringify({
    taskId,
    status,
  });

  return axios.post(`/api/v1/tasks/status?${ queryParams }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUploadTask>>) => data)
    .catch(onCatch);
};

export const useChangeTaskStatus = (taskId?: string) => useMutation(
  TASK_CHANGE_STATUS,
  (status: ETaskStatus) => changeTaskStatus(status, taskId),
  {
    onSuccess: () => {
      onSuccess('Task status was successfully changed.', false);
      queryClient.invalidateQueries([ TASK, taskId ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: handleApiErrorResponse,
  }
);
