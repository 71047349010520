import { CircularProgress }   from '@material-ui/core';
import React, { FC }          from 'react';
import styled                 from 'styled-components';
import { FamilyMemberCircle } from '../../modules/Dashboard/components/FamilyMembers/FamilyMemberCircle';

export const AvatarWrapper = styled.div`
  width: 150px;
  display: flex;
  position: relative;
  justify-content: center;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 42px;
  }
`;

export const StyledProgress = styled(CircularProgress)`&& {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 90px;
  display: flex;
  margin: auto;
  position: absolute;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 150px;
  }
}`;

interface IAvatarSection {
  initials: string;
  color?: string;
  image?: string;
  uploadPercentage?: number;
}

export const AvatarSection: FC<IAvatarSection> = ({
  image,
  color,
  initials,
  uploadPercentage,
}) => (
  <AvatarWrapper>
    <FamilyMemberCircle
      width="120px"
      fontSize="38px"
      image={image}
      color={color}
      initials={initials}
    />

    { (
      <StyledProgress
        size="120"
        thickness={2}
        variant="determinate"
        value={uploadPercentage}
      />
    ) }
  </AvatarWrapper>
);
