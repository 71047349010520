import React                   from 'react';
import { Route }               from 'react-router-dom';
import { EAppRoutes }          from '../../shared/constants/constants';
import { AboutMemberDetailed } from './about/AboutMemberDetailed';
import { ContactDetails }      from './contacts/ContactDetails';
import { ContactsSummary }     from './contacts/ContactsSummary';
import { DocumentDetails }     from './documents/DocumentDetails';
import { DocumentsSummary }    from './documents/DocumentsSummary';
import { MyFamilyCore }        from './MyFamilyCore';
import { TaskDetails }         from './tasks/TaskDetails';
import { TasksSummary }        from './tasks/TasksSummary';

export const MyFamilyCoreRoutes = () => (
  <>
    <Route
      exact
      path={`${ EAppRoutes.MyFamilyCore }`}
      component={MyFamilyCore}
    />

    <Route
      exact
      path={`${ EAppRoutes.AboutSummary }`}
      component={AboutMemberDetailed}
    />

    <Route
      exact
      path={`${ EAppRoutes.DocumentsSummary }`}
      component={DocumentsSummary}
    />

    <Route
      path={`${ EAppRoutes.DocumentsSummary }/:documentId`}
      component={DocumentDetails}
    />

    <Route
      exact
      path={`${ EAppRoutes.TasksSummary }`}
      component={TasksSummary}
    />

    <Route
      path={`${ EAppRoutes.TasksSummary }/:taskId`}
      component={TaskDetails}
    />

    <Route
      exact
      path={`${ EAppRoutes.ContactsSummary }`}
      component={ContactsSummary}
    />

    <Route
      path={`${ EAppRoutes.ContactsSummary }/:contactId`}
      component={ContactDetails}
    />
  </>
);
