import { Divider, FormHelperText, Grid, Hidden, Typography }       from '@material-ui/core';
import CropIcon                                                    from '@material-ui/icons/Crop';
import DeleteIcon                                                  from '@material-ui/icons/Delete';
import axios, { AxiosResponse }                                    from 'axios';
import React, { FC, useMemo, useState, useEffect }                 from 'react';
import { useMutation }                                             from 'react-query';
import { useHistory }                                              from 'react-router-dom';
import styled                                                      from 'styled-components';
import { ReactComponent as ImageGroup }                            from '../../images/family-group.svg';
import { FileSpecificationMessage }                                from '../../styles/components';
import { FamilyMemberWrapper }                                     from '../modules/Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { AvatarSection }                                           from '../shared/components/AvatarSection';
import { ColorPicker }                                             from '../shared/components/ColorPicker/ColorPicker';
import { LogoWhite }                                               from '../shared/components/Logo';
import { MuiButton }                                               from '../shared/components/MuiButton';
import { useFetchSelectedFamily }                                  from '../shared/components/SelectFamily/queries';
import { EAuthRoutes, colors, fileTypeMessage }                    from '../shared/constants/constants';
import { Loader }                                                  from '../shared/enhancers/withLoader';
import { useFetchCurrentUserProfile, useUpdateCurrentUserProfile } from '../shared/queries/user';
import { generateInitials }                                        from '../shared/utils/commonFunctions';
import { uploadPhoto }                                             from '../shared/utils/fileUpload';
import { IFamilyProfile, IRequestResponse, IUploadImageResponse }  from './shared/interfaces';
import { StyledBadge }                                             from '../shared/styledComponents';
import { AlertDialog }                                             from '../shared/components/modals/AlertDialog';
import { IMyProfileForm }                                          from '../modules/Settings/components/Profile/MyProfileForm/validation';
import { CropEasy }                                                from '../modules/Settings/shared/CropEasy';
import {
  AuthSVGSceneHolder,
  MultiButton,
  PageHeaderText,
  ProfilePage,
  ProfilePageContent,
  ProfilePageHeader,
  SignUpContainer,
}                     from './shared/styles';

export interface IImageAvatar {
  lastModified: number;
  lastModifiedDate: any;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export const AvatarSectionWrapper = styled.div`
  display: flex;
  padding: 10px 0 16px;
  width: 180px;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  line-height: 1.54;
  letter-spacing: 0.14px;

  input {
    opacity: 0;
    position: absolute;
  }

  ${ FamilyMemberWrapper } {
    margin: 22px 0;
    font-family: Jost;
    font-weight: 600;
    width: 120px;
    height: 120px;
    font-size: 38px;

    ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
      width: 75px;
      height: 75px;
      font-size: 22px;
    }
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 0;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: auto;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const AvatarControlSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    align-items: flex-start;
    padding-left: 15px;
  }

  span {
    font-family: Jost;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const ProfileColorLabel = styled(Typography)`&& {
  font-size: 20px;
  letter-spacing: 0.33px;
  font-weight: 600;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 32px;
  }
}`;

const SvgGroupHolder = styled(AuthSVGSceneHolder)`&& {
  min-height: 270px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    height: 225px;
    width: 250px;
    margin: 0 auto;
    padding-right: 25px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    height: 290px;
    width: 280px;
  }
}`;

const CustomizeAvatarGridSection = styled(Grid)`
  justify-content: center;
  text-align: center;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    justify-content: flex-start;
    text-align: left;
  }

  ${ PageHeaderText } {
    color: #2c3f69;
    font-weight: 600;
    letter-spacing: 0.23px;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      letter-spacing: 0.5px;
    }
  }
`;

const SubmitButton = styled(MuiButton)`
  .MuiButton-label {
    font-family: Jost;
    font-size: 15px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    max-width: 250px;
  }
`;

const CustomizeAvatarDivider = styled(Divider)`
  && {
    margin: 20px -10px;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      margin: 55px 0 40px;
    }
  }
`;

const AvatarSectionButtonWrapper = styled('div')`
  position: relative;
`;

const Badge = styled(StyledBadge).attrs(() => ({ overlap: 'rectangular' }))`&& {
  .MuiBadge-badge {
    width: 23px;
    min-height: 23px;
    border-radius: 100%;
    right: 13px;
    bottom: -13px;
    top: auto !important;
    
    svg {
      width: 12px;
    }
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    margin-bottom: 12px;
  };
}`;

const CropUserIcon = styled(CropIcon)`&& {
  color: #fff;
  width: 10px;
}`;

const BadgeCrop = styled(Badge)`&& {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 20px;
  transition: ease .3s opacity;

  .MuiBadge-badge {
    background-color: #0e991a;
    
    svg {
      width: 14px;
    }
  }

  ${ ({ theme: { breakpoints } }: any) => breakpoints.only('xs') } {
    right: 30px;
    bottom  : 25px;
  };
}`;

const TrashIcon = styled(DeleteIcon)`&& {
  color: #fff;
  width: 10px;
}`;

const BadgeDelete = styled(Badge)`&& {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50px;
  transition: ease .3s opacity;

  ${ ({ theme: { breakpoints } }: any) => breakpoints.only('xs') } {
    right: 30px;
    top: 50px;
  };

  .MuiBadge-badge {
    background-color  : #ff0000;
    
    svg {
      width: 14px;
    }
  }
}`;

export const CustomizeProfile: FC = () => {
  const { data: family } = useFetchSelectedFamily();
  const { data: user, isLoading: loadingUser, refetch } = useFetchCurrentUserProfile(family?.id);
  const { mutate: updateUserProfile, isLoading: isUpdatingUserProfile } = useUpdateCurrentUserProfile();

  const [ selectedColor, setSelectedColor ] = useState(colors[0]);
  const [ file, setFile ] = useState<string | Blob>();
  const [ error, setError ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ imagePreviewUrl, setImagePreviewUrl ] = useState('');
  const [ imageSelectedPreviewUrl, setImageSelectedPreviewUrl ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingCrop, setIsLoadingCrop ] = useState(false);
  const [ uploadPercentage, setUploadPercentage ] = useState(0);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);
  const [ isSubmit, setIsSubmit ] = useState(false);

  const history = useHistory();

  const [openCrop, setOpenCrop] = useState(false);
  const [selectedImg, setSelectedImg] = useState<string>('');

  const resetImage = () => {
    setFile('');
    setImagePreviewUrl('');
  };

  const uploadPhotoToMemory = (event: any) => {
    uploadPhoto(
      event,
      resetImage,
      setFile,
      setImagePreviewUrl,
      setErrorMessage,
    );
  };

  const updateAvatar = async (avatarId?: string) => {
    await axios.put<IFamilyProfile>('api/v1/users/me', {
      avatarId,
      color: selectedColor,
    });
  };

  const uploadMutation = useMutation('uploadAvatar', () => {
    const formData = new FormData();

    if (file) {
      formData.append('File', file);
    }

    return axios.post('/api/v1/images', formData, {
      params: {
        ImageType: 'Avatar',
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percent);
      },
    });
  }, {
    onSuccess: async ({ data: { data } }: IRequestResponse<AxiosResponse<IUploadImageResponse>>) => {
      await updateAvatar(data.id);
      await refetch();
    },
    onError: () => {
      setError('Something went wrong');
    },
  });

  const onSubmit = async () => {
    if (file) {
      setIsLoading(true);
      setIsSubmit(true);
      await uploadMutation.mutateAsync();
      setIsLoading(false);
      history.push(EAuthRoutes.CreatePin);
    } else {
      await updateAvatar();
      history.push(EAuthRoutes.CreatePin);
    }
  };

  const handleCroppedImage = (croppedFile: IImageAvatar | string | any) => {
    setFile(croppedFile);
    setIsLoadingCrop(true);
    uploadMutation.mutate(croppedFile);
    setOpenCrop(false);
    uploadPhotoToMemory(croppedFile);
  };

  useEffect(() => {
    user?.avatar?.compactPath && (setImageSelectedPreviewUrl(user?.avatar?.compactPath));
  }, [ user ]);

  useEffect(() => {
    if(isLoadingCrop){
      setTimeout(() => {
        setIsLoadingCrop(false);
        setUploadPercentage(0);
      }, 3000);
    }
  }, [ isLoadingCrop ]);

  useEffect(() => {
    setImageSelectedPreviewUrl(imagePreviewUrl);
  }, [ imagePreviewUrl ]);

  const updateUserAvatar = (data?: IUploadImageResponse) => {
    if (updateUserProfile) {
      updateUserProfile({
        avatar: null,
        ...user,
      } as unknown as IMyProfileForm, {
        onSuccess: () => {
          if (!data) {
            resetImage();
            setIsDeleteDialogOpen(false);
          }
        },
      });
    }
    user && setImageSelectedPreviewUrl('');
    refetch();

    const fileInput = document.getElementById('photo-upload') as HTMLInputElement | null;

    if (fileInput) {
      fileInput.value = '';
    }
  };

  const Page = useMemo(() => (
    <ProfilePage>
      <ProfilePageHeader>
        <LogoWhite />

        Welcome { family?.name } Family
      </ProfilePageHeader>

      <ProfilePageContent>
        <SignUpContainer maxWidth="md">
          <Grid
            container
            spacing={2}
          >
            <CustomizeAvatarGridSection
              item
              xs={12}
              sm={6}
              md={8}
            >
              <PageHeaderText centeredXs>
                Customize Your Avatar
              </PageHeaderText>

              <AvatarSectionWrapper>
                <AvatarSectionButtonWrapper>
                  <AvatarSection
                    color={selectedColor}
                    image={imageSelectedPreviewUrl}
                    initials={generateInitials(user?.firstName, user?.lastName)}
                    uploadPercentage={uploadPercentage}
                  />

                  {imageSelectedPreviewUrl ? (
                    <BadgeCrop
                      id="cropIcon"
                      color="primary"
                      badgeContent={<CropUserIcon />}
                      onClick={() => {
                        setOpenCrop(true);
                        setSelectedImg(imageSelectedPreviewUrl);
                      }}
                    />
                  ) : null}

                  {imageSelectedPreviewUrl ? (
                    <BadgeDelete
                      id="deleteIcon"
                      color="primary"
                      badgeContent={<TrashIcon />}
                      onClick={() => setIsDeleteDialogOpen(true)}
                    />
                  ) : null}
                </AvatarSectionButtonWrapper>

                <AvatarControlSection>
                  <label>
                    <MultiButton
                      variant="outlined"
                      color="primary"
                      size="large"
                    >
                      <input
                        id="photo-upload"
                        type="file"
                        onChange={uploadPhotoToMemory}
                      />
                      Add Profile Picture
                    </MultiButton>
                  </label>

                  <FileSpecificationMessage error={!!errorMessage}>
                    { errorMessage ? <b>{ errorMessage }</b> : fileTypeMessage }
                  </FileSpecificationMessage>
                </AvatarControlSection>
              </AvatarSectionWrapper>

              <FormHelperText error>{ error }</FormHelperText>
            </CustomizeAvatarGridSection>

            <Hidden xsDown>
              <Grid
                item
                sm={6}
                md={4}
              >
                <SvgGroupHolder>
                  <ImageGroup />
                </SvgGroupHolder>
              </Grid>
            </Hidden>

            <CustomizeAvatarDivider />

            <Grid
              item
              xs={12}
              sm={8}
              md={10}
            >
              <ProfileColorLabel variant="h4">Choose Profile Color</ProfileColorLabel>

              <ColorPicker
                margin="15px 0 0"
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />

              <SubmitButton
                fullWidth
                disabled={isLoadingCrop || isLoading || isSubmit}
                type="submit"
                margin="23px 0"
                onClick={onSubmit}
              >
                Next
              </SubmitButton>
            </Grid>
          </Grid>
        </SignUpContainer>

        {openCrop && <CropEasy
          photoURL={selectedImg}
          openCrop={openCrop}
          setOpenCrop={setOpenCrop}
          onCroppedImage={handleCroppedImage}
        />}

        <AlertDialog
          dialogWidth="350px"
          open={isDeleteDialogOpen}
          title="Delete avatar"
          content="Are you sure you want to delete avatar?"
          handleSubmit={updateUserAvatar}
          handleCancel={() => setIsDeleteDialogOpen(false)}
        />
      </ProfilePageContent>
    </ProfilePage>
  ), [ user, selectedColor, errorMessage, error, uploadPercentage, imagePreviewUrl, openCrop, imageSelectedPreviewUrl, isDeleteDialogOpen, isLoadingCrop, isSubmit ]);

  return loadingUser ? <Loader /> : Page;
};
