import { Box, Grid }                  from '@material-ui/core';
import {
  FastField,
  FormikProps,
}                                     from 'formik';
import React, {
  FC,
  RefObject,
  useMemo,
}                                     from 'react';
import { ReactComponent as IconTask } from '../../../../../images/icons/icon-landscape.svg';
import { ITask }                      from '../../../../modules/MyFamilyCore/tasks/queries';
import { documentAccessLevels }       from '../../../constants/constants';
import { DateRangeField }             from '../../../formFields/DateRangeField';
import { FamilyMembersSelector }      from '../../../formFields/FamilyMembersSelector';
import { SelectField }                from '../../../formFields/SelectField';
import { SelectTimeField }            from '../../../formFields/SelectTimeField';
import { TextInputField }             from '../../../formFields/TextInputField';
import { WithCategorySelection }      from '../../../hocs/withCategorySelection';
import { useFetchCategories }         from '../../../queries/categories';
import { StyledDivider }              from '../../../styledComponents';
import { DocumentPicker }             from '../../documents/DocumentPicker';
import {
  UploadEntityFormDropdownField,
  UploadEntityFormLabel,
  UploadEntityFormRow,
  UploadEntityFormSpacing,
}                                     from '../../documents/styles';
import { GridDivider }                from '../../layout/GridDivider';
import { RepeatOptions }              from '../../RepeatOptions/RepeatOptions';
import {
  ERecurrence,
  IRepeatOptions,
}                                     from '../shared';
import { ModalFooterActions }         from '../shared/ModalFooterActions';
import { IUploadTask }                from './validation';

interface IAddTaskModalFormProps extends FormikProps<ITask> {
  familyId?: string;
  loading: boolean;
  files: File[] | null;
  formikRef: RefObject<FormikProps<IUploadTask>>;

  onChange(files: File[]): void;
}

export const UploadTaskModalForm: FC<IAddTaskModalFormProps> = ({
  familyId,
  loading,
  files,
  onChange,
  formikRef,
  ...form
}) => {
  const { values, setFieldValue } = form;
  const { data: categories, isLoading: loadingCategories } = useFetchCategories(familyId);

  const updateRepeatOptions = (options: IRepeatOptions) => {
    form.setValues({
      ...values,
      ...options,
    });
  };

  const repeatOptions = {
    recurs          : values.recurs || ERecurrence.NoRecurrence,
    recursInterval  : values.recursInterval,
    recursDay       : values.recursDay,
    recursOrdinal   : values.recursOrdinal,
    recursWeekDays  : values.recursWeekDays,
    recursCount     : values.recursCount,
    recursStartDate : values.recursStartDate,
    recursEndDate   : values.recursEndDate,
  };

  const Form = WithCategorySelection(({
    categoriesDropdown,
  }) => (
    <UploadEntityFormSpacing>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <FastField
            label="Task Title"
            name={'title'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />

          <GridDivider margin="20px 0 0" />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Category</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              { categoriesDropdown }
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Assign</UploadEntityFormLabel>

            <FamilyMembersSelector
              multiple
              hideAllSelection
              name="assigned"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel width="135px">Assignee Permissions</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              <FastField
                name={'assignedPermission'}
                options={documentAccessLevels}
                component={SelectField}
              />
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Tag</UploadEntityFormLabel>

            <FamilyMembersSelector
              multiple
              hideAllSelection
              name="tagged"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Due Date</UploadEntityFormLabel>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <FastField
                  startName="recursStartDate"
                  endName="recursEndDate"
                  component={DateRangeField}
                />
              </Grid>

              <Grid item xs={9} sm={5}>
                <FastField
                  name="recursStartTime"
                  component={SelectTimeField}
                />
              </Grid>

              <Grid item xs={3} sm={2}>
                <RepeatOptions
                  values={repeatOptions}
                  onChange={(values) => updateRepeatOptions(values)}
                />
              </Grid>
            </Grid>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FastField
            fullWidth
            label="Add Location"
            name="location"
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FastField
            multiline
            fullWidth
            rows={4}
            label="Description"
            name={'description'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />
        </Grid>

        <GridDivider margin="-5px 0" />

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormLabel>Attach File</UploadEntityFormLabel>

          <Box height={10} />

          <DocumentPicker
            multiple
            icon={<IconTask />}
            files={files as File[]}
            onChange={onChange}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ModalFooterActions
            formikRef={formikRef}
            disabled={loading || !files}
          >
            { loading ? 'Uploading' : values?.id ? 'Edit' : 'Add' } Task
          </ModalFooterActions>
        </Grid>
      </Grid>
    </UploadEntityFormSpacing>
  ));

  return useMemo(() => (
    <Form
      values={values}
      familyId={familyId}
      categories={categories}
      loading={loadingCategories}
      setFieldValue={setFieldValue}
    />
  ), [
    loading,
    files,
    categories,
    values?.recursStartTime,
    values?.recursStartDate,
    values?.recursEndDate,
    values?.categoryId,
    values?.recursOrdinal,
    values?.recursDay,
  ]);
};
