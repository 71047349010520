import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { EAppRoutes }      from '../../shared/constants/constants';
import { Settings }        from './Settings';

export const SettingsRoutes = () => (
  <>
    <Route
      exact
      path="/"
    >
      <Redirect to={EAppRoutes.MyProfile} />
    </Route>

    <Route
      path={`${ EAppRoutes.Settings }/:tab?/:subPage?`}
      component={Settings}
    />
  </>
);
