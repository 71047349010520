import axios, { AxiosResponse }            from 'axios';
import { useMutation, useQuery }           from 'react-query';
import { queryClient }                     from '../../App';
import { IRequestResponse }                from '../../Auth/shared/interfaces';
import { IAboutUserForm }                  from '../../modules/Settings/components/Profile/AboutMeForm/validation';
import { IMyProfileRequest, IUserProfile } from '../../modules/Settings/components/Profile/MyProfileForm/validation';
import { Notistack }                       from '../components/Notistack/Notistack';
import { useCreateQuery }                  from '../utils/hooks/useReactQuery';
import { FAMILY_MEMBERS_GET }              from './family';

export const CURRENT_USER_PROFILE = 'CURRENT_USER_PROFILE';
export const CURRENT_USER_PROFILE_UPDATE = 'CURRENT_USER_PROFILE_UPDATE';

export const useFetchCurrentUserProfile = (familyId?: string, load = true) => useCreateQuery<IUserProfile>({
  queryKey : [ CURRENT_USER_PROFILE, familyId ],
  apiUrl   : '/api/v1/users/me',
  options  : {
    enabled              : !!familyId && load,
    refetchOnWindowFocus : false,
  },
  config: {
    params: {
      familyId: familyId,
    },
  },
});

export const updateCurrentUserProfile = (data: IMyProfileRequest) =>
  axios.put('/api/v1/users/me', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IUserProfile>>) => data);

export const useUpdateCurrentUserProfile = () => useMutation(
  CURRENT_USER_PROFILE_UPDATE,
  (data: IMyProfileRequest) => updateCurrentUserProfile(data),
  {
    onSuccess: () => {
      queryClient.invalidateQueries([ CURRENT_USER_PROFILE ]);
      queryClient.invalidateQueries([ FAMILY_MEMBERS_GET ]);
      Notistack.enqueueSnackbar('Your profile was successfully updated.', 'success');
    },
  }
);

export const getCurrentUserAccount = () =>
  axios.get<IRequestResponse<IAboutUserForm>>('/api/v1/users/me/about')
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IAboutUserForm>>) => data);

export const useGetUserAccount = () => useQuery('userAccount', getCurrentUserAccount);

export const updateCurrentUserAboutProfile = (data: IAboutUserForm) => {
  if (data?.contactEmail === '') {
    data.contactEmail = undefined; // BE returns error if email is empty string
  }
  return axios.put('/api/v1/users/me/about', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IAboutUserForm>>) => data);
};

export const useUpdateAboutProfile = () => useMutation('updateAboutProfile', (data: IAboutUserForm) => updateCurrentUserAboutProfile(data), {
  onSuccess: (data: IAboutUserForm) => {
    queryClient.setQueryData('userAccount', data);
    queryClient.invalidateQueries([ CURRENT_USER_PROFILE ]);
  },
});

