import { OptionsObject } from 'notistack';

export const optionsSuccess: OptionsObject = {
  variant      : 'success',
  anchorOrigin : {
    vertical   : 'top',
    horizontal : 'center',
  },
};

export const optionsError: OptionsObject = {
  variant      : 'error',
  anchorOrigin : {
    vertical   : 'top',
    horizontal : 'center',
  },
};

export const optionsLoading: OptionsObject = {
  variant      : 'success',
  persist      : true,
  anchorOrigin : {
    vertical   : 'top',
    horizontal : 'center',
  },
};
