import React, { FC, useMemo }                      from 'react';
import styled                                      from 'styled-components';
import { ReactComponent as IconByAccountCreation } from '../../../../../images/icons/by_acct_creation.svg';
import { ReactComponent as IconByInvite }          from '../../../../../images/icons/by_invite.svg';
import { PageHeaderContainer }                     from '../../../../shared/styledComponents';
import { SettingsPageWrapper }                     from '../../../Settings/shared/SettingsPageWrapper';
import { PreRegistrationForm }                     from './PreRegistrationForm';

export interface IPreRegistrationProps {
  byInvite?: boolean;
}

const PageHeader = styled.div`
  font-size: 28px;
  font-family: 'Jost';
  font-weight: 500;
  margin-bottom: 5px;
  color: #272932;
`;

const SubHeader = styled.div`
  font-size: 14px;
  line-height: 1.57;
`;

const IconWrapper = styled.div`
  margin-right: 20px;
`;

export const PreRegistration: FC<IPreRegistrationProps> = ({ byInvite }) => useMemo(() => (
  <SettingsPageWrapper>
    <PageHeaderContainer hideBottomBorder>
      <IconWrapper>
        { byInvite ? <IconByInvite /> : <IconByAccountCreation /> }
      </IconWrapper>

      <div>
        <PageHeader>
          { byInvite ? 'Invite Family or Friend' : 'Create a Profile' }
        </PageHeader>

        { !byInvite && (
          <SubHeader>
              Create a profile for a child or an adult who does not have access to an email address or phone
              number.
          </SubHeader>
        ) }
      </div>
    </PageHeaderContainer>

    <PreRegistrationForm byInvite={byInvite} />
  </SettingsPageWrapper>
), [ byInvite ]);
