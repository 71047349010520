import { Grid }                                                 from '@material-ui/core';
import { FastField }                                            from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled                                                   from 'styled-components';
import { NumberInputField }                                     from '../../../formFields/NumberInputField';
import { SelectField }                                          from '../../../formFields/SelectField';
import { ERecurrence }                                          from '../../modals/shared';
import { RepeatOptionsLabel }                                   from '../RepeatOptions';
import { IRepeatOptionsForm }                                   from '../RepeatOptionsForm';
import { SelectWeekDay }                                        from './SelectWeekDay';
import { theme }                                                from '../../../../../../src/styles/theme';

const RepeatEveryWrapper = styled.div`
  margin-bottom: 20px;
`;

const SelectWeekDayWrapper = styled.div`
  margin-top: 15px;
`;

const ErrorTextMessage = styled.span`
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: ${ theme.colors.warning };
  margin-bottom: 10px;
  text-align: justify;
  white-space: nowrap;
`;

export const RepeatEvery: FC<IRepeatOptionsForm> = ({ form, recursChange }) => {
  const { values: { recursInterval, recurs } } = form;
  const [ displayWeekDays, setDisplayWeekDays ] = useState(recurs === ERecurrence.Weekly);
  const repeatOptions = [
    { id: ERecurrence.Daily, value: 'Day' },
    { id: ERecurrence.Weekly, value: 'Week' },
    { id: ERecurrence.Monthly, value: 'Month' },
    { id: ERecurrence.Yearly, value: 'Year' },
  ];

  useEffect(() => () => {
    form?.setFieldValue('recursInterval', null);
  }, []);

  const handleRecursChange = useCallback((value: ERecurrence) => {
    setDisplayWeekDays(value === ERecurrence.Weekly);
    recursChange && recursChange();
  }, []);

  const validateRecursInterval = (value: number | null) => {
    if (value === 0) {
      return <ErrorTextMessage>Value cannot be 0</ErrorTextMessage>;
    }
  };

  return useMemo(() => (
    <RepeatEveryWrapper>
      <RepeatOptionsLabel>Repeat Every</RepeatOptionsLabel>

      <Grid container spacing={2}>
        <Grid item xs={4} sm={3}>
          <FastField
            name="recursInterval"
            inputProps={{ maxLength: 10 }}
            allowNegative={false}
            component={NumberInputField}
            validate={validateRecursInterval}
          />
        </Grid>

        <Grid item xs={8} sm={9}>
          <FastField
            name="recurs"
            options={repeatOptions}
            component={SelectField}
            onChange={handleRecursChange}
          />
        </Grid>
      </Grid>

      { displayWeekDays &&
      <SelectWeekDayWrapper>
        <SelectWeekDay
          multiple
          name="recursWeekDays"
        />
      </SelectWeekDayWrapper>}

    </RepeatEveryWrapper>
  ), [ recursInterval, recurs, displayWeekDays ]);
};
