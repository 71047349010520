import * as Yup from 'yup';

const genericRule = Yup.string()
  .trim()
  .nullable();

export const memberAccountValidationSchema = Yup.object().shape({
  id                : genericRule,
  timeZoneOffset    : genericRule,
  chatAccess        : Yup.boolean().nullable(),
  viewAboutMe       : Yup.boolean().nullable(),
  geoLocationAccess : Yup.array().of(Yup.string()).nullable(),
});
