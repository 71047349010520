import React, { FC }                                from 'react';
import styled                                       from 'styled-components';
import { PageHeaderContainer }                      from '../../../../../shared/styledComponents';
import { IFamilyMember }                            from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { SettingsHeader }                           from '../../../shared/styles';
import { AboutMeForm }                              from '../../Profile/AboutMeForm/AboutMeForm';
import { useFetchAboutUser, useUpdateAboutProfile } from './queries';

interface IAboutUserProps {
  member?: IFamilyMember;
  familyId?: string;
}

const AboutMeFormSpacing = styled.div`
  padding: 0 10px;
`;

export const AboutUser: FC<IAboutUserProps> = ({ member, familyId }) => {
  const { mutate: updateProfile, isLoading } = useUpdateAboutProfile(member?.id, familyId);
  const { data: aboutMe } = useFetchAboutUser(member?.id, familyId);

  return (
    <>
      <PageHeaderContainer>
        <SettingsHeader variant="h3">About</SettingsHeader>
      </PageHeaderContainer>

      <AboutMeFormSpacing>
        <AboutMeForm
          data={aboutMe}
          isDisabled={!member?.editAboutMe}
          isLoading={isLoading}
          onUpdate={updateProfile}
        />
      </AboutMeFormSpacing>

    </>
  );
};
