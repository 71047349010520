import moment                                         from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory }                                 from 'react-router-dom';
import { EventMenuItems }                             from '../../../shared/components/modals/UploadEvent/components/EventMenuItems';
import { UploadEventModal }                           from '../../../shared/components/modals/UploadEvent/UploadEventModal';
import { EAppRoutes }                                 from '../../../shared/constants/constants';
import { documentTypes }                              from '../../../shared/documentTypes';
import { shouldDisplayActionsMenu }                   from '../../../shared/functions';
import { mergeDateAndTime }                           from '../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { NoActivityScreen, NoActivityScreenWrapper }  from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                      from '../../Activity/ActivityRecordPreview';
import { IEvent }                                     from '../../Schedule/queries';
import { EntitiesListPreview }                        from '../shared/EntitiesListPreview';
import { EventDate }                                  from './components/EventDate';
import { EventIcon }                                  from './components/EventIcon';
import { useLoadEventsPage }                          from './queries';

interface IEventsListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

export const EventsListPreview: FC<IEventsListPreviewProps> = ({ familyId, familyMemberId }) => {
  const [ addEventModalOpen, setAddEventModalOpen ] = useState(false);
  const [ showViewAllSection, setShowViewAllSection ] = useState(false);
  const { data: events, isFetching } = useLoadEventsPage(familyId, familyMemberId, 5);
  const items = events?.pages?.[0]?.items;
  const history = useHistory();
  const currentUser = useContext(AuthorizationContext);

  const onViewAll = () => {
    history.push(`${ EAppRoutes.Schedule }?id=${ familyMemberId }`);
  };

  const viewEvent = (event?: IEvent) => {
    const eventMonthName = moment(event?.recursStartDate).format('MMMM');
    history.push(`${ EAppRoutes.Schedule }/${ eventMonthName }?id=${ familyMemberId }`);
  };

  useEffect(() => {
    setShowViewAllSection(!!(events?.pages?.[0]?.totalCount && events?.pages?.[0]?.totalCount > 0));
  }, [ events?.pages?.[0]?.totalCount ]);

  return (
    <>
      <EntitiesListPreview
        showViewAll={showViewAllSection}
        title="Events"
        count={events?.pages?.[0]?.totalCount}
        loading={isFetching}
        onViewAll={onViewAll}
        onAdd={() => setAddEventModalOpen(true)}
      >
        { items?.length ? items?.map((event, index, array) => {
          const icon = (
            <EventIcon date={event.recursStartDate} />
          );

          return (
            <ActivityRecordPreview
              key={index}
              showMenu={shouldDisplayActionsMenu(event, currentUser)}
              icon={icon}
              description={event?.title}
              details={<EventDate date={mergeDateAndTime(event.recursStartDate, event.recursStartTime)} />}
              isLast={index === array.length - 1}
              menuItems={<EventMenuItems
                event={event}
                familyId={familyId}
              />}
              onClick={() => viewEvent(event)}
            />
          );
        }) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.events.icon }
              There are no events available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListPreview>

      { addEventModalOpen && (
        <UploadEventModal
          familyId={familyId}
          open={addEventModalOpen}
          onClose={() => setAddEventModalOpen(false)}
        />
      ) }
    </>
  );
};
