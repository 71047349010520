import axios              from 'axios';
import { ITokens, IUser } from '../shared/interfaces/auth';

const tokenType = 'Bearer';
export const authTokenKey = 'authToken';

type TTokenObject = { accessToken: string };

//#region Auth Token helpers
export const getAuthToken = (): ITokens => {
  const storedAuthToken = localStorage.getItem(authTokenKey);

  return storedAuthToken && JSON.parse(storedAuthToken);
};

const parseAccessToken = (authToken: TTokenObject | null) => {
  let token = '';

  if (!authToken) return token;

  // Local strategy token
  if ('accessToken' in authToken) {
    token = authToken.accessToken;
  }

  return token;
};

export const setAuthToken = (authToken?: ITokens) => {
  if (authToken) {
    const accessToken = parseAccessToken(authToken);

    localStorage.setItem(authTokenKey, JSON.stringify(authToken));

    axios.defaults.headers.common.authorization = `${ tokenType } ${ accessToken }`;
  } else {
    localStorage.removeItem(authTokenKey);

    delete axios.defaults.headers.common.authorization;
  }
};
//#endregion

export const getUserDetails = (): IUser => {
  const storedAuthToken = localStorage.getItem('user');

  return storedAuthToken && JSON.parse(storedAuthToken);
};

const parseExpirationDate = () => {
  const authToken: ITokens = getAuthToken();

  let expiresAt = new Date(0);

  if (!authToken) return expiresAt;

  if ('expireDate' in authToken) {
    expiresAt = new Date(authToken?.expireDate);
  }

  return expiresAt;
};

export const isTokenExpired = () => {
  const expiresAt = parseExpirationDate();

  return expiresAt <= new Date();
};
//#endregion
