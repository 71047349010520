import { Divider }                                     from '@material-ui/core';
import React, { FC }                                   from 'react';
import styled                                          from 'styled-components';
import { ReactComponent as IconPinUser }               from '../../../../images/icons/icon-pin-user.svg';
import { ReactComponent as IconLocation }              from '../../../../images/icons/rounded/icon-location.svg';
import { Loader }                                      from '../../../shared/enhancers/withLoader';
import { datePassed }                                  from '../../../shared/utils/commonFunctions';
import { IFamilyMember }                               from '../../../shared/utils/withAuthorization/withAuthorization';
import { HeaderContent, NoActivityScreen, StyledList } from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                       from '../../Activity/ActivityRecordPreview';
import { EntityRecordAvatar }                          from '../../MyFamilyCore/shared/EntityRecordAvatar';
import { SummaryListTypography, SummaryListWrapper }   from '../../MyFamilyCore/shared/styles';
import { ICheckin }                                    from '../queries';

export interface ICheckinHistoryProps {
  checkins?: ICheckin[];
  isLoading?: boolean;
}

const CheckinHistoryWrapper = styled.div`
  & * {
    cursor: default;
  }
`;

const TableContent = styled.div`
  margin: auto;
`;

const RowLabelWrapper = styled.span`
  display: grid;
`;

const CheckinName = styled.span`
  margin-bottom: 8px;
  font-size    : 16px;
`;

const CheckinAddress = styled.span`
  font-weight  : normal;
  margin-bottom: 4px;
`;

export const CheckinHistoryTable: FC<ICheckinHistoryProps> = ({
  checkins = [],
  isLoading,
}) => {
  const EmptyTable = (
    <TableContent>
      <NoActivityScreen>
        <IconPinUser />

        There are no checkins
      </NoActivityScreen>
    </TableContent>
  );

  const CheckinsItems = checkins?.map((checkin: ICheckin, index: number) => {
    const firstName = checkin.creator.firstName || checkin.updater.firstName;
    const lastName = checkin.creator.lastName || checkin.updater.lastName;

    const label = (
      <RowLabelWrapper>
        <CheckinName>
          { `${ firstName } ${ lastName[0] }` }. checked in to { checkin.name }
        </CheckinName>

        <CheckinAddress>
          { checkin.address }
        </CheckinAddress>
      </RowLabelWrapper>
    );

    const avatar = (
      <EntityRecordAvatar
        badge={<IconLocation />}
        member={(checkin.creator || checkin.updater) as IFamilyMember}
      />
    );

    const details = (
      <span>{ datePassed(checkin.createdAt || checkin.updatedAt, true) }</span>
    );

    return (
      <ActivityRecordPreview
        key={checkin.id}
        truncateMessage
        icon={avatar}
        label={label}
        details={details}
        isLast={index === checkins.length - 1}
      />
    );
  });

  const content = (
    isLoading ? (
      <TableContent>
        <Loader />
      </TableContent>
    ) : checkins?.length ? CheckinsItems : EmptyTable
  );

  return (
    <CheckinHistoryWrapper>
      <SummaryListWrapper height="100%">
        <SummaryListTypography variant="h5">
          <HeaderContent>
            Check in History
          </HeaderContent>
        </SummaryListTypography>

        <Divider />

        <StyledList>
          { content }
        </StyledList>
      </SummaryListWrapper>
    </CheckinHistoryWrapper>
  );
};
