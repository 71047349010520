import { FastField, FieldProps, getIn }                                   from 'formik';
import React, { ForwardRefRenderFunction, forwardRef, memo, useCallback } from 'react';
import { ISelectDateProps, SelectDate }                                   from '../select/SelectDate/SelectDate';
import { formatDate }                                                     from '../utils/commonFunctions';
import { TextInputField }                                                 from './TextInputField';

interface ISelectDateFieldProps
  extends FieldProps, ISelectDateProps {
  id?: string;
}

const SelectDateFieldBase: ForwardRefRenderFunction<HTMLDivElement, ISelectDateFieldProps> = ({
  form,
  field,
  disabled,
  maxDate,
  ...props
}, ref) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const handleChange = useCallback(
    (event: unknown) => {
      field.onChange(event);
    },
    [ form, field ]
  );

  return (
    <React.Fragment>
      { disabled ? (
        <FastField
          {...field}
          {...props}
          value={formatDate(field.value)}
          component={TextInputField}
        />
      ) : (
        <SelectDate
          {...field}
          {...props}
          ref={ref}
          maxDate={maxDate}
          id={props.id || field.name}
          error={touched && !!error}
          helperText={(touched && error) || ''}
          onChange={handleChange}
        />
      ) }
    </React.Fragment>
  );
};

export const SelectDateField = memo(forwardRef(SelectDateFieldBase));
