import axios, { AxiosResponse }                                          from 'axios';
import qs                                                                from 'qs';
import { useInfiniteQuery }                                              from 'react-query';
import { IErrorResponse, IFamily, IPaginatedResponse, IRequestResponse } from '../../../Auth/shared/interfaces';
import { IAttachment }                                                   from '../../../shared/components/modals/UploadDocument/validation';
import { TASK }                                                          from '../../../shared/components/modals/UploadTask/queries';
import { IUploadTask }                                                   from '../../../shared/components/modals/UploadTask/validation';
import { ETaskStatus }                                                   from '../../../shared/constants/constants';
import { ICategory }                                                     from '../../../shared/queries/categories';
import { useCreateQuery }                                                from '../../../shared/utils/hooks/useReactQuery';
import { IFamilyMember }                                                 from '../../../shared/utils/withAuthorization/withAuthorization';

export interface ITask extends IUploadTask {
  family?: IFamily;
  category?: ICategory;
  status?: ETaskStatus;
  creationTime?: string;
  creator?: IFamilyMember;
  assignedPermission: string;
  attachments?: IAttachment[];
  attachmentsId?: string[];
  location?: string;
  offset?: number;
}

export const TASKS = 'TASKS';

const loadTasks = (
  familyId?: string,
  assignee?: string,
  categoryId?: string,
  status?: string,
  date?: string,
  offset = 0,
  limit  = 5
) => {
  const params = {
    familyId,
    assignee: [ assignee ],
    categoryId,
    status,
    date,
    offset,
    limit,
  };

  return axios.get(`/api/v1/tasks?${ qs.stringify(params) }`)
    .then(({ data: { data: { items, totalCount } } }: AxiosResponse<IRequestResponse<IPaginatedResponse<ITask>>>) => ({
      items,
      totalCount,
      next: offset + limit,
    }))
    .catch((error: IErrorResponse) => {
      throw error;
    });
};

export const useLoadTasksPage = (
  familyId?: string,
  assignee?: string,
  categoryId?: string,
  status?: string,
  date?: string,
  offset = 0,
  limit  = 5
) => useInfiniteQuery(
  [ TASKS, familyId, assignee, categoryId, status, date ],
  ({ pageParam: offset }) => loadTasks(familyId, assignee, categoryId, status, date, offset, limit),
  {
    enabled          : !!familyId,
    getNextPageParam : (lastPage) => lastPage?.totalCount > lastPage.next && lastPage.next,
  });

export const useFetchTaskDetails = (
  id?: string
) => useCreateQuery<ITask>({
  queryKey : [ TASK, id ],
  apiUrl   : `/api/v1/tasks/${ id }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!id,
  },
});
