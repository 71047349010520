import React, { FC }  from 'react';
import { datePassed } from '../../../../shared/utils/commonFunctions';

interface IDocumentDetailsProps {
  date?: string;
}

export const EventDate: FC<IDocumentDetailsProps> = ({ date }) => (
  <span>
    { datePassed(date) }
  </span>
);
