import { IconButton, Paper, Typography } from '@material-ui/core';
import { Close }                         from '@material-ui/icons';
import styled, { css }                   from 'styled-components';
import { TextBrandColor }                from '../../../shared/styledComponents';
import { RecordMessage }                 from '../../Activity/ActivityRecordPreview';

export const SummaryListWrapper = styled(Paper)<{ height?: string }>`&& {
  display: flex;
  width: 100%;
  height: ${ ({ height }) => height || '460px' };
  max-height: 100%;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.06);

  .MuiDivider-root {
    margin: 0;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
       margin: 0;
    }
  }
}`;

export const SummaryListTypography = styled(Typography)`${ ({ theme: { breakpoints } }) => css`&& {
  color: #2c3f69;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4px;
  padding: 15px 18px;

  ${ breakpoints.up('sm') } {
    font-size: 20px;
    font-weight: 500;
    padding: 21px 28px;
  }
}` }`;

export const ViewAll = styled(SummaryListTypography)`
  && {
    font-size: 16px;
    color: #5274be;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    font-family: Lato;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      font-size: 18px;
    }
  }

  &:hover {
    opacity: .8;
  }
`;

export const SummaryListCount = styled.span`
  color: #5071bb;
`;

export const AddEntity = styled(IconButton)`
  width: 28px;
  height: 28px;
  color: #5071bb;
  border: 2px solid #5071bb;
  margin-top: 0;
`;

export const SummaryHeader = styled(SummaryListTypography)`
  display: flex;
  justify-content: space-between;
`;

export const SummaryPageWrapper = styled(Paper)`
  width: 100%;
  padding: 22px;
  margin-top: 17px;
  font-size: 14px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 32px;
  }
`;

export const IconRemove = styled(Close)`&& {
  color: #fff;
  font-size: 32px;
  background-color: #979797;
  font-size: 18px;
  padding: 3px;
  border-radius: 100%;
  cursor: pointer;
}`;

export const DetailsPageFooter = styled.div<{ pt?: string }>`
  font-size: 14px;
  color: #272932;
  line-height: 22px;
  padding-top: ${({ pt }) => pt || '20px'};
`;

export const DetailsPageDescription = styled.div`
  margin-bottom: 35px;
  word-break: break-all;
`;

export const DetailsPageSpacedLabel = styled(TextBrandColor)`
  margin-right: 21px;
  font-weight: 600;
`;

export const DetailsPageCreatedDate = styled.div`
  margin-left: 10px;;
`;

export const ActivityPageHeader = styled(Typography)`${ ({ theme: { breakpoints } }) => css`&& {
  font-size: 20px;
  font-family: 'Jost';
  padding: 0 10px 0;
  margin-bottom: 24px;
  color: #2c3f69;
  display: none;
  font-weight: 500;

  ${ breakpoints.up('md') } {
    display: block;
  }
}` }`;

export const EntityDetailsHeader = styled.div`&& {
  display: flex;
  margin: -10px -11px 0 -15px;

  ${ RecordMessage } {
    color: #2c3f69;
    margin-bottom: 0;
    padding-left: 5px;
  }
}`;
