import * as Yup          from 'yup';
import { rules }         from '../../../../Auth/shared/rules';
import { fullNameRegex } from '../../../../Auth/SignUp/validation';

interface IInviteFormRequestCommon {
  userType: string;
  familyRole: string;
  joinFamily: boolean;
  familyId: string;
  taggedItemsOption: string;
  chatAccess: boolean;
  editAboutMe: boolean;
  geoLocationAccess: string[];
  aboutMeAccess: string[];
}

export interface IInviteMemberRequest extends IInviteFormRequestCommon {
  email: string;
  firstName: string;
  lastName: string;
}

export interface IInviteMemberForm extends IInviteFormRequestCommon {
  email?: string;
  dateOfBirth: string;
  fullName: string;
}

export const createAccountValidationSchema = (byInvite?: boolean) => Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .nullable()
    .required('Full Name is required')
    .matches(fullNameRegex, { message: 'Please provide your First and Last name' }),
  userType          : Yup.string().nullable().required(),
  familyRole        : Yup.string().nullable().required(),
  joinFamily        : Yup.boolean().nullable(),
  taggedItemsOption : Yup.string().nullable(),
  chatAccess        : Yup.boolean().nullable(),
  editAboutMe       : Yup.boolean().nullable(),
  geoLocationAccess : Yup.array().of(Yup.string()).nullable(),
  aboutMeAccess     : Yup.array().of(Yup.string()).nullable(),
  ...(byInvite ? { email: rules.email } : { dateOfBirth: rules.dateOfBirth }),
});
