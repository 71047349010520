import { Grid }  from '@material-ui/core';
import styled    from 'styled-components';
import { theme } from '../../../styles';

export const PageBody = styled.div`&& {
  padding: 24px 16px 45px;
  margin: 0 -16px -45px;
  background-color: ${ theme.colors.lightGrey };
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 32px 32px 45px;
    margin: 0px -32px -45px;
  }
}`;

export const FullWidthGrid = styled(Grid)`
  margin: 0 -16px;
  width: calc(100% + 32px);
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 0 -32px;
    width: calc(100% + 64px);
  }
}`;
