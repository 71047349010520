import { Button, IconButton, Popover, PopoverActions }       from '@material-ui/core';
import { Form, Formik, FormikProps }                         from 'formik';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled                                                from 'styled-components';
import { ReactComponent as IconRepeat }                      from '../../../../images/icons/icon-repeat.svg';
import { ERecurrence, IRepeatOptions }                       from '../modals/shared';
import { RepeatOptionsForm }                                 from './RepeatOptionsForm';
import { repeatOptionsValidationSchema }                     from './validations';

const Content = styled.div`
  padding: 20px 15px;
  width: 310px;
`;

const TriggerButton = styled(IconButton)`&& {
  width: 40px;
  height: 40px;
  background: #fafafa;
  border-radius: 9px;
}`;

const RepeatFormFooter = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

export const RepeatOptionsLabel = styled.div`
  margin-bottom: 15px;
`;

interface IRepeatOptionsProps {
  values?: IRepeatOptions;
  disabled?: boolean;

  onChange?(values: IRepeatOptions): void;
}

export const RepeatOptions: FC<IRepeatOptionsProps> = ({ values, disabled, onChange }) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
  const formikRef = useRef<FormikProps<IRepeatOptions>>(null);
  const popoverRefEl = useRef<HTMLDivElement>(null);
  const popoverActionRef = React.useRef<PopoverActions>(null);

  const initialValues: IRepeatOptions = {
    recurs          : values?.recurs === undefined ? ERecurrence.Daily : values?.recurs,
    recursInterval  : values?.recursInterval,
    recursDay       : values?.recursDay || null,
    recursOrdinal   : values?.recursOrdinal,
    recursWeekDays  : values?.recursWeekDays,
    recursCount     : values?.recursCount || null,
    recursStartDate : values?.recursStartDate || '',
    recursEndDate   : values?.recursEndDate || '',
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    formikRef?.current?.resetForm();
  };

  const onSubmit = (values: IRepeatOptions) => {
    handleClose();
    onChange && onChange(values);
  };

  const open = Boolean(anchorEl);

  const isApplyButtonDisabled = (form: FormikProps<IRepeatOptions>) => form?.values?.recurs === ERecurrence.Weekly && !form?.values?.recursWeekDays?.filter(i => i.length > 1)?.length;

  const setPopoverHeight = useCallback(() => {
    setTimeout(() => {
      const popoverContent = popoverRefEl.current?.getElementsByClassName('MuiPaper-root')[0] as HTMLDivElement;
      const popoverRect = popoverContent.getBoundingClientRect();
      const popoverBottomPosition = popoverRect.y + popoverRect.height + 20;
      if (popoverBottomPosition > window.innerHeight) {
        popoverContent.style.top = `${popoverRect.top - (popoverBottomPosition - window.innerHeight)}px`;
      } else if (popoverActionRef.current) {
        popoverActionRef.current.updatePosition();
      }
    });
  }, []);

  const FormContent = (form: FormikProps<IRepeatOptions>) => useMemo(() => (
    <Form onSubmit={form.handleSubmit}>
      <Content>
        <RepeatOptionsForm form={form} recursChange={setPopoverHeight} />

        <RepeatFormFooter>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleReset}
          >
            Clear
          </Button>

          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            disabled={isApplyButtonDisabled(form)}
          >
            Apply
          </Button>
        </RepeatFormFooter>
      </Content>
    </Form>
  ), [ values?.recurs, values?.recursEndDate, values?.recursCount, values?.recursWeekDays, form ]);

  return (
    <>
      <TriggerButton
        color="inherit"
        disabled={disabled}
        onClick={handleClick}
      >
        <IconRepeat />
      </TriggerButton>

      <Popover
        ref={popoverRefEl}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical   : 'top',
          horizontal : 'center',
        }}
        transformOrigin={{
          vertical   : 'top',
          horizontal : 'center',
        }}
        action={popoverActionRef}
        onClose={handleClose}
      >
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={repeatOptionsValidationSchema}
          onSubmit={onSubmit}
        >
          { (props) => <FormContent {...props} /> }
        </Formik>
      </Popover>
    </>
  );
};
