import { Fab }           from '@material-ui/core';
import ChatIcon          from '@material-ui/icons/Chat';
import React, { FC }     from 'react';
import styled            from 'styled-components';
import { ChatWindow }    from '../../modules/MessageCenter/components/ChatWindow';
import { IChat }         from '../../modules/MessageCenter/queries';
import { IFamilyMember } from '../../shared/utils/withAuthorization/withAuthorization';

const FixedChatWindow = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 420px;
  max-width: 100%;
  max-height: 95%;
  height: 580px;
  z-index: 9;
`;

const ChatFab = styled(Fab)`
  background: #6f90d8;
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

interface IFloatingChatProps {
  chat?: IChat;
  loading?: boolean;
  familyUser?: IFamilyMember;
  selectedChat: string;
  chatOpened: boolean;

  setChatOpened(isOpened: boolean | null): void;

  setSelectedChat(chatId: string): void;
}

export const FloatingChat: FC<IFloatingChatProps> = ({
  chat,
  loading,
  familyUser,
  selectedChat,
  setSelectedChat,
  chatOpened,
  setChatOpened,
}) => chatOpened ? (
  <FixedChatWindow>
    <ChatWindow
      fixed
      chat={chat}
      loading={loading}
      familyUser={familyUser}
      chatId={selectedChat}
      onMinimize={() => setChatOpened(null)}
      onClose={() => setSelectedChat('')}
    />
  </FixedChatWindow>
) : (
  <ChatFab
    aria-label="open chat"
    onClick={() => setChatOpened(true)}
  >
    <ChatIcon />
  </ChatFab>
);
