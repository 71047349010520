import axios           from 'axios';
import { useMutation } from 'react-query';

const sendConfirmationEmail = (
  code: string,
  email: string,
  isEmailConfirmation: boolean,
  isEmailChangeConfirmation: boolean
) => {
  let api = '/api/v1/sessions';

  if (isEmailConfirmation) {
    api = '/api/v1/verifications/email';
  } else if (isEmailChangeConfirmation) {
    api = '/api/v1/settings/email';
  }

  return axios.put(api, { email, code });
};

export const useSendConfirmationEmail = (
  email: string,
  isEmailConfirmation: boolean,
  isEmailChangeConfirmation: boolean
) =>
  useMutation('confirmCode', (code: string) =>
    sendConfirmationEmail(code, email, isEmailConfirmation, isEmailChangeConfirmation));

export const useResendEmail = (isEmailConfirmation: boolean) => useMutation('resendCode', (email: string) => {
  const api = isEmailConfirmation ? '/api/v1/verifications/email' : '/api/v1/sessions/code';
  return axios.post(api, { email });
});
