import { Position }                               from '@capacitor/geolocation';
import { Button, IconButton, Paper, Typography }  from '@material-ui/core';
import AddIcon                                    from '@material-ui/icons/Add';
import RemoveIcon                                 from '@material-ui/icons/Remove';
import GoogleMapReact, { Coords }                 from 'google-map-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled                                     from 'styled-components';
import { ReactComponent as IconRefresh }          from '../../../../images/icons/icon-refresh.svg';
import { ReactComponent as IconLocation }         from '../../../../images/icons/rounded/icon-location.svg';
import { queryParams }                            from '../../../shared/functions';
import { StyledDivider }                          from '../../../shared/styledComponents';
import { ICheckin }                               from '../queries';
import { CheckinMapMarker }                       from './CheckinMapMarker';

const MapWrapper = styled.div`
  height       : 550px;
  width        : 100%;
  border-radius: 8px;
  box-shadow   : 0 3px 8px 0 rgb(0 0 0 / 6%);
  overflow     : hidden;
  position     : relative;

  .gm-style {
    border-radius: 8px;
    overflow: hidden;
  }

  .gm-style-cc, a > div {
    display: none;
  }

  a {
    visibility: hidden;
  }

  .gm-bundled-control .gmnoprint {
    border-radius: 8px;
    overflow     : hidden;
  }

  .gm-bundled-control {
    top  : 4px;
    right: 45px!important;
  }
`;

const ReloadButton = styled(IconButton)`
  position        : absolute;
  right           : 12px;
  top             : 97px;
  z-index         : 2;
`;

const ZoomControl = styled(Paper)`
  border-radius: 8px;
  padding: 0;
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 15px;
  font-size: 28px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.06);

  .MuiButton-root {
    min-width: 0;
    padding: 6px;
  }

  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;

const MapLabel = styled(Paper)`
  position   : absolute;
  z-index    : 2;
  display    : flex;
  padding    : 8px 16px 8px 8px;
  left       : 20px;
  top        : 20px;
  align-items: flex-end;
  height     : 45px;
  width      : 105px;
  align-items: center;
  cursor     : pointer;

  .MuiTypography-root {
    font-size   : 20px;
    font-weight : 600;
    font-family : Jost;
    padding-left: 10px;
  }

  svg {
    width: 29px;
    height: 29px;
  }
`;

interface ICheckinMapProps {
  checkins?: ICheckin[];
  lastCheckin?: ICheckin;
  position?: Position;
  onReloadCheckins: () => void;
}

const PRIMARY_COORDS = { lat: '33.448376', lng: '-112.074036' }; // Phoenix AZ

export const CheckInMap: FC<ICheckinMapProps> = ({
  checkins = [],
  position,
  lastCheckin,
  onReloadCheckins,
}) => {
  const params = queryParams();
  const mapContainerRef = useRef(null);
  const [ zoom, setZoom ] = useState(4);
  const [ center, setCenter ] = useState<Coords>({} as Coords);
  const selectedUserCheckIns = checkins.find((checkin: ICheckin) => checkin.creator.id === params.id);
  const currLatitude = position?.coords?.latitude && `${ position?.coords?.latitude }`;
  const currLongitude = position?.coords?.longitude && `${ position?.coords?.longitude }`;

  const setMapCenter = () => {
    const lat = parseFloat(selectedUserCheckIns?.latitude || lastCheckin?.latitude || currLatitude || PRIMARY_COORDS.lat);
    const lng = parseFloat(selectedUserCheckIns?.longitude || lastCheckin?.longitude || currLongitude || PRIMARY_COORDS.lng);

    setCenter({ lat, lng });
  };

  const onReloadClick = () => {
    setMapCenter();
    onReloadCheckins();
  };

  useEffect(() => {
    if (center?.lat && `${center?.lat}` !== PRIMARY_COORDS.lat) {
      setZoom(8);
    }

    setMapCenter();
  }, [ selectedUserCheckIns, position, lastCheckin ]);

  const onCenterClick = () => {
    setCenter({
      lat : parseFloat(currLatitude || PRIMARY_COORDS.lat),
      lng : parseFloat(currLongitude || PRIMARY_COORDS.lng),
    });
  };

  return (
    <MapWrapper>
      <MapLabel onClick={onCenterClick}>
        <IconLocation />

        <Typography>
          Map
        </Typography>
      </MapLabel>

      <ZoomControl>
        <Button onClick={() => (zoom < 20) && setZoom(zoom + 1)}>
          <AddIcon
            color="secondary"
            fontSize="large"
          />
        </Button>

        <StyledDivider />

        <Button onClick={() => (zoom > 3) && setZoom(zoom - 1)}>
          <RemoveIcon
            color="secondary"
            fontSize="large"
          />
        </Button>
      </ZoomControl>

      <ReloadButton size="small" onClick={onReloadClick}>
        <IconRefresh />
      </ReloadButton>

      <GoogleMapReact
        ref={mapContainerRef}
        center={center}
        zoom={zoom}
        options={{ disableDefaultUI: true }}
        onZoomAnimationEnd={(value) => setZoom(value)}
        onChange={({ center }) => setCenter(center)}
      >
        { checkins?.map((checkin: ICheckin) =>
          (<CheckinMapMarker
            key={checkin.id}
            checkin={checkin}
            lat={checkin.latitude}
            lng={checkin.longitude}
            mapContainer={mapContainerRef.current}
          />)) }
      </GoogleMapReact>
    </MapWrapper>
  );
};
