import React                     from 'react';
import { Route }                 from 'react-router-dom';
import { EAuthRoutes }           from '../shared/constants/constants';
import { AcceptInvitation }      from './AcceptInvitation/AcceptInvitation';
import { Confirmation }          from './Confirmation/Confirmation';
import { CreateFamily }          from './CreateFamily/CreateFamily';
import { CreatePin }             from './CreatePin/CreatePin';
import { CustomizeProfile }      from './CustomizeProfile';
import { ForgotPassword }        from './ForgotPassword/ForgotPassword';
import { ResetPassword }         from './ResetPassword/ResetPassword';
import { SignInUpPage }          from './shared/styles';
import { SignIn }                from './SignIn/SignIn';
import { SignUp }                from './SignUp/SignUp';
import { SelectFamilyComponent } from './SelectFamilyComponent/SelectFamilyComponent';
import { ChangeSubscription }    from './ChangeSubscription/ChangeSubscription';

const Checkout = React.lazy(() => import('./Checkout/Checkout'));

export const AuthRoutes = () => (
  <SignInUpPage>
    <Route
      path={EAuthRoutes.SignUp}
      component={SignUp}
    />

    <Route
      path={EAuthRoutes.SignIn}
      component={SignIn}
    />

    <Route
      path={EAuthRoutes.ForgotPassword}
      component={ForgotPassword}
    />

    <Route
      path={EAuthRoutes.AcceptInvitation}
      component={AcceptInvitation}
    />

    <Route
      path={EAuthRoutes.ResetPassword}
      component={ResetPassword}
    />

    <Route
      path={EAuthRoutes.CodeConfirmation}
      component={Confirmation}
    />

    <Route
      path={EAuthRoutes.EmailConfirmation}
      component={Confirmation}
    />

    <Route
      path={EAuthRoutes.EmailChangeConfirmation}
      component={Confirmation}
    />

    <Route
      path={EAuthRoutes.CreateFamily}
      component={CreateFamily}
    />

    <Route
      path={EAuthRoutes.Checkout}
      component={Checkout}
    />

    <Route
      path={`${ EAuthRoutes.CustomizeProfile }/:familyId`}
      component={CustomizeProfile}
    />

    <Route
      path={EAuthRoutes.CreatePin}
      component={CreatePin}
    />

    <Route
      path={EAuthRoutes.SelectFamilyComponent}
      component={SelectFamilyComponent}
    />

    <Route
      path={EAuthRoutes.ChangeSubscription}
      component={ChangeSubscription}
    />
  </SignInUpPage>
);
