import { Form, Formik, FormikProps }                         from 'formik';
import React, { FC, useMemo, useRef }                        from 'react';
import { Prompt }                                            from 'react-router-dom';
import styled                                                from 'styled-components';
import { Loader }                                            from '../../../../../shared/enhancers/withLoader';
import { FormSpacing, PageHeaderContainer, TextBrand }       from '../../../../../shared/styledComponents';
import { FormFooterActions }                                 from '../../../shared/FormFooterActions';
import { SettingsHeader }                                    from '../../../shared/styles';
import { IFamilyUserPermissions }                            from '../../ManageFamilyAccount/ManageAccountForm/ManageAccountForm';
import { PermissionsFormFields }                             from './PermissionsFormFields';
import { useFetchUserPermissions, useUpdateUserPermissions } from './queries';
import { userPermissionsValidationSchema }                   from './validation';

interface IMyProfileFormProps {
  memberId?: string;
  familyId?: string;
}

export const SectionLabel = styled(TextBrand)`
  font-size: 18px;
  margin-bottom: 8px;
  min-width: 60px;
  line-height: 1.3em;
  margin-right: 15px;
  font-weight: 500;
  font-family: 'Jost';
`;

export const PermissionsForm: FC<IMyProfileFormProps> = ({
  memberId,
}) => {
  const { data: userPermissions, isFetching } = useFetchUserPermissions(memberId);
  const { mutate: updateUserPermissions, isLoading: updatingUserPermissions } = useUpdateUserPermissions(memberId);

  const formikRef = useRef<FormikProps<IFamilyUserPermissions>>(null);

  const initialValues = useMemo<any>(() => ({ ...userPermissions }), [ userPermissions ]);

  const submitForm = (data: IFamilyUserPermissions) => {
    updateUserPermissions(data, {
      onSuccess: () => {
        formikRef.current?.resetForm({ values: data });
      },
    });
  };

  const FormContent = (props: FormikProps<IFamilyUserPermissions>) => useMemo(() =>
    (<Form onSubmit={props.handleSubmit}>
      <PageHeaderContainer>
        <SettingsHeader variant="h3">Permissions</SettingsHeader>
      </PageHeaderContainer>

      <FormSpacing>
        <PermissionsFormFields byInvite />
      </FormSpacing>

      <FormFooterActions
        dirty={props.dirty}
        isLoading={updatingUserPermissions}
        formikRef={formikRef}
      />
      <Prompt
        when={props.dirty}
        message={`Are you sure you want to leave this page?
    Unsaved changes in the Customer Info would be lost.`}
      />
    </Form>), [ props.dirty, memberId ]);

  return (
    !isFetching ? (
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={userPermissionsValidationSchema}
        onSubmit={(data) => submitForm(data)}
      >
        { (props) => <FormContent {...props} /> }
      </Formik>
    ) : (
      <Loader minHeight="350px" />
    )
  );
};
