import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType, useSnackbar } from 'notistack';
import { useEffect }                                                             from 'react';

const defaultOptions: OptionsObject = {
  preventDuplicate : true,
  anchorOrigin     : {
    vertical   : 'top',
    horizontal : 'right',
  },
  style: {
    whiteSpace: 'pre-line',
  },
};

type TNotistack = any; // ProviderContext | () => null;

export const Notistack: TNotistack = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    () => {
      Notistack.enqueueSnackbar = (
        message: SnackbarMessage,
        variant?: VariantType,
        options?: OptionsObject
      ) => {
        enqueueSnackbar(
          message, {
            ...defaultOptions,
            ...options,
            variant,
          }
        );
      };
    },
    [ enqueueSnackbar ],
  );

  useEffect(
    () => {
      Notistack.closeSnackbar = (key?: SnackbarKey) => {
        closeSnackbar(key);
      };
    },
    [ closeSnackbar ],
  );

  return null;
};
