import React, { FC, useMemo } from 'react';
import styled, { css }        from 'styled-components';

const avatarSize = '38px';

interface IFamilyMemberWrapperProps {
  outlined?: boolean;
  width?: string;
  fontSize?: string;
  image?: string;
  fixed?: boolean;
  color: string;
  pending?: boolean;
}

export const FamilyMemberWrapper = styled.span<IFamilyMemberWrapperProps>`
  ${ ({ fontSize, outlined, width, fixed, color, pending, image, theme: { breakpoints } }: any) => css`
    position: relative;
    width: ${ width || avatarSize };
    height: ${ width || avatarSize };
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    ${ Content } {
      font-size: ${ fontSize || '16px' };
    }
    
    ${ outlined ? `
      box-shadow: inset 0 0 0 3px #fff;
      border-width: 1px;
    ` : '' }
    
    &:last-child {
      margin-right: 0;
    }
    
    ${ breakpoints.up('sm') } {
      width: ${ fixed ? avatarSize : width || '44px' };
      min-width: ${ fixed ? avatarSize : width || '44px' };
      height: ${ fixed ? avatarSize : width || '44px' };
      min-height: ${ fixed ? avatarSize : width || '44px' };
    }
    
    img {
      max-height: 100%;
    }
    
    button {
      padding: 0;
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      background: #6f90d8;
      
      &:hover {
        background: #516aa2;
      }
      
      img {
        height: 100%;
        position: absolute;
      }
    }
    
    ${ Bg } {
      background-color: ${ color };
    }
    
    ${ pending ? `
      ${ Bg } {
        width: calc(100% ${ image ? '- 1px' : '- 6px' });
        height: calc(100% ${ image ? '- 1px' : '- 6px' });
        
        &:after {
          content: '';
          position: absolute;
          left: -4px;
          top: -4px;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          border: 1px solid ${ color };
          border-radius: 100%;
        }
      }
  ` : '' }
` }`;

const Bg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
`;

const Content = styled.div<{ fixed?: boolean }>`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 100%;
  text-transform: uppercase;
`;

const Img = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 100%;
  background-size: cover;
  background-image: url(${ ({ url }) => url });
`;

const Image = ({ url }: { url: string }) => useMemo(() => <Img url={url} />, [ url ]);

interface IFamilyMemberCircleProps {
  color?: string;
  width?: string;
  fontSize?: string;
  image?: string;
  outlined?: boolean;
  initials?: string;
  fixed?: boolean;
  pending?: boolean;
}

export const FamilyMemberCircle: FC<IFamilyMemberCircleProps> = ({
  color,
  width,
  fontSize,
  image,
  outlined,
  initials,
  fixed,
  pending,
}) => (
  <FamilyMemberWrapper
    outlined={outlined}
    width={width}
    fontSize={fontSize}
    image={image}
    fixed={fixed}
    pending={pending}
    color={color || '#bababa'}
  >
    <Bg />

    <Content fixed={fixed}>
      { image ? <Image url={image} /> : <span>{ initials }</span> }
    </Content>
  </FamilyMemberWrapper>
);
