import axios, { AxiosResponse }             from 'axios';
import { useMutation }                      from 'react-query';
import { queryClient }                      from '../../App';
import { IErrorResponse, IRequestResponse } from '../../Auth/shared/interfaces';
import { IChangeEmailRequest }              from '../../modules/Settings/components/ManageFamilyAccount/ChangeEmailForm/validation';
import { IChangePasswordRequest }           from '../../modules/Settings/components/ManageFamilyAccount/ChangePasswordForm/validation';
import { IChangePinRequest }                from '../../modules/Settings/components/ManageFamilyAccount/ChangePinForm/ChangePinForm';
import { IFamilyUserPermissions }           from '../../modules/Settings/components/ManageFamilyAccount/ManageAccountForm/ManageAccountForm';
import { FAMILY_USER_GET }                  from './family';
import { CURRENT_USER_PROFILE }             from './user';

const SET_USER_PIN_CODE = 'SET_USER_PIN_CODE';

export const updateCurrentUserEmail = (data: IChangeEmailRequest) =>
  axios.post('/api/v1/settings/email', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const updateCurrentUserPassword = (data: IChangePasswordRequest) =>
  axios.put('/api/v1/settings/password', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const updateCurrentUserPin = (data: IChangePinRequest) =>
  axios.put('/api/v1/users/security-code/restoration', data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useUpdateCurrentUserPin = () => useMutation(
  SET_USER_PIN_CODE,
  (data: IChangePinRequest) => updateCurrentUserPin(data),
  {
    onSuccess: () => {
      queryClient.invalidateQueries(FAMILY_USER_GET);
      queryClient.invalidateQueries([ CURRENT_USER_PROFILE ]);
    },
  }
);

export const updateUserPermissions = (userId: string, data: IFamilyUserPermissions) =>
  axios.put(`/api/v1/family-users-permissions/${ userId }`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IFamilyUserPermissions>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });
