import styled from 'styled-components';

export const FileSpecificationMessage = styled.div<{ error?: boolean }>`&& {
  color: ${ ({ error }) => error ? '#e42e2e' : 'inherit' };
  width: 190px;
  max-width: 100%;
  margin-top: 15px;
  line-height: 1.3;
  font-size: 13px;
  text-align: center;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: auto;
  }
}`;
