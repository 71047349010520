import * as Yup                 from 'yup';
import { IUploadImageResponse } from '../../../../../Auth/shared/interfaces';
import { fullNameRegex }        from '../../../../../Auth/SignUp/validation';
import { TFamilyAccountType }   from '../../../../../shared/utils/withAuthorization/withAuthorization';

export interface IMyProfileRequest {
  avatarId?: string;
  color: string;
  firstName: string;
  lastName: string;
  gender?: string;
  dateOfBirth: string;
  country: string;
}

export interface IMyProfileForm extends IMyProfileRequest {
  fullName: string;
}

export interface IInitialValues {
  fullName?: string;
  avatarId?: string;
  id?: string;
  familyUserId?: string;
  registeredAt?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  country?: string;
  timeZoneOffset?: number;
  email?: string;
  avatar?: IUploadImageResponse;
  color?: string;
  gender?: number;
  firstRetrieve?: boolean;
  accountType?: TFamilyAccountType;
  hasPin?: boolean;
}

export interface IUserProfile {
  id: string;
  familyUserId: string;
  registeredAt: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  country: string;
  timeZoneOffset: number;
  email: string;
  avatar: IUploadImageResponse;
  color: string;
  gender: number;
  firstRetrieve: boolean;
  accountType: TFamilyAccountType;
  hasPin: boolean;
  isAllowedForStatistics?: boolean;
}

const min = '1900-01-01';

export const myProfileValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .required('Full Name is required')
    .matches(fullNameRegex, { message: 'Please provide your First and Last name' }),
  dateOfBirth: Yup.date()
    .typeError('Please enter a valid Date of Birth')
    .required('Please enter your Date of Birth')
    .min(min, `Date of Birth should be later than ${min}`)
    .max(new Date(), 'Date of Birth should be earlier than today'),
  color: Yup.string()
    .required('Color is required'),
  gender: Yup.string()
    .nullable()
    .required('Gender is required'),
});
