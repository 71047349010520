import { FastField, FieldProps, getIn }    from 'formik';
import React, { ChangeEvent, useCallback } from 'react';
import { ISelectProps, Select }            from '../select/Select/Select';
import { TextInputField }                  from './TextInputField';

export interface ISelectFieldProps
  extends FieldProps, ISelectProps<any> {
}

export const SelectField = ({ field, form, disabled, ...props }: ISelectFieldProps) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const options = [ 'Select', ...props.options ];

  const handleChange = useCallback(
    (value: unknown, event?: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      field.onChange(event);
      props.onChange?.(value);
    },
    [ field, props ]
  );

  return (
    <React.Fragment>
      {
        disabled ? (
          <FastField
            {...field}
            {...props}
            component={TextInputField}
          />
        ) : (
          <Select
            {...field}
            {...props}
            options={options}
            error={touched && !!error}
            helperText={(touched && error) || ''}
            onChange={handleChange}
          />
        )
      }
    </React.Fragment>
  );
};
