import { ListItemAvatar }                             from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory }                                 from 'react-router-dom';
import { UploadTaskModal }                            from '../../../shared/components/modals/UploadTask/UploadTaskModal';
import { EAppRoutes }                                 from '../../../shared/constants/constants';
import { documentTypes }                              from '../../../shared/documentTypes';
import { shouldDisplayActionsMenu }                   from '../../../shared/functions';
import { generateInitials, generateShortName }        from '../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { NoActivityScreen, NoActivityScreenWrapper }  from '../../Activity/ActivityItems';
import { ActivityRecordPreview }                      from '../../Activity/ActivityRecordPreview';
import { FamilyMemberCircle }                         from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { EntitiesListPreview }                        from '../shared/EntitiesListPreview';
import { TaskAddedBy }                                from './components/TaskAddedBy';
import { TaskMenuItems }                              from './components/TaskMenuItems';
import { useLoadTasksPage }                           from './queries';

interface ITasksListPreviewProps {
  familyId?: string;
  familyMemberId?: string;
}

export const TasksListPreview: FC<ITasksListPreviewProps> = ({ familyId, familyMemberId }) => {
  const [ addTaskModalOpen, setAddTaskModalOpen ] = useState(false);
  const [ showViewAllSection, setShowViewAllSection ] = useState(false);
  const { data: tasks, isFetching } = useLoadTasksPage(familyId, familyMemberId);
  const currentUser = useContext(AuthorizationContext);
  const items = tasks?.pages?.[0]?.items;
  const history = useHistory();

  const onViewAll = () => {
    history.push(`${ EAppRoutes.TasksSummary }${ familyMemberId ? `?id=${ familyMemberId }` : '' }`);
  };

  const viewTask = (taskId?: string) => {
    history.push(`${ EAppRoutes.TasksSummary }/${ taskId }`);
  };

  useEffect(() => {
    setShowViewAllSection(!!(tasks?.pages?.[0]?.totalCount && tasks?.pages?.[0]?.totalCount > 0));
  }, [ tasks?.pages?.[0]?.totalCount ]);

  return (
    <>
      <EntitiesListPreview
        title="Tasks"
        showViewAll={showViewAllSection}
        count={tasks?.pages?.[0]?.totalCount}
        loading={isFetching}
        onViewAll={onViewAll}
        onAdd={() => setAddTaskModalOpen(true)}
      >
        { items?.length ? items?.map((task, index, array) => {
          const shortName = generateShortName(task?.creator?.firstName, task?.creator?.lastName);

          const icon = (
            <ListItemAvatar>
              <FamilyMemberCircle
                color={task?.creator?.color}
                image={task?.creator?.avatar?.compactPath}
                initials={generateInitials(task?.creator?.firstName, task?.creator?.lastName)}
              />
            </ListItemAvatar>
          );

          return (
            <ActivityRecordPreview
              key={index}
              showMenu={shouldDisplayActionsMenu(task, currentUser)}
              icon={icon}
              description={`${ shortName } created task "${ task.title }"`}
              details={<TaskAddedBy task={task} />}
              isLast={index === array.length - 1}
              menuItems={<TaskMenuItems
                task={task}
                familyId={familyId}
              />}
              onClick={() => viewTask(task?.id)}
            />
          );
        }) : (
          <NoActivityScreenWrapper>
            <NoActivityScreen>
              { documentTypes.tasks.icon }
              There are no tasks available.
            </NoActivityScreen>
          </NoActivityScreenWrapper>
        ) }
      </EntitiesListPreview>

      { addTaskModalOpen && (
        <UploadTaskModal
          familyId={familyId}
          open={addTaskModalOpen}
          onClose={() => setAddTaskModalOpen(false)}
        />
      ) }
    </>
  );
};
