import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory }                            from 'react-router-dom';
import styled, { css }                           from 'styled-components';
import { theme }                                 from '../../styles';
import { queryClient }                           from '../App';
import { getToken }                              from '../shared/components/AuthWrapper/queries';
import {
  SELECTED_FAMILY_GET,
  useFetchSelectedFamily,
  useUpdateSelectedFamily,
}                                                from '../shared/components/SelectFamily/queries';
import { EAuthRoutes }                           from '../shared/constants/constants';
import { Loader }                                from '../shared/enhancers/withLoader';
import { debounce }                              from '../shared/functions';
import { FAMILIES_QUERY_KEY, useFetchFamilies }  from '../shared/queries/family';
import { useFetchCurrentUserProfile }            from '../shared/queries/user';
import { Header }                                from './Header';
import { Sidebar }                               from './Sidebar';

const hideSidebarScreenWidth = 1280;
const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  || window.innerWidth <= hideSidebarScreenWidth;

const Wrapper = styled.div`
  flex-grow: 1;
  height: calc(100vh - env(safe-area-inset-top, 0));
`;

const AppFrame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  position: relative;
`;

const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 93px 17px 0;
  background-color: ${ theme.colors.grey };

  ${ ({ isDesktop, leftDrawerOpen, theme }: any) =>
    isDesktop && css`
      transition: ${ theme.transitions.create('all', {
    easing: leftDrawerOpen
      ? theme.transitions.easing.easeOut
      : theme.transitions.easing.sharp,
    duration: leftDrawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }) };

  ${ leftDrawerOpen && `
      margin-left: 0;
    ` };
  ` };

  ${ ({ theme: { breakpoints } }) => `
    ${ breakpoints.up('sm') } {
      padding: 93px 32px 32px;
    } ;
  ` }
` as any;

interface ILayoutProps {
  loading?: boolean;
  children: ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ loading, children }) => {
  const history = useHistory();
  const [ drawerOpened, setDrawerOpened ] = useState(!isMobile());
  const { data: families, isLoading: loadingFamilies } = useFetchFamilies();
  const { data: family, isLoading: loadingSelectedFamily } = useFetchSelectedFamily();
  const { mutate: updateSelectedFamily } = useUpdateSelectedFamily();
  const { data: currentUser } = useFetchCurrentUserProfile(family?.id);

  const prefetchStaleData = async () => {
    // The results of this query will be cached like a normal query
    queryClient.prefetchQuery(SELECTED_FAMILY_GET, useFetchSelectedFamily);
    queryClient.prefetchQuery(FAMILIES_QUERY_KEY, useFetchFamilies);
  };

  useEffect(() => {
    prefetchStaleData();
  }, []);

  useEffect(() => {
    if (families && !family && !loadingFamilies && !loadingSelectedFamily) {
      if (families.length) {
        const [ firstFamily ] = families;
        updateSelectedFamily(firstFamily?.id);
      } else {
        history.push(EAuthRoutes.Checkout);
      }
    }
  }, [ families ]);

  const toggleLeftDrawerState = () => {
    setDrawerOpened(!drawerOpened);
  };

  const handleSidebarCheck = debounce(() => {
    setDrawerOpened(window.innerWidth >= hideSidebarScreenWidth);
  }, 300);

  useEffect(() => {
    if (!isMobile()) {
      handleSidebarCheck();
      window.addEventListener('resize', () => handleSidebarCheck());
    }

    return () => {
      if (!isMobile()) {
        window.removeEventListener('resize', () => handleSidebarCheck());
      }
    };
  }, []);

  return getToken() && (
    <Wrapper>
      <AppFrame>
        <Sidebar
          isMobile={isMobile()}
          isAllowedForStatistics={currentUser?.isAllowedForStatistics}
          sidebarOpen={drawerOpened}
          toggleDrawer={toggleLeftDrawerState}
        />

        <MainContent
          isDesktop={!isMobile()}
          leftDrawerOpen={drawerOpened}
        >
          <Header
            family={family}
            isMobile={isMobile()}
            sidebarOpen={drawerOpened}
            toggleDrawer={toggleLeftDrawerState}
          />

          { loading ? <Loader /> : children }
        </MainContent>
      </AppFrame>
    </Wrapper>
  );
};
