import axios                                         from 'axios';
import { FC, useMemo }                               from 'react';
import { Redirect, Route, Switch }                   from 'react-router-dom';
import { AuthRoutes }                                from './Auth/AuthRoutes';
import { ActivityRoutes }                            from './modules/Activity/ActivityRoutes';
import { AddMembersRoutes }                          from './modules/Dashboard/AddMembers/AddMembersRoutes';
import { DashboardRoutes }                           from './modules/Dashboard/DashboardRoutes';
import { LocateRoutes }                              from './modules/Locate/LocateRoutes';
import { MessageCenter }                             from './modules/MessageCenter/MessageCenter';
import { MyFamilyCoreRoutes }                        from './modules/MyFamilyCore/MyFamilyCoreRoutes';
import { NotificationPreferences }                   from './modules/NotificationPreferences/NotificationPreferences';
import { ScheduleRoutes }                            from './modules/Schedule/ScheduleRoutes';
import { SettingsRoutes }                            from './modules/Settings/SettingsRoutes';
import { TermsAndPrivacyPage }                       from './modules/TermsAndPrivacy/TermsAndPrivacyPage';
import { NotFound }                                  from './shared/components/NotFound';
import { useFetchSelectedFamily }                    from './shared/components/SelectFamily/queries';
import { EAppRoutes, EAuthRoutes, familyCoreConfig } from './shared/constants/constants';
import { withQueryParams }                           from './shared/functions';
import { ITokens }                                   from './shared/interfaces/auth';
import { useFetchFamilyUser }                        from './shared/queries/family';
import { AuthorizationProvider }                     from './shared/utils/withAuthorization/authorizationContext';
import { AdminStatistics }                           from './shared/components/AdminStatistics/AdminStatistics';
import { getAuthToken }                              from './utils/authHelpers';

const authToken: ITokens = getAuthToken();

export const AppRoutes: FC = () => {
  const loggedIn = !!axios.defaults.headers.common.authorization;
  const family = useFetchSelectedFamily({ enabled: loggedIn });
  const userProfile = useFetchFamilyUser(family?.data?.id, { enabled: loggedIn && !!family?.data?.id }) as any;
  const baseRoute = useMemo(() => {
    if (!authToken?.accessToken) {
      return EAuthRoutes.SignIn;
    }

    return EAuthRoutes.SelectFamilyComponent;
  }, [family]);

  return (
    <AuthorizationProvider value={userProfile}>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Redirect to={withQueryParams(baseRoute)} />}
        />

        <Route
          path={EAppRoutes.Terms}
          component={TermsAndPrivacyPage}
        />

        <Route
          path={EAuthRoutes.Auth}
          component={AuthRoutes}
        />

        <Route
          path={EAppRoutes.Settings}
          component={SettingsRoutes}
        />

        <Route
          path={EAppRoutes.Dashboard}
          component={DashboardRoutes}
        />

        <Route
          path={EAppRoutes.Activity}
          component={ActivityRoutes}
        />

        <Route
          path={EAppRoutes.Schedule}
          component={ScheduleRoutes}
        />

        <Route
          path={EAppRoutes.Locate}
          component={LocateRoutes}
        />

        { familyCoreConfig.isChatEnabled && (
          <Route
            path={EAppRoutes.MessageCenter}
            component={MessageCenter}
          />
        ) }

        <Route
          path={EAppRoutes.MyFamilyCore}
          component={MyFamilyCoreRoutes}
        />

        <Route
          path={EAppRoutes.AddMembers}
          component={AddMembersRoutes}
        />

        <Route
          path={EAppRoutes.NotificationPreferences}
          component={NotificationPreferences}
        />

        <Route
          path={EAppRoutes.AdminStatistics}
          component={AdminStatistics}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </AuthorizationProvider>
  );
};
