/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ReportsApi {
  requestString : string;
  typeReport    : string;
  fileName      : string;
  authToken     : string | null;
  isProdVersion : boolean;
  setIsLoading  : (arg: boolean) => void;
  setIsSuccess  : (arg: boolean) => void;
  setIsError    : (arg: boolean) => void;
}

const BASE_REPORT_API = 'https://familycore-qa-app-service-api.azurewebsites.net/api/v1/Statistics/';
const BASE_PROD_REPORT_API = 'https://familycore-prod-app-service-api.azurewebsites.net/api/v1/Statistics/';

const getReport = async ({
  requestString,
  typeReport,
  fileName,
  authToken,
  isProdVersion,
  setIsLoading,
  setIsSuccess,
  setIsError,
}: ReportsApi) => {
  const BASE_REPORT = isProdVersion ? BASE_PROD_REPORT_API : BASE_REPORT_API;
  setIsLoading(true);
  setIsSuccess(false);
  setIsError(false);

  try {
    const response = await fetch(`${BASE_REPORT}${typeReport}?${requestString}`, {
      method  : 'GET',
      cache   : 'no-cache',
      headers : {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      const blob = new Blob([await response.blob()], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      const file = URL.createObjectURL(blob);
      link.href = file;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(file);
      setIsSuccess(true);
    } else {
      throw new Error('Something happened, check the filter or contact the administrator');
    }
  } catch (error) {
    setIsError(true);
    console.error('Error: ', error);
  } finally {
    setIsLoading(false);
  }
};

function formatDateReports(dateString: any) {
  const date = new Date(dateString);
  return date.toISOString().replace('Z', '');
}

export { getReport };
