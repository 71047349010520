import * as Yup from 'yup';

export interface IAboutUserForm {
  dateOfBirth: string;
  gender: string;
  nickname: string;
  middleName: string;
  contactEmail?: string;
  phoneNumber: string;
  address: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  height: string;
  weight: string;
  hairColor: string;
  eyeColor: string;
  shirt: string;
  pants: string;
  dress: string;
  bra: string;
  shoes: string;
  sizeNotes: string;
  schoolName: string;
  schoolAddress: string;
  schoolAddress2: string;
  schoolCity: string;
  schoolState: string;
  schoolZip: string;
  schoolLevel: string;
  bloodType: string;
  allergies: string;
  medicalNote: string;
  citizenship: string;
  favoriteFoods: string;
  favoriteColors: string;
  favoriteActivities: string;
  favoriteNote: string;
  dislikeFoods: string;
  dislikeNote: string;
  wishlist: string;
  adminNote: string;
}

const genericRule = Yup.string()
  .trim()
  .nullable();

export const aboutMeValidationSchema = Yup.object().shape({
  dateOfBirth        : genericRule,
  gender             : genericRule,
  nickname           : genericRule,
  middleName         : genericRule,
  contactEmail       : Yup.string().nullable().email(),
  phoneNumber        : Yup.string().nullable().phone(),
  address            : genericRule,
  address2           : genericRule,
  country            : genericRule,
  city               : genericRule,
  state              : genericRule,
  zip                : Yup.string().nullable().zipCode(),
  height             : genericRule,
  weight             : genericRule,
  hairColor          : genericRule,
  eyeColor           : genericRule,
  shirt              : genericRule,
  pants              : genericRule,
  dress              : genericRule,
  bra                : genericRule,
  shoes              : genericRule,
  sizeNotes          : genericRule,
  schoolName         : genericRule,
  schoolAddress      : genericRule,
  schoolAddress2     : genericRule,
  schoolCity         : genericRule,
  schoolState        : genericRule,
  schoolZip          : Yup.string().nullable().zipCode(),
  schoolLevel        : genericRule,
  bloodType          : genericRule,
  allergies          : genericRule,
  medicalNote        : genericRule,
  citizenship        : genericRule,
  favoriteFoods      : genericRule,
  favoriteColors     : genericRule,
  favoriteActivities : genericRule,
  favoriteNote       : genericRule,
  dislikeFoods       : genericRule,
  dislikeNote        : genericRule,
  wishlist           : genericRule,
  adminNote          : genericRule,
});
