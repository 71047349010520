import * as Yup                 from 'yup';
import { IUploadImageResponse } from '../../../../Auth/shared/interfaces';
import { IRepeatOptions }       from '../shared';

export interface IUploadTask extends IRepeatOptions {
  id?: string;
  title: string;
  assigned?: string[];
  tagged: string[];
  description: string;
  familyId: string;
  categoryId: string;
  assignedPermission: string;
  image?: IUploadImageResponse;
}

export const addTaskFormSchema = Yup.object().shape({
  title              : Yup.string().required('Task Title is required'),
  assigned           : Yup.array().min(1, 'Task has to be assigned').of(Yup.string()).required('Task has to be assigned'),
  description        : Yup.string().nullable(),
  tagged             : Yup.array().of(Yup.string()).required('Tagged Member is required'),
  categoryId         : Yup.string().required('Category is required'),
  recursStartDate    : Yup.string().required('Due date is required'),
  assignedPermission : Yup.string().required('Assigned permission is required'),
});
