import React, { FC }              from 'react';
import { PageHeaderContainer }    from '../../../../../shared/styledComponents';
import { IFamilyMember }          from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { SettingsHeader }         from '../../../shared/styles';
import { MentionedEntities }      from '../MentionedEntities/MentionedEntities';
import { EFamilyItemType }        from '../MentionedEntities/shared';
import { useLoadTaggedItemsPage } from './queries';

interface IMyProfileFormProps {
  familyId?: string;
  memberId?: string;
  familyMembers?: IFamilyMember[];
}

export const TaggedItems: FC<IMyProfileFormProps> = ({
  familyId,
  memberId,
  familyMembers,
}) => {
  const { data: documents, isFetching: loadingDocuments } = useLoadTaggedItemsPage(memberId, EFamilyItemType.Document);
  const { data: events, isFetching: loadingEvents } = useLoadTaggedItemsPage(memberId, EFamilyItemType.Event);
  const { data: contacts, isFetching: loadingContacts } = useLoadTaggedItemsPage(memberId, EFamilyItemType.Contact);
  const { data: tasks, isFetching: loadingTasks } = useLoadTaggedItemsPage(memberId, EFamilyItemType.Task);

  return (
    <div>
      <PageHeaderContainer>
        <SettingsHeader variant="h3">Tagged Items </SettingsHeader>
      </PageHeaderContainer>

      <MentionedEntities
        documents={documents}
        loadingDocuments={loadingDocuments}
        events={events}
        loadingEvents={loadingEvents}
        contacts={contacts}
        loadingContacts={loadingContacts}
        tasks={tasks}
        loadingTasks={loadingTasks}
        familyId={familyId}
        familyMembers={familyMembers}
      />
    </div>
  );
};
