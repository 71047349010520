import { Box, Grid }                      from '@material-ui/core';
import {
  FastField,
  FormikProps,
}                                         from 'formik';
import React, {
  FC,
  RefObject,
  useMemo,
}                                         from 'react';
import { ReactComponent as IconDocument } from '../../../../../images/icons/icon-document.svg';
import { documentAccessLevels }           from '../../../constants/constants';
import { FamilyMembersSelector }          from '../../../formFields/FamilyMembersSelector';
import { SelectField }                    from '../../../formFields/SelectField';
import { TextInputField }                 from '../../../formFields/TextInputField';
import { WithCategorySelection }          from '../../../hocs/withCategorySelection';
import { useFetchCategories }             from '../../../queries/categories';
import { StyledDivider }                  from '../../../styledComponents';
import { DocumentPicker }                 from '../../documents/DocumentPicker';
import {
  UploadEntityFormDropdownField,
  UploadEntityFormLabel,
  UploadEntityFormRow,
  UploadEntityFormSpacing,
}                                         from '../../documents/styles';
import { GridDivider }                    from '../../layout/GridDivider';
import { ModalFooterActions }             from '../shared/ModalFooterActions';
import { IUploadDocument }                from './validation';

interface IAddDocumentModalFormProps extends FormikProps<IUploadDocument> {
  familyId?: string;
  loading: boolean;
  files: File[] | null;
  formikRef: RefObject<FormikProps<IUploadDocument>>;

  onChange(files: File[]): void;
}

export const UploadDocumentModalForm: FC<IAddDocumentModalFormProps> = ({
  familyId,
  values,
  setFieldValue,
  loading,
  files,
  formikRef,
  onChange,
}) => {
  const { data: categories, isLoading: loadingCategories } = useFetchCategories(familyId);

  const Form = WithCategorySelection(({
    categoriesDropdown,
    subCategoriesDropdown,
  }) => (
    <UploadEntityFormSpacing>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <FastField
            label="Document Name"
            name={'name'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />

          <GridDivider margin="20px 0 0" />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Category</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              { categoriesDropdown }
            </UploadEntityFormDropdownField>

            <UploadEntityFormDropdownField>
              { subCategoriesDropdown }
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Assign</UploadEntityFormLabel>

            <FamilyMembersSelector
              hideAllSelection
              multiple
              name="assigned"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel width="135px">Assignee Permissions</UploadEntityFormLabel>

            <UploadEntityFormDropdownField>
              <FastField
                name={'assignedPermission'}
                options={documentAccessLevels}
                component={SelectField}
              />
            </UploadEntityFormDropdownField>
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormRow>
            <UploadEntityFormLabel>Tag</UploadEntityFormLabel>

            <FamilyMembersSelector
              hideAllSelection
              multiple
              name="tagged"
            />
          </UploadEntityFormRow>

          <StyledDivider
            mt={20}
            mb={0}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FastField
            multiline
            fullWidth
            rows={4}
            label={<>Description <i>Optional</i></>}
            name={'description'}
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />
        </Grid>

        <GridDivider margin="-5px 0" />

        <Grid
          item
          xs={12}
        >
          <UploadEntityFormLabel>Attach File <i>Optional</i></UploadEntityFormLabel>

          <Box height={10} />

          <DocumentPicker
            multiple
            icon={<IconDocument />}
            files={files as File[]}
            onChange={onChange}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ModalFooterActions
            formikRef={formikRef}
            disabled={loading || !files}
          >
            { loading ? 'Uploading' : values?.id ? 'Edit' : 'Add' } Document
          </ModalFooterActions>
        </Grid>
      </Grid>
    </UploadEntityFormSpacing>
  ));

  return useMemo(() => (
    <Form
      values={values}
      familyId={familyId}
      categories={categories}
      loading={loadingCategories}
      setFieldValue={setFieldValue}
    />
  ), [ loading, files, categories, values?.categoryId, values?.subCategoryId ]);
};
