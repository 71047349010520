import { Paper }                                     from '@material-ui/core';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useHistory }                                from 'react-router-dom';
import styled                                        from 'styled-components';
import { Layout }                                    from '../../../Layout/Layout';
import { NavigateBack }                              from '../../../shared/components/NavigateBack';
import { useFetchSelectedFamily }                    from '../../../shared/components/SelectFamily/queries';
import { EAppRoutes }                                from '../../../shared/constants/constants';
import { Loader }                                    from '../../../shared/enhancers/withLoader';
import { isAdminRoles, queryParams }                 from '../../../shared/functions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { EFamilyAccountType }                        from '../../../shared/utils/withAuthorization/withAuthorization';
import { useFetchAboutUser, useFetchUserInfo }       from '../../Settings/components/ManageUsers/About/queries';
import { AboutMeForm }                               from '../../Settings/components/Profile/AboutMeForm/AboutMeForm';
import { SettingsPageWithAvatar }                    from '../../Settings/shared/SettingsPageWithAvatar';
import { ContentWrapper }                            from '../../Settings/shared/SettingsPageWrapper';
import { SummaryPageHead }                           from '../shared/SummaryPageHead';

const Wrapper = styled.div`&& {
  width: 100%;
  
  ${ ContentWrapper } {
    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      padding-right: 0;
    }
  }
}`;

export const AboutMeFormWrapper = styled(Paper)`
  width: 100%;
`;

export const AboutMemberDetailed: FC = () => {
  const params = queryParams();
  const history = useHistory();
  const userProfile = useContext(AuthorizationContext);
  const isAdminRole = isAdminRoles(userProfile?.data?.accountType);

  const familyMemberId = params?.id as string;
  const { data: family } = useFetchSelectedFamily();

  const { data: memberInfo, isLoading: loadingMemberInfo } = useFetchUserInfo(familyMemberId, family?.id, {
    enabled: isAdminRole,
  });

  const { data: aboutMember, isLoading: loadingAboutMember } = useFetchAboutUser(familyMemberId, family?.id, {
    enabled: isAdminRole,
  });

  useEffect(() => {
    if (userProfile?.data?.accountType === EFamilyAccountType.Member) {
      history.push(EAppRoutes.MyFamilyCore);
    }
  }, [ userProfile ]);

  const Page = (
    <Wrapper>
      <NavigateBack
        title="My Family Core"
        to={EAppRoutes.MyFamilyCore}
      />

      <SummaryPageHead
        hideAllSelection
        needFiltration
        margin="0 0 -30px"
        title="About Me"
      />

      { loadingAboutMember || loadingMemberInfo ? <Loader minHeight="200px" /> : (
        <SettingsPageWithAvatar
          clear
          familyMember={memberInfo}
        >
          <AboutMeFormWrapper>
            <AboutMeForm
              isDisabled
              data={aboutMember}
              isLoading={loadingAboutMember}
            />
          </AboutMeFormWrapper>
        </SettingsPageWithAvatar>
      ) }
    </Wrapper>
  );

  return useMemo(() =>
    (<Layout>
      { Page }
    </Layout>), [ loadingAboutMember, loadingMemberInfo, window.location.href ]
  );
};
