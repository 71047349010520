import { Typography }                                     from '@material-ui/core';
import axios, { AxiosResponse }                           from 'axios';
import React, { ComponentType, FC, useState }             from 'react';
import { useMutation }                                    from 'react-query';
import styled                                             from 'styled-components';
import { FileSpecificationMessage }                       from '../../../../styles/components';
import { AvatarSectionWrapper }                           from '../../../Auth/CustomizeProfile';
import { IRequestResponse, IUploadImageResponse }         from '../../../Auth/shared/interfaces';
import { AvatarSection, AvatarWrapper }                   from '../../../shared/components/AvatarSection';
import { fileTypeMessage }                                from '../../../shared/constants/constants';
import { memberType }                                     from '../../../shared/functions';
import { generateInitials }                               from '../../../shared/utils/commonFunctions';
import { uploadPhoto }                                    from '../../../shared/utils/fileUpload';
import { IFamilyMember }                                  from '../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMemberWrapper }                            from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';
import { IUserProfile }                                   from '../components/Profile/MyProfileForm/validation';
import { AvatarBadge, CameraIcon, UserDetails, UserRole } from './AvatarPicker';
import { VerifiedIconWrapper }                            from '../../../Auth/shared/styles';
import { useFetchSelectedFamily }                         from '../../../shared/components/SelectFamily/queries';
import { useFetchFamilies }                               from '../../../shared/queries/family';
import { IFamily }                                        from '../../../Auth/shared/interfaces';

const AvatarContainer = styled(AvatarSectionWrapper)`{
  width: auto;
  height: auto;
  padding: 0;
  
  ${ FamilyMemberWrapper } {
    margin: 0;
  } 
  
  ${ AvatarWrapper } {
    width: auto;
  }
}`;

export interface ISettingsPageWithAvatarComponent {
  handleSubmit: () => void;
  avatarPicker: FC<{ color?: string }>;
  userDetails: FC;
}

interface IWithAvatarPickerProps {
  header?: FC;
  currentUser?: IUserProfile | IFamilyMember;

  onSuccess?(data: IUploadImageResponse): void;
}

export const WithAvatarPicker = <P extends IWithAvatarPickerProps>(
  Component: ComponentType<ISettingsPageWithAvatarComponent>
): FC<P> => {
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ uploadPercentage, setUploadPercentage ] = useState(0);
  const [ file, setFile ] = useState<string | Blob>();
  const [ imagePreviewUrl, setImagePreviewUrl ] = useState<string | undefined>();

  const { data: selectedFamily } = useFetchSelectedFamily();
  const { data: families } = useFetchFamilies();

  const matchingFamily = families?.find((family: IFamily) => family.id === selectedFamily?.id);

  const resetImage = () => {
    setFile('');
    setImagePreviewUrl(undefined);
  };

  const uploadPhotoToMemory = (event: any) => {
    uploadPhoto(
      event,
      resetImage,
      setFile,
      setImagePreviewUrl,
      setErrorMessage,
    );
  };

  return function WithAvatar({
    header: Header,
    currentUser,
    onSuccess,
  }) {
    const fullName = `${ currentUser?.firstName } ${ currentUser?.lastName }`;

    const handleSubmit = () => {
      uploadMutation.mutate();
    };

    const uploadMutation = useMutation('uploadAvatar', () => {
      if (file) {
        const formData = new FormData();

        formData.append('File', file);

        return axios.post('/api/v1/images', formData, {
          params: {
            ImageType: 'Avatar',
          },
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percent < 100 ? percent : 0);
          },
        });
      }

      return new Promise((resolve) => resolve({ data: { data: null } })) as any;
    }, {
      onSuccess: ({ data: { data } }: AxiosResponse<IRequestResponse<IUploadImageResponse>>) => {
        onSuccess?.(data);
        setFile('');
      },
    });

    const BadgeElement = ({ color }: { color?: string }) => (
      <AvatarBadge
        color="primary"
        badgeContent={onSuccess && <CameraIcon />}
        aria-controls="user-menu"
        aria-haspopup="true"
      >
        <AvatarContainer>
          <label>
            <AvatarSection
              color={color || currentUser?.color}
              image={imagePreviewUrl || currentUser?.avatar?.compactPath}
              initials={generateInitials(currentUser?.firstName, currentUser?.lastName)}
              uploadPercentage={uploadPercentage}
            />

            { onSuccess && (
              <input
                id="avatar-upload"
                type="file"
                onChange={uploadPhotoToMemory}
              />
            ) }
          </label>
        </AvatarContainer>
      </AvatarBadge>
    );

    const UserDetailsElement = () => (
      <UserDetails>
        <Typography variant="h3">{ fullName }{ matchingFamily?.isActivePayment && <VerifiedIconWrapper />}</Typography>
        <UserRole>{ memberType(currentUser?.accountType) }</UserRole>

        { errorMessage && (
          <FileSpecificationMessage error={!!errorMessage}>
            { errorMessage || fileTypeMessage }
          </FileSpecificationMessage>
        ) }
      </UserDetails>
    );

    return (
      <Component
        handleSubmit={handleSubmit}
        avatarPicker={BadgeElement}
        userDetails={UserDetailsElement}
      />
    );
  };
};
