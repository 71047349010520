import { Grid }                                                  from '@material-ui/core';
import { FastField }                                             from 'formik';
import { FC }                                                    from 'react';
import styled                                                    from 'styled-components';
import { GridDivider }                                           from '../../../../../shared/components/layout/GridDivider';
import { documentAccessLevels, familyCoreConfig }                from '../../../../../shared/constants/constants';
import { FamilyMembersSelector }                                 from '../../../../../shared/formFields/FamilyMembersSelector';
import { SelectField }                                           from '../../../../../shared/formFields/SelectField';
import { SwitchField }                                           from '../../../../../shared/formFields/SwitchField';
import { FlexEndGrid, JustifyGrid, ShiftedFormGroup, TextLabel } from '../../../../../shared/styledComponents';
import { SettingsParagraph }                                     from '../../../shared/styles';
import { SectionLabel }                                          from './PermissionsForm';

const ChatDescription = styled(Grid)`
  margin-top: -20px;
`;

interface IPermissionsFormFields {
  byInvite?: boolean;
}

export const PermissionsFormFields: FC<IPermissionsFormFields> = ({
  byInvite,
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={3}
        >
          { byInvite && (
            <>
              <Grid
                item
                xs={12}
                sm={8}
                lg={10}
              >
                <SectionLabel>Tagged Items</SectionLabel>
                <SettingsParagraph>This controls how this family member engages with your Family Core.</SettingsParagraph>
              </Grid>

              <FlexEndGrid
                item
                xs={12}
                sm={4}
                lg={2}
              >
                <FastField
                  name={'taggedItemsOption'}
                  options={documentAccessLevels}
                  component={SelectField}
                />
              </FlexEndGrid>

              <GridDivider margin="10px 0" />
            </>
          ) }

          { byInvite && familyCoreConfig.isChatEnabled && (
            <>
              <JustifyGrid
                item
                xs={12}
              >
                <SectionLabel>Chat Access</SectionLabel>

                <SwitchField
                  name={'chatAccess'}
                  labelPlacement="start"
                />
              </JustifyGrid>

              <ChatDescription
                item
                xs={10}
              >
                <SettingsParagraph>Allow this family member access to Chat with your Family Core.</SettingsParagraph>
              </ChatDescription>
            </>
          ) }

          { byInvite && familyCoreConfig.isGeolocationEnabled && (
            <>
              <GridDivider margin="10px 0" />

              <Grid
                item
                xs={8}
                lg={10}
              >
                <SectionLabel>Geo Location Access</SectionLabel>
                <SettingsParagraph>Select members that can have access to geo location</SettingsParagraph>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FamilyMembersSelector
                  multiple
                  hideAllSelection
                  name="geoLocationAccess"
                />
              </Grid>

              <GridDivider margin="10px 0" />
            </>
          ) }

          <>
            <Grid
              item
              xs={12}
            >
              <SectionLabel>About Me Access</SectionLabel>
            </Grid>

            <ShiftedFormGroup container>
              <Grid
                container
                spacing={3}
              >
                { byInvite && (
                  <Grid
                    item
                    xs={12}
                  >
                    <>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={8}
                          lg={10}
                        >
                          <TextLabel>Edit</TextLabel>
                          <SettingsParagraph>Enable this user to edit his or her profile details</SettingsParagraph>
                        </Grid>

                        <FlexEndGrid
                          item
                          xs={4}
                          lg={2}
                        >
                          <SwitchField name={'editAboutMe'} />
                        </FlexEndGrid>
                      </Grid>

                      <GridDivider margin={'25px 10px 5px 0'} />
                    </>
                  </Grid>
                ) }

                <Grid
                  item
                  xs={12}
                >
                  <TextLabel>Permissions</TextLabel>
                  <SettingsParagraph>Select the family members that can view user&apos;s About Me
                                   section</SettingsParagraph>
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <FamilyMembersSelector
                    multiple
                    hideAllSelection
                    name="aboutMeAccess"
                  />
                </Grid>
              </Grid>
            </ShiftedFormGroup>
          </>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
