import { Grid }                        from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC }                   from 'react';
import { MuiButton }                   from '../../shared/components/MuiButton';
import { PasswordInputField }          from '../../shared/formFields/PasswordInputField';
import { FormFieldWrapper }            from '../CreateFamily/CreateFamily';
import { AuthField }                   from '../shared/styles';
import { resetPasswordFormSchema }     from './validation';

export interface IResetPasswordForm {
  password: string;
  confirmPassword: string;
}

interface IResetPasswordFormProps {
  handleSubmit(values: IResetPasswordForm, formikActions: FormikHelpers<IResetPasswordForm>): void;
}

export const ResetPasswordForm: FC<IResetPasswordFormProps> = ({ handleSubmit }) => {
  const initialValues: IResetPasswordForm = {
    password        : '',
    confirmPassword : '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={resetPasswordFormSchema}
      onSubmit={handleSubmit}
    >
      { ({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormFieldWrapper centered>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <AuthField
                  autoComplete="off"
                  label="Password"
                  name={'password'}
                  placeholder="At least 8 characters"
                  inputProps={{ maxLength: 50 }}
                  component={PasswordInputField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <AuthField
                  autoComplete="off"
                  label="Confirm Password"
                  name={'confirmPassword'}
                  placeholder="At least 8 characters"
                  inputProps={{ maxLength: 50 }}
                  component={PasswordInputField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <MuiButton
              fullWidth
              type="submit"
              margin="33px 0 0"
              disabled={isSubmitting}
            >
              Save & Sign In
            </MuiButton>
          </FormFieldWrapper>
        </Form>
      ) }
    </Formik>
  );
};
