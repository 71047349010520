import { Box, IconButton }    from '@material-ui/core';
import { Close }              from '@material-ui/icons';
import React, { FC }          from 'react';
import styled                 from 'styled-components';
import { RecordLabel }        from '../../modules/Activity/ActivityRecordPreview';
import { IMessage }           from '../../modules/MessageCenter/queries';
import { EntityRecordAvatar } from '../../modules/MyFamilyCore/shared/EntityRecordAvatar';
import { fullName }           from '../../shared/utils/commonFunctions';

const IconClose = styled(Close)`&& {
  color: #ccc;
  font-size: 20px;
}`;

const CloseButton = styled(IconButton)`&& {
  align-self: flex-start;
  margin: -15px -22px 0 0;
}`;

const MessageNotificationWrapper = styled.div`
  width: 100%;
  width: calc(100vw - 72px);
  display: flex;
  padding: 10px 0;
  left: -15px;
  position: relative;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: 50vw;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: 40vw;
  }
`;

const MessageBody = styled.div`
  cursor: pointer;
  transition: ease .3s opacity;
  width: calc(100% - 22px);

  &:hover {
    opacity: .9;
  }
`;

const MessageNotificationText = styled.div`
  color: #000;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageData = styled.div`
  width: calc(100% - 56px);
`;

interface IMessageNotificationProps {
  message: IMessage;

  onOpen?(): void;
  onClose?(): void;
}

export const MessageNotification: FC<IMessageNotificationProps> = ({ message, onOpen, onClose }) => {
  const recipientFullName = fullName(message?.user?.firstName, message?.user?.lastName);

  return (
    <MessageNotificationWrapper>
      <MessageBody onClick={onOpen}>
        <EntityRecordAvatar member={message?.user} />

        <MessageData>
          <RecordLabel>
            <b>{ recipientFullName }</b>
          </RecordLabel>

          <Box height={5} />

          <MessageNotificationText>
            { message?.text }
          </MessageNotificationText>
        </MessageData>
      </MessageBody>

      <CloseButton
        color="inherit"
        onClick={onClose}
      >
        <IconClose />
      </CloseButton>
    </MessageNotificationWrapper>
  );
};
