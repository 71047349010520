import { fileTypeMessage, fileTypesString } from '../constants/constants';
import { formatBytes }                      from './commonFunctions';

export const uploadPhoto = (
  event: any,
  resetImage: () => void,
  setFile: (file: File) => void,
  setImagePreviewUrl: (url: string) => void,
  setErrorMessage: (message: string) => void,
  uploadCallback?: () => void
) => {
  event?.preventDefault();
  const reader = new FileReader();
  const file = event?.target.files[0];
  const tenMegabytes = 1e+7;

  const allowedFileTypes = [ 'image/jpeg', 'image/png', 'image/gif' ];
  const isFileTypeAllowed = allowedFileTypes.indexOf(file?.type) >= 0;

  if (file?.size >= tenMegabytes) {
    setErrorMessage(`File size is ${
      formatBytes(file?.size) }. It should be ${ !isFileTypeAllowed ? fileTypesString : '' } less than 10MB.`);
    resetImage();
  } else if (!isFileTypeAllowed) {
    setErrorMessage(fileTypeMessage);
    resetImage();
  } else {
    setErrorMessage('');

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result as any);
      uploadCallback?.();
    };
    reader.readAsDataURL(file);
  }
};
