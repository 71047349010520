import { FormikProps }                                    from 'formik';
import React, { FC, useEffect, useState }                 from 'react';
import styled                                             from 'styled-components';
import { ReactComponent as IconRepeat }                   from '../../../../images/icons/icon-repeat.svg';
import { Select }                                         from '../../select/Select/Select';
import { ERecurrence, IRepeatOptions, recurrenceOptions } from '../modals/shared';
import { EndsOn }                                         from './components/EndsOn';
import { MonthlyOrdinalOptions }                          from './components/MonthlyOrdinalOptions';
import { RepeatEvery }                                    from './components/RepeatEvery';
import { SelectWeekDay }                                  from './components/SelectWeekDay';

const ContentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 275px;
  margin-bottom: 20px;
  gap: 40px;
`;

const RepeatLabel = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export interface IRepeatOptionsForm {
  form: FormikProps<IRepeatOptions>;
  recursChange?: () => void;
}

export const RepeatOptionsForm: FC<IRepeatOptionsForm> = ({ form, recursChange }) => {
  const [ recurs, setRecurs ] = useState<string | null>(null);

  useEffect(() => {
    if (form?.values?.recurs) {
      const hasCustomOptions = form?.values?.recursInterval;
      setRecurs(hasCustomOptions ? '' : form?.values?.recurs);
    }
  }, []);

  const onRecursChange = (value: string) => {
    setRecurs(value);
    form?.setFieldValue('recurs', value || ERecurrence.NoRecurrence);
    recursChange && recursChange();
  };

  const displayCustomControls = recurs !== null && !recurs;
  const displayWeekDays = recurs === ERecurrence.Weekly;
  const isMonthlyRecurrence = recurs === ERecurrence.Monthly;
  const displayEndsOn = displayCustomControls || isMonthlyRecurrence || form?.values?.recurs === ERecurrence.Yearly;

  return (
    <>
      <ContentHead>
        <RepeatLabel>
          <IconRepeat />
          Repeat
        </RepeatLabel>

        <Select
          options={recurrenceOptions}
          value={recurs}
          onChange={onRecursChange}
        />
      </ContentHead>

      { displayCustomControls && (
        <RepeatEvery
          form={form}
          recursChange={recursChange}
        />
      ) }

      { isMonthlyRecurrence && (
        <MonthlyOrdinalOptions form={form} />
      ) }

      { displayWeekDays && (
        <SelectWeekDay
          multiple
          name="recursWeekDays"
        />
      ) }

      { displayEndsOn && (
        <EndsOn form={form} />
      ) }
    </>
  );
};
