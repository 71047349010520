import React, { FC }                   from 'react';
import { InfiniteQueryObserverResult } from 'react-query/types/core/types';
import scrollIntoView                  from 'smooth-scroll-into-view-if-needed';
import styled                          from 'styled-components';
import { IPaginatedResponse }          from '../../Auth/shared/interfaces';
import { ViewAll }                     from '../MyFamilyCore/shared/styles';

const Wrapper = styled.div`
  display: flex;
  padding: 10px 0 20px;
  justify-content: center;
`;

interface ILoadMoreFooter {
  loading?: boolean;

  fetchNextPage?(): Promise<InfiniteQueryObserverResult<IPaginatedResponse<any>>>;
}

export const LoadMoreFooter: FC<ILoadMoreFooter> = ({ loading, fetchNextPage }) => {
  const onLoadMore = () => {
    const anchor = document.getElementById('load-more-button');

    if (fetchNextPage) {
      fetchNextPage().then(() => {
        if (anchor) {
          scrollIntoView(anchor, {
            scrollMode : 'if-needed',
            block      : 'center',
            inline     : 'center',
          });
        }
      });
    }
  };

  return (
    <Wrapper>
      <ViewAll
        id="load-more-button"
        variant="h5"
        onClick={onLoadMore}
      >
        { loading ? 'Loading...' : 'Load More' }
      </ViewAll>
    </Wrapper>
  );
};
