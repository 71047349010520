import { ListItemAvatar }     from '@material-ui/core';
import React                  from 'react';
import IconContact            from '../../../../../images/icons/contacts-icon.svg';
import { FamilyMemberCircle } from '../../../Dashboard/components/FamilyMembers/FamilyMemberCircle';

export const ContactIcon = () => (
  <ListItemAvatar>
    <FamilyMemberCircle
      color={'#6f90d9'}
      image={IconContact}
    />
  </ListItemAvatar>
);
