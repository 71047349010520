import axios, { AxiosResponse }                from 'axios';
import { useMutation }                         from 'react-query';
import { queryClient }                         from '../../../../../App';
import { IRequestResponse }                    from '../../../../../Auth/shared/interfaces';
import { Notistack }                           from '../../../../../shared/components/Notistack/Notistack';
import { CURRENT_USER_PROFILE }                from '../../../../../shared/queries/user';
import { TCreateQueryOptions, useCreateQuery } from '../../../../../shared/utils/hooks/useReactQuery';
import { IFamilyMember }                       from '../../../../../shared/utils/withAuthorization/withAuthorization';
import { IAboutUserForm }                      from '../../Profile/AboutMeForm/validation';

export const USER_INFORMATION_GET = 'USER_INFORMATION_GET';
export const ABOUT_FAMILY_MEMBER_GET = 'ABOUT_FAMILY_MEMBER_GET';
export const ABOUT_FAMILY_MEMBER_UPDATE = 'ABOUT_FAMILY_MEMBER_UPDATE';

export const useFetchUserInfo = (
  userId?: string,
  familyId?: string,
  options?: TCreateQueryOptions<IFamilyMember>
) => useCreateQuery<IFamilyMember>({
  queryKey : [ USER_INFORMATION_GET, familyId, userId ],
  apiUrl   : `/api/v1/families/${ familyId }/users/${ userId }`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId && !!userId,
    ...options,
  },
});

export const useFetchAboutUser = (
  userId?: string,
  familyId?: string,
  options?: TCreateQueryOptions<IAboutUserForm>
) => useCreateQuery<IAboutUserForm>({
  queryKey : [ ABOUT_FAMILY_MEMBER_GET, familyId, userId ],
  apiUrl   : `/api/v1/families/${ familyId }/users/${ userId }/about`,
  options  : {
    refetchOnWindowFocus : false,
    enabled              : !!familyId && !!userId,
    ...options,
  },
});

export const updateAboutUserProfile = (
  data: IAboutUserForm,
  userId?: string,
  familyId?: string,
) => {
  if (data?.contactEmail === '') {
    data.contactEmail = undefined; // BE returns error if email is empty string
  }
  return axios.put(`/api/v1/families/${ familyId }/users/${ userId }/about`, data)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<IAboutUserForm>>) => data);
};

export const useUpdateAboutProfile = (
  userId?: string,
  familyId?: string,
) => useMutation(
  ABOUT_FAMILY_MEMBER_UPDATE,
  (data: IAboutUserForm) => updateAboutUserProfile(data, userId, familyId), {
    onSuccess: (data: IAboutUserForm) => {
      queryClient.invalidateQueries([ CURRENT_USER_PROFILE ]);
      queryClient.setQueryData('userAccount', data);
      Notistack.enqueueSnackbar('About was successfully updated.', 'success');
    },
  }
);

