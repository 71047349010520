import * as Yup from 'yup';

export interface IAttachment {
  id: string;
  path: string;
  documentId: string;
  documentSize: number;
}

export interface IUploadDocument {
  id?: string;
  name: string;
  categoryId: string;
  subCategoryId: string;
  familyId: string;
  assignedPermission: string;
  assigned?: string[];
  description: string;
  tagged?: string[] | null;
  attachments?: IAttachment[];
  attachmentsId?: string[];
}

export const addDocumentFormSchema = Yup.object().shape({
  name               : Yup.string().required('Document Name is required'),
  categoryId         : Yup.string().required('Category is required'),
  subCategoryId      : Yup.string().nullable(),
  assigned           : Yup.array().min(1, 'Document has to be assigned').of(Yup.string()).required('Document has to be assigned'),
  assignedPermission : Yup.string().required('Assigned permission is required'),
  tagged             : Yup.array().of(Yup.string()).nullable(),
});
