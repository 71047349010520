import MomentUtils                                  from '@date-io/moment';
import { MuiPickersUtilsProvider }                  from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider }        from '@material-ui/styles';
import axios, { AxiosError }                        from 'axios';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider }         from 'react-query';
import { HashRouter }                               from 'react-router-dom';
import { ThemeProvider }                            from 'styled-components';
import config                                       from '../config';
import { BaseStyles, muiTheme, theme }              from '../styles';
import { AppRoutes }                                from './AppRoutes';
import { updateAuthToken }                          from './shared/components/AuthWrapper/queries';
import { Notistack }                                from './shared/components/Notistack/Notistack';
import { NotistackProvider }                        from './shared/components/Notistack/NotistackProvider';
import { Loader }                                   from './shared/enhancers/withLoader';
import { logout, setAuthorizationToken }            from './shared/utils/setAuthorizationToken';
import { AppUrlListener }                           from './utils/AppUrlListener';

axios.defaults.baseURL = config.apiUrl;

axios.defaults.headers.common = {
  'Cache-Control' : 'no-cache, no-store, must-revalidate',
  'Pragma'        : 'no-cache',
  'Expires'       : '0',
};

let updatingToken = false;
axios.interceptors.response.use(
  undefined,
  (error): Promise<AxiosError> => {
    if (error.response?.status === 401 && !updatingToken) {
      updatingToken = true;
      updateAuthToken()
        .then(() => {
          updatingToken = false;
          queryClient.invalidateQueries();
        })
        .catch(() => {
          updatingToken = false;
          logout();
        });
    }

    return Promise.reject(error);
  }
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry                : 0,
      staleTime            : 0,
      refetchOnWindowFocus : false,
    },
  },
});

export const App: FC = () => {
  const [ isReady, setIsReady ] = useState(false);

  useEffect(
    () => {
      if (localStorage.authToken) {
        setAuthorizationToken(JSON.parse(localStorage.authToken));
      }

      setTimeout(() => {
        setIsReady(true);
      }, 300);
    }, []
  );

  const routerContent = (
    <>
      <AppUrlListener />

      { isReady && <AppRoutes /> }
    </>
  );

  return (
    <Suspense fallback={<Loader absolute />}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={{ ...muiTheme, ...theme }}>
            <QueryClientProvider client={queryClient}>
              <BaseStyles />
              <NotistackProvider>
                <HashRouter>{ routerContent }</HashRouter>
                <Notistack />
              </NotistackProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </Suspense>
  );
};

export default App;
