import { FormikProps }        from 'formik';
import { RefObject }          from 'react';
import * as Yup               from 'yup';
import { Notistack }          from '../../components/Notistack/Notistack';
import { scrollToErrorInput } from '../commonFunctions';

const defaultValidationErrorMessage = 'Form validation failed!';

export const isArray = (v: any) => !!v && v.constructor === Array;
export const isObject = (v: any) => !!v && v.constructor === Object;
export const isEmptyObject = (v: any) => isObject(v) && !Object.keys(v).length;

export const getFormikTouchedObj = (errors: any) => {
  const touched: any = {};

  for (const key in errors) {
    if (isArray(errors[key])) {
      errors[key].forEach((val: any, index: number) => {
        if (index === 0) touched[key] = [];
        touched[key].push(getFormikTouchedObj(val));
      });
    } else if (isObject(errors[key])) {
      touched[key] = getFormikTouchedObj(errors[key]);
    } else {
      touched[key] = true;
    }
  }

  return touched;
};

export const parseYupValidationErrors = (error: any) => {
  const errors = {} as Record<string, string>;

  error.inner.forEach(
    (item: { path: string; message: string }) => {
      errors[item.path] = item.message;
    });

  return errors;
};

interface IReturnProps {
  formSubmitter: (forceSubmit: boolean, message: string, ...formikRefs: RefObject<FormikProps<any>>[]) => Promise<unknown>;
  formValidator: (...formikRefs: RefObject<FormikProps<any>>[]) => Promise<unknown>;
  customValidator: (formikRef: RefObject<FormikProps<any>>, validationSchema: Yup.AnyObjectSchema) => Promise<unknown>;
}

export const useFormikHelpers = (validationErrorMessage?: string): IReturnProps => {
  const errorMessage = validationErrorMessage || defaultValidationErrorMessage;

  const formValidator = (...formikRefs: RefObject<FormikProps<any>>[]) =>
    new Promise<void>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        for (const formikRef of formikRefs) {
          if (!formikRef.current) return;

          const { validateForm, setTouched } = formikRef.current;

          const errors = await validateForm();

          if (errors && Object.keys(errors).length) {
            const touched = getFormikTouchedObj(errors);

            setTouched(touched);

            scrollToErrorInput();
          }
        }

        resolve();
      }
    );

  const formSubmitter = (forceSubmit: boolean, message: string, ...formikRefs: RefObject<FormikProps<any>>[]) =>
    new Promise<void>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve) => {
        for (const formikRef of formikRefs) {
          if (!formikRef.current) continue;

          const { dirty, submitForm } = formikRef.current;

          if (forceSubmit || dirty) {
            await submitForm();
          }
        }

        resolve();
      }
    );

  const customValidator = (formikRef: RefObject<FormikProps<any>>, validationSchema: Yup.AnyObjectSchema) =>
    new Promise<void>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        if (!formikRef.current) return;

        const { values, setErrors, setTouched } = formikRef.current ?? {};

        try {
          await validationSchema.validate(values, { abortEarly: false });
        } catch (error) {
          const errors = parseYupValidationErrors(error);

          if (errors && Object.keys(errors).length) {
            const touched = getFormikTouchedObj(errors);

            setTouched(touched);
            setErrors(errors);

            Notistack.enqueueSnackbar(errorMessage, 'warning');

            scrollToErrorInput();

            return reject(errorMessage);
          }
        }

        resolve();
      }
    );

  return {
    formSubmitter,
    formValidator,
    customValidator,
  };
};
