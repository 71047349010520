import { CircularProgress }         from '@material-ui/core';
import React, { ComponentType, FC } from 'react';
import styled, { css }              from 'styled-components';

export interface IWithLoaderProps {
  loading?: boolean;
}

interface ILoaderWrapperProps {
  absolute?: boolean;
  minHeight?: string;
}

const LoaderWrapper = styled.div<ILoaderWrapperProps>`
  display: flex;
  min-height: ${ ({ minHeight }) => minHeight || 'auto' };
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  
  ${ ({ absolute }) => absolute && css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  ` }
`;

export const Loader: FC<ILoaderWrapperProps> = ({ minHeight, absolute }) => (
  <LoaderWrapper
    absolute={absolute}
    minHeight={minHeight}
  >
    <CircularProgress />
  </LoaderWrapper>
);

export const withLoader = <P extends IWithLoaderProps>(
  Component: ComponentType<IWithLoaderProps>
): FC<P> => function WithLoader({ loading, ...props }: IWithLoaderProps) {
  return loading ? <Loader /> : <Component {...props} />;
};
