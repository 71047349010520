import * as Yup from 'yup';

export const userAccountValidationSchema = Yup.object().shape({
  accountType: Yup.string()
    .trim()
    .nullable()
    .required('Member Type is required'),
  familyRole: Yup.string()
    .trim()
    .nullable()
    .required('Family Role is required'),
});
